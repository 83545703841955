<template>
  <LayoutWrapper
    :is-side-bar-open="isSideBarOpen"
    @update-is-side-bar-open="setSideBarStatus"
  >
    <PMPCanvas v-if="showCanvas" />
    <FormDialog
      v-if="layoutStore.isFormDialogOpen"
      class="prose prose-neutral dark:prose-invert"
    />
    <template #side>
      <div
        class="flex w-full flex-col bg-light-1 shadow dark:bg-dark-1 md:h-screen md:grow md:basis-0 lg:pt-10"
      >
        <div class="flex flex-shrink flex-grow-0 basis-auto flex-col">
          <div class="h-24 flex-col pl-6">
            <div class="h-6 text-sm">
              {{ selectedRoom?.number }} {{ selectedRoom?.description }}
            </div>
            <div class="flex flex-row justify-between">
              <h1 class="mb-0">
                {{ usageType }}
              </h1>

              <OptionsDropdown
                class="mr-6 self-center"
                icon="mdi-dots-vertical"
                direction-x="left"
                data-testid="roomOptionsDropdown"
              >
                <li data-testid="editRoomButton" @click="editRoom">
                  <em class="mdi mdi-pencil mr-2" />Bearbeiten
                </li>
                <li
                  data-testid="deleteRoomButton"
                  :class="[
                    {
                      'cursor-not-allowed text-light-disabled-text hover:bg-transparent dark:text-dark-disabled-text':
                        !isRoomDeletable,
                    },
                  ]"
                  @click="isRoomDeletable && deleteEntity()"
                >
                  <em class="mdi mdi-delete mr-2" />Löschen
                </li>
              </OptionsDropdown>
            </div>
            <BaseChip
              v-if="!selectedRoom?.isAccessible"
              text="Kein Zutritt"
              icon="mdi-cancel"
              outlined
              class="mt-2"
              type="warning"
              data-testid="roomNotAccessibleChip"
            />
          </div>

          <BaseTabs
            :options-list="tabsOptionList"
            :selected-tab="selectedTab"
            :select-tab="selectTab"
          />
        </div>
        <div
          ref="entityList"
          class="mb-5 flex-shrink flex-grow basis-auto overflow-y-auto rounded-xl bg-light-1 px-4 pb-20 dark:bg-dark-1 lg:px-6"
        >
          <DeviceListForm
            v-if="selectedRoom && selectedTab === 'Geräte'"
            :parent-id="selectedRoom.id"
            :is-side-bar-open="isSideBarOpen"
          />

          <PlantListForm
            v-if="selectedTab === 'Anlagen'"
            :parent-id="selectedRoom?.id"
          />

          <RadiatorListForm
            v-if="selectedRoom && selectedTab === 'Heizkörper'"
            :parent-id="selectedRoom.id"
          />
        </div>
      </div>
    </template>
  </LayoutWrapper>
</template>

<script lang="ts">
import PMPCanvas from '@/components/Canvas/PMPCanvas.vue';
import { useLayoutStore } from '@/store/layout/layoutStore';
import { useFormStore } from '@/store/form/formStore';
import FormDialog from '@/components/UI/Modal/FormDialog.vue';
import LayoutWrapper from '@/components/LayoutWrapper.vue';
import DeviceListForm from '@/components/Forms/DeviceListForm.vue';
import { computed, ref } from 'vue';
import { Notification, Room } from '@/models';
import { roomTypeList } from '@/enums/room';
import RadiatorListForm from '@/components/Forms/RadiatorListForm.vue';
import { useEntitiesStore } from '@/store/entities/entitiesStore';
import OptionsDropdown from '@/components/UI/Dropdown/OptionsDropdown.vue';
import BaseTabs from '@/components/UI/Tabs/BaseTabs.vue';
import { useRoomStore } from '@/store/entities/roomStore';
import BaseChip from '@/components/UI/Chip/BaseChip.vue';
import PlantListForm from '@/components/Forms/PlantListFormComponents/PlantListForm.vue';
import { onMounted, provide } from 'vue';
import { useOrderStore } from '@/store/order/orderStore';

export default {
  name: 'MapView',
  components: {
    PlantListForm,
    BaseChip,
    BaseTabs,
    OptionsDropdown,
    RadiatorListForm,
    DeviceListForm,
    LayoutWrapper,
    PMPCanvas,
    FormDialog,
  },
  setup() {
    const layoutStore = useLayoutStore();
    const formStore = useFormStore();
    const selectedRoom = computed(() => {
      if (!layoutStore.currentNode?.id) return undefined;
      return useRoomStore().rooms.get(layoutStore.currentNode.id);
    });

    const isScrolled = ref(false);
    provide('isScrolled', isScrolled);
    const entityList = ref<HTMLElement | undefined>(undefined);
    const isSideBarOpen = ref(false);

    let tabsOptionList = ['Geräte', 'Anlagen', 'Heizkörper'];
    if (useOrderStore().isOnSiteInspection()) {
      tabsOptionList = ['Geräte', 'Anlagen'];
    }

    onMounted(() => {
      entityList?.value?.addEventListener('scroll', checkScroll);
    });

    const checkScroll = () => {
      isScrolled.value = (entityList?.value?.scrollTop as number) > 100;
    };

    const isRoomDeletable = computed(() => {
      if (!selectedRoom.value) return false;
      return useRoomStore().isDeletable(selectedRoom.value);
    });

    const usageType = computed(
      () =>
        roomTypeList.find((r) => r.value === selectedRoom?.value?.usageType)
          ?.name
    );
    const selectedTab = ref(tabsOptionList[0]);
    const selectTab = (tab: string) => {
      selectedTab.value = tab;
    };

    const editRoom = () => {
      formStore.editEntity(selectedRoom.value as Room);
    };
    const deleteEntity = () => {
      useEntitiesStore().deleteEntity(
        selectedRoom.value as Room,
        new Notification()
      );
    };

    const setSideBarStatus = (status: boolean) => {
      isSideBarOpen.value = status;
      selectedTab.value = tabsOptionList[0];
    };

    return {
      tabsOptionList,
      setSideBarStatus,
      isSideBarOpen,
      formStore,
      layoutStore,
      selectedRoom,
      usageType,
      selectedTab,
      isScrolled,
      entityList,
      selectTab,
      deleteEntity,
      editRoom,
      checkScroll,
      isRoomDeletable,
    };
  },

  data() {
    return {
      showCanvas: true,
    };
  },
  computed: {
    Notification() {
      return Notification;
    },
  },
  methods: { useRoomStore },
};
</script>

<style scoped lang="scss">
.canvas {
  background: $canvas-background;
  transition: all 0.5s;
}
</style>
