<template>
  <div v-if="currentRoomgroup" class="canvas-container">
    <div
      class="absolute z-10 flex w-full flex-col bg-light-0 px-7 pb-6 pt-8 dark:bg-dark-0"
    >
      <div class="flex flex-row items-center font-light leading-10">
        <a class="flex flex-row items-center" @click="goToBuildingOverview">
          <em class="mdi mdi-chevron-left text-2xl leading-none" />
          {{ currentBuilding.shortAddress }}</a
        >
        <em class="mdi mdi-slash-forward" />
        <span>{{ firstRoom?.getFloor() }}</span>
        <em class="mdi mdi-slash-forward" />
        <span>{{ currentRoomgroup.ordinal }} </span>
      </div>
      <div class="ml-2 flex flex-row justify-between text-4xl font-bold">
        <div class="flex flex-row items-end">
          <div>
            <NoEntryIcon v-if="!currentRoomgroup?.isAccessible" />
            {{ currentRoomgroup.getLabel() }}
          </div>
          <div class="ml-3 pb-1 text-xs font-light text-gray-400">
            <div v-if="currentRoomgroup.number">
              Nr. {{ currentRoomgroup.number }}
            </div>
            <div v-if="currentRoomgroup.customerAssignmentReference">
              KDOB: {{ currentRoomgroup.customerAssignmentReference }}
            </div>
          </div>
        </div>
      </div>
      <div>
        <TertiaryButton
          icon="mdi mdi-check-bold"
          data-testId="finishRoomGroupButton"
          label="Fertig"
          @click="completeRoomGroup()"
        />
      </div>
    </div>

    <div
      id="wrapper"
      class="canvas-wrapper"
      :class="layoutStore.isDarkMode() ? 'canvas-bg-dark' : 'canvas-bg-light'"
    >
      <div id="myCanvas" class="myCanvas"></div>

      <div
        class="fixed right-10 top-28 flex gap-2 md:bottom-10 md:left-32 md:top-auto"
      >
        <div
          class="rounded-xl bg-light-1 px-3 py-2 dark:bg-dark-2"
          @click="resetCanvas"
        >
          <em class="fa fa-lg fa-location-crosshairs"></em>
        </div>
        <div
          class="rounded-xl bg-light-1 px-3 py-2 dark:bg-dark-2"
          @click="toggleCanvasLock"
        >
          <em v-if="isCanvasLocked" class="fa fa-lg fa-lock"></em>
          <em v-else class="fa fa-lg fa-lock-open"></em>
        </div>
        <div
          class="rounded-xl bg-light-1 px-3 py-2 dark:bg-dark-2"
          :class="{ swapModeActive: isSwapModeActive }"
          @click="toggleSwapMode()"
        >
          <em class="fa fa-lg fa-exchange"></em>
        </div>
      </div>
    </div>
  </div>
  <div v-if="!currentRoomgroup" class="error-message">
    <em class="fa-solid fa-triangle-exclamation"></em>
    Keine Raumgruppe ausgewählt, bitte wählen Sie zunächst eine in der
    Gebäudeübersicht
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, watch, onMounted, ComputedRef } from 'vue';
import { createCanvas } from '@/utils/canvas/drawing';
import { Node } from '@/utils/canvas/drawingUtils';
import { createTreeNode } from '@/utils/canvas/treeMapping';
import useStores from '@/composables/useStores';
import { Action, Room } from '@/models';
import { Form } from '@/models/Form';
import { entityTypes } from '@/enums/generic';
import { useFormStore } from '@/store/form/formStore';
import { useBuildingStore } from '@/store/entities/buildingStore';
const { layoutStore, roomGroupStore, entitiesStore, roomStore } = useStores();
import { routesNames } from '@/router';
import { useRouter } from 'vue-router';
import NoEntryIcon from '@/components/UI/Badge/NoEntryIcon.vue';
import { useRoomGroupProgress } from '@/composables/useRoomGroupProgress';
import TertiaryButton from '@/components/UI/Button/TertiaryButton.vue';
const router = useRouter();
import Panzoom, { PanzoomObject } from '@panzoom/panzoom';

const isCanvasLocked = ref(false);
const canvas = ref<any>(undefined);
const isSwapModeActive = ref(false);
const node = ref<Node | undefined>(undefined);
const roomsOfRoomgroup = ref<Room[]>([]);
const firstRoom = ref<Room | undefined>(undefined);

const currentRoomgroup = computed(() => roomGroupStore.getCurrentRoomGroup);
const getBusinessEntityMap = computed(() => entitiesStore.businessEntityMap);

const getCurrentNode: ComputedRef<Room> = computed(
  () => layoutStore.currentNode as Room
);

const currentBuilding = computed(() => {
  return useBuildingStore().getCurrentBuilding();
});
const goToBuildingOverview = () => {
  router.push({
    name: routesNames.BUILDING_OVERVIEW,
  });
};
const currentAction = ref<Action | undefined>();
let panzoom: PanzoomObject | undefined = undefined;

const { completeRoomGroup, isRoomGroupComplete, roomGroupProgressLabel } =
  useRoomGroupProgress(currentRoomgroup.value);

watch(getCurrentNode, () => {
  generateNodeCanvas();
});

watch(
  getBusinessEntityMap,
  () => {
    generateNodeCanvas();
  },
  { deep: true }
);

onMounted(() => {
  const canvas = document.getElementById('myCanvas') as HTMLElement;
  panzoom = Panzoom(canvas);
  if (currentRoomgroup.value) {
    drawCanvas();
    currentAction.value = new Action(
      'Gerät hinzufügen',
      useFormStore().createEntity,
      'fas fa-mobile-alt',
      new Form()
        .setParentId(getCurrentNode.value?.id)
        .setName(entityTypes.installationPoint)
    );
  }
});

const toggleCanvasLock = () => {
  panzoom?.setOptions({
    disablePan: !isCanvasLocked.value,
    disableZoom: !isCanvasLocked.value,
  });
  isCanvasLocked.value = !isCanvasLocked.value;
};

const resetCanvas = () => {
  panzoom?.reset();
};

const toggleSwapMode = () => {
  isSwapModeActive.value = !isSwapModeActive.value;
  generateNodeCanvas();
};

const drawCanvas = () => {
  const canvasElement = document.getElementById(
    'myCanvas'
  ) as HTMLCanvasElement;
  if (!canvasElement) return;

  const parent = canvasElement.parentNode as HTMLElement;
  const styles = getComputedStyle(parent);

  const h = parseInt(styles.getPropertyValue('height'), 10);
  const w = parseInt(styles.getPropertyValue('width'), 10);
  canvasElement.width = w;
  canvasElement.height = h;
  const options = {
    boxWidth: 150,
    boxHeight: 136,
    marginX: 40,
    marginY: 30,
    maxDepth: 3,
    menu: {
      width: 180,
    },
    groups: {
      onClick: (currentGroup: Room) => {
        const group = entitiesStore.getEntityById(currentGroup.id);
        layoutStore.changeCurrentNode(group);
      },
      addRoom: () => {
        useFormStore().createEntity(
          new Form()
            .setParentId(getCurrentNode.value?.id)
            .setName(entityTypes.room)
        );
      },
    },
  };

  if (!currentRoomgroup.value) return;
  roomsOfRoomgroup.value = roomStore.getRoomsByRoomGroupId(
    currentRoomgroup.value.id
  );

  firstRoom.value = roomStore.getFirstRoomOfRoomgroup(
    currentRoomgroup.value.id
  );

  if (
    getCurrentNode.value?.roomGroupId !== currentRoomgroup.value.id &&
    firstRoom.value
  ) {
    layoutStore.changeCurrentNode(firstRoom.value);
  }

  const treeNode = createTreeNode(
    firstRoom.value,
    roomsOfRoomgroup.value,
    getCurrentNode.value.id
  );

  node.value = treeNode;
  canvas.value = createCanvas(treeNode, canvasElement, options);
};

watch(
  () => layoutStore.currentNode,
  () => {
    generateNodeCanvas();
  }
);

const generateNodeCanvas = () => {
  if (!currentRoomgroup.value) return;
  roomsOfRoomgroup.value = roomStore.getRoomsByRoomGroupId(
    currentRoomgroup.value.id
  );
  firstRoom.value = roomStore.getFirstRoomOfRoomgroup(
    currentRoomgroup.value.id
  );

  const treeNode = createTreeNode(
    firstRoom.value,
    roomsOfRoomgroup.value,
    getCurrentNode.value.id
  );
  canvas.value.redraw(treeNode, isSwapModeActive.value);
};
</script>

<style scoped lang="scss">
.canvas-bg-dark {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.03) 1px,
      transparent 1px
    ),
    linear-gradient(90deg, rgba(255, 255, 255, 0.03) 1px, transparent 1px);
  background-size: 20px 20px;
}

.canvas-bg-light {
  background-image: linear-gradient(rgba(0, 0, 0, 0.05) 1px, transparent 1px),
    linear-gradient(90deg, rgba(0, 0, 0, 0.05) 1px, transparent 1px);
  background-size: 20px 20px;
}
</style>
