<template>
  <div class="flex flex-col">
    <div
      class="radiator-card border dark:border-dark-3 dark:bg-dark-1"
      data-testid="radiatorCard"
    >
      <div class="radiator-card-header text-white dark:text-white">
        <div>
          <em :class="['fas', { 'fa-circle-check mr-2': isIdentified }]" />
          <em v-if="showRadiatorHasImage" class="fa-solid fa-camera" />
        </div>

        <div
          class="is-flex is-justify-content-space-between is-align-items-center relative"
        >
          <div class="is-size-3 has-text-weight-bold">
            {{ radiator.getRadiatorLabel() }}
          </div>

          <OptionsDropdown
            class="text-black dark:text-white"
            icon="mdi-dots-vertical"
            direction-x="left"
            direction="down"
            :data-testid="`radiatorDropdownButton_${radiator.radiatorType}`"
          >
            <li
              :data-testid="`editDropdownItem_${radiator.radiatorType}`"
              @click="goToEditRadiator(radiator)"
            >
              <em class="mdi mdi-pencil mr-2" />Bearbeiten
            </li>
            <li
              :data-testid="`deleteDropdownItem_${radiator.radiatorType}`"
              :class="[
                {
                  'cursor-not-allowed text-light-disabled-text hover:bg-transparent dark:text-dark-disabled-text':
                    !isRadiatorDeletable,
                },
              ]"
              @click="isRadiatorDeletable && deleteEntityModal(radiator)"
            >
              <em class="mdi mdi-delete mr-2" />Löschen
            </li>
          </OptionsDropdown>
        </div>

        <div class="is-size-6">
          {{ isIdentified ? radiator.identificationResult?.model : '-' }}
        </div>

        <div class="is-size-6 mt-1">{{ radiator.getDimensions() }}</div>
      </div>

      <div class="radiator-card-body flex flex-col gap-4 dark:bg-dark-3">
        <RadiatorCardDeviceList
          :device-type="deviceTypeDetails[DeviceTypeEnum.HEAT_COST_ALLOCATOR]"
          :devices="getHKVsOfRadiator"
          :radiator="radiator"
          :parent-id="parentId"
        />
        <RadiatorCardDeviceList
          :device-type="
            deviceTypeDetails[DeviceTypeEnum.SMART_RADIATOR_THERMOSTAT]
          "
          :devices="getSRTsOfRadiator"
          :parent-id="parentId"
          :radiator="radiator"
        />
      </div>
    </div>
    <em
      v-if="!isLastRadiator && isSwapModeActive"
      class="fas fa-exchange swap-btn left mt-6 justify-center"
      data-testid="swapButton"
      @click="swapRadiator(radiator)"
    />
    <BasePrompt
      :open="isDeleteActive"
      :close="closeModal"
      :cancel="closeModal"
      :proceed="deleteRadiator"
      title="Heizkörper löschen"
      question="Bist du sicher, dass du diesen Heizkörper löschen möchtest?"
    >
    </BasePrompt>
  </div>
</template>

<script lang="ts">
import { deviceTypeDetails, DeviceTypeEnum, deviceTypes } from '@/enums/device';
import { Radiator } from '@/models/radiators/Radiator';
import { computed, defineComponent, ref } from 'vue';
import RadiatorCardDeviceList from '@/components/Forms/RadiatorListFormComponents/RadiatorCardDeviceList.vue';
import { useRadiatorStore } from '@/store/entities/radiatorStore';
import { useDeviceStore } from '@/store/entities/deviceStore';
import { useEntitiesStore } from '@/store/entities/entitiesStore';
import { useFormStore } from '@/store/form/formStore';
import BasePrompt from '@/components/UI/Modal/BasePrompt.vue';
import OptionsDropdown from '@/components/UI/Dropdown/OptionsDropdown.vue';
import { useImagesStore } from '@/store/images/imageStore';

export default defineComponent({
  name: 'RadiatorCard',
  computed: {
    DeviceTypeEnum() {
      return DeviceTypeEnum;
    },
  },
  components: {
    OptionsDropdown,
    BasePrompt,
    RadiatorCardDeviceList,
  },
  props: {
    radiator: {
      type: Radiator,
      required: true,
    },
    parentId: {
      type: String,
      required: true,
    },
    isSwapModeActive: {
      type: Boolean,
      required: false,
    },
  },
  setup(props) {
    const radiatorStore = useRadiatorStore();
    const deviceStore = useDeviceStore();
    const entitiesStore = useEntitiesStore();
    const formStore = useFormStore();
    const imagesStore = useImagesStore();

    const isDeleteActive = ref(false);
    const toBeDeletedEntity = ref<Radiator | undefined>(undefined);

    const isLastRadiator = computed(() => {
      if (props.radiator.parentRoomId) {
        const radiators = radiatorStore.getAllRadiatorsInRoom(
          props.radiator.parentRoomId
        );

        return !radiators.some(
          (r: Radiator) => r.ordinal > props.radiator.ordinal
        );
      }
      return true;
    });

    const isIdentified = computed(() => {
      return !!props.radiator.identificationResult;
    });

    const showRadiatorHasImage = computed(() => {
      return imagesStore.objectHasImages(props.radiator.id);
    });

    const goToEditRadiator = (radiator: Radiator) => {
      formStore.editEntity(radiator, props.parentId, {}, true);
    };

    const swapRadiator = (radiator: Radiator) => {
      radiatorStore.swapRadiator(radiator);
    };

    const getHKVsOfRadiator = computed(() => {
      return deviceStore.getDevicesOnRadiator(
        props.radiator.id,
        deviceTypes.HeatCostAllocator
      );
    });

    const getSRTsOfRadiator = computed(() => {
      return deviceStore.getDevicesOnRadiator(
        props.radiator.id,
        deviceTypes.SmartRadiatorThermostat
      );
    });

    const isRadiatorDeletable = computed(() => {
      return (
        getHKVsOfRadiator.value.length === 0 &&
        getSRTsOfRadiator.value.length === 0
      );
    });

    const deleteEntityModal = (entity: Radiator) => {
      toBeDeletedEntity.value = entity;
      isDeleteActive.value = true;
    };

    const deleteRadiator = () => {
      if (toBeDeletedEntity.value !== undefined) {
        entitiesStore.deleteEntity(toBeDeletedEntity.value);
      }
      closeModal();
    };
    const closeModal = () => {
      isDeleteActive.value = false;
      toBeDeletedEntity.value = undefined;
    };

    return {
      goToEditRadiator,
      isIdentified,
      getSRTsOfRadiator,
      deleteEntityModal,
      showRadiatorHasImage,
      getHKVsOfRadiator,
      deleteRadiator,
      closeModal,
      swapRadiator,
      isLastRadiator,
      isDeleteActive,
      deviceTypes,
      deviceTypeDetails,
      isRadiatorDeletable,
    };
  },
});
</script>

<style lang="scss" scoped>
.radiator-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: dimgrey;
  border-radius: 15px;
  box-shadow: inherit;
  padding: 0;
}

.radiator-card-header {
  border-radius: 15px;
  padding: 1.25rem;
}

.radiator-card-body {
  background-color: #f1f1f1;
  height: 100%;
  border-radius: 15px;
  padding: 1.25rem;
}

.hca-box .column {
  padding-left: 0;
}

.description {
  max-width: 800px;
}

.swap-btn {
  display: flex;
  justify-items: center;
  align-items: center;
  margin-left: 25px;
  font-size: 35px;
}
</style>
