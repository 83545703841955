import { ref } from 'vue';
import { InspectionData } from '@/models/inspectionPoint/InspectionPoint';
import { useEntitiesStore } from '@/store/entities/entitiesStore';
import { instanceToInstance } from 'class-transformer';
import { InspectionPoint } from '@/models/inspectionPoint/InspectionPoint';
import {
  InspectionDevice,
  InspectionDeviceFactory,
  InspectionDeviceType,
  InspectionDeviceTypeParams,
} from '@/models/inspectionPoint/InspectionDevice';

export function useInspectionForm(emit: any) {
  const inspectionDevice = ref<InspectionDevice>();
  const inspectionPoint = ref<InspectionPoint>();
  const inspectionData = ref<InspectionData>(new InspectionData());

  const initInspectionPoint = (
    roomId: string,
    existingInspectionPoint?: InspectionPoint
  ) => {
    if (existingInspectionPoint) {
      inspectionPoint.value = instanceToInstance(existingInspectionPoint);
      return;
    }

    inspectionPoint.value = new InspectionPoint(roomId);
    const room = useEntitiesStore().getEntityById(roomId);
    if (!room?.roomGroupId) {
      throw new Error('RoomGroupId is not defined');
    }
    inspectionPoint.value.billedInRoomGroupId = room.roomGroupId;
  };

  const initInspectionData = () => {
    if (inspectionPoint.value?.inspectionData) {
      inspectionData.value = instanceToInstance(
        inspectionPoint.value.inspectionData
      );
    }
  };

  const initInspectionDevice = <T extends InspectionDeviceType>(
    deviceType: T,
    params?: Extract<InspectionDeviceTypeParams, { deviceType: T }>
  ) => {
    if (!inspectionPoint.value) {
      throw new Error('Inspectionpoint is not initialized');
    }

    inspectionDevice.value = InspectionDeviceFactory.createDevice(deviceType);
    if (params) {
      if (inspectionPoint.value?.supportedDeviceType !== deviceType) {
        throw new Error('Device types are not matching');
      }

      if (inspectionPoint.value?.id !== params?.inspectionPointId) {
        throw new Error('Inspectionpoint ids are not matching');
      }

      inspectionDevice.value.updateProperties(params);
    } else {
      inspectionDevice.value?.setInspectionPointId(inspectionPoint.value.id);
      inspectionPoint.value?.setSupportedDeviceType(deviceType);
      inspectionPoint.value.activeTestDeviceId = inspectionDevice.value.id;
    }
  };

  const updateInspectionDevice = () => {
    if (!inspectionPoint.value) {
      throw new Error('Inspectionpoint is not initialized');
    }

    emit('update-entities', [inspectionDevice.value, inspectionPoint.value]);
  };

  return {
    inspectionPoint,
    inspectionDevice,
    inspectionData,
    initInspectionDevice,
    initInspectionPoint,
    initInspectionData,
    updateInspectionDevice,
  };
}
