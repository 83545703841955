<template>
  <div v-if="isOnSiteInspection">
    <h4>Testgeräte</h4>
    <BaseRadioButton
      v-model:selection="selectedDeviceType"
      :options-list="inspectionDeviceTypeList"
      @option-click="setDeviceType($event)"
    />
  </div>
  <div v-else>
    <h4>Standartgeräte</h4>
    <BaseRadioButton
      v-model:selection="selectedDeviceType"
      :options-list="deviceTypeList"
      @option-click="setDeviceType($event)"
    />

    <h4>Heizungsgeräte</h4>
    <BaseRadioButton
      v-model:selection="selectedDeviceType"
      :options-list="radiatorDeviceTypeList"
      @option-click="setDeviceType($event)"
    />

    <h4>Bestandsgeräte</h4>
    <BaseRadioButton
      v-model:selection="selectedDeviceType"
      :options-list="legacyDeviceTypeList"
      @option-click="setDeviceType($event)"
    />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import {
  deviceTypeList,
  inspectionDeviceTypeList,
  legacyDeviceTypeList,
  radiatorDeviceTypeList,
} from '@/enums/device';
import BaseRadioButton from '@/components/UI/Radio/BaseRadioButton.vue';
import { computed } from 'vue';
import { useOrderStore } from '@/store/order/orderStore';

const props = defineProps<{
  deviceType?: string;
}>();

const emit = defineEmits(['update:deviceType']);

const selectedDeviceType = ref(props.deviceType);

const setDeviceType = (value: string | undefined) => {
  selectedDeviceType.value = value;
  emit('update:deviceType', value);
};

const isOnSiteInspection = computed(() => {
  return useOrderStore().isOnSiteInspection();
});
</script>

<style scoped></style>
