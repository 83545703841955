export const radiatorTypes = Object.freeze({
  PP: 'PP',
  GS: 'GS',
  HW: 'HW',
  GG: 'GG',
  GR: 'GR',
  PG: 'PG',
  UNKNOWN: 'UNKNOWN',
  PlaceholderRadiator: 'PlaceholderRadiator',
});

export type RadioOption = {
  name: string;
  value: any;
  subtitle?: string;
  image?: string;
  icon?: string;
};

type radiatorFormType = {
  [key: string]: RadioOption;
};

export const radiatorFormTypes: radiatorFormType = Object.freeze({
  PP: {
    name: 'PP',
    subtitle: 'Profilierte Platte',
    value: 'PP',
    image: require('@/assets/img/radiators/PP/PP.svg'),
  },
  GS: {
    name: 'GS',
    subtitle: 'Gliederheizkörper aus Stahl',
    value: 'GS',
    image: require('@/assets/img/radiators/GS/GS.svg'),
  },
  HW: {
    name: 'HW',
    subtitle: 'Handtuchwärmekörper',
    value: 'HW',
    image: require('@/assets/img/radiators/HW/HW.svg'),
  },
  GG: {
    name: 'GG',
    subtitle: 'Gliederheizkörper aus Guss',
    value: 'GG',
    image: require('@/assets/img/radiators/GG/GG.svg'),
  },
  GR: {
    name: 'GR',
    subtitle: 'Gliederheizkörper aus Rohren',
    value: 'GR',
    image: require('@/assets/img/radiators/GR/GR.svg'),
  },
  PG: {
    name: 'PG',
    subtitle: 'Glatter Plattenheizkörper',
    value: 'PG',
    image: require('@/assets/img/radiators/PG/PG_with_cover.svg'),
  },
  UNKNOWN: {
    name: 'Unbekannt',
    subtitle: 'Heizkörper passt zu keinem der Typen',
    value: 'UNKNOWN',
    image: require('@/assets/img/radiators/unknown.svg'),
  },
  PlaceholderRadiator: {
    name: 'Heizkörper',
    subtitle: '',
    value: 'PlaceholderRadiator',
    image: '',
  },
});
