<template>
  <div class="relative">
    <form class="flex flex-col gap-4" @submit.prevent="handleSubmit">
      <BaseSection heading="Nutzeinheit Informationen">
        <BaseRadioButton
          v-model:selection="roomgroup.usageType"
          class="my-3"
          :options-list="usageTypeList"
          label="Nutzungsart"
          :cols="3"
          data-testid="radioButton_usageType"
          @option-click="handleUsageType"
        />
        <div v-if="!isGeneral" class="md:flex md:gap-4">
          <BaseFormInput
            v-model.number="roomgroup.number"
            class="md:basis-1/3"
            :validation="v$.roomgroup.number"
            label="Nr. NE"
            type="number"
            pattern="[0-9]*"
            inputmode="numeric"
            test-id="input_roomgroupNumber"
            :warning="warningMessageRoomgroupNumber"
          />
          <BaseFormInput
            v-model="roomgroup.doorbellName"
            class="md:basis-2/3"
            :validation="v$.roomgroup.doorbellName"
            label="Bewohner / Klingelschild"
            test-id="input_roomgroupDoorbellName"
            :auto-correct="false"
          />
        </div>
        <BaseInformation
          class="mb-[-1px]"
          status="warning"
          information-text="Bitte tragen Sie den Kundenordnungsbegriff ein. Dies ist wichtig für die eindeutige Zuordnung und Identifikation der Nutzeinheit."
        />
        <BaseFormInput
          v-model="roomgroup.customerAssignmentReference"
          label="Kundenordnungsbegriff"
          test-id="input_customerAssignmentReference"
          :maxlength="50"
        />
        <BaseFormInput
          v-if="isBusiness"
          v-model="roomgroup.businessType"
          label="Gewerbeart"
          :optional="true"
          test-id="input_businessType"
          :maxlength="25"
        />
        <BaseFormInput
          v-model="roomgroup.deviceReference"
          label="S/N vom KALO Referenzgerät"
          type="number"
          pattern="[0-9]*"
          inputmode="numeric"
          test-id="input_deviceReference"
          :maxlength="8"
          :validation="v$.roomgroup.deviceReference"
          :warning="warningMessageDeviceReference"
        >
          <template #trailing>
            <span
              class="mdi mdi-qrcode-scan text-3xl"
              @click="openDeviceCodeScanner"
            />
          </template>
        </BaseFormInput>
      </BaseSection>

      <BaseSection heading="Weitere Angaben" :required="false">
        <BaseTextarea
          v-model:input-text="roomgroup.note"
          label="Notizen"
          test-id="textarea_note"
        />
        <BaseToggle
          v-model:is-checked="roomgroup.isVacant"
          label="Leerstand"
          test-id="toggle_isVacant"
        />
        <BaseToggle
          v-model:is-checked="roomgroup.isAccessible"
          label="Kein Zutritt"
          :is-inverted="true"
          test-id="toggle_isAccessible"
          @is-toggled="handleIsAccessibleChange($event)"
        />

        <div v-if="!roomgroup.isAccessible" class="mt-3 gap-3 md:flex">
          <BaseDropdown
            v-model:selection="roomgroup.reasonNotAccessible"
            :options-list="roomgroupNotAccessibleReasonsList"
            :validation="v$.roomgroup.reasonNotAccessible"
            dropdown-text="Grund wählen"
            label="Grund"
            class="md:basis-4/5"
            :numbered="true"
          />
          <BaseDateTime
            v-model:date="roomgroup.dateNotAccessible"
            :validation="v$.roomgroup.dateNotAccessible"
            class="md:basis-1/5"
          />
        </div>
      </BaseSection>
      <div v-if="isCodeScanActive">
        <DeviceCodeScanner @scan-result="scanResult($event)" />
      </div>
    </form>
  </div>
</template>
<script setup lang="ts">
import BaseRadioButton from '@/components/UI/Radio/BaseRadioButton.vue';
import {
  usageTypeList,
  roomgroupNotAccessibleReasonsList,
} from '@/enums/roomgroup';
import { computed, onBeforeMount, ref } from 'vue';
import { Roomgroup } from '@/models';
import BaseFormInput from '@/components/UI/Input/BaseFormInput.vue';
import BaseTextarea from '@/components/UI/Note/BaseTextarea.vue';
import BaseToggle from '@/components/UI/Switch/BaseToggle.vue';
import BaseDropdown from '@/components/UI/Dropdown/BaseDropdown.vue';
import { useValidation } from '@/composables/useValidation';
import { useRoomGroupForm } from '@/composables/useRoomgroupForm';
import BaseDateTime from '@/components/UI/Input/BaseDateTime.vue';
import BaseSection from '@/components/UI/Card/BaseSection.vue';
import BaseInformation from '@/components/UI/Hint/BaseInformation.vue';
import DeviceCodeScanner from '@/components/UI/Scanner/DeviceCodeScanner.vue';
import { useFormChangeTrackerPlugin } from '@/composables/useFormChangeTracker';

const props = defineProps<{
  node?: Roomgroup;
  parentId: string;
}>();

const emit = defineEmits(['update-entities']);

const {
  roomgroup,
  room,
  isBusiness,
  isGeneral,
  initializeRoomGroupForm,
  handleIsAccessibleChange,
  handleUsageType,
  handleSubmit,
  isRoomGroupNumberDuplicated,
  isDeviceReferenceDuplicated,
} = useRoomGroupForm(props.node, props.parentId, emit);

const { v$, isFormValid } = useValidation({ roomgroup });

onBeforeMount(() => {
  initializeRoomGroupForm();
  useFormChangeTrackerPlugin(roomgroup);
});

const isCodeScanActive = ref(false);

const openDeviceCodeScanner = () => {
  return (isCodeScanActive.value = true);
};

const scanResult = (scanDataObject: Record<string, unknown> | undefined) => {
  for (const [key, value] of Object.entries(scanDataObject)) {
    if (key === `serialNumber` && value) {
      roomgroup.value.deviceReference = value;
    }
  }
  isCodeScanActive.value = false;
};

const isRoomGroupFormValid = computed(() => {
  if (roomgroup.value.usageType === usageTypeList[2].value) {
    return isFormValid.value;
  }
  return isFormValid.value && !isRoomGroupNumberDuplicated.value;
});

const warningMessageRoomgroupNumber = computed(() => {
  return isRoomGroupNumberDuplicated.value
    ? 'Diese Nutzernummer existiert bereits'
    : undefined;
});

const warningMessageDeviceReference = computed(() => {
  return isDeviceReferenceDuplicated.value
    ? 'Diese Seriennummer existiert bereits'
    : undefined;
});

defineExpose({
  handleSubmit,
  isFormValid: isRoomGroupFormValid,
});
</script>
