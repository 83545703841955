<template>
  <div class="note-scroll">
    <BaseTextarea
      v-model:input-text="localDevice.note"
      placeholder="'Hier können Sie weiterführende Informationen hinterlegen'"
      label="Notizen"
      test-id="deviceNote"
    >
    </BaseTextarea>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import BaseTextarea from '@/components/UI/Note/BaseTextarea.vue';
import { DeviceClasses } from '@/models/devices/Device';

const props = defineProps<{
  device: DeviceClasses;
}>();

const emit = defineEmits(['update:device']);

const localDevice = computed({
  get() {
    return props.device;
  },
  set(value) {
    emit('update:device', value);
  },
});
</script>
