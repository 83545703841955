<template>
  <div class="mr-5">
    <BaseRadioButton
      v-model:selection="localType"
      :options-list="types"
      label="Heizkörpertyp"
      :cols="3"
    />
  </div>
</template>
<script setup lang="ts">
import { radiatorFormTypes } from '@/enums/radiator/generic';
import { computed } from 'vue';
import BaseRadioButton from '@/components/UI/Radio/BaseRadioButton.vue';

const props = defineProps<{
  type: string | undefined;
}>();

const emit = defineEmits(['update:type']);

const types = Object.values(radiatorFormTypes).filter(
  (r) => r.value !== 'PlaceholderRadiator'
);

const localType = computed({
  get() {
    return props.type;
  },
  set(value) {
    emit('update:type', value);
  },
});
</script>
