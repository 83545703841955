import {
  ActiveFunkcheck,
  initializeFunkcheck,
  pollScanResults,
  radioCheckApi,
} from '@/api';
import { deviceTypes } from '@/enums/device';
import { useEntitiesStore } from '@/store/entities/entitiesStore';
import { useDeviceStore } from '@/store/entities/deviceStore';
import { getAllEntitiesByCriteria } from '@/store/entities/helper';
import { receiveScanResults } from '@/api/funkcheck/Funkcheck';
import { devicefunkcheckStatus } from '@/enums/funkcheck';
import { Pinia, Store } from '@/store/pinia-class-component';
import {
  EchoResult,
  LoRaWanRadioCheck,
  RadioCheckPayload,
} from '@/api/funkcheck/LoRaWanRadioCheck';
import { DeviceClasses } from '@/models/devices/Device';
import { useRadiatorStore } from '@/store/entities/radiatorStore';
import { useInstallationPointStore } from '@/store/entities/installationPointStore';
import { triggerEchoApi } from '@/api/funkcheck/RadioCheckApi';

export function useFunkcheckStore() {
  return new FunkcheckStore();
}

@Store
export class FunkcheckStore extends Pinia {
  constructor() {
    super();
    this._funkcheck = new ActiveFunkcheck('', '', {
      createdAt: '',
      expectResultAt: '',
    });
    this._activeFunkcheck = this._funkcheck;
    this._loRaWanRadioCheck = new LoRaWanRadioCheck();
    this._funkcheckErrors = [];
    this.buildingFilter = [];
    this.statusFilter = [];
  }

  statusFilter = [];
  buildingFilter = [];

  resetAllFilters() {
    this.statusFilter = [];
    this.buildingFilter = [];
  }

  private _funkcheckErrors: Array<Record<string, unknown>>;

  get funkcheckErrors(): Array<Record<string, unknown>> {
    return this._funkcheckErrors;
  }

  private _funkcheck: ActiveFunkcheck;

  get funkcheck(): ActiveFunkcheck {
    return this._funkcheck;
  }

  private _activeFunkcheck: ActiveFunkcheck;

  get activeFunkcheck(): ActiveFunkcheck {
    return this._activeFunkcheck;
  }

  private _loRaWanRadioCheck: LoRaWanRadioCheck;

  get loRaWanRadioCheck(): LoRaWanRadioCheck {
    return this._loRaWanRadioCheck;
  }

  getRemainingTime() {
    const activeFunkcheck = this._activeFunkcheck;
    if (!activeFunkcheck?.startedAt) return 0;
    const startedAt = new Date(activeFunkcheck.startedAt).getTime();
    const attempt = activeFunkcheck.attempt;
    const currentTime = new Date().getTime();
    const intervals: Array<number> = this.getIntervals();
    return intervals[attempt] - (currentTime - startedAt);
  }

  getIntervals() {
    const result = this._activeFunkcheck?.initResult;
    if (!result) return [];

    const expectResultAt = new Date(result.expectResultAt);
    const createdAt = new Date(result.createdAt);
    const firstInterval = expectResultAt.getTime() - createdAt.getTime();
    return [firstInterval, 120_000, 60_000];
  }

  get getFilterList() {
    const deviceStore = useDeviceStore();
    const devices = deviceStore.devices;

    const measuringDevices = getAllEntitiesByCriteria(
      devices,
      (node: DeviceClasses) =>
        node.serialNumber !== '' &&
        node.deviceType !== deviceTypes.DirectMeterGateway &&
        node.deviceType !== deviceTypes.SmartRadiatorThermostat &&
        node.deviceType !== deviceTypes.LoRaWanGateway &&
        node.isAutomatedMeterReading
    );

    return measuringDevices.map((device) => ({
      type: device.deviceType,
      serialNumber: device.serialNumber,
    }));
  }

  initFunkcheck(gatewaySerialNumber: string) {
    const filterList = this.getFilterList;
    const entitiesStore = useEntitiesStore();
    this._funkcheckErrors = [];

    return initializeFunkcheck(gatewaySerialNumber, filterList).then(
      (response: InitResult) => {
        if (response.status === 200 && entitiesStore.activeBusinessEntityId) {
          this._activeFunkcheck = new ActiveFunkcheck(
            entitiesStore.activeBusinessEntityId,
            gatewaySerialNumber,
            response.data
          );
        }
        return response;
      }
    );
  }

  getLastScanResult() {
    const deviceStore = useDeviceStore();
    const gateway = deviceStore.getDirectMeterGateway();
    if (!gateway) {
      return Promise.reject(new Error('NO_GATEWAY_FOUND'));
    }
    this._funkcheckErrors = [];
    return pollScanResults(gateway.serialNumber);
  }

  updateDevicesFunkcheckStatus(scannedDevices: Array<any>) {
    const deviceStore = useDeviceStore();
    const allDevices = deviceStore.getDevices();
    this._funkcheckErrors = [];

    allDevices.forEach((device) => {
      if (
        !device.id ||
        device.deviceType === deviceTypes.SmartRadiatorThermostat ||
        device.deviceType === deviceTypes.LoRaWanGateway
      )
        return;
      const currentDevice = scannedDevices.find(
        (scannedDevice) => scannedDevice.id.serialNumber === device.serialNumber
      );

      const deviceId = device.id;
      if (
        currentDevice ||
        device.deviceType === deviceTypes.DirectMeterGateway
      ) {
        deviceStore.setDeviceFunkcheckStatus(
          deviceId,
          devicefunkcheckStatus.done
        );
        return;
      }

      deviceStore.setDeviceFunkcheckStatus(
        deviceId,
        devicefunkcheckStatus.error
      );
      this.updateFunkcheckErrors(deviceId);
    });
  }

  updateFunkcheckErrors(deviceId: string) {
    const deviceStore = useDeviceStore();
    const device = deviceStore.devices.get(deviceId);
    if (device === undefined) {
      throw new Error('Device not found');
    }
    const installationPoint =
      useInstallationPointStore().installationPoints.get(
        device.installationPointId
      );
    if (!installationPoint) {
      throw new Error('InstallationPoint not found');
    }
    let roomId = installationPoint.roomId;
    const roomGroupId = installationPoint.billedInRoomGroupId;

    if (device.hasOwnProperty('radiatorId')) {
      const parentId = device.getParentId();
      if (!parentId) {
        throw new Error('Parent not found');
      }
      const radiator = useRadiatorStore().radiators.get(parentId);
      if (!radiator?.parentRoomId) {
        throw new Error('Radiator parentRoomId not found');
      }
      roomId = radiator.parentRoomId;
    }

    const funkcheckError = {
      roomId,
      roomGroupId,
      deviceId,
    };

    this._funkcheckErrors.push(funkcheckError);
  }

  updateActiveFunkcheck(
    attempt?: number,
    startedAt?: Date | null,
    scanResult?: ScanResult
  ) {
    if (attempt) {
      this._activeFunkcheck.attempt = attempt;
    }
    if (startedAt) {
      this._activeFunkcheck.startedAt = startedAt;
    }
    if (scanResult) {
      this._activeFunkcheck.scanResult = scanResult;
    }
  }

  getScanResults() {
    return receiveScanResults(
      this._activeFunkcheck?.gatewaySerialNumber,
      this.getIntervals(),
      this._activeFunkcheck?.initResult?.createdAt
    );
  }

  triggerEcho(payload: RadioCheckPayload): Promise<EchoResult> {
    const entitiesStore = useEntitiesStore();

    if (!entitiesStore.activeBusinessEntityId) {
      return Promise.reject(new Error('NO_ACTIVE_BUSINESS_ENTITY'));
    }
    const businessEntityId = entitiesStore.activeBusinessEntityId;
    return triggerEchoApi(businessEntityId, payload).then((response) => {
      return response.data;
    });
  }

  fetchRadioCheck(payload: RadioCheckPayload): Promise<LoRaWanRadioCheck> {
    const entitiesStore = useEntitiesStore();

    if (!entitiesStore.activeBusinessEntityId) {
      return Promise.reject(new Error('NO_ACTIVE_BUSINESS_ENTITY'));
    }
    const businessEntityId = entitiesStore.activeBusinessEntityId;
    return radioCheckApi(businessEntityId, payload).then((response) => {
      const radioCheckResult = response.data;
      if (radioCheckResult) {
        this._loRaWanRadioCheck = {
          businessEntityId: entitiesStore.activeBusinessEntityId,
          ...radioCheckResult,
        };
      }
      return radioCheckResult;
    });
  }

  getDevicesWithRadioCheckErrorInRoom(roomId: string) {
    if (!this.loRaWanRadioCheck?.result) return [];
    return useDeviceStore()
      .getAllDevicesInRoom(roomId)
      .filter(
        (device) =>
          device.deviceType === deviceTypes.SmartRadiatorThermostat &&
          this.loRaWanRadioCheck?.result?.[device.id]?.status !== 'OK'
      );
  }
}
