<template>
  <BasePrompt
    :open="isModalOpen"
    title="Code scannen"
    :cancel="onModalClose"
    :close="onModalClose"
    :show-actions="false"
  >
    <div id="qr-code-full-region" class="w-[30vw] border-0"></div>
  </BasePrompt>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted } from 'vue';
import BasePrompt from '@/components/UI/Modal/BasePrompt.vue';
import { AccessoryType, DeviceTypeEnum } from '@/enums/device';
import useCodeScanner from '@/components/UI/Scanner/useCodeScanner';

const props = defineProps<{
  deviceType?: AccessoryType | DeviceTypeEnum;
}>();

const emit = defineEmits(['scan-result']);
const { isModalOpen, onModalClose, startScan, stopScan } = useCodeScanner(
  (result) => emit('scan-result', result),
  props.deviceType
);

onMounted(() => {
  startScan();
});

onUnmounted(() => {
  stopScan();
});
</script>

<style scoped></style>
