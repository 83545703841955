<template>
  <div>
    <div class="text-center">
      {{
        activeBuilding.address.street + ' ' + activeBuilding.address.houseNumber
      }}
    </div>
    <BaseSection heading="Hinweise">
      <BaseTextarea
        v-model:input-text="inspectedBuilding.note"
        label="Notizen"
        test-id="textarea_note"
      />
    </BaseSection>
    <BaseSection heading="Fotos">
      <ImageCapture
        ref="imageCaptureRef"
        :object-id="inspectedBuilding.id"
        :object-type="ImageObjectType.INSPECTED_BUILDING"
        :capture-image-information="true"
      />
    </BaseSection>
  </div>
</template>
<script setup lang="ts">
import { computed, defineProps, onBeforeMount, ref, Ref } from 'vue';
import { ImageObjectType } from '@/models/Image';
import ImageCapture, {
  ImageCaptureRef,
} from '@/components/Forms/DeviceFormComponents/ImageCapture.vue';
import BaseTextarea from '@/components/UI/Note/BaseTextarea.vue';
import BaseSection from '@/components/UI/Card/BaseSection.vue';
import { InspectedBuilding } from '@/models/InspectedBuilding';
import { useBuildingStore } from '@/store/entities/buildingStore';
import { useInspectedBuildingForm } from '@/composables/useInspectedBuildingForm';
import { useFormChangeTrackerPlugin } from '@/composables/useFormChangeTracker';

const props = defineProps<{
  node?: InspectedBuilding;
}>();

const emit = defineEmits(['update-entity']);

const imageCaptureRef: Ref<ImageCaptureRef | null> = ref(null);

const { inspectedBuilding, initializeInspectedBuildingForm, handleSubmit } =
  useInspectedBuildingForm(props.node, imageCaptureRef, emit);

const activeBuilding = computed(() => {
  return useBuildingStore().getCurrentBuilding();
});

onBeforeMount(() => {
  initializeInspectedBuildingForm();
  useFormChangeTrackerPlugin(inspectedBuilding);
});

defineExpose({
  isFormValid: true,
  handleSubmit,
});
</script>
