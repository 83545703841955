export class UserAuth {
  public message?: string;
  private success: boolean;
  private challengeName?: string;

  constructor() {
    this.success = false;
    this.message = undefined;
  }

  public isPasswordChangeRequired(): boolean {
    return this.success && this.challengeName === 'NEW_PASSWORD_REQUIRED';
  }

  public isSuccessful(): boolean {
    return this.success;
  }

  public setChallengeName(challengeName: string): this {
    this.challengeName = challengeName;
    return this;
  }

  public setAuthSuccess(state = true): this {
    this.success = state;
    return this;
  }

  public setMessage(message: string | undefined): this {
    this.message = message;
    return this;
  }

  public reset() {
    this.message = undefined;
    this.success = false;
    return this;
  }
}

export enum AuthMessage {
  PASSWORD_CHANGED = 'Ihr Passwort wurde erfolgreich geändert',
  EMAIL_SENT = 'Eine Bestätigungs-E-Mail wurde an Ihre E-Mail-Adresse gesendet',
}
