<template>
  <div
    ref="dropdown"
    v-click-outside="closeDeviceListDropdown"
    class="flex h-16 w-full flex-shrink cursor-pointer flex-col rounded-b-xl border border-light-1 py-1.5"
    :class="[
      { 'bg-light-2 dark:border-dark-4 dark:bg-dark-4': isDeviceListOpen },
      { 'bg-light-2 dark:border-dark-4 dark:bg-dark-4': !isDeviceListOpen },
    ]"
    @click="toggleDeviceListDropdown()"
  >
    <div class="flex flex-col px-3">
      <span class="flex flex-row justify-between text-xs">
        Geräte {{ activeInstallationPoints.length }}
        <em
          class="mt-[-4px] text-base transition transition-all"
          :class="
            isDeviceListOpen
              ? ['mdi', 'mdi-chevron-down rotate-180']
              : ['mdi', 'mdi-chevron-down']
          "
      /></span>
      <div class="flex flex-row justify-between">
        <div class="flex flex-row gap-2">
          <template v-if="useOrderStore().isOnSiteInspection()">
            <GatewayStatusBadge
              v-for="(ip, index) in activeInspectionGateways"
              :key="ip.type + index"
              :status="ip.inspectionData?.radioCheckResult"
            />
            <BaseChip
              v-for="(tag, index) in getDeviceTags(props.roomGroup)[
                'ACTIVE'
              ].filter((tag) => tag.label === 'Test-SRT')"
              :key="tag.label + index"
              :text="tag.label"
              :number="tag.info"
              :custom-color="tag.color"
              class="mb-1 w-full"
            />
          </template>
          <template v-else>
            <GatewayStatusBadge
              v-if="isInstallationLocation"
              :status="OverallRadioCheckStatus.COMPLETED"
              data-testId="installationLocationTag"
            />
          </template>

          <BaseChip
            v-if="inactiveInstallationPoints.length > 0"
            :icon="NOT_MOUNTED_ICON"
            type="danger"
            :number="numberOfInactiveDevices"
          />
          <BaseChip
            v-if="noSignalDevices.length > 0"
            :icon="NO_SIGNAL_ICON"
            type="danger"
            :number="noSignalDevices.length.toString()"
          />
        </div>
      </div>
    </div>
    <div
      :style="translateX"
      :class="[
        {
          'scale-x-99 scale-y-0': !isDeviceListOpen,
          'scale-x-100 scale-y-100': isDeviceListOpen,
        },
        'absolute z-40 flex w-[13.5rem] origin-top translate-y-[calc(0%)] transform flex-col justify-center rounded-b-xl border border-t-0 border-dark-4 border-light-2 bg-light-2 shadow-[0_1px_2px_0px_rgba(0,0,0,0.1)] transition transition-all dark:border-dark-4 dark:bg-dark-4',
      ]"
    >
      <div
        class="mx-3 my-2 flex flex-col gap-2"
        :class="{ 'scale-0': !isDeviceListOpen }"
      >
        <div v-if="!hasInstallationPoints" class="p-1">
          Es sind noch keine Geräte in der Nutzeinheit installiert.
        </div>

        <div v-if="noSignalDevices.length > 0">
          <em class="mdi text-light-error" :class="NO_SIGNAL_ICON"></em>
          Kein Signal
          <BaseChip
            class="mb-1 mr-2 w-full"
            custom-color="#710f0f"
            :number="`${noSignalDevices.length.toString()} / ${
              getDeviceTags(props.roomGroup)['ACTIVE'].find(
                (tag) => tag.label === 'SRT'
              )?.info || 0
            }`"
            text="SRT"
          />
        </div>

        <div v-if="getDeviceTags(props.roomGroup)['INACTIVE']?.length > 0">
          <em class="mdi mr-2 text-light-error" :class="NOT_MOUNTED_ICON"></em>
          <span>Keine Montage</span>
          <BaseChip
            v-for="(tag, index) in getDeviceTags(props.roomGroup)['INACTIVE']"
            :key="tag + index"
            :text="tag.label"
            :number="tag.info"
            :custom-color="tag.color"
            class="mb-1 w-full"
          />
        </div>

        <div v-if="getDeviceTags(props.roomGroup)['ACTIVE']?.length > 0">
          <em class="mdi mdi-check-bold mr-2 text-dark-success"></em>installiert
          <BaseChip
            v-for="(tag, index) in getDeviceTags(props.roomGroup)['ACTIVE']"
            :key="tag + index"
            :text="tag.label"
            :number="tag.info"
            :custom-color="tag.color"
            class="mb-1 mr-2 w-full"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { Roomgroup } from '@/models';
import { computed, inject, onMounted, ref } from 'vue';
import useStores from '@/composables/useStores';
import { useInstallationPointStore } from '@/store/entities/installationPointStore';
import BaseChip from '@/components/UI/Chip/BaseChip.vue';
import { useFunkcheckStore } from '@/store/funkcheck/funkcheckStore';
import { Tag } from '@/utils/canvas/drawingUtils';
import { NO_SIGNAL_ICON, NOT_MOUNTED_ICON } from '@/enums/icons';
import { useLayoutStore } from '@/store/layout/layoutStore';
import { useInspectionPointStore } from '@/store/entities/inspectionPointStore';
import GatewayStatusBadge from '@/components/LoRaWanRadioCheck/Badges/GatewayStatusBadge.vue';
import { DeviceTypeEnum } from '@/enums/device';
import { useOrderStore } from '@/store/order/orderStore';
import { OverallRadioCheckStatus } from '@/enums/radiocheck';

const props = defineProps<{
  roomGroup: Roomgroup;
}>();
const { deviceStore } = useStores();
const dropdown = ref(null);

const isDeviceListOpen = ref<boolean>(false);
const scrollPositionRef = inject('scrollPositionRef', ref(null));
const scrollPosition = ref(0);

onMounted(() => {
  if (!scrollPositionRef.value) return;
  (scrollPositionRef.value as any).addEventListener(
    'scroll',
    getScrollPosition
  );
});

const isDarkMode = computed(() => {
  return useLayoutStore().isDarkMode();
});

const getScrollPosition = () => {
  isDeviceListOpen.value = false;
  if (!scrollPositionRef.value) return;
  scrollPosition.value = (scrollPositionRef.value as any).scrollLeft;
};

const translateX = computed(() => {
  const x = Math.abs(scrollPosition.value + 2);
  return `--tw-translate-x: -${x}px !important`;
});

const toggleDeviceListDropdown = () => {
  if (!dropdown.value) return;
  (dropdown.value as any).scrollIntoView({
    behavior: 'smooth',
    block: 'center',
    inline: 'nearest',
  });
  setTimeout(() => {
    isDeviceListOpen.value = !isDeviceListOpen.value;
  }, 300);
};
const closeDeviceListDropdown = () => {
  isDeviceListOpen.value = false;
};
const hasInstallationPoints = computed(() => {
  return (
    getDeviceTags(props.roomGroup)['ACTIVE']?.length > 0 ||
    getDeviceTags(props.roomGroup)['INACTIVE'].length > 0
  );
});

const numberOfInactiveDevices = computed(() => {
  let totalOfInactiveDevices = 0;
  getDeviceTags(props.roomGroup)['INACTIVE'].forEach((tag) => {
    totalOfInactiveDevices = totalOfInactiveDevices + tag.info;
  });
  return totalOfInactiveDevices;
});

const getDeviceTags = (roomgroup: Roomgroup): Record<string, Array<Tag>> => {
  const inspectionTags = useInspectionPointStore().getTagsOfRoomgroup(
    roomgroup.id
  );
  const installationTags = useInstallationPointStore().getTagsOfRoomgroup(
    roomgroup.id
  );

  const result: Record<string, Array<Tag>> = {
    ACTIVE: [],
    INACTIVE: [],
  };

  result.ACTIVE = [...inspectionTags.ACTIVE, ...installationTags.ACTIVE];
  result.INACTIVE = [...inspectionTags.INACTIVE, ...installationTags.INACTIVE];

  return result;
};

const activeInspectionGateways = computed(() => {
  return useInspectionPointStore()
    .getInspectionPointsByRoomGroupId(props.roomGroup.id)
    .filter(
      (ip) => ip.supportedDeviceType === DeviceTypeEnum.LO_RA_WAN_GATEWAY
    );
});

const activeInstallationPoints = computed(() => {
  if (useOrderStore().isOnSiteInspection()) {
    return useInspectionPointStore()
      .getInspectionPointsByRoomGroupId(props.roomGroup.id)
      .filter((ip) => ip.activeTestDeviceId);
  }

  return useInstallationPointStore()
    .getInstallationPointsByRoomGroupId(props.roomGroup.id)
    .filter((ip) => ip.activeDeviceId);
});

const inactiveInstallationPoints = computed(() => {
  return getDeviceTags(props.roomGroup)['INACTIVE'];
});

const noSignalDevices = computed(() => {
  const radioCheckResult = useFunkcheckStore()?.loRaWanRadioCheck?.result;
  if (!radioCheckResult) {
    return [];
  }
  return useInstallationPointStore()
    .getInstallationPointsByRoomGroupId(props.roomGroup.id)
    .reduce((acc, value) => {
      if (
        value.supportedDeviceType === 'SMART_RADIATOR_THERMOSTAT' &&
        value.activeDeviceId &&
        radioCheckResult[value.activeDeviceId]?.status !== 'OK'
      ) {
        return acc.concat(value);
      } else {
        return acc;
      }
    }, []);
});

const isInstallationLocation = computed(() => {
  return (
    useInspectionPointStore().getCompletedGatewaysByRoomGroupId(
      props.roomGroup.id
    ).length > 0
  );
});
</script>
