import {
  GetBundlesResult,
  LiveUpdate,
} from '@capawesome/capacitor-live-update';
import { WebReleases } from '@/models/mobile/AppInfo';

export const installRelease = async (app: WebReleases) => {
  await LiveUpdate.getBundles().then(
    async (bundlesResult: GetBundlesResult) => {
      const sameBundleFound = bundlesResult.bundleIds.find(
        (bundleVersion) => bundleVersion === app.versionCode
      );
      if (sameBundleFound) {
        await LiveUpdate.deleteBundle({ bundleId: app.versionCode });
      }
      await LiveUpdate.downloadBundle({
        url: app.url,
        bundleId: app.versionCode,
      }).then((response) => {
        console.debug(response);
        LiveUpdate.setBundle({ bundleId: app.versionCode });
        console.debug('downloaded :' + app.versionCode);
      });
    }
  );
};
