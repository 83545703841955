<template>
  <div v-if="isLoading" class="loader-wrapper">
    <div class="loader-body">
      <div class="loader-icon">
        <svg
          v-if="loader.status === Status.SUCCESS"
          id="checkmark-path"
          viewBox="0 0 24 24"
        >
          <circle cx="12" cy="12" r="10" fill="white"></circle>
          <path
            id="checkmark-tick"
            d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
          />
        </svg>
        <svg
          v-if="loader.status === Status.ERROR"
          id="error-path"
          viewBox="0 0 24 24"
        >
          <circle cx="12" cy="12" r="10" fill="white"></circle>

          <path
            d="M13,13H11V7H13M13,17H11V15H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"
          />
        </svg>

        <svg
          v-if="loader.status === Status.PENDING"
          id="L6"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 100 100"
          enable-background="new 0 0 100 100"
          xml:space="preserve"
        >
          <rect
            fill="none"
            stroke="#fff"
            stroke-width="4"
            x="25"
            y="25"
            width="50"
            height="50"
          >
            <animateTransform
              id="strokeBox"
              attributeName="transform"
              dur="0.5s"
              from="0 50 50"
              to="180 50 50"
              type="rotate"
              attributeType="XML"
              begin="rectBox.end"
            />
          </rect>
          <rect x="27" y="27" fill="#fff" width="46" height="50">
            <animate
              id="rectBox"
              attributeName="height"
              dur="1.3s"
              attributeType="XML"
              from="50"
              to="0"
              fill="freeze"
              begin="0s;strokeBox.end"
            />
          </rect>
        </svg>
      </div>
      <div class="loader-title has-text-centered text-xl">
        <slot>{{ loader.text }}</slot>
      </div>
    </div>
  </div>
</template>

<script>
import { useLayoutStore } from '@/store/layout/layoutStore';
import { Status } from '@/enums/generic';

export default {
  name: 'BaseLoader',
  setup() {
    const layoutStore = useLayoutStore();

    return {
      layoutStore,
    };
  },
  computed: {
    Status() {
      return Status;
    },
    loader() {
      return useLayoutStore().loader;
    },
    isLoading() {
      return this.layoutStore.isLoading;
    },
  },
};
</script>

<style scoped lang="scss">
.loader-icon {
  display: flex;
  justify-content: center;

  svg {
    width: 100px;
    height: 100px;
    margin: 20px;
    display: inline-block;
  }
}

.loader-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-title {
  color: white;
}

@keyframes fadeInScale {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  50% {
    opacity: 0.5;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

#checkmark-path {
  stroke: $success-color;
  fill: $success-color;
  animation: fadeInScale 0.5s ease-in-out forwards;
}

#error-path {
  stroke: $error-color;
  fill: $error-color;
  animation: fadeInScale 0.5s ease-in-out forwards;
}
</style>
