<template>
  <BaseFormInput
    v-model="localDate"
    :validation="validation"
    label="Datum & Uhrzeit"
    placeholder="Datum"
    type="datetime-local"
  />
</template>
<script setup lang="ts">
import { computed, WritableComputedRef } from 'vue';
import BaseFormInput from '@/components/UI/Input/BaseFormInput.vue';
import { Validation } from '@vuelidate/core';

const props = defineProps<{
  date?: string;
  validation?: Validation;
}>();
const emit = defineEmits(['update:date']);

const localDate: WritableComputedRef<string> = computed({
  get() {
    const date = new Date(props.date);
    const yyyy = date.getFullYear();
    let mm = date.getMonth() + 1;
    let dd = date.getDate();
    let hh = date.getHours();
    let min = date.getMinutes();

    mm = mm < 10 ? '0' + mm : mm;
    dd = dd < 10 ? '0' + dd : dd;
    hh = hh < 10 ? '0' + hh : hh;
    min = min < 10 ? '0' + min : min;

    return `${yyyy}-${mm}-${dd}T${hh}:${min}`;
  },
  set(value) {
    const localDate = new Date(value);
    const yyyy = localDate.getUTCFullYear();
    const mm = String(localDate.getUTCMonth() + 1).padStart(2, '0');
    const dd = String(localDate.getUTCDate()).padStart(2, '0');
    const hh = String(localDate.getUTCHours()).padStart(2, '0');
    const min = String(localDate.getUTCMinutes()).padStart(2, '0');
    const sec = String(localDate.getUTCSeconds()).padStart(2, '0');
    const ms = String(localDate.getUTCMilliseconds()).padStart(3, '0');

    emit('update:date', `${yyyy}-${mm}-${dd}T${hh}:${min}:${sec}.${ms}Z`);
  },
});
</script>
