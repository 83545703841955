import { Pinia, Store } from '@/store/pinia-class-component';
import {
  fetchImages,
  fetchImagesConfirmation,
} from '@/api/pictures/PicturesApi';
import { ImageObjectType } from '@/models/Image';
import { PictureConfirmation } from '@/api/pictures/PictureConfirmation';
import { Image } from '@/models/Image';

export function useImagesStore() {
  return new ImageStore();
}

@Store
export class ImageStore extends Pinia {
  private readonly _images: PictureConfirmation[];
  private _image: Image | undefined;
  private _isImageOpen: boolean;
  private _remoteImages: Image[];
  private _imagesToBeDeleted: Image[];
  private _keywordLists: { [key: string]: Set<string> };

  constructor() {
    super();
    this._images = [];
    this._image = undefined;
    this._isImageOpen = false;
    this._remoteImages = [];
    this._imagesToBeDeleted = [];
    this._keywordLists = {
      BUILDING: new Set(),
      INSPECTED_BUILDING: new Set(),
      ORDER: new Set(),
    };
  }

  get images(): PictureConfirmation[] {
    return this._images;
  }

  get remoteImages(): Image[] {
    return this._remoteImages;
  }

  get imagesToBeDeleted(): Image[] {
    return this._imagesToBeDeleted;
  }

  get image(): Image | undefined {
    return this._image;
  }

  get isImageOpen(): boolean {
    return this._isImageOpen;
  }

  openImage() {
    this._isImageOpen = true;
  }

  setImage(image: Image) {
    this._image = image;
  }

  resetImage() {
    this._image = undefined;
  }

  closeImage() {
    this._isImageOpen = false;
    this.resetImage();
  }

  getKeywordList(type: ImageObjectType | undefined | null): Set<string> {
    if (!type) return new Set();

    return this._keywordLists[type] || new Set();
  }
  updateKeywordList(
    type: ImageObjectType | undefined | null,
    keywords: string[]
  ) {
    if (!type) return new Set();

    keywords.forEach((keyword) => {
      this._keywordLists[type].add(keyword);
    });
  }

  objectHasImages(objectId: string): boolean {
    return this._images.some((img) => img.objectId === objectId);
  }

  addImageToRemote(image: Image) {
    this._remoteImages.push(image);
  }

  addImagesToBeDeleted(images: Image[]) {
    images.forEach((image) => this._imagesToBeDeleted.push(image));
  }

  resetImagesToBeDeleted() {
    this._imagesToBeDeleted = [];
  }

  addObjectToList(objectId: string, objectType: ImageObjectType) {
    if (!this.objectHasImages(objectId)) {
      this._images.push(new PictureConfirmation(objectId, objectType));
    }
  }

  async getImagesConfirmation(businessEntityId: string) {
    const picturesConfirmation = await fetchImagesConfirmation(
      businessEntityId
    );
    picturesConfirmation.forEach(({ objectId, objectType }) => {
      if (!this.objectHasImages(objectId)) {
        this.addObjectToList(objectId, objectType);
      }
    });
  }

  getImages(businessEntityId: string, objectId: string) {
    return fetchImages(businessEntityId, objectId);
  }
}
