/**
 * Provides functionality to map DTOs to domain objects and vice versa.
 * Mapper for radiator rating.
 */
import { PGRadiator, PPRadiator, Radiator } from '@/models/radiators/Radiator';

export class RadiatorIdentificationFactory {
  /**
   * Mapper for radiator rating to be consumed by the Backend
   *
   * Important: This mapping should be avoided!
   * Backend data model should be aligned instead
   */
  public static by<T extends Radiator>(radiator: T): T {
    const radiatorDto = JSON.parse(JSON.stringify(radiator));
    radiatorDto.id = radiator.id;
    delete radiatorDto['id'];
    radiatorDto.parentRoomId = radiator.parentRoomId;
    delete radiatorDto['type'];

    return radiatorDto;
  }

  public static android<T extends Radiator>(radiator: T): T {
    const replacer = (key: any, value: any) =>
      typeof value === 'undefined' ? null : value;
    const radiatorDto = JSON.parse(JSON.stringify(radiator, replacer));
    radiatorDto.type = radiator.radiatorType;
    delete radiatorDto['radiatorType'];

    if (radiator instanceof PPRadiator) {
      radiatorDto.depth = radiator.depth.withCoverPanel
        ? radiator.depth.withCoverPanel
        : radiator.depth.withoutCoverPanel;

      radiatorDto.layers = radiator.layers.join('');
    } else if (radiator instanceof PGRadiator) {
      radiatorDto.layers = radiator.layers.join('');
    }
    return radiatorDto;
  }
}
