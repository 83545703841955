<template>
  <div class="grid auto-rows-fr gap-5 pl-0" :class="cols">
    <div
      v-for="(option, index) in optionsList"
      :key="option.name + index"
      :data-testid="option.name"
      class="relative m-0 p-0"
    >
      <input
        :id="label + '_' + option.name"
        v-model="localSelection"
        type="radio"
        :value="option.value"
        class="peer absolute top-0 opacity-0"
        :data-testid="
          'radioButtonInput' +
          (JSON.stringify(option.value) === JSON.stringify(selection)
            ? '_active'
            : '')
        "
      />
      <label
        :for="label + '_' + option.name"
        class="flex h-full cursor-pointer flex-col items-center justify-end rounded-lg border-2 bg-light-1 p-5 hover:bg-light-2 peer-checked:border-primary-2 peer-checked:text-primary-2 dark:border-dark-5 dark:bg-dark-3 dark:hover:bg-dark-4 dark:peer-checked:text-primary-2"
      >
        <div
          v-if="option.image"
          class="mb-3 flex h-36 items-center justify-center"
        >
          <img
            class="m-0 max-h-32 object-contain dark:invert"
            :src="option.image"
            :alt="option.name"
          />
        </div>
        <div>
          <em
            v-if="option.icon"
            :class="'mdi-' + option.icon"
            class="mdi mr-2 text-xl"
          />

          {{ option.name }}
        </div>
        <div class="text-center text-sm">
          {{ option.subtitle }}
        </div>
      </label>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import { RadioOption } from '@/enums/radiator/generic';

const props = defineProps<{
  optionsList: Array<RadioOption>;
  label?: string;
  selection: any;
  cols?: number | string;
}>();

const emit = defineEmits(['update:selection', 'option-click']);

const localSelection = computed({
  get() {
    return props.selection;
  },
  set(value) {
    emit('update:selection', value);
    emit('option-click', value);
  },
});

const cols = computed(() => {
  switch (props.cols) {
    case 1:
      return 'md:grid-cols-1';
    case 2:
      return 'md:grid-cols-2';
    case 3:
      return 'md:grid-cols-3';
    case 4:
      return 'md:grid-cols-4';
    case 5:
      return 'md:grid-cols-5';
    case 6:
      return 'md:grid-cols-6';
    case 7:
      return 'md:grid-cols-7';
    case 8:
      return 'md:grid-cols-8';
    case 9:
      return 'md:grid-cols-9';
    case 'wrap':
      return 'flex flex-wrap';
    default:
      return 'md:grid-cols-2';
  }
});

const uniqueId = () => {
  return Math.random().toString(36).substr(2, 9);
};
</script>
<style scoped>
.active {
  filter: invert(80%) sepia(70%) saturate(7052%) hue-rotate(198deg)
    brightness(93%) contrast(105%);
}
</style>
