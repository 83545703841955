<template>
  <div class="sm:flex sm:gap-4">
    <div class="sm:basis-1/2">
      <BaseFormInput
        v-model.number="localDeviceEvent.counter"
        :validation="validation.deviceEvent.counter"
        :optional="optional"
        label="Zählerstand bei Erfassung"
        inputmode="numeric"
        pattern="[0-9]*"
        placeholder="Zählerstand"
        type="number"
        test-id="counterOnMount"
      />
    </div>
    <div class="sm:basis-1/2">
      <MeasurementUnit
        v-model:unit="localDevice.measurementUnit"
        :validation="validation.legacyDevice.measurementUnit"
        :optional="optional"
        :device="localDevice"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps, WritableComputedRef } from 'vue';
import MeasurementUnit from '@/components/Forms/DeviceFormComponents/MeasurementUnit.vue';
import { LegacyMeteringDevice } from '@/models/devices/Device';
import { DeviceEvent } from '@/models/installationPoint/DeviceEvent';
import { Validation } from '@vuelidate/core';
import BaseFormInput from '@/components/UI/Input/BaseFormInput.vue';

const props = defineProps<{
  device: LegacyMeteringDevice<any>;
  deviceEvent: DeviceEvent;
  validation: Validation;
  optional?: boolean;
}>();

const emit = defineEmits(['update:device', 'update:deviceEvent']);

const localDevice: WritableComputedRef<LegacyMeteringDevice<any>> = computed({
  get() {
    return props.device;
  },
  set(value) {
    emit('update:device', value);
  },
});

const localDeviceEvent: WritableComputedRef<DeviceEvent> = computed({
  get() {
    return props.deviceEvent;
  },
  set(value) {
    emit('update:deviceEvent', value);
  },
});
</script>
