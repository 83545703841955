export const wizardStepsGR = Object.freeze({
  DIMENSIONS: 'Maße',
  SPACING: 'Teilung',
  PIPE_STRENGTH: 'Rohrstärke',
  TYPE_KEY: 'Typenschlüssel',
  DISTRIBUTION_CHANNEL_SHAPE: 'Form Verteilkanal',
  PIPE_SHAPE: 'Form Rohre',
  PIPE_INNER_SHAPE: 'Rohrinnenform',
  ELEMENT_COUNT: 'Gliederzahl',
  MOUNTING_VARIANT: 'Montagevariante',
  MANUFACTURER: 'Hersteller',
  CONNECTION_TYPE: 'Anschlussart',
});

export const imagesGR = Object.freeze({
  DIMENSIONS: require('@/assets/img/radiators/GR/dimensions.svg'),
  SPACING: require('@/assets/img/radiators/GR/spacing.svg'),
  PIPE_STRENGTH: require('@/assets/img/radiators/GR/pipeStrength.png'),
});

export const connectionTypesGR = Object.freeze([
  {
    name: 'E',
    subtitle: 'Gleichseitiger Anschluss, mit oberem Vorlauf',
    value: 'E1',
    image: require('@/assets/img/radiators/common/connectionTypes/AA_E1.svg'),
  },
  {
    name: 'E',
    subtitle: 'Untere Anbindung mit Trennscheibe im Vorlauf',
    value: 'E2',
    image: require('@/assets/img/radiators/common/connectionTypes/AA_E2.svg'),
  },
  {
    name: 'E',
    subtitle: 'Obere Vorlaufeinführung in Verbindung mit unterer Anbindung',
    value: 'E3',
    image: require('@/assets/img/radiators/common/connectionTypes/AA_E3.svg'),
  },
  {
    name: 'E',
    subtitle: 'Obere Vorlaufeinführung in Verbindung mit unterer Anbindung',
    value: 'E4',
    image: require('@/assets/img/radiators/common/connectionTypes/AA_E4.svg'),
  },
  {
    name: 'E',
    subtitle:
      'Untere Vorlaufeinführung Achtung! Keine HKV-Montage, siehe Handbuch',
    value: 'E5',
    image: require('@/assets/img/radiators/common/connectionTypes/AA_E5.svg'),
  },
  {
    name: 'W',
    subtitle: 'Vorlauf oben, Rücklauf unten',
    value: 'W1',
    image: require('@/assets/img/radiators/common/connectionTypes/AA_W1.svg'),
  },
  {
    name: 'W',
    subtitle: 'Kurzschlussstrecke (Einrohrsystem)',
    value: 'W2',
    image: require('@/assets/img/radiators/common/connectionTypes/AA_W2.svg'),
  },
  {
    name: 'W',
    subtitle: 'Reihenschaltung von mehreren Heizkörpern',
    value: 'W3',
    image: require('@/assets/img/radiators/common/connectionTypes/AA_W3.svg'),
  },
  {
    name: 'W',
    subtitle:
      'Untere Vorlaufeinführung Achtung! Keine HKV-Montage, siehe Handbuch',
    value: 'W4',
    image: require('@/assets/img/radiators/common/connectionTypes/AA_W4.svg'),
  },
  {
    name: 'R',
    subtitle: 'Beide Anschlüsse unten, links und rechts',
    value: 'R1',
    image: require('@/assets/img/radiators/common/connectionTypes/AA_R1.svg'),
  },
  {
    name: 'R',
    subtitle: 'Kurzschlussstrecke (Einrohrsystem)',
    value: 'R2',
    image: require('@/assets/img/radiators/common/connectionTypes/AA_R2.svg'),
  },
  {
    name: 'R',
    subtitle: 'Reihenschaltung von mehreren Heizkörpern',
    value: 'R3',
    image: require('@/assets/img/radiators/common/connectionTypes/AA_R3.svg'),
  },
  {
    name: 'S',
    subtitle: 'Mit unterer Trennscheibe am Vorlauf oder mittig',
    value: 'S1',
    image: require('@/assets/img/radiators/common/connectionTypes/AA_S1.svg'),
  },
  {
    name: 'S',
    subtitle:
      'Obere Einführung von Vor- und Rücklauf, obere Trennscheibe am Rücklauf',
    value: 'S2',
    image: require('@/assets/img/radiators/common/connectionTypes/AA_S2.svg'),
  },
  {
    name: 'S',
    subtitle: 'Reihenschaltung mit Trennscheibe vorlaufseitig',
    value: 'S3',
    image: require('@/assets/img/radiators/common/connectionTypes/AA_S3.svg'),
  },
  {
    name: 'M',
    subtitle: 'Mittenanschluss mit Ventileinsatz am Vorlaufanschluss',
    value: 'M1',
    image: require('@/assets/img/radiators/common/connectionTypes/AA_M1.svg'),
  },
]);

export const typeKey = Object.freeze([
  {
    name: '0',
    subtitle: '',
    value: 0,
    image: require('@/assets/img/radiators/GR/typeKey/TS_0.png'),
  },
  {
    name: '1',
    subtitle: '',
    value: 1,
    image: require('@/assets/img/radiators/GR/typeKey/TS_1.png'),
  },
  {
    name: '2',
    subtitle: '',
    value: 2,
    image: require('@/assets/img/radiators/GR/typeKey/TS_2.png'),
  },
  {
    name: '3',
    subtitle: '',
    value: 3,
    image: require('@/assets/img/radiators/GR/typeKey/TS_3.png'),
  },
  {
    name: '4',
    subtitle: '',
    value: 4,
    image: require('@/assets/img/radiators/GR/typeKey/TS_4.png'),
  },
  {
    name: '5',
    subtitle: '',
    value: 5,
    image: require('@/assets/img/radiators/GR/typeKey/TS_5.png'),
  },
  {
    name: '6',
    subtitle: '',
    value: 6,
    image: require('@/assets/img/radiators/GR/typeKey/TS_6.png'),
  },
  {
    name: '7',
    subtitle: '',
    value: 7,
    image: require('@/assets/img/radiators/GR/typeKey/TS_7.png'),
  },
  {
    name: '8',
    subtitle: '',
    value: 8,
    image: require('@/assets/img/radiators/GR/typeKey/TS_8.png'),
  },
  {
    name: '9',
    subtitle: '',
    value: 9,
    image: require('@/assets/img/radiators/GR/typeKey/TS_9.png'),
  },
  {
    name: '10',
    subtitle: '',
    value: 10,
    image: require('@/assets/img/radiators/GR/typeKey/TS_10.png'),
  },
  {
    name: '11',
    subtitle: '',
    value: 11,
    image: require('@/assets/img/radiators/GR/typeKey/TS_11.png'),
  },
  {
    name: '12',
    subtitle: '',
    value: 12,
    image: require('@/assets/img/radiators/GR/typeKey/TS_12.png'),
  },
]);

export const pipeInnerShape = Object.freeze([
  {
    name: '1',
    subtitle: '',
    value: 1,
    image: require('@/assets/img/radiators/GR/pipeInnerShape/RIF_1.png'),
  },
  {
    name: '2',
    subtitle: '',
    value: 2,
    image: require('@/assets/img/radiators/GR/pipeInnerShape/RIF_2.png'),
  },
]);

export const pipeShape = Object.freeze([
  {
    name: '0',
    subtitle: '',
    value: 0,
    image: require('@/assets/img/radiators/HW/pipeShape/FR_0.svg'),
  },
  {
    name: '1',
    subtitle: '',
    value: 1,
    image: require('@/assets/img/radiators/HW/pipeShape/FR_1.svg'),
  },
  {
    name: '2',
    subtitle: '',
    value: 2,
    image: require('@/assets/img/radiators/HW/pipeShape/FR_2.svg'),
  },
  {
    name: '3',
    subtitle: '',
    value: 3,
    image: require('@/assets/img/radiators/HW/pipeShape/FR_3.svg'),
  },
  {
    name: '4',
    subtitle: '',
    value: 4,
    image: require('@/assets/img/radiators/HW/pipeShape/FR_4.svg'),
  },
  {
    name: '5',
    subtitle: '',
    value: 5,
    image: require('@/assets/img/radiators/HW/pipeShape/FR_5.svg'),
  },
  {
    name: '6',
    subtitle: '',
    value: 6,
    image: require('@/assets/img/radiators/HW/pipeShape/FR_6.svg'),
  },
  {
    name: '7',
    subtitle: 'keine Anbindung',
    value: 7,
    image: undefined,
  },
]);

export const mountingVariant = Object.freeze([
  {
    name: '1',
    subtitle: '',
    value: 'SW1',
    image: require('@/assets/img/radiators/GR/mountingVariant/MV_1.png'),
  },
  {
    name: '2',
    subtitle: '',
    value: 'SR2',
    image: require('@/assets/img/radiators/GR/mountingVariant/MV_2.png'),
  },
  {
    name: '3',
    subtitle: '',
    value: 'SW3',
    image: require('@/assets/img/radiators/GR/mountingVariant/MV_3.png'),
  },
  {
    name: '4',
    subtitle: '',
    value: 'SR4',
    image: require('@/assets/img/radiators/GR/mountingVariant/MV_4.png'),
  },
  {
    name: '5',
    subtitle: '',
    value: 'SR5',
    image: require('@/assets/img/radiators/GR/mountingVariant/MV_5.png'),
  },
  {
    name: '6',
    subtitle: '',
    value: 'SW6',
    image: require('@/assets/img/radiators/GR/mountingVariant/MV_6.png'),
  },
  {
    name: '7',
    subtitle: '',
    value: 'SR7',
    image: require('@/assets/img/radiators/GR/mountingVariant/MV_7.png'),
  },
  {
    name: '8',
    subtitle: '',
    value: 'SR8',
    image: require('@/assets/img/radiators/GR/mountingVariant/MV_8.png'),
  },
  {
    name: '9',
    subtitle: '',
    value: 'SR9',
    image: require('@/assets/img/radiators/GR/mountingVariant/MV_9.png'),
  },
  {
    name: '10',
    subtitle: '',
    value: 'SW10',
    image: require('@/assets/img/radiators/GR/mountingVariant/MV_10.png'),
  },
  {
    name: '11',
    subtitle: '',
    value: 'SR11',
    image: require('@/assets/img/radiators/GR/mountingVariant/MV_11.png'),
  },
  {
    name: '12',
    subtitle: '',
    value: 'SR12',
    image: require('@/assets/img/radiators/GR/mountingVariant/MV_12.png'),
  },
  {
    name: '13',
    subtitle: '',
    value: 'SR13',
    image: require('@/assets/img/radiators/GR/mountingVariant/MV_13.png'),
  },
  {
    name: '14',
    subtitle: '',
    value: 'SR14',
    image: require('@/assets/img/radiators/GR/mountingVariant/MV_14.png'),
  },
]);

export const distributionChannelShape = Object.freeze([
  {
    name: '0',
    subtitle: '',
    value: 0,
    image: require('@/assets/img/radiators/HW/distributionChannelShape/DCShape_0.svg'),
  },
  {
    name: '1',
    subtitle: '',
    value: 1,
    image: require('@/assets/img/radiators/HW/distributionChannelShape/DCShape_1.svg'),
  },
  {
    name: '2',
    subtitle: '',
    value: 2,
    image: require('@/assets/img/radiators/HW/distributionChannelShape/DCShape_2.svg'),
  },
  {
    name: '3',
    subtitle: '',
    value: 3,
    image: require('@/assets/img/radiators/HW/distributionChannelShape/DCShape_3.svg'),
  },
  {
    name: '4',
    subtitle: '',
    value: 4,
    image: require('@/assets/img/radiators/HW/distributionChannelShape/DCShape_4.svg'),
  },
  {
    name: '5',
    subtitle: '',
    value: 5,
    image: require('@/assets/img/radiators/HW/distributionChannelShape/DCShape_5.svg'),
  },
  {
    name: '6',
    subtitle: '',
    value: 6,
    image: require('@/assets/img/radiators/HW/distributionChannelShape/DCShape_6.svg'),
  },
  {
    name: '7',
    subtitle: '',
    value: 7,
    image: require('@/assets/img/radiators/HW/distributionChannelShape/DCShape_7.svg'),
  },
  {
    name: '8',
    subtitle: 'unterschiedliche Querschnitte',
    value: 8,
    image: undefined,
  },
  {
    name: '9',
    subtitle: 'weitere/keine',
    value: 9,
    image: undefined,
  },
]);
