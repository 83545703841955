import { useOrderStore } from '@/store/order/orderStore';
import { computed } from 'vue';
import { Roomgroup } from '@/models';
import { useRouter } from 'vue-router';

export function useRoomGroupProgress(roomGroup: Roomgroup | undefined) {
  const router = useRouter();

  if (!roomGroup) {
    throw new Error('roomGroup is undefined');
  }

  const completeRoomGroup = () => {
    if (!roomGroup) return;
    if (!isRoomGroupComplete.value) {
      toggleRoomGroupProgress();
    }
    router.push('/gebaeudeuebersicht');
  };

  const toggleRoomGroupProgress = () => {
    useOrderStore().toggleRoomGroupProgress(roomGroup);
  };

  const isRoomGroupComplete = computed(() => {
    return useOrderStore().isRoomGroupCompleted(roomGroup);
  });

  const roomGroupProgressLabel = computed(() => {
    return isRoomGroupComplete.value
      ? 'als unfertig markieren'
      : 'als fertig markieren';
  });

  return {
    completeRoomGroup,
    toggleRoomGroupProgress,
    isRoomGroupComplete,
    roomGroupProgressLabel,
  };
}
