import { generateUniqueId } from '@/helpers/uuidUtils';
import { entityTypes } from '@/enums/generic';

export class InspectedBuilding {
  public id: string;
  public buildingId?: string;
  public note: string;
  public type: string;

  constructor() {
    this.id = generateUniqueId();
    this.buildingId = undefined;
    this.note = '';
    this.type = entityTypes.inspectedBuilding;
  }

  getParentId() {
    return this.buildingId;
  }
}
