<template>
  <div :class="`toast-container ${position} ${background}`">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BaseToast',
  components: {},
  props: {
    position: {
      type: String,
      default: 'upper-right',
      validator: (prop: string) =>
        ['upper-right', 'upper-left', 'bottom-right', 'bottom-left'].includes(
          prop
        ),
    },
    background: {
      type: String,
      default: 'is-background-success',
    },
  },
});
</script>

<style lang="scss" scoped>
.toast-container {
  position: absolute;
  padding: 20px;
  border-radius: 6px;
  color: #fff;

  &.upper-right {
    top: 92px;
  }

  &.upper-left {
    top: 92px;
  }

  &.bottom-right {
    bottom: 92px;
  }

  &.bottom-left {
    bottom: 92px;
  }
}

.is-background-success {
  background-color: #00d1b2;
}

.is-background-info {
  background-color: #209cee;
}

.is-background-danger {
  background-color: #ff3860;
}
</style>
