import { entityTypes } from '@/enums/generic';
import { generateUniqueId } from '@/helpers/uuidUtils';
import { allgemeineNutzung, usageTypeList } from '@/enums/roomgroup';

/**
 * Any adjustments to the properties of this class
 * should be reflected in the android kotlin class {@see Roomgroup.kt}
 */
export class Roomgroup {
  public id: string;
  public buildingId?: string;
  public customerAssignmentReference?: string;
  public usageType: string;
  public ordinal: number;
  public note?: string;
  public isAccessible: boolean;
  public reasonNotAccessible?: string;
  public dateNotAccessible?: string;
  public isVacant: boolean;
  public number?: number;
  public doorbellName?: string;
  public businessType?: string;
  public deviceReference?: string;
  public type: string;

  constructor() {
    this.id = generateUniqueId();
    this.customerAssignmentReference = undefined;
    this.buildingId = undefined;
    this.usageType = usageTypeList[0].value;
    this.ordinal = 0;
    this.note = undefined;
    this.isAccessible = true;
    this.reasonNotAccessible = undefined;
    this.dateNotAccessible = undefined;
    this.isVacant = false;
    this.number = undefined;
    this.doorbellName = undefined;
    this.businessType = undefined;
    this.deviceReference = undefined;
    this.type = entityTypes.roomgroup;
  }

  clear() {
    this.note = undefined;
    this.isAccessible = true;
    this.reasonNotAccessible = undefined;
    this.isVacant = false;
    this.number = undefined;
    this.doorbellName = undefined;
    this.customerAssignmentReference = undefined;
    this.businessType = undefined;
  }

  getParentId() {
    return this.buildingId;
  }

  getLabel() {
    if (
      this.usageType ===
      usageTypeList.find((u) => u.name === allgemeineNutzung)?.value
    ) {
      return allgemeineNutzung;
    }

    return this.doorbellName;
  }

  setIsAccessible(isAccessible: boolean) {
    if (isAccessible) {
      this.reasonNotAccessible = undefined;
    }
    this.isAccessible = isAccessible;
  }
}
