export const timeAgo = (date: Date) => {
  const seconds = Math.floor((new Date() - date) / 1000);

  let interval = Math.floor(seconds / 31536000);
  if (interval > 1) {
    return 'vor ' + interval + ' Jahren';
  }

  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return 'vor ' + interval + ' Monaten';
  }

  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return 'vor ' + interval + ' Tagen';
  }

  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return 'vor ' + interval + ' Stunden';
  }

  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return 'vor ' + interval + ' Minuten';
  }

  if (seconds < 10) return 'gerade eben';

  return 'vor ' + Math.floor(seconds) + ' Sekunden';
};
