import { generateUniqueId } from '@/helpers/uuidUtils';

export const EventType = {
  MOUNT_EVENT: 'MOUNT_EVENT',
  UNMOUNT_EVENT: 'UNMOUNT_EVENT',
  CAPTURE_EVENT: 'CAPTURE_EVENT',
} as const;

export type DeviceEventParams = {
  timestamp: string;
  reason?: string;
  counter?: number;
  controlValue?: number;
  type: (typeof EventType)[keyof typeof EventType];
};

export class DeviceEvent {
  id: string;
  deviceId: string;
  timestamp: string;
  counter?: number;
  reason?: string;
  type: (typeof EventType)[keyof typeof EventType];

  constructor(
    type: (typeof EventType)[keyof typeof EventType],
    deviceId: string,
    timestamp: string,
    counter: number | undefined,
    reason?: string
  ) {
    this.id = generateUniqueId();
    this.type = type;
    this.deviceId = deviceId;
    this.timestamp = timestamp;
    this.reason = reason;
    this.counter = counter;
  }
}
export class MountEvent extends DeviceEvent {
  controlValue?: number;
  constructor(
    deviceId: string,
    timestamp: string,
    counter?: number,
    controlValue?: number,
    reason?: string
  ) {
    super(EventType.MOUNT_EVENT, deviceId, timestamp, counter, reason);
    this.controlValue = controlValue;
  }
}

export class UnmountEvent extends DeviceEvent {
  controlValue?: number;
  constructor(
    deviceId: string,
    timestamp: string,
    counter?: number,
    controlValue?: number,
    reason?: string
  ) {
    super(EventType.UNMOUNT_EVENT, deviceId, timestamp, counter, reason);
    this.controlValue = controlValue;
  }
}

export class CaptureEvent extends DeviceEvent {
  controlValue?: number;

  constructor(
    deviceId: string,
    timestamp: string,
    counter?: number,
    controlValue?: number,
    reason?: string
  ) {
    super(EventType.CAPTURE_EVENT, deviceId, timestamp, counter, reason);
    this.controlValue = controlValue;
  }
}
