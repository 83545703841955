<template>
  <div class="inline-flex items-center">
    <label id="select" class="mr-2 whitespace-nowrap">{{ label }}</label>
    <select
      id="select"
      v-model="selectValue"
      class="rounded-lg border border-dark-3 text-sm focus:border-primary-2 focus:ring-primary-2 dark:border-dark-3 dark:bg-dark-3"
    >
      <option
        v-for="option in selectList"
        :key="option.value"
        :value="option.value"
      >
        {{ option.name }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'BaseSelect',
  props: {
    label: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    selectList: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: String || Number,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      selectValue: '',
    };
  },
  watch: {
    selectValue(newSelectedValue) {
      this.$emit(
        'update:modelValue',
        this.type === 'number' ? Number(newSelectedValue) : newSelectedValue
      );
    },
  },
  mounted() {
    this.selectValue = !this.modelValue
      ? this.selectList[0].value
      : this.modelValue;
  },
  methods: {},
};
</script>
