<template>
  <LayoutWrapper is-side-bar-open>
    <div class="mt-10 flex flex-col justify-center">
      <h4>App versions beta</h4>
      <pre> CurrentBundle: {{ currentBundleId }} </pre>

      <div class="flex" style="width: 100%">
        <ul class="w-full">
          <li class="mb-4 flex justify-between">
            <span class="has-text-weight-bold">Version</span>
            <span class="has-text-weight-bold">Timestamp</span>
            <span></span>
          </li>
          <li
            v-for="app in apps"
            :key="app.versionCode"
            class="mt-4 flex justify-between"
          >
            <span> {{ app.versionCode }} </span>
            <span> {{ app.releaseDate }} </span>
            <PrimaryButton label="Install" @click="installVersion(app)" />
          </li>
        </ul>
      </div>
    </div>
  </LayoutWrapper>
</template>

<script setup lang="ts">
import LayoutWrapper from '@/components/LayoutWrapper.vue';
import {
  GetBundlesResult,
  LiveUpdate,
} from '@capawesome/capacitor-live-update';
import PrimaryButton from '@/components/UI/Button/PrimaryButton.vue';
import { useNotificationStore } from '@/store/notifications/notificationStore';
import { Notification } from '@/models';
import { useLayoutStore } from '@/store/layout/layoutStore';
import { onMounted, ref } from 'vue';
import { fetchLatestReleases } from '@/api/mobile/AppInfoApi';
import { WebReleases } from '@/models/mobile/AppInfo';
import { installRelease } from '@/utils/liveUpdater';

const apps = ref<WebReleases[]>([]);

const getAllReleases = () => {
  fetchLatestReleases().then((data) => {
    apps.value = data;
  });
};

const currentBundleId = ref<string | null>('default');

const getCurrentBundleId = async () => {
  return LiveUpdate.getBundle().then((res) => {
    currentBundleId.value = res.bundleId;
  });
};

onMounted(() => {
  getCurrentBundleId();
  getAllReleases();
});

const installVersion = async (app: WebReleases) => {
  useLayoutStore().setLoadingState(true);
  useNotificationStore().addNotification(
    new Notification('Applying latest version ....')
  );

  await installRelease(app).then(() => {
    useLayoutStore().setLoadingState(false);
    useNotificationStore().addNotification(new Notification('Updated !'));
  });
};
</script>
