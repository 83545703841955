<template>
  <div class="my-2">
    <label class="relative inline-flex cursor-pointer items-center">
      <input
        v-model="isCheckedLocal"
        type="checkbox"
        :value="isCheckedLocal"
        class="peer sr-only"
        :data-testid="testId + '_state'"
      />
      <div
        class="peer h-7 w-14 rounded-full bg-gray-200 after:absolute after:left-[4px] after:top-0.5 after:h-6 after:w-6 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-primary-2 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-300 dark:border-gray-600 dark:bg-dark-3 dark:peer-focus:ring-blue-800"
      ></div>
      <span
        class="ml-3 text-sm text-gray-600 dark:text-gray-300"
        :data-testid="testId"
        >{{ label }}</span
      >
    </label>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  label: string;
  isChecked: boolean;
  isInverted?: boolean;
  testId?: string;
}>();
const emit = defineEmits(['update:isChecked', 'isToggled']);

const isCheckedLocal = computed({
  get() {
    if (props.isInverted) {
      return !props.isChecked;
    }
    return props.isChecked;
  },
  set(value) {
    let isChecked = value;
    if (props.isInverted) {
      isChecked = !isChecked;
    }
    emit('update:isChecked', isChecked);
    emit('isToggled', isChecked);
  },
});
</script>
