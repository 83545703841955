<template>
  <BaseModal :active="true" @closed="closeModal()">
    <template #title> Versionshinweise </template>
    <template #default>
      <div v-if="changelog.length > 0">
        <div
          v-for="(release, index) in changelog"
          :key="index"
          class="release-section"
        >
          <p class="has-text-weight-bold text-xl">
            {{ release.currentVersion }}
            <span class="text-sm dark:text-gray-500">
              - {{ parseDate(release.releaseDate) }}</span
            >
          </p>
          <div
            v-for="(note, noteIndex) in release.releaseNotes.slice(0, 3)"
            :key="noteIndex"
            class="text-sm"
          >
            <!-- eslint-disable vue/no-v-html -->
            <div v-html="note"></div>
          </div>
        </div>
      </div>
      <div v-else>
        <p>
          Heute gibt’s keine Updates! Wahrscheinlich braut sich was Großes
          zusammen. Bleib gespannt!
        </p>
      </div>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import BaseModal from '@/components/UI/Modal/BaseModal.vue';
import { onMounted, ref } from 'vue';
import changelogJson from '@/assets/CHANGELOG_JIRA_DE.json';

const emit = defineEmits(['closeDialog']);
const closeModal = () => {
  emit('closeDialog');
};

const parseDate = (date: string) => {
  return new Date(date).toLocaleDateString('de-DE');
};

const changelog = ref<Record<any, any>[]>([]);
onMounted(() => {
  try {
    changelog.value = changelogJson;
  } catch (error) {
    console.error('Error loading changelog:', error);
  }
});
</script>
