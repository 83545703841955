<template>
  <div class="flex flex-col gap-6">
    <div v-if="radiators.length === 0">
      <BaseInformation
        status="info"
        information-text="In diesem Raum sind noch keine Heizkörper installiert."
      />
    </div>

    <div v-if="radiators.length > 0" class="flex justify-between">
      <div class="self-center text-xl font-bold">Erfasste Heizkörper</div>
      <TertiaryButton
        label="Reihenfolge anpassen"
        icon="mdi-swap-horizontal"
        @click="toggleSwapMode"
      />
    </div>

    <div data-testid="radiatorCardList" class="flex flex-col gap-6">
      <RadiatorCard
        v-for="(radiator, key) in radiators"
        :key="key"
        :radiator="radiator"
        :parent-id="parentId"
        :is-swap-mode-active="isSwapModeActive"
      />
    </div>
    <div class="fixed bottom-8 right-10 z-10 flex justify-between">
      <ScrollButton
        button-text="Heizkörper erfassen"
        button-icon="heating-coil"
        data-testid="addRadiatorButton"
        @on-click="goToAddRadiator"
      />
    </div>
  </div>
</template>

<script>
import { entityTypes } from '@/enums/generic';
import { deviceTypes } from '@/enums/device';
import RadiatorCard from '@/components/Forms/RadiatorListFormComponents/RadiatorCard.vue';
import { useRadiatorStore } from '@/store/entities/radiatorStore';
import { useEntitiesStore } from '@/store/entities/entitiesStore';
import { useFormStore } from '@/store/form/formStore';
import { Form } from '@/models/Form';
import TertiaryButton from '@/components/UI/Button/TertiaryButton.vue';
import BaseInformation from '@/components/UI/Hint/BaseInformation.vue';
import ScrollButton from '@/components/UI/Button/ScrollButton.vue';

export default {
  name: 'RadiatorListForm',
  components: {
    ScrollButton,
    BaseInformation,
    TertiaryButton,
    RadiatorCard,
  },
  props: {
    parentId: {
      type: String,
      required: true,
    },
  },
  setup() {
    const radiatorStore = useRadiatorStore();
    const entitiesStore = useEntitiesStore();
    const formStore = useFormStore();

    return {
      formStore,
      entitiesStore,
      radiatorStore,
    };
  },
  data() {
    return {
      isSwapModeActive: false,
    };
  },
  computed: {
    radiators() {
      const radiators = this.radiatorStore.getAllRadiatorsInRoom(this.parentId);
      if (!radiators) {
        throw new Error("Can't call this component on a non Room node");
      }
      radiators.sort((a, b) => a.ordinal - b.ordinal);
      return radiators;
    },
  },
  methods: {
    goToAddRadiator() {
      this.formStore.createEntity(
        new Form()
          .setParentId(this.parentId)
          .setName(entityTypes.radiator)
          .setPayload({ deviceType: deviceTypes.HeatCostAllocator })
      );
    },
    toggleSwapMode() {
      this.isSwapModeActive = !this.isSwapModeActive;
    },
  },
};
</script>

<style lang="scss" scoped>
.button.is-big {
  width: 360px;
}

.description {
  max-width: 1000px;
}
</style>
