import axios from 'axios';
import { default as envConfig } from '@/config/runtimeConfig';

export const initializeFunkcheck = async function (
  gatewaySerialNumber: string,
  filterList: Array<FilterListDevice>
): Promise<InitResult> {
  return axios({
    method: 'PUT',
    url: `${envConfig.backendBaseUrl}/gateways/${gatewaySerialNumber}/funkcheck`,
    data: {
      devices: filterList,
    },
  });
};
export const pollScanResults = async function (
  gatewaySerialNumber: string
): Promise<ScanResult> {
  return axios.get(
    `${envConfig.backendBaseUrl}/gateways/${gatewaySerialNumber}/funkcheck/results/latest`
  );
};
