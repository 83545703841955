import { ImageObjectType } from '@/models/Image';

export class PictureConfirmation {
  public objectId: string;
  public objectType: ImageObjectType;

  constructor(objectId: string, objectType: ImageObjectType) {
    this.objectId = objectId;
    this.objectType = objectType;
  }
}
