import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useEntitiesStore } from '@/store/entities/entitiesStore';
import { useNotificationStore } from '@/store/notifications/notificationStore';
import { useOrderStore } from '@/store/order/orderStore';
import { Image } from '@/models/Image';
import { putImage } from '@/api/pictures/PicturesApi';
import { convertTreeToFlat } from '@/utils/transformers';
import { Entity } from '@/models/types/Entity';
import {
  putBusinessEntity,
  putRawBusinessEntity,
} from '@/api/businessEntity/BusinessEntityApi';
import { Order, OrderType } from '@/models/Order';
import { putOrderProgress } from '@/api/order/OrderManagement';
import {
  LocalStorageFunctions,
  useLocalStorage,
} from '@/composables/useLocalStorage';
import { useLayoutStore } from '@/store/layout/layoutStore';

type errorStatus = {
  hasError: boolean;
  error: string;
};

export const usePersistenceStore = defineStore('PersistenceStore', () => {
  const _errorStatus = ref<errorStatus>({
    hasError: false,
    error: '',
  });

  const errorStatus = computed(() => _errorStatus);

  const getStores = () => {
    const entitiesStore = useEntitiesStore();
    const notificationStore = useNotificationStore();
    const orderStore = useOrderStore();

    return {
      entitiesStore,
      notificationStore,
      orderStore,
    };
  };

  const saved = computed(() => {
    return !useOrderStore().hasUnsavedOrderChanges;
  });

  const updateSaveSuccess = () => {
    useOrderStore().updateLastSavedOnActiveOrder();
  };

  const updateSaveFailed = (error: any) => {
    _errorStatus.value.hasError = true;
    _errorStatus.value.error = error;
  };

  const saveImage = async (image: Image) => {
    return await putImage(image);
  };

  const saveData = async () => {
    const rawDataMap = getStores().entitiesStore.businessEntityMap;
    const dataMap = convertTreeToFlat(rawDataMap);
    const selectedOrder = getStores().orderStore?.selectedOrder;

    await backupRawData(rawDataMap);

    const isSaved = await saveBusinessEntity(
      dataMap,
      selectedOrder?.workTypeCode
    );
    if (!isSaved)
      return Promise.reject(new Error('Business entity save failed'));

    if (selectedOrder) {
      const orderProgressSuccess = await saveOrderProgress(selectedOrder);
      if (!orderProgressSuccess)
        return Promise.reject(new Error('Order progress save failed'));
    }

    return Promise.resolve(isSaved && selectedOrder);
  };

  const backupRawData = async (rawDataMap: Map<string, Entity>) => {
    try {
      await putRawBusinessEntity(rawDataMap);
      return true;
    } catch (error) {
      console.error(`Raw Save failed: ${error}`);
      return false;
    }
  };

  const saveBusinessEntity = async (
    dataMap: Record<string, any>,
    workTypeCode: OrderType | undefined
  ) => {
    try {
      await putBusinessEntity(dataMap, workTypeCode);
      updateSaveSuccess();
      return true;
    } catch (error) {
      updateSaveFailed(error);
      console.error(`Business entity save failed: ${error}`);
      return false;
    }
  };

  const saveOrderProgress = async (selectedOrder: Order) => {
    if (!selectedOrder) return false;
    try {
      await putOrderProgress(selectedOrder);
      return true;
    } catch (error) {
      console.error(`Order progress save failed: ${error}`);
      return false;
    }
  };

  const localStorageFunctions = new Proxy(useLocalStorage(), {
    get(target: Record<string, any>, prop: string) {
      const original = target[prop];
      if (typeof original === 'function') {
        return function (...args: any[]) {
          if (!useLayoutStore().isLocalStorageEnabled()) {
            console.warn('Local storage is disabled in the current mode.');
            return;
          }
          return original.apply(target, args);
        };
      }
      return original;
    },
  });

  return {
    updateSaveFailed,
    saved,
    updateSaveSuccess,
    saveImage,
    saveData,
    backupRawData,
    saveBusinessEntity,
    saveOrderProgress,
    errorStatus,
    ...(localStorageFunctions as LocalStorageFunctions),
  };
});
