<template>
  <div class="flex flex-col gap-5">
    <div class="text-xl font-bold">Geräteliste an Plattform senden</div>
    <div>Bitte die aufgenommenen Geräte an die Plattform senden.</div>

    <div class="content-box">
      <div class="progress-container">
        <div style="height: 25px">
          {{ isDeviceListActive ? 'Geräteliste wird übermittelt...' : '' }}
        </div>
        <progress
          v-if="devicePoolSent"
          :value="isFunkcheckLoading ? undefined : progress"
          class="progress is-large is-info"
          max="100"
        />
        <PrimaryButton
          v-if="isSendDevicePoolAvailable"
          class="w-full"
          label="Geräteliste senden"
          data-testid="sendDeviceListButton"
          @click="startFunkcheck()"
        />
      </div>

      <StatusIndicator
        :is-complete="devicePoolSent"
        label="Geräteliste gesendet"
      />
      <StatusIndicator
        :is-complete="devicePoolReceived"
        label="Geräteliste von Plattform empfangen"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { inject, ref, computed, watch } from 'vue';
import { Notification } from '@/models';
import StatusIndicator from '@/components/Funkcheck/StatusIndicator.vue';
import useStores from '@/composables/useStores';
import PrimaryButton from '@/components/UI/Button/PrimaryButton.vue';

const validateStep = inject('validateStep') as (isValid: boolean) => void;
const devicePoolSent = ref<boolean>(false);
const devicePoolReceived = ref<boolean>(false);
const isFunkcheckLoading = ref<boolean>(false);
const progress = ref<number>(0);

const { entitiesStore, formStore, notificationStore, funkcheckStore } =
  useStores();

const isSendDevicePoolAvailable = computed(
  () => !isFunkcheckLoading.value && !devicePoolReceived.value
);
const isValid = computed(
  () => devicePoolSent.value && devicePoolReceived.value
);

watch(devicePoolReceived, () => {
  if (devicePoolReceived.value) {
    setInterval(() => {
      progress.value = progress.value + 1;
    }, 5);
  }
});

async function startFunkcheck() {
  const installationPointId = formStore.lastForm.id;
  if (!installationPointId) {
    throw new Error('No installationPoint id provided');
  }
  const installationPoint = entitiesStore.getEntityById(installationPointId);
  const gateway = entitiesStore.getEntityById(installationPoint.activeDeviceId);
  const gatewaySerialNumber = gateway.serialNumber;

  devicePoolSent.value = true;
  isFunkcheckLoading.value = true;

  try {
    await funkcheckStore.initFunkcheck(gatewaySerialNumber);
    setTimeout(() => {
      devicePoolReceived.value = true;
      isFunkcheckLoading.value = false;
      validateStep(true);
    }, 3_000);
  } catch {
    isFunkcheckLoading.value = false;
    devicePoolSent.value = false;
    notificationStore.addNotification(
      new Notification(
        'Der Funkcheckservice steht derzeit nicht zur Verfügung - Sie können den Auftrag dennoch abschließen',
        'IN_F-500',
        'error',
        10_000
      )
    );
  }
}
</script>

<style lang="scss" scoped>
.progress-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 50px;
  margin-bottom: 40px;
}
</style>
