<template>
  <BaseSpinner
    v-if="!minimal"
    :countdown="formattedElapsedTime"
    :label="label"
  ></BaseSpinner>

  <span v-else>{{ formattedElapsedTime }}</span>
</template>

<script>
import BaseSpinner from '@/components/UI/Timer/BaseSpinner.vue';
export default {
  name: 'BaseCountdownTimer',
  components: { BaseSpinner },
  props: {
    time: {
      type: Number,
      required: false,
      default: undefined,
    },
    minimal: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['countdown-over'],
  data() {
    return {
      elapsedTime: undefined,
      label: undefined,
      timer: undefined,
    };
  },
  computed: {
    formattedElapsedTime() {
      const totalSeconds = Math.floor(this.elapsedTime / 1000);

      const minutes = Math.floor(totalSeconds / 60);
      const seconds = totalSeconds % 60;

      const formattedMinutes = minutes.toString().padStart(2, '0');
      const formattedSeconds = seconds.toString().padStart(2, '0');

      return `${formattedMinutes}:${formattedSeconds}`;
    },
  },
  watch: {
    elapsedTime(value) {
      if (value <= 0) {
        this.stop();
        this.$emit('countdown-over');
      }
    },
    time(value) {
      this.elapsedTime = value;
      this.stop();
      this.start();
    },
  },
  mounted() {
    this.elapsedTime = this.time;
    this.start();
  },
  unmounted() {
    this.stop();
  },
  methods: {
    start() {
      this.timer = setInterval(() => {
        this.elapsedTime -= 1000;
      }, 1000);
    },
    stop() {
      clearInterval(this.timer);
    },
    reset() {
      this.elapsedTime = this.time;
    },
  },
};
</script>
