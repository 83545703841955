import { generateUniqueId } from '@/helpers/uuidUtils';

export class Address {
  id: string;
  street?: string;
  houseNumber?: string;
  zipCode?: string;
  city?: string;

  constructor() {
    this.id = generateUniqueId();
    this.street = undefined;
    this.houseNumber = undefined;
    this.zipCode = undefined;
    this.city = undefined;
  }
}
