<template>
  <div
    class="fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center backdrop-blur-[1px] backdrop-brightness-50"
  >
    <div
      class="flex h-full w-full flex-col bg-white transition-all duration-300 ease-in-out dark:bg-dark-1"
      :class="[
        {
          'md:h-[95vh] md:max-w-4xl md:rounded-xl 2xl:h-[90vh]':
            !isRadiatorForm,
        },
        { 'rounded-xl': isRadiatorForm },
      ]"
    >
      <header
        id="modal-header"
        class="relative mx-8 shrink-0 pb-4 pt-10 text-center dark:border-dark-2 md:pt-8 keyboard-open:hidden"
      >
        <div v-if="isDialogMinimal">
          <div class="text-3xl font-bold">
            {{ formHeading }}
          </div>

          <div v-if="deviceFormHeading" class="text-xl">
            <em :class="'mdi mdi-' + deviceFormHeading.icon" class="mr-2"></em>
            <span>{{ deviceFormHeading.name }}</span>
          </div>
        </div>

        <button
          type="button"
          class="absolute right-[-5px] top-6 z-10 ml-auto inline-flex items-center rounded-lg bg-transparent px-2 py-1.5 text-xl text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-dark-4 dark:hover:text-white"
          data-modal-hide="popup-modal"
          data-testId="closeDialogBtn"
          @click="openCancelFormPrompt"
        >
          <em class="mdi mdi-close" />
        </button>
      </header>

      <div class="grow overflow-auto px-8">
        <component
          :is="formComponent"
          ref="formComponentRef"
          :node="getEntity"
          :parent-id="getParentId"
          :payload="getPayload"
          @update-entity="updateEntity"
          @update-entities="updateEntities"
        />
      </div>
      <footer
        id="modal-footer"
        class="mx-8 flex shrink-0 items-end justify-end gap-3 pb-8 pt-3 dark:border-dark-2 keyboard-open:hidden"
      >
        <template v-if="isDialogMinimal">
          <TertiaryButton
            label="Abbrechen"
            data-testid="button-cancel"
            @click="openCancelFormPrompt"
          />
          <PrimaryButton
            :label="saveButtonLabel"
            :is-disabled="!isFormValid"
            data-testid="button-save"
            class="mr-0"
            @click="onSave"
          />
          <BasePrompt
            :title="i18n.prompt.title"
            :question="i18n.prompt.question"
            :proceed="closeFormDialog"
            :cancel="closeFormPrompt"
            :close="closeFormPrompt"
            :open="isCancelFormPromptOpen"
            :proceed-text="i18n.prompt.proceedText"
            :cancel-text="i18n.prompt.cancelText"
          />
        </template>
      </footer>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useLayoutStore } from '@/store/layout/layoutStore';
import { computed, inject, provide, Ref, ref, watch } from 'vue';
import { useFormStore, FormAction } from '@/store/form/formStore';
import { useEntitiesStore } from '@/store/entities/entitiesStore';
import { Entity } from '@/models/types/Entity';
import { generateUniqueId, Notification } from '@/models';
import TertiaryButton from '@/components/UI/Button/TertiaryButton.vue';
import PrimaryButton from '@/components/UI/Button/PrimaryButton.vue';
import { InstallationPoint } from '@/models/installationPoint/InstallationPoint';
import { useInstallationPointStore } from '@/store/entities/installationPointStore';
import { entityTypes } from '@/enums/generic';
import {
  deviceTypeList,
  deviceTypes,
  legacyDeviceTypeList,
  radiatorDeviceTypeList,
} from '@/enums/device';
import { ANALYTICS_PROVIDER } from '@/plugins/bxAnalytics';
import { useOrderStore } from '@/store/order/orderStore';
import BasePrompt from '@/components/UI/Modal/BasePrompt.vue';
import {
  FORM_CHANGE_TRACKER,
  IMAGE_CHANGE_TRACKER,
  useFormChangeTracker,
} from '@/composables/useFormChangeTracker';
import { useInspectedBuildingStore } from '@/store/entities/inspectedBuildingStore';
import i18n from '@/utils/i18n/form.json';

import BuildingForm from '@/components/Forms/BuildingForm.vue';
import RoomForm from '@/components/Forms/RoomForm.vue';
import RoomgroupForm from '@/components/Forms/RoomgroupForm.vue';
import DeviceForm from '@/components/Forms/DeviceForm.vue';
import PlantDataForm from '@/components/Forms/PlantDataForm.vue';
import RadiatorForm from '@/components/Forms/RadiatorForm.vue';
import FunkcheckForm from '@/components/Forms/FunkcheckForm.vue';
import InspectedBuildingForm from '@/components/Forms/InspectedBuildingForm.vue';
import OrderForm from '@/components/Forms/OrderForm.vue';
import InspectionForm from '@/components/Forms/InspectionForm.vue';

const formStore = useFormStore();
const entitiesStore = useEntitiesStore();
const orderStore = useOrderStore();

/*
 * Import paths have to be known at build time
 * for Vite to be able to resolve them
 * and for HMR to work
 */
const formImports: any = {
  building: BuildingForm,
  room: RoomForm,
  roomgroup: RoomgroupForm,
  installationPoint: DeviceForm,
  plant: PlantDataForm,
  radiator: RadiatorForm,
  Funkcheck: FunkcheckForm,
  inspectionPoint: InspectionForm,
  inspectedBuilding: InspectedBuildingForm,
  Order: OrderForm,
};

const formComponent = computed(() => {
  const formName = formStore.getForm.name as string;
  return formImports[formName];
});

const analytics = inject(ANALYTICS_PROVIDER);
const formId = generateUniqueId();
analytics?.captureEvent('open_form', { formId });

const { trackChanges, hasFormChanges } = useFormChangeTracker();
const { trackChanges: trackImageChanges, hasFormChanges: hasImageChanges } =
  useFormChangeTracker();

provide(FORM_CHANGE_TRACKER, trackChanges);
provide(IMAGE_CHANGE_TRACKER, trackImageChanges);

watch(hasFormChanges || hasImageChanges, (newVal) => {
  useFormStore().setFormChangesState(newVal);
});

const currentFormId = computed(() => {
  return formStore.getForm?.id as string;
});

const isCancelFormPromptOpen = computed(() => {
  return useLayoutStore().isCancelFormPromptOpen;
});

const openCancelFormPrompt = () => {
  if (!hasFormChanges.value && !hasImageChanges.value) {
    closeFormDialog();
    return;
  }
  useLayoutStore().setCancelPromptState(true);
};

const closeFormPrompt = () => {
  useLayoutStore().setCancelPromptState(false);
};

const isRadiatorForm = computed(() => {
  return (
    (formStore.getForm.name as keyof typeof formImports) ===
      entityTypes.radiator || formStore.editNode?.type === entityTypes.radiator
  );
});

const getEntity = computed(() => {
  if (formStore.currentForm?.payload?.node) {
    const installationPoint = new InstallationPoint(
      formStore.currentForm.payload.node.roomId
    );
    installationPoint.updateProperties(formStore.currentForm.payload.node);
    return installationPoint;
  }

  if (
    formStore.currentForm?.payload?.radiatorId &&
    !formStore.currentForm?.payload?.new
  ) {
    return useInstallationPointStore().getInstallationPointsByRadiatorId(
      formStore.currentForm.payload.radiatorId
    )[0];
  }

  if (formStore.currentForm.name === 'Order') {
    return orderStore.activeOrder;
  }

  if (formStore.currentForm.name === 'inspectedBuilding') {
    return useInspectedBuildingStore().getCurrentInspectedBuilding();
  }

  if (!currentFormId.value) {
    return undefined;
  }

  return entitiesStore.getEntityById(currentFormId.value);
});

const saveButtonLabel = computed(() => {
  if (formStore.getForm.name === 'Order') {
    return 'Auftrag abschließen';
  }
  if (formStore.getForm.name === 'inspectedBuilding') {
    return 'Speichern';
  }
  return currentFormId.value ? 'Speichern' : 'Anlegen';
});

const getParentId = computed(() => {
  if (formStore.currentForm?.payload?.node) {
    return formStore.currentForm.payload.node.roomId;
  }
  if (formStore.getForm.parentId) {
    return formStore.getForm.parentId;
  }
  return undefined;
});

const getPayload = computed(() => {
  if (formStore.getForm.payload) {
    return formStore.getForm.payload;
  }
  return undefined;
});

const closeFormDialog = () => {
  if (formStore.getForm.name === 'Order') {
    orderStore.resetInstallerNote();
  }
  useLayoutStore().closeFormDialog();
};

const updateEntity = (entity: Entity) => {
  let notification: Notification = new Notification();

  if (!entitiesStore.hasEntityById(entity.id)) {
    notification = generateNotificationForCreate(entity);
  }

  entitiesStore.saveEntity(entity, notification);

  analytics?.captureEvent('save_form', {
    formId,
    entityId: entity.id,
    entityType: entity.type,
    hasFormChanges: hasFormChanges.value,
  });
  closeFormDialog();
};

const updateEntities = (entityList: Array<any>) => {
  const firstEntity = entityList.shift();
  updateEntity(firstEntity);
  entityList.forEach((entity) => {
    entitiesStore.saveEntity(entity as Entity, undefined);
  });
  closeFormDialog();
};

const entityTypeTranslation: { [key: string]: string } = {
  building: 'Gebäude',
  inspectedBuilding: 'Begehungsgebäude',
  room: 'Raum',
  roomgroup: 'Nutzeinheit',
  device: 'Gerät',
  inspectionDevice: 'Testgerät',
  installationPoint: 'Gerät',
  inspectionPoint: 'Testgerät',
  plant: 'Anlage',
  radiator: 'Heizkörper',
  Funkcheck: 'Funkcheck',
};

const generateNotificationForCreate = (
  entity: any
): Notification | undefined => {
  const entityType = entity.type;
  const notificationTranslation = entityTypeTranslation[entityType];
  if (!notificationTranslation) {
    throw new Error('Entity type does not exist');
  }

  if (entity.type === entityTypes.inspectedBuilding) return undefined;

  const notificationText = `${notificationTranslation} wurde hinzugefügt`;
  const notificationType = 'success';

  return new Notification()
    .setType(notificationType)
    .setText(notificationText)
    .setTitle(notificationText);
};

/*
 * Child Form Components should have the appropriate methods
 * to work properly
 * isFormValid -> for validation
 * handleSubmit -> for entity save
 */
type FormComponentRef = {
  isFormValid: boolean;
  handleSubmit: () => void;
  saveRadiators: () => void;
};

const formComponentRef: Ref<FormComponentRef | null> = ref(null);

const isFormValid = computed(() => {
  return formComponentRef.value?.isFormValid;
});

const onSave = () => {
  if (!formComponentRef.value?.handleSubmit) {
    throw new Error(
      'Form Component must contain handleSubmit function to work properly!'
    );
  }
  formComponentRef.value?.handleSubmit();
};

const isOnSiteInspection = computed(() => {
  return orderStore.isOnSiteInspection();
});

const formHeading = computed(() => {
  const entityType = formStore.getForm.name as string;
  let editType;

  editType = getEntity.value?.id ? 'bearbeiten' : 'anlegen';

  if (formStore.formAction === FormAction.REPLACE) {
    editType = 'austauschen';
  }
  if (isOnSiteInspection.value) {
    if (
      deviceFormHeading.value?.value === deviceTypes.SmartRadiatorThermostat
    ) {
      return `Testgerät ${editType}`;
    }
    if (deviceFormHeading.value?.value === deviceTypes.LoRaWanGateway) {
      return 'Installationsort testen';
    }
    if (entityType === 'inspectedBuilding') {
      return 'Allgemeine Hinweise zum Eingang';
    }
    if (entityType === 'Order') {
      return 'Auftrag abschließen';
    }
  }

  return entityType === 'installationPoint'
    ? 'Gerät erfassen'
    : `${entityTypeTranslation[entityType]} ${editType}`;
});

const deviceFormHeading = computed(() => {
  const editNode = useFormStore().editNode;
  if (editNode && editNode.type === entityTypes.device) {
    const allDevices = [
      ...deviceTypeList,
      ...legacyDeviceTypeList,
      ...radiatorDeviceTypeList,
    ];

    return allDevices.find((device) => device.value === editNode.deviceType);
  }
  return undefined;
});

const isDialogMinimal = computed(() => {
  return !(
    deviceFormHeading.value?.value === deviceTypes.HeatCostAllocator ||
    useFormStore().editNode?.type === entityTypes.radiator ||
    useFormStore().currentForm?.name === 'Funkcheck'
  );
});
</script>
