import { ref } from 'vue';
import { Building, Room, Roomgroup } from '@/models';
import { instanceToInstance, plainToInstance } from 'class-transformer';
import { usageTypeList } from '@/enums/roomgroup';
import { Address } from '@/models/types/Address';
import { useBuildingStore } from '@/store/entities/buildingStore';

export function useBuildingForm(
  node: Building | undefined,
  parentId?: string,
  imageCaptureRef?: any,
  emit?: any
) {
  const building = ref(new Building());
  const roomGroup = ref(new Roomgroup());
  const room = ref(new Room());
  const isEditMode = ref(false);
  const buildingStore = useBuildingStore();

  const initializeBuildingForm = () => {
    if (!(node instanceof Building)) {
      isEditMode.value = false;
      building.value.businessEntityId = parentId;
      setupNewBuilding();
      return;
    }

    isEditMode.value = true;
    building.value = instanceToInstance(node);
    building.value.address = plainToInstance(Address, building.value.address);
  };

  const setupNewBuilding = () => {
    building.value.ordinal = buildingStore.getNewOrdinal();

    roomGroup.value.buildingId = building.value.id;
    roomGroup.value.usageType = usageTypeList[2].value;
    roomGroup.value.ordinal = 0;

    room.value.roomGroupId = roomGroup.value.id;
    room.value.usageType = 'HALLWAY_AND_STAIRCASE';
    room.value.floorLevel = { type: 'GROUND' };
    room.value.ordinal = 0;
  };

  const handleSubmit = () => {
    if (isEditMode.value) {
      emit('update-entities', [building.value]);
    } else {
      emit('update-entities', [building.value, roomGroup.value, room.value]);
    }
    if (imageCaptureRef.value) {
      imageCaptureRef.value.saveImage();
    }
  };

  const handleIsAccessibleChange = (isAccessible: boolean) => {
    building.value.isAccessible = isAccessible;
    if (isAccessible) {
      building.value.reasonNotAccessible = undefined;
      building.value.dateNotAccessible = undefined;
    } else {
      building.value.dateNotAccessible = new Date().toISOString();
    }
  };

  return {
    building,
    roomGroup,
    room,
    isEditMode,
    initializeBuildingForm,
    handleIsAccessibleChange,
    handleSubmit,
  };
}
