export class DeviceScanResult {
  productKey?: string;
  manufacturerId?: string;
  version?: number;
  dueDate?: string;
  serialNumber?: string;

  addProductKey(productKey: string) {
    this.productKey = productKey;
    return this;
  }

  addManufacturerId(manufacturerId: string) {
    this.manufacturerId = manufacturerId;
    return this;
  }

  addVersion(version: number) {
    this.version = version;
    return this;
  }

  addDueDate(dueDate: string) {
    this.dueDate = dueDate;
    return this;
  }

  addSerialNumber(serialNumber: string) {
    this.serialNumber = serialNumber;
    return this;
  }
}

export class BatteryPackScanResult {
  serialNumberBatteryPack?: string;
  articleNumberBatteryPack?: string;
  unknownProperties: string;

  constructor() {
    this.unknownProperties = '';
  }

  addArticleNumberForBatteryPack(articleNumberBatteryPack: string) {
    this.articleNumberBatteryPack = articleNumberBatteryPack;
    return this;
  }
  addSerialNumberForBatteryPack(serialNumberBatteryPack: string) {
    this.serialNumberBatteryPack = serialNumberBatteryPack;
    return this;
  }

  addUnknownProperties(unknownProperties: string) {
    this.unknownProperties = unknownProperties;
    return this;
  }
}

export class LoRaWanMatrixScanResult {
  serialNumber?: string;
  eui?: string;
  productKey?: string;
  unknownProperties?: string;

  addSerialNumber(serialNumber: string) {
    this.serialNumber = serialNumber;
    return this;
  }

  addEUI(eui: string) {
    this.eui = eui;
    return this;
  }

  addProductKey(productKey: string) {
    this.productKey = productKey;
    return this;
  }
  addUnknownProperties(unknownProperties: string) {
    this.unknownProperties = unknownProperties;
    return this;
  }
}

export class SRTAdapterScanResult {
  customizedNumber: string;
  productKey: string;
  unknownProperties: string;

  constructor() {
    this.customizedNumber = '';
    this.productKey = '';
    this.unknownProperties = '';
  }

  addCustomizedNumber(customizedNumber: string) {
    this.customizedNumber = customizedNumber;
    return this;
  }

  addProductKey(productKey: string) {
    this.productKey = productKey;
    return this;
  }

  addUnknownProperties(unknownProperties: string) {
    this.unknownProperties = unknownProperties;
    return this;
  }
}
