<template>
  <div>
    <ImageCaptureModal
      v-model:is-camera-open="isCameraOpen"
      @save-image="saveAndOpenImage($event)"
    />
    <ImageGallery
      v-if="images.length > 0"
      ref="imageGalleryRef"
      :images="images"
      :capture-image-information="captureImageInformation"
      :image-keyword-list="imageKeywords"
      @update-images="onDelete"
      @update-image-information="updateImageInformation($event)"
    />
    <PrimaryButton
      :label="images.length > 0 ? 'Weitere Fotos aufnehmen' : 'Kamera öffnen'"
      icon="mdi-camera-plus-outline"
      class="w-full"
      data-testid="open-camera-button"
      @click="openCamera"
    />

    <BasePrompt
      :open="isPromptOpen && showHint"
      :close="cancelPrompt"
      :cancel="cancelPrompt"
      :proceed="confirmPrompt"
      :title="promptTitle"
    >
      <slot name="prompt" />
      <div v-if="showCheckbox" class="text-left">
        <br />
        <Checkbox v-model:checked="isHintHidden" label="Nicht mehr anzeigen" />
      </div>
    </BasePrompt>
  </div>
</template>

<script setup lang="ts">
import ImageCaptureModal from '@/components/UI/Image/ImageCaptureModal.vue';
import ImageGallery from '@/components/UI/Image/ImageGallery.vue';
import { computed, inject, onBeforeMount, onMounted, ref, toRef } from 'vue';
import PrimaryButton from '@/components/UI/Button/PrimaryButton.vue';
import { ImageObjectType } from '@/models/Image';
import { useImageCapture } from '@/composables/useImageCapture';
import BasePrompt from '@/components/UI/Modal/BasePrompt.vue';
import { useUserStore } from '@/store/user/userStore';
import Checkbox from '@/components/UI/Input/Checkbox.vue';
import { useImagesStore } from '@/store/images/imageStore';
import { IMAGE_CHANGE_TRACKER } from '@/composables/useFormChangeTracker';

interface Props {
  objectType: ImageObjectType;
  objectId: string;
  showCheckbox?: boolean;
  promptTitle?: string;
  captureImageInformation?: boolean;
  imageKeywordList?: string[];
}

const props = withDefaults(defineProps<Props>(), {
  promptTitle: '',
  imageKeywordList: undefined,
});

export type ImageCaptureRef = {
  saveImage: () => void;
  hasImage: boolean;
};

const imageGalleryRef = ref<InstanceType<typeof ImageGallery> | null>(null);

const emit = defineEmits(['finish-step']);

const saveAndOpenImage = (imagePayload: string) => {
  saveImagesToGallery(imagePayload);
  if (props.captureImageInformation) {
    useImagesStore().openImage();
  } else {
    useImagesStore().resetImage();
  }
};

const imageKeywords = computed(() => {
  return props.imageKeywordList;
});

const trackFormChanges = inject(IMAGE_CHANGE_TRACKER);
const trackImageChanges = () => {
  if (trackFormChanges) {
    trackFormChanges(images);
  }
};

onBeforeMount(async () => {
  if (!props.objectId || !props.objectType) {
    throw new Error('Id for object not found not found');
  }

  await getImages();
  trackImageChanges();
});

const {
  images,
  saveImagesToGallery,
  getImages,
  onDelete,
  saveImagesToIndexedDb,
  updateImageInformation,
} = useImageCapture(props.objectId, props.objectType);

const isCameraOpen = ref<boolean>(false);
const isPromptOpen = ref(false);
const isHintHidden = ref<boolean>(false);

const showHint = computed(() => {
  switch (props.objectType) {
    case ImageObjectType.VALVE:
      return useUserStore().isValveHintVisible;
    case ImageObjectType.GATEWAY_LOCATION:
      return useUserStore().isGatewayLocationHintVisible;
    default:
      return false;
  }
});
const togglePrompt = () => {
  isPromptOpen.value = !isPromptOpen.value;
};

const cancelPrompt = () => {
  togglePrompt();
  isHintHidden.value = false;
};

const confirmPrompt = () => {
  togglePrompt();
  if (isHintHidden.value) {
    switch (props.objectType) {
      case ImageObjectType.VALVE:
        useUserStore().hideValveHint();
        break;
      case ImageObjectType.GATEWAY_LOCATION:
        useUserStore().hideGatewayLocationHint();
        break;
    }
  }
  isCameraOpen.value = true;
};

const openCamera = () => {
  if (showHint.value) {
    togglePrompt();
  } else {
    isCameraOpen.value = true;
  }
};

defineExpose({
  hasImage: computed(() => images.value.length > 0),
  saveImage: saveImagesToIndexedDb,
});
</script>
