<template>
  <div class="py-3">
    <div class="relative">
      <button
        v-click-outside="closeDropdown"
        :class="[
          'flex w-full appearance-none items-center justify-between rounded-t-lg border-0 border-b-2 border-gray-400 bg-light-1 px-2.5 pb-1.5 pt-4 text-sm text-gray-900  focus:outline-none focus:ring-0 dark:border-dark-3 dark:bg-dark-2 dark:text-white',
          {
            'border-primary-2 dark:border-primary-2': isOpen,
          },
        ]"
      >
        <div class="flex grow flex-wrap" data-testId="keywords">
          <span
            v-for="(option, index) in selectedOptions"
            :key="index"
            class="m-1 items-center whitespace-nowrap rounded-full border border-black px-2 py-1 text-sm dark:border-white dark:text-white"
            data-testId="keyword"
          >
            {{ option }}
            <button
              class="ml-2 focus:outline-none"
              data-testId="removeKeywordButton"
              @click="removeSelectedOption(index)"
            >
              <em class="mdi mdi-close" />
            </button>
          </span>
          <span class="grow">
            <input
              v-model="search"
              type="text"
              :maxlength="maxLength"
              class="w-full appearance-none border-0 bg-transparent px-2.5 text-sm focus:ring-0"
              :placeholder="placeholder"
              :data-testId="testId"
              @focus="isOpen = true"
              @input="filterOptions"
            />
          </span>
        </div>
        <button
          type="button"
          class="rounded-full px-4 py-1 text-base font-medium"
          :class="[
            hasInput
              ? 'bg-primary-2 text-white dark:bg-primary-1'
              : 'bg-light-disabled-background text-light-disabled-text dark:bg-dark-disabled-background dark:text-dark-disabled-text',
          ]"
          :disabled="!hasInput"
          data-testId="addOption_button"
          @click="addOption"
        >
          <em class="mdi mdi-plus"></em>
        </button>
        <em
          class="mdi ml-3 text-xl"
          :class="isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down'"
          @click="toggleDropdown"
        ></em>
      </button>
      <div
        class="absolute z-20 mb-4 w-full rounded-b-lg bg-light-1 shadow-md dark:bg-dark-2"
        :class="isOpen && filteredOptions.length > 0 ? '' : 'hidden'"
      >
        <ul
          class="my-0 max-h-[293px] list-none overflow-y-auto pb-2 pl-0 pt-1 text-sm text-gray-700 dark:text-gray-200"
        >
          <li
            v-for="(option, index) in filteredOptions"
            :key="option"
            class="my-0 block px-4 py-3 no-underline hover:bg-light-0 dark:hover:bg-gray-600 dark:hover:text-white"
            :class="
              index !== filteredOptions.length - 1
                ? 'border-b border-b-light-0  dark:border-b-dark-3'
                : ''
            "
            @click="selectOption(option)"
          >
            {{ option }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { normalizeString } from '@/helpers/normalizeString';

interface Props {
  optionsList?: Array<string> | ReadonlyArray<string>;
  initialSelectedOptions?: Array<string> | ReadonlyArray<string>;
  placeholder?: string;
  maxLength?: number;
  testId?: string;
}

const props = withDefaults(defineProps<Props>(), {
  optionsList: () => [] as string[],
  initialSelectedOptions: () => [] as string[],
  placeholder: 'Option hinzufügen',
  maxLength: 50,
  testId: 'input_interActiveDropdown',
});

const emit = defineEmits(['update:selected-options']);

const search = ref('');
const options = ref<string[]>([...props.optionsList]);
const filteredOptions = ref<string[]>([...options.value]);
const selectedOptions = ref<string[]>([...props.initialSelectedOptions]);
const isOpen = ref(false);

const toggleDropdown = () => {
  isOpen.value = !isOpen.value;
};

const closeDropdown = () => {
  isOpen.value = false;
};

const hasInput = computed(() => {
  return search.value !== '';
});

const filterOptions = () => {
  const searchTerm = normalizeString(search.value);
  filteredOptions.value = options.value.filter((option) =>
    normalizeString(option).includes(searchTerm)
  );
};

const selectOption = (option: string) => {
  if (!selectedOptions.value.some((o) => o === option)) {
    selectedOptions.value.push(option);
    emit('update:selected-options', selectedOptions.value);
  }
  search.value = '';
  filterOptions();
};

const removeSelectedOption = (index: number) => {
  selectedOptions.value.splice(index, 1);
  emit('update:selected-options', selectedOptions.value);
};

const addOption = () => {
  const trimmedSearch = search.value.trim();
  if (!trimmedSearch) return;
  const existingOption = options.value.find(
    (option) => normalizeString(option) === normalizeString(trimmedSearch)
  );
  if (existingOption) {
    selectOption(existingOption);
    return;
  }
  selectOption(trimmedSearch);
};

onMounted(() => {
  filterOptions();
});
</script>
