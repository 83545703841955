<template>
  <transition name="notifications" mode="out-in">
    <div
      v-if="hasMessages"
      class="fixed bottom-4 left-1/2 z-50 -translate-x-1/2 transform space-y-4"
    >
      <transition-group name="message" tag="div" mode="out-in">
        <div v-for="(notification, index) in notifications" :key="index">
          <div
            class="notification-bg m-2 flex cursor-pointer items-center space-x-2 rounded p-4 text-white shadow-lg"
            data-testid="app-notification"
            @click="deleteNotification(notification)"
          >
            <i
              :class="getTypeClass(notification)"
              class="mdi pr-3 text-2xl"
            ></i>
            <div>
              <span> {{ notification.text }}</span>
            </div>
          </div>
        </div>
      </transition-group>
    </div>
  </transition>
</template>

<script>
import { useNotificationStore } from '@/store/notifications/notificationStore';

export default {
  name: 'TheNotifications',
  setup() {
    const notificationStore = useNotificationStore();
    return { notificationStore };
  },
  computed: {
    hasMessages() {
      return this.notifications.length !== 0;
    },
    notifications() {
      return this.notificationStore.notifications.slice(0, 3);
    },
  },
  methods: {
    deleteNotification(notification) {
      this.notificationStore.removeNotification(notification.id);
    },
    getTypeClass(notification) {
      return notification.getCssClass();
    },
  },
};
</script>
<style>
.notification-bg {
  background-color: #1b1b1b;
}
</style>
