import * as Sentry from '@sentry/vue';
import { default as envConfig } from '@/config/runtimeConfig';
import {
  HttpClient as HttpClientIntegration,
  CaptureConsole as CaptureConsoleIntegration,
} from '@sentry/integrations';

export const initSentry = (app: any) => {
  Sentry.init({
    app,
    enabled: envConfig.sentryEnv != 'dev',
    dsn: envConfig.sentryDSN,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: envConfig.sentryEnv != 'dev' ? 1.0 : 0.0,
    integrations: [
      new Sentry.Replay({
        maskAllText: true,
        blockAllMedia: true,
      }),
      new HttpClientIntegration({
        failedRequestTargets: [/https:\/\/bxapp.*beyonnex.*/, /.*localhost.*/], // Only log failed beyonnex request
      }),
      new CaptureConsoleIntegration({
        levels: ['error'],
      }),
    ],
    environment: envConfig.sentryEnv,
    tracesSampleRate: 1.0,
  });
};
