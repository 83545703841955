import { AnalyticsProvider } from '../types/AnalyticsProvider';
import posthog, { PostHogConfig } from 'posthog-js';

export class PostHogAnalytics implements AnalyticsProvider {
  public eventContext = {};

  constructor(apiKey: string, options?: Record<string, any>) {
    posthog.init(apiKey, options);
  }

  identifyUser(userId: string, properties: Record<string, any> = {}): void {
    posthog.identify(userId, properties);
  }

  captureEvent(event: string, properties: Record<string, any> = {}): void {
    const mergedProperties = { ...properties, ...this.eventContext };
    posthog.capture(event, { properties: mergedProperties });
  }

  setEventContext(context: Record<string, any>): void {
    this.eventContext = context;
  }

  clearEventContext(): void {
    this.eventContext = {};
  }

  getEventContext(): Record<string, any> {
    return this.eventContext;
  }
}
