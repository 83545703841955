import { ref } from 'vue';
import { instanceToInstance } from 'class-transformer';
import { computed } from 'vue';
import { InspectedBuilding } from '@/models/InspectedBuilding';
import { useBuildingStore } from '@/store/entities/buildingStore';

export function useInspectedBuildingForm(
  node: InspectedBuilding | undefined,
  imageCaptureRef?: any,
  emit?: any
) {
  const inspectedBuilding = ref(new InspectedBuilding());

  const activeBuilding = computed(() => {
    return useBuildingStore().getCurrentBuilding();
  });

  const initializeInspectedBuildingForm = () => {
    if (!node?.buildingId) {
      inspectedBuilding.value.buildingId = activeBuilding.value?.id;
    } else {
      inspectedBuilding.value = instanceToInstance(node);
    }
  };

  const handleSubmit = () => {
    emit('update-entity', inspectedBuilding.value);

    if (imageCaptureRef.value) {
      imageCaptureRef.value.saveImage();
    }
  };

  return {
    inspectedBuilding,
    initializeInspectedBuildingForm,
    handleSubmit,
  };
}
