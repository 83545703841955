import { computed, ref } from 'vue';
import { Room, Roomgroup } from '@/models';
import { usageTypeList } from '@/enums/roomgroup';
import { roomTypeList } from '@/enums/room';
import useStores from '@/composables/useStores';
import { useRoomGroupStore } from '@/store/entities/roomGroupStore';

export function useRoomGroupForm(
  node?: Roomgroup,
  parentId?: string,
  emit?: any
) {
  const roomgroup = ref(new Roomgroup());
  const room = ref(new Room());

  const { buildingStore, roomStore, roomGroupStore, entitiesStore } =
    useStores();

  const isBusiness = computed(
    () => roomgroup.value.usageType === usageTypeList[1].value
  );
  const isGeneral = computed(
    () => roomgroup.value.usageType === usageTypeList[2].value
  );
  const handleIsAccessibleChange = (isAccessible: boolean) => {
    roomgroup.value.isAccessible = isAccessible;
    if (isAccessible) {
      roomgroup.value.reasonNotAccessible = undefined;
      roomgroup.value.dateNotAccessible = undefined;
    } else {
      roomgroup.value.dateNotAccessible = new Date().toISOString();
    }
  };

  const handleUsageType = () => {
    return roomgroup.value.clear();
  };

  const handleSubmit = () => {
    emit('update-entities', [roomgroup.value, room.value]);
  };

  const initializeRoomGroupForm = () => {
    room.value.ordinal = 1;

    if (!node?.id) {
      roomgroup.value.buildingId = buildingStore.buildingId;
      room.value.roomGroupId = roomgroup.value.id;
      room.value.usageType = roomTypeList.find(
        (r) => r.value === 'CORRIDOR'
      )?.value;
      if (!parentId) return;
      roomgroup.value.ordinal = roomGroupStore.getNewRoomgroupOrdinal(parentId);
      if (buildingStore.buildingId !== parentId) {
        room.value.parentRoomId = parentId;
        const parentRoom = entitiesStore.getEntityById(parentId) as Room;
        room.value.floorLevel = parentRoom.floorLevel;
      }
      return;
    }

    Object.assign(roomgroup.value, node);
    Object.assign(room.value, roomStore.getFirstRoomOfRoomgroup(node.id));
  };

  const isRoomGroupNumberDuplicated = computed(() => {
    if (roomgroup.value && roomgroup.value.number) {
      return useRoomGroupStore().isRoomGroupNumberDuplicated(roomgroup.value);
    }
    return false;
  });

  const isDeviceReferenceDuplicated = computed(() => {
    if (roomgroup.value && roomgroup.value.deviceReference) {
      return useRoomGroupStore().isDeviceReferenceDuplicated(roomgroup.value);
    }
    return false;
  });

  return {
    roomgroup,
    room,
    isBusiness,
    isGeneral,
    isRoomGroupNumberDuplicated,
    isDeviceReferenceDuplicated,
    initializeRoomGroupForm,
    handleIsAccessibleChange,
    handleUsageType,
    handleSubmit,
  };
}
