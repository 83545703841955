<template>
  <LayoutWrapper>
    <BuildingOverview />
    <FormDialog v-if="layoutStore.isFormDialogOpen"></FormDialog>
  </LayoutWrapper>
</template>

<script setup lang="ts">
import BuildingOverview from '@/components/BuildingOverview/BuildingOverview.vue';
import FormDialog from '@/components/UI/Modal/FormDialog.vue';
import { useLayoutStore } from '@/store/layout/layoutStore';
import LayoutWrapper from '@/components/LayoutWrapper.vue';

const layoutStore = useLayoutStore();
</script>
