import { computed, Ref } from 'vue';
import {
  DeviceRadioCheckStatus,
  OverallRadioCheckStatus,
  ReceptionQualityStatus,
} from '@/enums/radiocheck';
import i18n from '@/utils/i18n/radiocheck.json';
import { Status } from '@/enums/generic';

const ICONS = {
  checkCircle: 'mdi-check-circle',
  alertCircle: 'mdi-alert-circle',
  helpCircle: 'mdi-help-circle',
  loading: 'mdi-spin mdi-loading',
};

const STATUS_TEXTS = {
  success: i18n.status.success,
  error: i18n.status.error,
  pending: i18n.status.pending,
  unknown: i18n.status.unknown,
};

const STATUS_DESCRIPTIONS = {
  completed: i18n.info.completed,
  error: i18n.info.error,
  pending: i18n.info.pending,
  failed: i18n.info.failed,
  timedOut: i18n.info.timedOut,
  canceled: i18n.info.canceled,
};

const STATUS_TYPES = {
  success: Status.SUCCESS,
  error: Status.ERROR,
  info: Status.INFO,
  warning: Status.WARNING,
};

const TEXT_COLORS = {
  info: 'text-primary-2 dark:text-primary-2',
  error: 'text-light-error dark:text-dark-error',
  success: 'text-light-success dark:text-dark-success',
  warning: 'text-light-warning dark:text-dark-warning',
};

export function useRadioCheckStatus(
  statusRef: Ref<
    OverallRadioCheckStatus | DeviceRadioCheckStatus | ReceptionQualityStatus
  >
) {
  function getDeviceRadioCheckStatusProps(status: DeviceRadioCheckStatus) {
    switch (status) {
      case DeviceRadioCheckStatus.OK:
        return {
          icon: ICONS.checkCircle,
          type: STATUS_TYPES.success,
          text: STATUS_TEXTS.success,
          color: TEXT_COLORS.success,
        };
      case DeviceRadioCheckStatus.NO_MATCHED_GW:
      case DeviceRadioCheckStatus.BAD_SIGNAL:
        return {
          icon: ICONS.alertCircle,
          type: STATUS_TYPES.error,
          text: STATUS_TEXTS.error,
          color: TEXT_COLORS.error,
        };
      case DeviceRadioCheckStatus.PENDING:
        return {
          icon: ICONS.loading,
          type: STATUS_TYPES.info,
          text: STATUS_TEXTS.pending,
          color: TEXT_COLORS.info,
        };
      case DeviceRadioCheckStatus.TIMED_OUT:
        return {
          icon: ICONS.helpCircle,
          type: STATUS_TYPES.warning,
          text: STATUS_DESCRIPTIONS.timedOut,
          color: TEXT_COLORS.warning,
        };
      default:
        return {
          icon: ICONS.helpCircle,
          type: STATUS_TYPES.warning,
          text: STATUS_TEXTS.unknown,
          color: TEXT_COLORS.warning,
        };
    }
  }

  function getOverallRadioCheckStatusProps(status: OverallRadioCheckStatus) {
    switch (status) {
      case OverallRadioCheckStatus.COMPLETED:
        return {
          icon: ICONS.checkCircle,
          type: STATUS_TYPES.success,
          text: STATUS_DESCRIPTIONS.completed,
          color: TEXT_COLORS.success,
        };
      case OverallRadioCheckStatus.FAILED:
        return {
          icon: ICONS.alertCircle,
          type: STATUS_TYPES.error,
          text: STATUS_DESCRIPTIONS.failed,
          color: TEXT_COLORS.error,
        };
      case OverallRadioCheckStatus.ERROR:
        return {
          icon: ICONS.alertCircle,
          type: STATUS_TYPES.error,
          text: STATUS_DESCRIPTIONS.error,
          color: TEXT_COLORS.error,
        };
      case OverallRadioCheckStatus.PENDING:
        return {
          icon: ICONS.loading,
          type: STATUS_TYPES.info,
          text: STATUS_DESCRIPTIONS.pending,
          color: TEXT_COLORS.info,
        };
      case OverallRadioCheckStatus.TIMED_OUT:
        return {
          icon: ICONS.helpCircle,
          type: STATUS_TYPES.warning,
          text: STATUS_DESCRIPTIONS.timedOut,
          color: TEXT_COLORS.warning,
        };
      case OverallRadioCheckStatus.CANCELED:
        return {
          icon: ICONS.helpCircle,
          type: STATUS_TYPES.warning,
          text: STATUS_DESCRIPTIONS.canceled,
          color: TEXT_COLORS.warning,
        };
      default:
        return {
          icon: ICONS.helpCircle,
          type: STATUS_TYPES.warning,
          text: STATUS_TEXTS.unknown,
          color: TEXT_COLORS.warning,
        };
    }
  }

  function getReceptionQualityStatusProps(status: ReceptionQualityStatus) {
    switch (status) {
      case ReceptionQualityStatus.ABOVE_THRESHOLD:
      case ReceptionQualityStatus.EQUAL_THRESHOLD:
        return {
          icon: ICONS.checkCircle,
          type: STATUS_TYPES.success,
          text: STATUS_TEXTS.success,
          color: TEXT_COLORS.success,
        };
      case ReceptionQualityStatus.INVALID_DATA:
        return {
          icon: ICONS.alertCircle,
          type: STATUS_TYPES.error,
          text: STATUS_TEXTS.error,
          color: TEXT_COLORS.error,
        };
      case ReceptionQualityStatus.PENDING:
        return {
          icon: ICONS.loading,
          type: STATUS_TYPES.info,
          text: STATUS_TEXTS.pending,
          color: TEXT_COLORS.info,
        };
      case ReceptionQualityStatus.TIMED_OUT:
        return {
          icon: ICONS.helpCircle,
          type: STATUS_TYPES.warning,
          text: STATUS_DESCRIPTIONS.timedOut,
          color: TEXT_COLORS.warning,
        };
      default:
        return {
          icon: ICONS.helpCircle,
          type: STATUS_TYPES.warning,
          text: STATUS_TEXTS.unknown,
          status: TEXT_COLORS.warning,
        };
    }
  }

  const statusProps = computed(() => {
    const statusValue = statusRef.value;
    if (isOverallRadioCheckStatus(statusValue)) {
      return getOverallRadioCheckStatusProps(statusValue);
    }
    if (isDeviceRadioCheckStatus(statusValue)) {
      return getDeviceRadioCheckStatusProps(statusValue);
    }
    if (isReceptionQualityStatus(statusValue)) {
      return getReceptionQualityStatusProps(statusValue);
    }

    return {
      icon: ICONS.helpCircle,
      text: STATUS_TEXTS.unknown,
      type: STATUS_TYPES.warning,
      color: TEXT_COLORS.warning,
    };
  });

  return {
    statusIcon: computed(() => statusProps.value.icon),
    statusText: computed(() => statusProps.value.text),
    statusType: computed(() => statusProps.value.type),
    statusTextColor: computed(() => statusProps.value.color),
  };

  function isOverallRadioCheckStatus(
    value: any
  ): value is OverallRadioCheckStatus {
    return Object.values(OverallRadioCheckStatus).includes(value);
  }

  function isDeviceRadioCheckStatus(
    value: any
  ): value is DeviceRadioCheckStatus {
    return Object.values(DeviceRadioCheckStatus).includes(value);
  }

  function isReceptionQualityStatus(
    value: any
  ): value is ReceptionQualityStatus {
    return Object.values(ReceptionQualityStatus).includes(value);
  }
}
