class UserSettings {
  private readonly settingsKey: string = 'appSettings';
  private settings: Partial<UserSettingsType>;

  constructor() {
    this.settingsKey = 'appSettings';
    this.settings = this.loadSettings();
  }

  loadSettings() {
    const savedSettings = localStorage.getItem(this.settingsKey);
    return savedSettings ? JSON.parse(savedSettings) : {};
  }

  saveSettings() {
    localStorage.setItem(this.settingsKey, JSON.stringify(this.settings));
  }

  getSetting<K extends keyof UserSettingsType>(
    key: K
  ): UserSettingsType[K] | undefined {
    return this.settings[key];
  }

  setSetting<K extends keyof UserSettingsType>(
    key: K,
    value: UserSettingsType[K]
  ): void {
    this.settings[key] = value;
    this.saveSettings();
  }

  resetSettings() {
    this.settings = {};
    this.saveSettings();
  }
}

export default new UserSettings();

export type UserSettingsType = {
  hasDismissedChangelog: boolean;
};

export enum UserSettingsKeys {
  HAS_DISMISSED_CHANGELOG = 'hasDismissedChangelog',
}
