<template>
  <div
    class="inline-flex h-7 items-center justify-center justify-self-center rounded-md border-2 px-1.5 text-xs font-medium"
    :class="getStatusColor"
    data-testid="gateway-status-badge"
  >
    <em class="mdi mdi-router-wireless mr-1 text-sm"></em>
    Gateway
  </div>
</template>

<script setup lang="ts">
import { defineProps, computed } from 'vue';
import { OverallRadioCheckStatus } from '@/enums/radiocheck';

const props = defineProps<{
  status: OverallRadioCheckStatus;
}>();

const getStatusColor = computed(() => {
  switch (props.status) {
    case OverallRadioCheckStatus.COMPLETED:
      return 'border-green-600 bg-green-50 dark:bg-green-950 dark:border-green-600';
    case OverallRadioCheckStatus.FAILED:
      return 'border-red-600 bg-red-50 dark:bg-red-950 dark:border-red-600';
    case OverallRadioCheckStatus.CANCELED:
      return 'border-yellow-600 bg-yellow-50 dark:bg-yellow-950 dark:border-yellow-600';
    default:
      return '';
  }
});
</script>
