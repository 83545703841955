function getRunTimeConfig() {
  if (typeof window.bxappParams === 'object') {
    return window.bxappParams || {};
  }

  return {};
}
/*
The exported file holds an object by the type of BxAppWindow with
the values of the configuration given at runtime.

Usage:
 If there is environment present by .env.[placeholder] this will be used, otherwise
 the fallback data is used.
 In the end of creating the config at runtime the values of window.bxappParams are overwriting
 every any default/ environment values.

 The values from window.bxaParams are provides by public/params.js
 This params.js will be an empty object until bxapp is build in the pipeline.
 Within the pipeline the params.js will be filled up by the pipeline environment variables
 and will be copied to the nginx web root.
 The nginx scripts can be found in this project unter nginx directory.

Important:
 For Jest testing there must be the fallback information present.
 */
export default Object.freeze({
  backendBaseUrl: import.meta.env.VITE_BACKEND_BASE_URL,
  cognitoUserPoolId: import.meta.env.VITE_COGNITO_USERPOOL_ID,
  cognitoClientID: import.meta.env.VITE_COGNITO_CLIENT_ID,
  qAppDeeplinkUrl: import.meta.env.VITE_Q_APP_DEEPLINK_URL,
  analyticsEnabled: import.meta.env.VITE_ANALYTICS_ENABLED,
  isDevelopment: import.meta.env.VITE_DEVELOPMENT_ENABLED,
  sentryDSN: import.meta.env.VITE_SENTRY_DSN,
  sentryEnv: import.meta.env.VITE_SENTRY_ENV,
  postHogApiKey: import.meta.env.VITE_POSTHOG_API,
  /**
   * Allow config specified at run time to override everything above.
   */
  ...getRunTimeConfig(),
});
