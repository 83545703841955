import { useNotificationStore } from '@/store/notifications/notificationStore';
import { Notification } from '@/models';

export const errorHandler = (error: unknown) => {
  const notificationStore = useNotificationStore();

  notificationStore.addNotification(
    new Notification()
      .setTitle('Fehler')
      .setText('Etwas ist schief gelaufen.')
      .setType('error')
  );
  console.error(error);
};
