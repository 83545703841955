import axios from 'axios';
import { Auth } from 'aws-amplify';

const configureAxios = () => {
  axios.interceptors.request.use(
    async (config) => {
      const data = await Auth.currentSession();
      config.headers.Authorization = `Bearer ${data
        .getAccessToken()
        .getJwtToken()}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) {
      // errorHandler(error);
      return Promise.reject(error);
    }
  );
};

export { configureAxios };
