<template>
  <div class="py-3">
    <div class="relative">
      <textarea
        :id="uniqueId"
        ref="textarea"
        :value="inputText"
        placeholder=" "
        class="peer mb-0 block w-full resize-none appearance-none rounded-t-lg border-0 border-b-2 border-gray-400 bg-light-1 px-2.5 pb-2.5 pt-5 text-sm text-gray-900 focus:border-primary-2 focus:outline-none focus:ring-0 dark:border-dark-3 dark:bg-dark-2 dark:text-white dark:focus:border-primary-2"
        :class="{
          'dark:focus-border-red-500 border-red-600 focus:border-red-600 dark:border-red-500 dark:focus:border-red-600':
            isError,
        }"
        :data-testid="testId"
        @input="updateInputText"
      >
      </textarea>
      <label
        :for="uniqueId"
        class="z-9 absolute left-2.5 top-4 origin-[0] -translate-y-4 scale-75 transform text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:text-primary-2 dark:text-gray-400 peer-focus:dark:text-primary-2"
        :class="{
          'text-red-600 peer-focus:text-red-500 dark:text-red-500 peer-focus:dark:text-red-500':
            isError,
        }"
        >{{ label }}
        <span v-if="!isRequired" class="ml-1 text-xs">(optional)</span>
      </label>
      <div
        v-if="isError"
        class="absolute mt-0 text-xs text-red-600 dark:text-red-500"
      >
        <template v-for="error in validation.$silentErrors" :key="error">
          {{ error.$message }}
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { Validation } from '@vuelidate/core';

interface Props {
  inputText?: string;
  label: string;
  testId?: string;
  validation?: Validation;
}

const props = withDefaults(defineProps<Props>(), {
  inputText: '',
  testId: '',
  validation: undefined,
});

const emit = defineEmits(['update:inputText']);

const textarea = ref<HTMLTextAreaElement | null>(null);
const resizeTextarea = () => {
  if (!textarea.value) {
    return;
  }
  textarea.value.style.height = '70px';
  textarea.value.style.height = textarea.value.scrollHeight + 'px';
};

const updateInputText = (event: Event) => {
  resizeTextarea();
  const element = event.target as HTMLInputElement;
  if (element.value === '') {
    emit('update:inputText', '');
    return;
  }
  emit('update:inputText', element.value.trim());
};

const uniqueId = computed(() => {
  return Math.random().toString(36).substr(2, 9);
});

const isRequired = computed(() => {
  if (props.validation) {
    return !props.validation.required?.$params?.optional;
  }
  return false;
});

const isError = computed(() => {
  if (props.validation) {
    return props.validation?.$errors?.length > 0 && props.validation?.$dirty;
  }
  return false;
});
</script>
