import { App, InjectionKey } from 'vue';
import { AnalyticsConfig } from './types/AnalyticsConfig';
import { PostHogAnalytics } from './providers/PostHogAnalytics';
import { PiniaPluginContext } from 'pinia';
import { AnalyticsProvider } from '@/plugins/bxAnalytics/types/AnalyticsProvider';

let analyticsProvider: AnalyticsProvider | undefined = undefined;

function getProvider(
  provider: string,
  apiKey: string,
  config: Record<string, any>
) {
  switch (provider) {
    case 'posthog':
      return new PostHogAnalytics(apiKey, config);
    default:
      throw new Error(`Unsupported analytics provider: ${provider}`);
  }
}

export type ActionsMap = {
  actions: string[];
  analyticsFunctions: {
    [actionName: string]: (
      args: any[],
      analyticsProvider: AnalyticsProvider
    ) => any;
  };
};

export function AnalyticsPlugin(
  app: App,
  { provider, apiKey, config = {} }: AnalyticsConfig
) {
  analyticsProvider = getProvider(provider, apiKey, config);
  app.provide(ANALYTICS_PROVIDER, analyticsProvider);
}

export const ANALYTICS_PROVIDER: InjectionKey<AnalyticsProvider> =
  Symbol('analytics');

export const piniaAnalytics = (actionsMap: ActionsMap) => {
  return ({ store }: PiniaPluginContext) => {
    store.$onAction((action) => {
      if (!analyticsProvider) return console.log('No analytics provider');

      const { actions, analyticsFunctions } = actionsMap;
      if (actions.includes(action.name)) {
        if (!analyticsFunctions[action.name])
          return console.log('No analytics function for this action');
        const analyticsEvent = analyticsFunctions[action.name](
          action.args,
          analyticsProvider
        );
        analyticsProvider.captureEvent(
          analyticsEvent['eventName'],
          analyticsEvent['properties']
        );
      }
    });
  };
};
