<template>
  <template v-if="isMounted">
    <span v-show="isActive">
      <slot></slot>
    </span>
  </template>
</template>

<script lang="ts">
import { defineComponent, inject, ref } from 'vue';

export default defineComponent({
  name: 'StepperContent',
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
      required: false,
    },
    subtitle: {
      type: String,
      default: '',
      required: false,
    },
    icon: {
      type: String,
      required: false,
      default: '',
    },
    isSkippable: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup() {
    /**
     * Functions provided by
     * {@link FormStepper#addTab}
     * {@link FormStepper#removeTab}.
     */
    const addTab = inject('addTab') as (tab: any) => void;
    const removeTab = inject('removeTab') as (tab: any) => void;

    const isActive = ref<boolean>(false);
    const isValid = ref<boolean>(false);
    const isMounted = ref<boolean>(false);

    return {
      isMounted,
      addTab,
      removeTab,
      isActive,
      isValid,
    };
  },
  watch: {
    isActive(newValue: boolean) {
      if (newValue) {
        this.isMounted = newValue;
      }
    },
  },
  mounted() {
    this.addTab(this);
  },
  created() {
    this.isActive = this.$props.selected;
  },
  unmounted() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
    this.removeTab(this);
  },
});
</script>
<script setup lang="ts"></script>
