import { AccessoryType } from '@/enums/device';
import { generateUniqueId } from '@/helpers/uuidUtils';

export abstract class Accessory {
  public id: string;
  public type: AccessoryType;
  public unknownProperties: string;

  constructor(params: any) {
    this.id = generateUniqueId();
    this.type = params.type;
    this.unknownProperties = params.unknownProperties ?? undefined;
  }
}

export class Adapter extends Accessory {
  public productKey: string;
  public customizedNumber: string;

  constructor() {
    const params = {
      type: AccessoryType.SRT_ADAPTER,
    };
    super(params);
    this.productKey = '';
    this.customizedNumber = '';
  }
}
