<template>
  <form @submit.prevent>
    <fieldset>
      <legend class="title">Passwort zurücksetzen</legend>
      <AuthenticationMessage :user-auth="authenticationMessage" />
      <BaseInput
        v-model="user.resetCode"
        label="Reset Code"
        placeholder=""
        :validation="v$.user.resetCode"
      />

      <BaseInput
        v-model="user.newPassword"
        class="mt-5"
        label="Passwort"
        type="password"
        placeholder="Passwort"
        :validation="v$.user.newPassword"
      />

      <BaseInput
        v-model="user.confirmPassword"
        class="mt-5"
        label="Passwort bestätigen"
        placeholder="Passwort bestätigen"
        type="password"
        :validation="v$.user.confirmPassword"
      />
    </fieldset>

    <div class="action-buttons mt-5">
      <button class="button is-black is-inverted" @click="goToLoginForm()">
        <em class="fa fa-arrow-left mr-3"></em>
        Zurück zum Login
      </button>
      <button
        :class="['button is-black is-big', { 'is-loading': isLoading }]"
        :disabled="isSubmitDisabled"
        @click="validateAndChangePassword()"
      >
        Passwort ändern
      </button>
    </div>
  </form>
</template>

<script lang="ts">
import { computed, defineComponent, inject, Ref } from 'vue';
import BaseInput from '@/components/UI/Input/BaseInput.vue';
import AuthenticationMessage from '@/components/Login/AuthenticationMessage.vue';
import { UserAuth } from '@/models/auth/UserAuth';
import { User } from '@/models/auth/User';
import { Validation } from '@vuelidate/core';

export default defineComponent({
  name: 'ResetPassword',
  components: { BaseInput, AuthenticationMessage },
  emits: ['go-to-login-form', 'validate-change-password'],
  setup(_props, { emit }) {
    /*
     * Injected properties
     */
    const user = inject<User>('user');
    const v$ = inject<Ref<Validation>>('v$');
    const authenticationMessage = inject<UserAuth>('authenticationMessage');
    const isLoading = inject<boolean>('isLoading');

    const validateAndChangePassword = () => {
      emit('validate-change-password');
    };

    const goToLoginForm = () => {
      emit('go-to-login-form');
    };

    const isSubmitDisabled = computed(() => {
      return v$?.value.$validationGroups?.resetPassword.$invalid;
    });

    return {
      user,
      v$,
      isLoading,
      authenticationMessage,
      isSubmitDisabled,
      goToLoginForm,
      validateAndChangePassword,
    };
  },
});
</script>
<style>
.action-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
