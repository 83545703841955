<template>
  <tr
    :class="{ 'bg-light-3 dark:bg-dark-2': isExpanded }"
    class="hover:bg-light-3 hover:dark:bg-dark-2"
    @click="
      device.uplinks && Object.keys(device?.uplinks)?.length > 0
        ? toggleExpand()
        : () => null
    "
  >
    <td class="text-center">
      <em
        v-if="device.uplinks && Object.keys(device?.uplinks)?.length > 0"
        :class="[
          {
            'mdi mdi-chevron-down text-xl': isExpanded,
          },
          {
            'mdi mdi-chevron-right text-xl': !isExpanded,
          },
        ]"
        data-testid="radiocheckResultExpansion"
      />
    </td>
    <td>{{ device?.serialNumber }}</td>
    <td>
      <em
        class="mr-3 px-1"
        :class="['mdi', statusIcon, statusTextColor, 'text-2xl']"
        data-testid="statusRadioCheck"
      />
    </td>
    <td></td>
    <td>
      {{ device?.floorLevel }}
    </td>
    <td>
      {{ device?.ordinal }}
    </td>

    <td @click="!isMinimal && goToRoomGroup($event)">
      {{ device?.roomLabel }}
    </td>
    <td
      :class="[
        'pr-3',
        {
          'cursor-pointer hover:bg-light-0 hover:dark:bg-dark-3': !isMinimal,
        },
      ]"
      @click="!isMinimal && goToRoomGroup($event)"
    >
      <div class="flex flex-row justify-between">
        {{ device?.roomgroup }}
        <em v-if="!isMinimal" class="mdi mdi-arrow-right mr-2 text-xl" />
      </div>
    </td>
  </tr>
  <SRTTableRowDetails :is-expanded="isExpanded" :device="device" />
</template>

<script setup lang="ts">
import { computed, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { useRoomGroupStore } from '@/store/entities/roomGroupStore';

import { useLayoutStore } from '@/store/layout/layoutStore';
import { SRTTableElementType } from '@/components/LoRaWanRadioCheck/SRTTable.vue';
import { useBuildingStore } from '@/store/entities/buildingStore';
import SRTTableRowDetails from '@/components/LoRaWanRadioCheck/SRTTableRowDetails.vue';
import { useOrderStore } from '@/store/order/orderStore';
import { MountEvent } from '@/models/installationPoint/DeviceEvent';
import { useRadioCheckStatus } from '@/composables/useRadioCheckStatus';

const router = useRouter();
const emits = defineEmits(['update:expanded']);
const props = defineProps<{
  device: SRTTableElementType;
  isMinimal?: boolean;
  expanded?: string;
  index: number;
}>();

const isExpanded = computed(() => {
  return props.expanded === props.device.uuid;
});

const toggleExpand = () => {
  if (isExpanded.value) {
    emits('update:expanded', undefined);
  } else {
    emits('update:expanded', props.device.uuid);
  }
};

const deviceStatus = computed(() => {
  return props.device.status;
});
const { statusIcon, statusTextColor } = useRadioCheckStatus(
  toRef(deviceStatus)
);

const goToRoomGroup = (event: MouseEvent) => {
  event.stopPropagation();
  const roomgroup = useRoomGroupStore()?.roomGroups?.get(
    props.device.roomgroupId
  );
  if (!roomgroup?.buildingId) return;
  useBuildingStore().setCurrentBuilding(roomgroup?.buildingId);
  useRoomGroupStore().setActiveMapRoomGroup(roomgroup?.id);
  useLayoutStore().changeCurrentNode(props.device.room);
  router.push('/detailansicht');
};
</script>
