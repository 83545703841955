<template>
  <button
    :class="{ 'w-56': !isScrolled, 'w-14 ': isScrolled }"
    class="text-md flex cursor-pointer flex-row items-center justify-center rounded-2xl bg-primary-1 p-3.5 text-primary-3 shadow transition-all hover:bg-primary-2 hover:text-white"
    @click="onClick"
  >
    <span class="flex flex-col items-center justify-center text-xl">
      <em v-if="buttonIcon" :class="`mdi mdi-${buttonIcon}`" />
    </span>

    <span
      v-if="!isScrolled"
      class="flex-col items-center justify-center overflow-hidden whitespace-nowrap pl-2"
    >
      {{ showAlternativeButtonText ? alternativeButtonText : buttonText }}
    </span>
  </button>
</template>

<script setup lang="ts">
import { inject, ref } from 'vue';

interface Props {
  buttonText?: string;
  alternativeButtonText?: string;
  buttonIcon?: string;
  showAlternativeButtonText?: boolean;
}

const emit = defineEmits(['on-click']);

const props = withDefaults(defineProps<Props>(), {
  buttonText: undefined,
  alternativeButtonText: undefined,
  buttonIcon: undefined,
});

const isScrolled = inject('isScrolled', ref(false));

const onClick = () => {
  emit('on-click');
};
</script>
