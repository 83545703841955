import { ref } from 'vue';
import { ImageObjectType } from '@/models/Image';
import * as indexedDB from '@/utils/indexedDb/IndexedDb';
import { useImagesStore } from '@/store/images/imageStore';
import { useEntitiesStore } from '@/store/entities/entitiesStore';
import {
  addEntityToDb,
  isEntityInDb,
  updateEntityInDb,
} from '@/utils/indexedDb/IndexedDb';
import { Image } from '@/models/Image';
import { plainToInstance } from 'class-transformer';

export function useImageCapture(objectId: string, objectType: ImageObjectType) {
  const galleryImages = ref<Array<Image>>([]);
  const indexedDbImages = ref<Image[]>([]);
  const imagesToBeDeleted = ref<Image[]>([]);

  const entitiesStore = useEntitiesStore();
  const imagesStore = useImagesStore();
  const getImages = async () => {
    if (imagesStore.objectHasImages(objectId)) {
      await getImagesFromRemote();
    }
    await getImagesFromIndexDB(objectId, objectType);
  };

  const getImagesFromIndexDB = async (
    objectId: string,
    objectType: ImageObjectType
  ) => {
    const retrievedImages = await indexedDB.getEntitiesFromDBByIndex(
      objectId,
      objectType,
      'objectId'
    );

    retrievedImages.forEach((newImage: Image) => {
      const existingImageIndex = galleryImages.value.findIndex(
        (existingImage) => existingImage.id === newImage.id
      );

      if (existingImageIndex !== -1) {
        const updatedImage = {
          ...galleryImages.value[existingImageIndex],
          ...newImage,
        };

        galleryImages.value[existingImageIndex] = plainToInstance(
          Image,
          updatedImage
        );
      } else {
        galleryImages.value.unshift(newImage);
      }
    });
  };

  const getImagesFromRemote = async () => {
    const businessEntityId = entitiesStore.activeBusinessEntityId;

    if (objectId && businessEntityId) {
      const imagePayload = await imagesStore.getImages(
        businessEntityId,
        objectId
      );

      imagePayload.forEach((image: Image) => {
        if (
          imagesStore.imagesToBeDeleted.some(
            (imageToBeDeleted) => image.id === imageToBeDeleted.id
          )
        ) {
          return;
        }

        const imageInstance: Image = plainToInstance(Image, image);
        imageInstance.businessEntityId = businessEntityId;

        galleryImages.value.push(imageInstance);
        imagesStore.addImageToRemote(imageInstance);
      });
    } else {
      throw new Error('Object not found');
    }
  };

  const onDelete = (index: number) => {
    imagesToBeDeleted.value.push(galleryImages.value[index]);
    galleryImages.value.splice(index, 1);
  };

  const updateImageInformation = (imgInfo: any) => {
    const index = galleryImages.value.findIndex(
      (image) => image.id === imgInfo.id
    );
    galleryImages.value[index] = plainToInstance(Image, {
      ...galleryImages.value[index],
      note: imgInfo.note,
      keywords: imgInfo.keywords,
    });
  };

  const saveImagesToIndexedDb = async () => {
    if (!objectId) {
      throw new Error(
        'Cant save installationPoint without installationPoint id'
      );
    }

    if (imagesToBeDeleted.value.length > 0) {
      imagesStore.addImagesToBeDeleted(
        imagesToBeDeleted.value.filter((image) => image?.id)
      );
      for (const image of imagesToBeDeleted.value) {
        if (image.id) {
          await indexedDB.deleteEntityByIdIndex(image.id);
        }
      }
    }

    for (const image of galleryImages.value) {
      const entryExists = await isEntityInDb(image);
      if (!entryExists) {
        await addEntityToDb(image);
        imagesStore.addObjectToList(objectId, objectType);
      } else {
        await updateEntityInDb(image);
      }
    }
  };

  const saveImagesToGallery = (imagePayload: string): void => {
    const businessEntityId = useEntitiesStore().activeBusinessEntityId;

    if (!objectId || !businessEntityId) {
      throw new Error(`Id not found for object ${objectType}`);
    }

    const imageInstance: Image = new Image();
    imageInstance.assignImageProperties(
      objectId,
      objectType,
      imagePayload,
      businessEntityId
    );

    galleryImages.value.push(imageInstance);
    imagesStore.setImage(imageInstance);
  };

  return {
    images: galleryImages,
    saveImagesToGallery,
    getImages,
    onDelete,
    indexedDbImages,
    saveImagesToIndexedDb,
    updateImageInformation,
  };
}
