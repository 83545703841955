import { computed, ref } from 'vue';
import {
  HeatingPlant,
  PlantDataClasses,
  PlantFactory,
  PlantType,
  PlantClasses,
  TemperatureSetting,
} from '@/models/plant/Plant';
import {
  isLegacyPlantMeterDevice,
  LegacyPlantMeterDevice,
} from '@/models/devices/Device';
import { InstallationPoint } from '@/models/installationPoint/InstallationPoint';
import { deviceTypes } from '@/enums/device';
import { useDeviceForm } from '@/composables/useDeviceForm';
import useStores from '@/composables/useStores';
import { instanceToInstance } from 'class-transformer';
import { DeviceEvent } from '@/models/installationPoint/DeviceEvent';

export function usePlantDataForm(emit: any) {
  const { deviceStore, installationPointStore } = useStores();
  const {
    device,
    installationPoint,
    initDevice,
    initInstallationPoint,
    deviceEvent,
  } = useDeviceForm(emit);

  const plantData = ref<PlantDataClasses>();

  const relatedDevicesAndInstallationPoints = ref<
    Record<
      string,
      {
        device: LegacyPlantMeterDevice;
        deviceEvent: DeviceEvent;
        installationPoint: InstallationPoint;
      }
    >
  >({});

  const toBeDeletedInstallationPoints = ref<InstallationPoint[]>([]);

  const selectedPlant = ref<PlantType | undefined>(undefined);
  const temperatureSettings = computed(() => {
    if (plantData.value instanceof HeatingPlant) {
      return plantData.value.temperatureSettings;
    } else {
      throw new Error('temperatureSettings do not exist on this plantType');
    }
  });

  const isHeatingPlant = computed(() => {
    return plantData.value instanceof HeatingPlant;
  });

  const initPlantData = <T extends PlantType>(
    plantType: T,
    params?: Extract<PlantClasses, { plantType: T }>
  ) => {
    if (!plantType) {
      throw new Error('plantType is not defined');
    }
    plantData.value = PlantFactory.createPlant(plantType) as PlantClasses;
    if (params) {
      plantData.value.updateProperties(params);
    }
  };

  const addLegacyPlantMeter = (parentId: string) => {
    initInstallationPoint(parentId);
    if (!installationPoint.value) {
      throw new Error('installationPoint is not defined');
    }
    installationPoint.value?.setPlantId(plantData.value?.id);
    if (plantData.value instanceof HeatingPlant) {
      initDevice(deviceTypes.LegacyHeatingPlantMeter);
    }
    if (plantData.value?.plantType === PlantType.WATER_PLANT) {
      initDevice(deviceTypes.LegacyWaterPlantMeter);
    }
    if (!device.value) {
      throw new Error('device is not defined');
    }
    if (!deviceEvent.value) {
      throw new Error('device event is not defined');
    }
    if (isLegacyPlantMeterDevice(device.value.deviceType)) {
      installationPoint.value.captureDevice(
        device.value.id,
        deviceEvent.value.timestamp,
        deviceEvent.value.counter
      );
      addRelatedDeviceAndInstallationPoint(
        device.value as LegacyPlantMeterDevice,
        deviceEvent.value as DeviceEvent,
        installationPoint.value
      );
    } else {
      throw new Error('device is not a legacyPlantMeterDevice');
    }
  };

  const addRelatedDeviceAndInstallationPoint = (
    device: LegacyPlantMeterDevice,
    deviceEvent: DeviceEvent,
    installationPoint: InstallationPoint
  ) => {
    relatedDevicesAndInstallationPoints.value[installationPoint.id] = {
      device,
      deviceEvent,
      installationPoint,
    };
  };

  const connectedLegacyPlantMeterDevices = computed(() => {
    if (!plantData.value) {
      return [];
    }
    return deviceStore.getDevicesOnPlant(plantData.value.id);
  });

  const connectedInstallationPoints = computed(() => {
    if (!plantData.value) {
      return [];
    }
    return installationPointStore.getInstallationPointsOnPlant(
      plantData.value.id
    );
  });

  const initRelatedDevicesAndInstallationPoints = () => {
    const devices: LegacyPlantMeterDevice[] =
      connectedLegacyPlantMeterDevices.value.map((device) =>
        instanceToInstance(device)
      );
    const installationPoints: InstallationPoint[] =
      connectedInstallationPoints.value.map((installationPoint) =>
        instanceToInstance(installationPoint)
      );

    relatedDevicesAndInstallationPoints.value = {};

    devices.forEach((device) => {
      const installationPointId = device.installationPointId;
      const installationPoint = installationPoints.find(
        (ip) => ip.id === installationPointId
      );
      if (!installationPoint) {
        throw new Error('installationPoint is not defined');
      }

      relatedDevicesAndInstallationPoints.value[installationPointId] = {
        device: device,
        deviceEvent: installationPoint.deviceEvents[0],
        installationPoint: installationPoint,
      };
    });
  };

  const deleteLegacyPlantMeter = (installationPoint: InstallationPoint) => {
    toBeDeletedInstallationPoints.value.push(installationPoint);

    const updatedDevicesMap = { ...relatedDevicesAndInstallationPoints.value };
    delete updatedDevicesMap[installationPoint.id];
    relatedDevicesAndInstallationPoints.value = updatedDevicesMap;
  };

  const addTemperatureSetting = () => {
    if (plantData.value instanceof HeatingPlant) {
      plantData.value.temperatureSettings.push(new TemperatureSetting());
    } else {
      throw new Error('temperatureSettings do not exist on this plantType');
    }
  };

  const removeTemperatureSetting = (index: number) => {
    if (plantData.value instanceof HeatingPlant) {
      plantData.value.temperatureSettings.splice(index, 1);
    } else {
      throw new Error('temperatureSettings do not exist on this plantType');
    }
  };

  return {
    plantData,
    relatedDevicesAndInstallationPoints,
    selectedPlant,
    temperatureSettings,
    isHeatingPlant,
    toBeDeletedInstallationPoints,
    addLegacyPlantMeter,
    initRelatedDevicesAndInstallationPoints,
    initPlantData,
    deleteLegacyPlantMeter,
    addTemperatureSetting,
    removeTemperatureSetting,
  };
}
