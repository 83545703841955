import { computed, inject, InjectionKey, ref } from 'vue';
import diff, { Difference } from 'microdiff';
import { instanceToInstance } from 'class-transformer';

export const FORM_CHANGE_TRACKER: InjectionKey<(refsArray: any) => void> =
  Symbol('form-change-tracker');
export const IMAGE_CHANGE_TRACKER: InjectionKey<(refsArray: any) => void> =
  Symbol('image-change-tracker');

export function useFormChangeTracker() {
  const initialSnapshots = ref([]);
  const currentSnapshots = ref([]);

  const trackChanges = (refsArray: any) => {
    if (!Array.isArray(refsArray)) {
      refsArray = [refsArray];
    }

    initialSnapshots.value = refsArray.map((ref: Record<any, any>) =>
      instanceToInstance(ref.value)
    );
    currentSnapshots.value = refsArray;
  };

  const changes = computed(() => {
    if (initialSnapshots.value.length > 0) {
      let diffs: Array<Difference> = [];
      initialSnapshots.value.forEach((snapshot, index) => {
        const currentSnapshot = currentSnapshots.value[index]['value'];
        diffs = [...diff(snapshot, currentSnapshot), ...diffs];
      });
      return diffs;
    }
    return [];
  });

  const hasFormChanges = computed(() => {
    return changes.value.length > 0;
  });

  return {
    initialSnapshots,
    currentSnapshots,
    trackChanges,
    changes,
    hasFormChanges,
  };
}

/*
 * Injection can't be used outside a component context
 * so this function can only be used in lifecycle hooks
 * If you need to use it in a method, you need to do the injection
 * manually
 */
export const useFormChangeTrackerPlugin = (entity: any) => {
  const trackFormChanges = inject(FORM_CHANGE_TRACKER);
  if (trackFormChanges) {
    trackFormChanges(entity);
  }
};
