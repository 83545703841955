<template>
  <div
    class="relative flex flex-row gap-4 rounded-xl bg-light-3 px-4 py-2 shadow dark:bg-dark-1"
  >
    <div class="flex min-w-[4rem] flex-col justify-center text-center">
      <div>{{ floorName.split('-')[0] }}</div>
      <div style="font-weight: 700; font-size: 48px; line-height: 48px">
        {{ floorName !== 'EG' ? floorName.split('-')[1] : '-' }}
      </div>
    </div>

    <BuildingFloorRoomGroups
      :entrance="roomGroupData['entrance']"
      :roomgroups="roomGroupData['roomgroups']"
    />

    <div
      :data-testid="floorName + '_createRoomGroupButton'"
      class="my-1 flex min-w-[5rem] items-center justify-center rounded-xl border-dark-4 bg-light-2 text-center text-3xl text-primary-2 shadow hover:bg-light-2 dark:border dark:bg-dark-3 hover:dark:bg-dark-4"
      @click="onAddRoomGroup"
    >
      <em class="mdi mdi-plus" />
    </div>

    <button
      v-if="isBuildingFloorDeletable"
      class="mdi mdi-close ml-auto flex justify-start text-2xl"
      data-testid="removeFloorButton"
      @click="deleteFloorLevel()"
    />
  </div>
</template>

<script lang="ts" setup>
import { floorLevelListMapping } from '@/enums/room';
import { Building, Notification } from '@/models';
import { computed } from 'vue';
import { Form } from '@/models/Form';
import { useRoomStore } from '@/store/entities/roomStore';
import BuildingFloorRoomGroups from '@/components/BuildingOverview/BuildingFloorRoomGroups.vue';
import useStores from '@/composables/useStores';

const props = defineProps<{
  floorName: string;
  roomGroupData: any;
  currentBuilding: Building;
  isHighestFloorLevel: boolean;
}>();

const { entitiesStore, roomGroupStore, formStore } = useStores();

const isBuildingFloorDeletable = computed(() => {
  return Boolean(
    props.roomGroupData['roomgroups'].length === 0 &&
      props.floorName !== floorLevelListMapping.GROUND &&
      props.isHighestFloorLevel &&
      roomGroupStore.isRoomgroupEmpty(entranceRoomGroup.value)
  );
});

const entranceRoomGroup = computed(() => {
  return entitiesStore.getEntityById(props.roomGroupData['entrance'].id);
});
const onAddRoomGroup = () => {
  const parentRoom = useRoomStore().getFirstRoomOfRoomgroup(
    props.roomGroupData['entrance'].id
  );
  if (!parentRoom) return;
  formStore.createEntity(
    new Form().setName('roomgroup').setParentId(parentRoom.id)
  );
};
const deleteFloorLevel = () => {
  const entrance = props.roomGroupData['entrance'];
  entitiesStore.deleteEntity(
    entrance,
    new Notification(
      'Etage wurde entfernt!',
      `Die Etage ${props.floorName} wurde entfernt!`,
      'success',
      3000
    )
  );
};
</script>
