<template>
  <em
    v-if="hasErrors"
    class="mdi radiocheck-status-error px-1 text-[1.2em] text-light-error dark:text-dark-error"
    :class="NO_SIGNAL_ICON"
  />
</template>

<script lang="ts" setup>
import { useFunkcheckStore } from '@/store/funkcheck/funkcheckStore';
import { computed } from 'vue';
import { Room, Roomgroup } from '@/models';
import { DeviceClasses } from '@/models/devices/Device';
import { NO_SIGNAL_ICON } from '@/enums/icons';

const props = defineProps<{
  device?: DeviceClasses;
  room?: Room;
  roomgroup?: Roomgroup;
  roomId?: string;
}>();

const hasErrors = computed(() => {
  if (props.device) {
    return hasDeviceErrors.value;
  }
  if (props.roomgroup) {
    return hasRoomgroupErrors.value;
  }
  if (props.room) {
    return hasRoomErrors.value;
  }
  return false;
});

const hasRoomErrors = computed(() => {
  return (
    useFunkcheckStore().funkcheckErrors.filter((error) => {
      return error.roomId === props.room?.id;
    }).length > 0
  );
});

const hasDeviceErrors = computed(() => {
  return (
    useFunkcheckStore().funkcheckErrors.filter((error) => {
      return error.deviceId === props.device?.id;
    }).length > 0 ||
    useFunkcheckStore()
      .getDevicesWithRadioCheckErrorInRoom(props.roomId)
      .find((device) => device.id === props.device?.id)
  );
});

const hasRoomgroupErrors = computed(() => {
  return (
    useFunkcheckStore().funkcheckErrors.filter((error) => {
      return error.roomGroupId === props.roomgroup?.id;
    }).length > 0
  );
});
</script>
