<template>
  <div class="status-indicator is-flex">
    <div :class="[{ isComplete: isComplete }, 'fas fa-square-check']"></div>
    <div>{{ label }}</div>
  </div>
</template>
<script>
export default {
  name: 'StatusIndicator',
  props: {
    label: {
      type: String,
      default: undefined,
    },
    isComplete: {
      type: Boolean,
    },
  },
};
</script>
<style scoped lang="scss">
.status-indicator {
  font-size: 20px;
  font-weight: 700;
  margin-top: 8px;
}

.fa-square-check {
  margin-right: 10px;
  align-self: center;
  color: #888888;
}
.isComplete {
  color: #14c011;
}
</style>
