<template>
  <LayoutWrapper>
    <div class="mt-24 flex justify-between md:mt-9">
      <h1>LoRaWan Radiocheck</h1>
      <div>
        <SecondaryButton
          :is-disabled="!isRadioCheckAvailable"
          data-testid="performRadioCheck"
          label="Radiocheck durchführen"
          :icon="loading ? 'mdi-loading mdi-spin' : 'mdi-radio-tower'"
          @click="radioCheck"
        />
      </div>
    </div>
    <div v-if="gateways.length > 0">
      <div class="mb-5 flex flex-row justify-between">
        <div class="flex basis-3/4 flex-col justify-center">
          <BaseSection
            :heading="i18n.label.installedGateways"
            :label="gateways.length.toString()"
          >
            <div class="mt-1 rounded-xl bg-light-1 p-0.5 dark:bg-dark-1">
              <GatewayTable />
            </div>
          </BaseSection>
        </div>
        <div class="flex flex-col self-end rounded-xl">
          <div class="flex flex-row justify-center">
            <div class="mr-3 flex flex-col justify-center font-semibold">
              Status:
            </div>

            <div
              class="rounded-full px-4 py-1"
              :class="statusClasses"
              data-testId="overallStatusRadioCheck"
            >
              <em
                :class="[
                  {
                    'mdi mdi-alert font-bold':
                      radioCheckResult?.status ===
                      OverallRadioCheckStatus.FAILED,
                  },
                  {
                    'mdi mdi-check font-bold':
                      radioCheckResult?.status ===
                      OverallRadioCheckStatus.COMPLETED,
                  },
                  {
                    'mdi mdi-help font-bold': !radioCheckResult?.status,
                  },
                ]"
              />
              <span class="mx-2"> {{ statusText }} </span>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-10 rounded-xl bg-light-1 pb-0.5 pt-0.5 dark:bg-dark-1">
        <SRTTable :radio-check-devices="radioCheckDevices" />
      </div>
    </div>
    <div v-else>
      <BaseInformation
        status="warning"
        :information-text="i18n.info.noGatewayInstalled"
      />
    </div>
  </LayoutWrapper>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useDeviceStore } from '@/store/entities/deviceStore';
import { useRoomGroupStore } from '@/store/entities/roomGroupStore';
import { useBuildingStore } from '@/store/entities/buildingStore';
import { useFunkcheckStore } from '@/store/funkcheck/funkcheckStore';
import { useLayoutStore } from '@/store/layout/layoutStore';
import { RadioCheckResponse } from '@/api/funkcheck/LoRaWanRadioCheck';
import { OverallRadioCheckStatus } from '@/enums/radiocheck';
import LayoutWrapper from '@/components/LayoutWrapper.vue';
import SecondaryButton from '@/components/UI/Button/SecondaryButton.vue';
import BaseInformation from '@/components/UI/Hint/BaseInformation.vue';
import GatewayTable from '@/components/LoRaWanRadioCheck/GatewayTable.vue';
import SRTTable from '@/components/LoRaWanRadioCheck/SRTTable.vue';
import i18n from '@/utils/i18n/radiocheck.json';
import { useEntitiesStore } from '@/store/entities/entitiesStore';
import BaseSection from '@/components/UI/Card/BaseSection.vue';

const funkcheckStore = useFunkcheckStore();
const deviceStore = useDeviceStore();
const roomGroupStore = useRoomGroupStore();
const buildingStore = useBuildingStore();
const layoutStore = useLayoutStore();
const router = useRouter();

onMounted(() => {
  const loRaWanRadioCheck = useFunkcheckStore().loRaWanRadioCheck;
  if (
    loRaWanRadioCheck?.result &&
    loRaWanRadioCheck?.businessEntityId ===
      useEntitiesStore().activeBusinessEntityId
  ) {
    radioCheckResult.value = useFunkcheckStore().loRaWanRadioCheck;
    radioCheckDevices.value = useFunkcheckStore().loRaWanRadioCheck?.result;
  }
});

const gateways = computed(() => deviceStore.getLoRaWanGateways);

const devices = deviceStore.getSRTs;
const loRaWanGateway = computed(() => {
  return deviceStore.getLoRaWanGateways
    ? deviceStore.getLoRaWanGateways[0]
    : undefined;
});

const isRadioCheckAvailable = computed(() => {
  return loRaWanGateway?.value;
});

const radioCheckDevices = ref<RadioCheckResponse | undefined>({});
const radioCheckResult = ref();

const loading = ref(false);
const radioCheck = () => {
  if (!loRaWanGateway.value.serialNumber) {
    return;
  }

  loading.value = true;
  const payload = {
    gateways: deviceStore.getLoRaWanGateways,
    srts: deviceStore.getSRTs,
  };

  funkcheckStore
    .fetchRadioCheck(payload)
    .then((response) => {
      radioCheckDevices.value = response.result;
      radioCheckResult.value = response;
    })
    .catch((error) => {
      console.error(error);
    })
    .finally(() => {
      loading.value = false;
    });
};

const loRaWanRadioCheck = computed(() => {
  return funkcheckStore.loRaWanRadioCheck;
});

const statusText = computed(() => {
  switch (radioCheckResult.value?.status) {
    case OverallRadioCheckStatus.COMPLETED:
      return i18n.status.success;
    case OverallRadioCheckStatus.FAILED:
      return i18n.status.error;
    default:
      return i18n.status.unknown;
  }
});

const statusClasses = computed(() => {
  switch (radioCheckResult.value?.status) {
    case OverallRadioCheckStatus.COMPLETED:
      return 'radiocheck-status-success';
    case OverallRadioCheckStatus.FAILED:
      return 'radiocheck-status-error';
    default:
      return 'radiocheck-status-warning';
  }
});
</script>

<style>
.expand {
  @apply bg-light-3 dark:bg-dark-2;
}

.radiocheck-status-success {
  @apply rounded-full border-light-success bg-light-success/10 text-light-success dark:border-dark-success dark:bg-dark-success/10 dark:text-dark-success;
}

.radiocheck-status-error {
  @apply rounded-full border-light-error bg-light-error/10 text-light-error dark:border-dark-error dark:bg-dark-error/10 dark:text-dark-error;
}

.radiocheck-status-warning {
  @apply rounded-full border-light-warning bg-light-warning/10 text-light-warning dark:border-dark-warning dark:bg-dark-warning/10 dark:text-dark-warning;
}
</style>
