import { computed, ref } from 'vue';
import { Room, Roomgroup } from '@/models';
import useStores from '@/composables/useStores';
import { allRoomTypesList } from '@/enums/room';

export function useRoomForm(
  node: Room | undefined,
  parentId: string,
  emit?: any
) {
  const room = ref<Room>(new Room());
  const { entitiesStore, roomStore } = useStores();

  const handleSubmit = () => {
    emit('update-entity', room.value);
  };

  const initializeRoomForm = () => {
    room.value = new Room();
    const parentOfRoom = entitiesStore.getEntityById(parentId);
    if (!(node instanceof Room)) {
      room.value.roomGroupId =
        parentOfRoom instanceof Roomgroup
          ? parentOfRoom.id
          : parentOfRoom.roomGroupId;
      room.value.parentRoomId = parentId;
      room.value.ordinal = roomStore.getNewRoomOrdinal(parentId);
      const parentRoom = entitiesStore.getEntityById(parentId);
      room.value.floorLevel = parentRoom.floorLevel;
      return;
    }
    Object.assign(room.value, node);
  };

  const handleIsAccessibleChange = (isAccessible: boolean) => {
    room.value.isAccessible = isAccessible;
    if (isAccessible) {
      room.value.reasonNotAccessible = undefined;
      room.value.dateNotAccessible = undefined;
    } else {
      room.value.dateNotAccessible = new Date().toISOString();
    }
  };

  const isFirstRoomOfRoomGroup = computed(() => {
    return room.value.usageType === allRoomTypesList[0].value;
  });

  return {
    room,
    handleSubmit,
    isFirstRoomOfRoomGroup,
    handleIsAccessibleChange,
    initializeRoomForm,
  };
}
