import { computed, ref } from 'vue';
import { BarcodeScanner } from '@/native/plugins/BarcodeScanner';
import { Capacitor } from '@capacitor/core';
import { AccessoryType, DeviceTypeEnum, deviceTypesDE } from '@/enums/device';
import { parseCode } from '@/utils/scanner/codeParser';

export default function useCodeScanner(
  emitScanResult: (result: any) => void,
  deviceType?: AccessoryType | DeviceTypeEnum
) {
  const isCameraOpen = ref(false);
  const isModalOpen = ref(false);
  const scannerLabel = computed(() => {
    if (deviceType) {
      if (deviceTypesDE[deviceType])
        return `Scanne den Code auf dem ${deviceTypesDE[deviceType]}`;
      if (deviceType === AccessoryType.SRT_ADAPTER)
        return `Scanne den Code auf dem Smart Radiator Thermostat Adapter`;
      if (deviceType === AccessoryType.SRT_BATTERY_PACK)
        return `Scanne den Code auf dem Smart Radiator Thermostat Batteriepack`;
    }
    return undefined;
  });

  const startScan = async () => {
    if (!Capacitor.isNativePlatform()) {
      isCameraOpen.value = true;
      isModalOpen.value = true;
    }

    const scanResult = await BarcodeScanner.start({
      label: scannerLabel.value,
    });
    if (scanResult && scanResult.hasContent) {
      emitScanResult(parseCode(scanResult, deviceType));
    } else {
      emitScanResult({});
    }
    stopScan();
  };

  const stopScan = () => {
    isCameraOpen.value = false;
  };

  const onModalClose = () => {
    isCameraOpen.value = false;
    emitScanResult({});
  };

  return {
    startScan,
    stopScan,
    isModalOpen,
    onModalClose,
  };
}
