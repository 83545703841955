export class User {
  public username: string;
  public password: string;
  public email?: string;
  public confirmPassword: string;
  public resetCode: string;
  public newPassword: string;

  constructor() {
    this.username = '';
    this.password = '';
    this.email = undefined;
    this.confirmPassword = '';
    this.resetCode = '';
    this.newPassword = '';
  }
}
