<template>
  <div
    class="inline-flex items-center justify-center rounded-md border-2 py-0.5 px-1.5 text-xs"
    :class="[chipClasses, { 'h-7': icon }]"
    :style="customColor && customColorStyle"
  >
    <span v-if="number" class="mr-1.5 pl-0.5 pt-0.5 font-bold">{{
      number
    }}</span>
    <em v-if="icon" :class="['mdi text-xl', icon, { 'mr-1 ': text }]" />
    <span>{{ text }}</span>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  text?: string;
  icon?: string;
  number?: string | number;
  type?: string;
  customColor?: string;
  outlined?: boolean;
}>();

const customColorStyle = computed(() => {
  if (props.customColor) {
    if (props.outlined) {
      return `border: 1px solid ${props.customColor}; color: ${props.customColor}`;
    } else {
      return `background: ${props.customColor}; border: 1px solid ${props.customColor}; color: white`;
    }
  }
  return '';
});

const chipClasses = computed(() => {
  let cssString = '';
  switch (props.type) {
    case 'info':
      cssString = 'border border-zinc-500 dark:border-zinc-400';
      if (props.outlined) {
        cssString = `${cssString} text-zinc-500 dark:text-zinc-300`;
      } else {
        cssString = `${cssString} bg-zinc-500 dark:bg-zinc-300 text-zinc-50 dark:text-zinc-900`;
      }
      break;
    case 'danger':
      cssString = 'border border-light-error dark:border-dark-error';
      if (props.outlined) {
        cssString = `${cssString} radiocheck-status-error text-light-error`;
      } else {
        cssString = `${cssString} bg-light-error dark:bg-dark-error text-gray-50 dark:text-white`;
      }
      break;
    case 'success':
      cssString = 'border border-light-success dark:border-dark-success';
      if (props.outlined) {
        cssString = `${cssString} text-light-success dark:text-dark-success`;
      } else {
        cssString = `${cssString} bg-light-success dark:bg-dark-success text-gray-50`;
      }
      break;
    case 'warning':
      cssString = 'border border-light-warning dark:border-dark-warning';
      if (props.outlined) {
        cssString = `${cssString} text-light-warning dark:text-dark-warning`;
      } else {
        cssString = `${cssString} bg-light-warning dark:bg-dark-warning text-gray-50 font-bold`;
      }
      break;
    case 'successInfo':
      cssString =
        'border border-light-success bg-light-0 dark:border-dark-success dark:bg-dark-0';
      break;
    default:
      break;
  }
  return cssString;
});
</script>
