/*
 * this layer of transformation will be removed once this
 * responsibility is moved to android
 */

import { mappingTypes } from '@/enums/generic';

const convertTreeToFlat = (businessEntityMap) => {
  const outputArray = {};

  businessEntityMap.forEach((obj) => {
    const entity = JSON.parse(JSON.stringify(obj));
    const entityType = entity.type;
    if (entity.serialNumber === '') {
      entity.serialNumber = null;
    }
    if (entityType === 'businessEntity') {
      outputArray.address = entity.address;
      outputArray.contactPerson = entity.contactPerson;
      // TODO: remove this in v4
      outputArray.humanReadableId = entity.humanReadableId;
      outputArray.externalId = entity.externalId;
      outputArray.billingUnitReference = entity.billingUnitReference;
      outputArray.propertyId = entity.propertyId;
      outputArray.updatedAt = entity.updatedAt;
      outputArray.id = entity.id;
      return;
    }

    if (
      (entityType === 'installationPoint' ||
        entityType === 'inspectionPoint') &&
      entity.inspectionData &&
      entity.inspectionData.radioChecks
    ) {
      entity.inspectionData.radioChecks = entity.inspectionData.radioChecks.map(
        (radioCheck) => {
          return {
            ...radioCheck,
            installationPointId: entity.id,
            result: JSON.stringify(radioCheck.result),
          };
        }
      );
    }

    if (!outputArray[mappingTypes[entityType]]) {
      outputArray[mappingTypes[entityType]] = [];
    }
    outputArray[mappingTypes[entityType]].push(entity);
  });

  return outputArray;
};

export { convertTreeToFlat };
