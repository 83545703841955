import { generateUniqueId } from '@/helpers/uuidUtils';
import { entityTypes } from '@/enums/generic';
import { allRoomTypesList, floorLevelListMapping } from '@/enums/room';
import { FloorLevel } from '@/models/types/FloorLevel';
import 'reflect-metadata';

/**
 * Any adjustments to the properties of this class
 * should be reflected in the android kotlin class {@see Room.kt}
 */
export class Room {
  public id: string;
  public roomGroupId?: string;
  public parentRoomId?: string;
  public isAccessible: boolean;
  public reasonNotAccessible?: string;
  public dateNotAccessible?: string;
  public hasHighCeiling: boolean;
  public isDIN14676: boolean;
  public ordinal: number;
  public usageType?: string;
  public floorLevel: FloorLevel;
  public number?: string;
  public description?: string;
  public note: string;
  public type: string;

  constructor() {
    this.id = generateUniqueId();
    this.roomGroupId = undefined;
    this.parentRoomId = undefined;
    this.isAccessible = true;
    this.reasonNotAccessible = undefined;
    this.dateNotAccessible = undefined;
    this.hasHighCeiling = false;
    this.isDIN14676 = false;
    this.ordinal = 0;
    this.usageType = undefined;
    this.floorLevel = { type: undefined, level: undefined };
    this.number = undefined;
    this.description = undefined;
    this.note = '';
    this.type = entityTypes.room;
  }

  getLabel() {
    const usageType = allRoomTypesList.find((obj) => {
      return obj.value === this.usageType;
    });
    if (usageType) {
      return `${usageType.name}`;
    }
    return '';
  }

  getParentId() {
    return this.parentRoomId ? this.parentRoomId : this.roomGroupId;
  }

  getArrowAction() {
    return [];
  }

  getFloor() {
    const floorLevel = this.floorLevel.type;
    if (!floorLevel) {
      return undefined;
    }

    if (floorLevelListMapping[floorLevel] === floorLevelListMapping.GROUND) {
      return `${floorLevelListMapping[floorLevel]}`;
    }
    return `${floorLevelListMapping[floorLevel]}-${this.floorLevel.level}`;
  }
}
