export const devicefunkcheckStatus = Object.freeze({
  error: 'ERROR',
  done: 'FINISHED',
});

export const mobileSignalStrength = Object.freeze([
  {
    name: '',
    subtitle: '',
    value: 1,
    image: require('@/assets/img/funkcheck/mobileSignalStrength/signal1.svg'),
  },
  {
    name: '',
    subtitle: '',
    value: 2,
    image: require('@/assets/img/funkcheck/mobileSignalStrength/signal2.svg'),
  },
  {
    name: '',
    subtitle: '',
    value: 3,
    image: require('@/assets/img/funkcheck/mobileSignalStrength/signal3.svg'),
  },
  {
    name: '',
    subtitle: '',
    value: 4,
    image: require('@/assets/img/funkcheck/mobileSignalStrength/signal4.svg'),
  },
]);
