<template>
  <tr :class="{ hidden: !isExpanded, expand: isExpanded }">
    <td colspan="8">
      <table>
        <thead>
          <tr>
            <th class="w-[5%]"></th>
            <th class="w-[13%]">
              {{ i18n.label.eui }}
            </th>
            <th class="w-[15%]">
              {{ i18n.label.status }}
            </th>
            <th class="w-[2%]"></th>
            <th class="w-[10%]">
              {{ i18n.label.rssi }}
            </th>
            <th class="w-[10%]">
              {{ i18n.label.snr }}
            </th>
            <th class="w-[10%]">
              {{ i18n.label.spreadingFactor }}
            </th>
            <th class="w-[30%]"></th>
          </tr>
        </thead>
        <tbody id="tbody_id">
          <SRTTableReceptionData
            :reception-quality="device.downlink"
            :receive-date="device.downlink.lastReceived"
            direction="downlink"
          />
          <SRTTableReceptionData
            v-for="(gateway, key) in device?.uplinks"
            :key="key"
            :reception-quality="gateway"
            :receive-date="getReceiveDate(gateway.receiveTime)"
            direction="uplink"
            :eui="key"
          />
        </tbody>
      </table>
    </td>
  </tr>
</template>

<script setup lang="ts">
import { SRTTableElementType } from '@/components/LoRaWanRadioCheck/SRTTable.vue';
import i18n from '@/utils/i18n/radiocheck.json';
import SRTTableReceptionData from '@/components/LoRaWanRadioCheck/SRTTableReceptionData.vue';
import { timeAgo } from '@/helpers/timeAgo';

const props = defineProps<{
  device: SRTTableElementType;
  isExpanded: boolean;
}>();

const getReceiveDate = (date: string | undefined) => {
  if (date) {
    return timeAgo(new Date(Date.parse(date)));
  }
  return undefined;
};
</script>
