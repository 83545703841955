<template>
  <div>
    <button
      :class="[
        'rounded-full p-6 py-2 text-white transition-all ',
        { 'is-loading': isSaving },
        { 'is-justify-content-start': saved && hasActiveOrder },
        {
          'bg-primary-2 dark:bg-primary-1 hover:dark:bg-primary-2':
            !errorStatus.hasError,
        },
        {
          'dark:bg-dark-error-0 hover:dark:bg-dark-error-0 bg-light-error/10 hover:bg-light-error':
            errorStatus.hasError,
        },
      ]"
      data-testid="uploadButton"
      @click="startSaveProcess"
    >
      <em
        :class="[
          'mdi leading-none',
          { 'mdi-content-save-outline': !saved },
          { 'mdi-check': saved },
          { 'mdi-loading mdi-spin': isSaving },
        ]"
      ></em>

      <span> Daten speichern </span>
    </button>
    <button
      v-if="hasActiveOrder"
      :class="[
        'ml-3 rounded-full px-6 py-2 transition-all duration-300 ease-in-out',
        {
          'cursor-not-allowed bg-light-disabled-background dark:bg-dark-disabled-background':
            !saved,
        },
        {
          'bg-primary-2 text-white dark:bg-primary-1 hover:dark:bg-primary-2 ':
            saved,
        },
      ]"
      :disabled="!saved || !isOnline"
      data-testid="closeOrderBtn"
      @click="openDialog"
    >
      <span
        :class="[
          {
            'text-light-disabled-text dark:text-dark-disabled-text': !saved,
          },
        ]"
        >Auftrag abschließen</span
      >
    </button>
  </div>
  <BasePrompt
    :open="modalOpen"
    :close="toggleModal"
    :cancel="toggleModal"
    :proceed="finishOrder"
    title="Auftrag abschließen"
    question="Sind Sie sicher, dass Sie den Auftrag abschließen möchten?"
  />
  <ImageUploadHeader ref="imageUpload" />
</template>

<script lang="ts" setup>
import { usePersistenceStore } from '@/store/persistence/persistenceStore';
import { computed, ref } from 'vue';
import { useOrderStore } from '@/store/order/orderStore';
import { useLayoutStore } from '@/store/layout/layoutStore';
import { useRouter } from 'vue-router';
import BasePrompt from '@/components/UI/Modal/BasePrompt.vue';
import ImageUploadHeader from '@/components/Header/ImageUploadHeader.vue';
import { useNotificationStore } from '@/store/notifications/notificationStore';
import { useFormStore } from '@/store/form/formStore';
import { useCloseOrderButton } from '@/composables/useCloseOrderButton';

const persistenceStore = usePersistenceStore();
const orderStore = useOrderStore();
const notificationStore = useNotificationStore();
const router = useRouter();

const saved = computed(() => {
  return persistenceStore.saved;
});

const errorStatus = computed(() => {
  return persistenceStore.errorStatus;
});

const hasActiveOrder = computed(() => {
  return !!orderStore.activeOrderId;
});

const imageUpload = ref<null | { uploadImages: () => Promise<void> }>(null);

const isOnSiteInspection = computed(() => {
  return useOrderStore().isOnSiteInspection();
});

const openDialog = () => {
  return isOnSiteInspection.value ? openOrderForm() : toggleModal();
};

const openOrderForm = () => {
  const layoutStore = useLayoutStore();
  layoutStore.openFormDialog();

  useFormStore().setCurrentFormByName('Order', false);
};

const {
  isOnline,
  isSaving,
  modalOpen,
  startSaveProcess,
  toggleModal,
  finishOrder,
} = useCloseOrderButton(imageUpload);
</script>
