export const getAllEntitiesByCriteria = (entityMap, criteria) => {
  const result = [];

  entityMap.forEach((value) => {
    if (criteria(value)) {
      result.push(value);
    }
  });

  return result;
};
