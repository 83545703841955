<template>
  <div v-for="point in points" :key="point" class="list-item">
    <em class="fa-solid fa-circle list-dot mr-2"></em> {{ point }}
  </div>
</template>
<script>
export default {
  name: 'DottedList',
  props: {
    points: {
      type: Array,
      required: true,
    },
  },
};
</script>
<style scoped lang="scss">
.list-dot {
  @apply text-primary-1;
  font-size: 12px;
}

.list-item {
  margin-bottom: 12px;
  list-style: none;
}

.list-item:last-child {
  margin-bottom: 0 !important;
}
</style>
