<template>
  <div
    data-testId="radiatorIdentificationCard"
    :class="[
      'min-w-[250px] cursor-pointer rounded-xl border-2 bg-light-1 p-4 dark:bg-dark-3 dark:hover:bg-dark-4 md:mx-0 md:flex-wrap',
      {
        'border-primary-2': isSelected,
      },
      {
        'border-light-1 dark:border-dark-4': !isSelected,
      },
    ]"
  >
    <em
      class="mdi mdi-image-area mb-2 text-3xl text-primary-2 hover:text-primary-1 md:hidden"
      @click="showPictures(rating.idNumber)"
    />
    <div class="flex justify-between">
      <div
        class="mb-1 font-semibold"
        :class="[
          {
            'text-primary-2': isSelected,
          },
        ]"
      >
        {{ rating.manufacturer }}
        <span
          class="ml-2 whitespace-nowrap rounded-lg bg-primary-1 px-2 font-normal text-blue-100"
          >{{ confidence }} %</span
        >
      </div>
      <div
        class="hidden text-primary-2 hover:text-primary-1 md:block"
        data-testId="radiatorPicturesButton"
        @click="() => showPictures(rating.idNumber)"
      >
        <Spinner v-if="picturesLoading" />
        <span v-else>Bilder</span>
      </div>
      <BasePrompt
        :open="picturesDialogOpen"
        :show-actions="false"
        :show-icon="identificationPictures.length === 0"
        :close="() => (picturesDialogOpen = false)"
        :cancel="() => (picturesDialogOpen = false)"
      >
        <ImageGallery
          v-if="identificationPictures.length > 0"
          :images="identificationPictures"
          position="space-around"
        />
        <div v-else class="has-text-centered">
          Für diesen Heizkörper sind keine Bilder verfügbar.
        </div>
      </BasePrompt>
    </div>
    <div>
      <span class="hidden md:block">{{ rating.model }}</span>
    </div>
    <div>
      Maße (H x B x T): {{ rating.height }}mm x {{ rating.nominalLength }}mm x
      {{ rating.depth }}mm
    </div>
    <div>Leistung: {{ rating.kQ }}</div>
  </div>
</template>
<script setup lang="ts">
import { computed, ref } from 'vue';
import BasePrompt from '@/components/UI/Modal/BasePrompt.vue';
import ImageGallery from '@/components/UI/Image/ImageGallery.vue';
import { Notification } from '@/models';
import { useRadiatorStore } from '@/store/entities/radiatorStore';
import { useNotificationStore } from '@/store/notifications/notificationStore';
import Spinner from '@/components/UI/Loader/Spinner.vue';
import { Image } from '@/models/Image';
import { plainToInstance } from 'class-transformer';

const props = defineProps<{
  rating: RadiatorRating;
  isSelected: boolean;
}>();

const picturesDialogOpen = ref<boolean>(false);
const picturesLoading = ref<boolean>(false);
const identificationPictures = ref<Array<Image>>([]);

const confidence = computed(() => {
  return Math.trunc(props.rating.confidence);
});

const showPictures = (prefix: string | undefined) => {
  if (!prefix) {
    return;
  }
  picturesLoading.value = true;
  useRadiatorStore()
    .fetchRadiatorIdentPictures(prefix)
    .then((response) => {
      picturesLoading.value = false;
      picturesDialogOpen.value = true;
      identificationPictures.value = plainToInstance(Image, response);
    })
    .catch((error) => {
      const notification = new Notification()
        .setType('error')
        .setTimeout(6000)
        .setText(
          'Es können zurzeit keine Bilder für diesen Heizkörper geladen werden.'
        );
      useNotificationStore().addNotification(notification);
      console.error(error);
    });
};
</script>
