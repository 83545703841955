<template>
  <div :class="`spinner-wrapper ${size}`">
    <div class="spinner"></div>
    <div v-if="countdown" :class="`loading-text ${size}`">
      <span class="digit has-text-weight-bold">
        {{ countdown }}
      </span>
      <span class="min has-text-weight-bold"> {{ label }} </span>
      <p class="sub">verbleiben</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseSpinner',
  props: {
    countdown: {
      type: Number,
      default: undefined,
    },
    label: {
      type: String,
      default: undefined,
      validator: (value) => {
        return ['min', 's'].includes(value);
      },
    },
    size: {
      type: String,
      default: 'is-small',
    },
  },
};
</script>
<style scoped lang="scss">
.spinner-wrapper {
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;

  &.is-small {
    height: 120px;
    width: 120px;
  }
  &.is-medium {
    height: 200px;
    width: 200px;
  }
  &.is-large {
    height: 400px;
    width: 400px;
  }
}

.spinner {
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background: conic-gradient(transparent, $primary-color);
  animation: rotate 2s linear infinite;
  animation-direction: reverse;
}
.spinner:before,
.spinner:after {
  content: '';
  position: absolute;
  border-radius: 50%;
}
.loading-text {
  position: absolute;
  text-align: center;
  line-height: 1;

  &.is-small {
    font-size: 8px;
  }
  &.is-medium {
    font-size: 14px;
  }
  &.is-large {
    font-size: 20px;
  }

  .digit {
    font-size: 3em;
  }

  .min {
    font-size: 2em;
  }

  .sub {
    font-size: 1.3em;
  }
}
.spinner:before {
  width: 70%;
  height: 70%;
  top: 15%;
  left: 15%;
  @apply bg-light-0 dark:bg-dark-0;
}
.spinner:after {
  height: 15%;
  width: 15%;
  background-color: $primary-color;
  top: 0;
  left: 42.5%;
}
@keyframes rotate {
  from {
    transform: rotateZ(360deg);
  }
  to {
    transform: rotateZ(0);
  }
}
</style>
