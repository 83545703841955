import { AccessoryType, DeviceTypeEnum, srtTypeList } from '@/enums/device';
import { parseDataMatrix } from '@/utils/scanner/dataMatrixParser';

export interface QRCodeParseResult {
  serialNumber?: string;
  productKey?: string;
  eui?: string;
  unknownProperties: string;
}

export const parseQrCode = (
  result: string,
  deviceType?: AccessoryType | DeviceTypeEnum
) => {
  // Handle LoRaWAN Gateway
  if (deviceType === DeviceTypeEnum.LO_RA_WAN_GATEWAY) {
    return parseDataMatrix(result, deviceType);
  }

  // Handle Smart Radiator Thermostat
  if (deviceType === DeviceTypeEnum.SMART_RADIATOR_THERMOSTAT) {
    const splitResult = result.split('-');
    const serialNumber = splitResult[0];
    const productKey = srtTypeList.find(
      (srt) => srt.deviceSKU === splitResult[3]
    )?.productKey;
    return {
      serialNumber: serialNumber,
      productKey: productKey,
      unknownProperties: result,
    } as QRCodeParseResult;
  }

  // Handle other QR codes
  if (result.indexOf('-') > 0) {
    const splitResult = result.split('-');
    return {
      serialNumber: splitResult[0],
      unknownProperties: result,
    } as QRCodeParseResult;
  } else if (result.indexOf(',') > 0) {
    const splitResult = result.split(',');
    return {
      serialNumber: splitResult[0],
      eui: splitResult[1],
      unknownProperties: result,
    } as QRCodeParseResult;
  }

  return {
    unknownProperties: result,
  };
};
