<template>
  <BaseToggle
    v-if="hasToggleEnabled"
    v-model:is-checked="isMounted"
    :label="label"
    test-id="failedMountAttempt"
    :is-inverted="true"
  />
  <BaseDropdown
    v-if="!isMounted"
    v-model:selection="failedInstallationAttempt.reason"
    label="Grund für keine Montage"
    dropdown-text="Grund wählen"
    test-id="failedMountAttemptReason"
    :options-list="failedInstallationAttemptReasons"
    :validation="v$.failedInstallationAttempt.reason"
    :numbered="true"
  />
  <BaseTextarea
    v-if="!isMounted"
    v-model:input-text="failedInstallationAttempt.note"
    test-id="failedMountAttemptNote"
    label="Bemerkung"
    class="mb-3"
    :validation="v$.failedInstallationAttempt.note"
  />
  <BaseSection v-if="!isMounted" heading="Foto">
    <BaseInformation
      information-text="Zur Veranschaulichung des Grundes, weshalb das Gerät nicht montiert werden kann, nehmen Sie bitte ein Foto auf."
    />
    <Checkbox
      v-model:checked="isImageNotAllowedChecked"
      label="Nutzer verweigert Foto"
      checkbox-id="failedInstallationAttemptCheckbox"
    />
    <ImageCapture
      v-if="!isMounted"
      ref="imageCaptureRef"
      class="mt-4"
      :object-id="failedInstallationAttempt.id"
      :object-type="ImageObjectType.UNMOUNTED_DEVICE"
    />
  </BaseSection>
</template>

<script setup lang="ts">
import { computed, Ref, ref, WritableComputedRef } from 'vue';
import {
  deviceTypes,
  notMountedReasonsList,
  notMountedReasonsListSRT,
} from '@/enums/device';
import BaseToggle from '@/components/UI/Switch/BaseToggle.vue';
import { useValidation } from '@/composables/useValidation';
import { FailedInstallationAttempt } from '@/models/installationPoint/InstallationPoint';
import BaseDropdown from '@/components/UI/Dropdown/BaseDropdown.vue';
import BaseTextarea from '@/components/UI/Note/BaseTextarea.vue';
import { DeviceClasses } from '@/models/devices/Device';
import useStores from '@/composables/useStores';
import { FormAction, useFormStore } from '@/store/form/formStore';
import Checkbox from '@/components/UI/Input/Checkbox.vue';
import BaseInformation from '@/components/UI/Hint/BaseInformation.vue';
import BaseSection from '@/components/UI/Card/BaseSection.vue';
import ImageCapture, {
  ImageCaptureRef,
} from '@/components/Forms/DeviceFormComponents/ImageCapture.vue';
import { ImageObjectType } from '@/models/Image';

const props = withDefaults(
  defineProps<{
    failedInstallationAttempt: FailedInstallationAttempt;
    isMounted: boolean;
    deviceType: string;
    device: DeviceClasses;
    hasToggleEnabled?: boolean;
  }>(),
  {
    hasToggleEnabled: true,
  }
);

const { imagesStore } = useStores();
const isImageNotAllowedChecked = ref<boolean>(false);

const emit = defineEmits([
  'update:failedInstallationAttempt',
  'update:isMounted',
]);

const imageCaptureRef: Ref<ImageCaptureRef | null> = ref(null);

const failedInstallationAttemptReasons = computed(() => {
  if (props.deviceType === deviceTypes.SmartRadiatorThermostat) {
    return notMountedReasonsListSRT;
  }
  return notMountedReasonsList;
});

const failedInstallationAttempt: WritableComputedRef<FailedInstallationAttempt> =
  computed({
    get() {
      return props.failedInstallationAttempt;
    },
    set(value) {
      emit('update:failedInstallationAttempt', value);
    },
  });

const isMounted: WritableComputedRef<boolean> = computed({
  get() {
    return props.isMounted;
  },
  set(value) {
    emit('update:isMounted', value);
  },
});

const label = computed(() => {
  return useFormStore().formAction === FormAction.REPLACE
    ? 'Kein Austausch möglich'
    : 'Keine Montage möglich';
});

const { v$, isFormValid } = useValidation({ failedInstallationAttempt });

defineExpose({
  isFormValid: computed(
    () =>
      isFormValid.value &&
      (imageCaptureRef.value?.hasImage || isImageNotAllowedChecked.value)
  ),
  saveImage: () => imageCaptureRef.value?.saveImage(),
});
</script>
