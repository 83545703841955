import { pollScanResults } from '@/api/funkcheck/FunkcheckApi';
import { useFunkcheckStore } from '@/store/funkcheck/funkcheckStore';

export class ActiveFunkcheck {
  businessEntityID: string;
  gatewaySerialNumber: string;
  initResult: InitResultData;
  attempt: number;
  startedAt: Date | null;
  scanResult: ScanResult | null;

  constructor(
    businessEntityID: string,
    gatewaySerialNumber: string,
    initResult: InitResultData
  ) {
    this.businessEntityID = businessEntityID;
    this.gatewaySerialNumber = gatewaySerialNumber;
    this.initResult = initResult;
    this.attempt = 0;
    this.startedAt = null;
    this.scanResult = null;
  }
}

export const receiveScanResults = async function (
  gatewaySerialNumber: string,
  intervals: Array<number>,
  startedAt: string
): Promise<ScanResult> {
  console.debug('Start scan...');
  let attempts = -1;
  const maxAttempts = intervals.length;

  const validate = (result: ScanResult): boolean => {
    if (result.data.finishedAt && result.data.createdAt) {
      const finished = new Date(result.data.finishedAt);
      const started = new Date(startedAt);
      return finished > started;
    }
    return false;
  };

  const setAttempts = (attempt: number, startedAt: Date | null): void => {
    useFunkcheckStore().updateActiveFunkcheck(attempt, startedAt);
  };

  const executePoll = async (
    resolve: any,
    reject: any
  ): Promise<Error | ScanResult | undefined> => {
    attempts++;
    if (attempts === 0) {
      setTimeout(executePoll, intervals[attempts], resolve, reject);
      console.debug(
        'attempt: ' + attempts + ' polling in: ' + intervals[attempts]
      );
    } else {
      console.debug('polling...');
      const result = await pollScanResults(gatewaySerialNumber);
      console.debug('poll result: ');
      console.debug(result);

      await useFunkcheckStore().updateActiveFunkcheck(
        undefined,
        undefined,
        result
      );
      if (result.data && validate(result)) {
        // startedAt must be set to null again so we know in the UI that no funkcheck is currently in progress
        setAttempts(0, null);
        return resolve(result);
      } else if (attempts === maxAttempts) {
        // startedAt must be set to null again so we know in the UI that no funkcheck is currently in progress
        setAttempts(0, null);
        return reject(new Error('Exceeded max attempts'));
      } else {
        setAttempts(attempts, new Date());
        setTimeout(executePoll, intervals[attempts], resolve, reject);
        console.debug(
          'attempt: ' + attempts + ' polling in: ' + intervals[attempts]
        );
      }
    }
  };
  return new Promise(executePoll);
};
