<template>
  <div class="md:flex md:gap-4">
    <BaseFormInput
      v-model="timestamp"
      class="pr-2 md:basis-1/2"
      :validation="validation?.timestamp"
      :label="inputLabel"
      :disabled="disabled"
      placeholder="Datum"
      :test-id="testId ? testId : 'mounting-date'"
      type="date"
    />
  </div>
</template>
<script setup lang="ts">
import { computed, ref, watch, WritableComputedRef } from 'vue';
import BaseFormInput from '@/components/UI/Input/BaseFormInput.vue';
import { DeviceEventParams } from '@/models/installationPoint/DeviceEvent';
import { Validation } from '@vuelidate/core';

const props = defineProps<{
  deviceEvent: DeviceEventParams;
  validation?: Validation;
  disabled?: boolean;
  testId?: string;
  label?: string;
}>();
const emit = defineEmits(['update:deviceEvent']);

const localDeviceEvent: WritableComputedRef<DeviceEventParams> = computed({
  get() {
    return props.deviceEvent;
  },
  set(value) {
    emit('update:deviceEvent', value);
  },
});

const timestamp = ref(localDeviceEvent.value.timestamp.substr(0, 10));

const inputLabel = computed(() => {
  return props.label ? props.label : 'Montagedatum';
});

watch(
  () => timestamp.value,
  (value) => {
    localDeviceEvent.value.timestamp = value + 'T08:00:00.000Z';
  }
);
</script>
