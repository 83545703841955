import { useVuelidate } from '@vuelidate/core';
import { validationRules } from '@/validation/rules';

export const getValidator = (state: any, options?: any) => {
  let rules = {};
  const stateElements = Object.keys(state);
  stateElements.forEach((element) => {
    try {
      rules = { ...rules, ...validationRules[element](state, options) };
    } catch (e) {
      throw Error(`No validation rule found for ${element}`);
    }
  });

  return useVuelidate(rules, state, {
    $autoDirty: true,
  });
};
