<template>
  <div
    v-click-outside="closeDropdown"
    :class="['flex', { 'md:relative': direction === 'up' }]"
    data-testid="optionsDropdown"
  >
    <slot :on-click="toggleDropdown" name="toggle"></slot>
    <div
      v-if="!$slots.toggle"
      :class="[
        'relative flex h-8 w-8 cursor-pointer flex-row justify-center rounded-lg hover:bg-light-2 hover:dark:bg-dark-4',
        { 'bg-light-2 dark:bg-dark-4': isDropdownOpen },
      ]"
      @click="toggleDropdown"
    >
      <em :class="['mdi', 'text-2xl', toggleIcon]" />
    </div>

    <div
      v-if="isDropdownOpen"
      :style="translateX"
      :class="[
        width,
        'absolute z-40 flex flex-col justify-center rounded-lg bg-light-2 shadow dark:bg-dark-4',
        {
          'translate-y-[45px] md:bottom-full md:-translate-y-2':
            direction === 'up',
        },
        { 'translate-y-10': direction === 'down' },
        {
          '-translate-x-[calc(100%-39px)] md:translate-x-0':
            direction === 'right',
        },
        { '-translate-x-[calc(100%-40px)]': directionX === 'left' },
      ]"
    >
      <ul class="dropdown-container m-0 list-none px-0 py-1 text-sm">
        <slot></slot>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, inject, onMounted, ref } from 'vue';

interface Props {
  icon: string;
  direction: string;
}

export default {
  props: {
    icon: {
      type: String,
      default: '',
    },
    direction: {
      type: String,
      default: 'down',
    },
    directionX: {
      type: String,
      default: 'right',
    },
    width: {
      type: String,
      default: 'w-52',
    },
  },
  setup(props: Props) {
    const scrollPositionRef = inject(
      'scrollPositionRef',
      ref<HTMLElement | null>(null)
    );
    const scrollPosition = ref(0);
    const isDropdownOpen = ref<boolean>(false);

    const getScrollPosition = () => {
      isDropdownOpen.value = false;
      scrollPosition.value = scrollPositionRef.value?.scrollLeft || 0;
    };

    onMounted(() => {
      scrollPositionRef.value?.addEventListener('scroll', getScrollPosition);
    });

    const translateX = computed(() => {
      if (scrollPosition.value === 0) {
        return;
      }
      return `--tw-translate-x: -${scrollPosition.value}px !important`;
    });

    const closeDropdown = () => {
      isDropdownOpen.value = false;
    };
    const toggleDropdown = () => {
      isDropdownOpen.value = !isDropdownOpen.value;
    };

    return {
      scrollPositionRef,
      scrollPosition,
      isDropdownOpen,
      translateX,
      closeDropdown,
      toggleDropdown,
      toggleIcon: props.icon,
    };
  },
};
</script>

<style lang="scss">
.dropdown-container {
  > li {
    @apply px-5 py-3;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
      @apply bg-light-3 dark:bg-dark-5;
    }
  }
}
</style>
