<template>
  <div
    :class="`${width} bborder-dark-3 mb-3 flex h-14 cursor-pointer flex-col justify-center rounded-xl bg-light-1 py-5 text-center align-middle text-primary-2 shadow  hover:bg-light-2 dark:border dark:border-dark-2 dark:bg-dark-2 hover:dark:bg-dark-3`"
    :data-testid="`${testId}_plusButton`"
    @click="addElement"
  >
    <span>
      <em
        :class="[
          {
            'text-3xl': !title,
          },
          'mdi mdi-plus',
        ]"
      ></em
      >{{ title }}
    </span>
  </div>
</template>

<script setup lang="ts">
interface Props {
  width?: string;
  testId?: string;
  title?: string;
}

const emit = defineEmits(['add-element']);

const props = withDefaults(defineProps<Props>(), {
  width: 'w-full ',
  testId: '',
  title: undefined,
});

const addElement = () => {
  emit('add-element');
};
</script>
