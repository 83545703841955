import { Store } from '@/store/pinia-class-component';
import { miniEntityStore } from '@/store/entities/types/miniEntityStore';
import { InspectedBuilding } from '@/models/InspectedBuilding';
import { useBuildingStore } from '@/store/entities/buildingStore';

export function useInspectedBuildingStore() {
  return new InspectedBuildingStore();
}

@Store
export class InspectedBuildingStore extends miniEntityStore {
  private _inspectedBuildings: Map<string, InspectedBuilding>;

  constructor() {
    super();
    this._inspectedBuildings = new Map();
  }

  get inspectedBuildings(): Map<string, InspectedBuilding> {
    return this._inspectedBuildings;
  }

  addEntity(inspectedBuilding: InspectedBuilding): void {
    this._inspectedBuildings.set(
      <string>inspectedBuilding.id,
      inspectedBuilding
    );
  }

  deleteEntity(entity: InspectedBuilding): void {
    this._inspectedBuildings.delete(entity.id);
  }

  resetState() {
    this._inspectedBuildings = new Map();
  }

  getCurrentInspectedBuilding() {
    const activeBuildingId = useBuildingStore().getCurrentBuilding()?.id;

    for (const [key, inspectedBuilding] of this._inspectedBuildings.entries()) {
      if (inspectedBuilding.buildingId === activeBuildingId) {
        return inspectedBuilding;
      }
    }
  }

  getInspectedBuildingByBuildingId(buildingId: string) {
    for (const [key, inspectedBuilding] of this._inspectedBuildings.entries()) {
      if (inspectedBuilding.buildingId === buildingId) {
        return inspectedBuilding;
      }
    }
  }
}
