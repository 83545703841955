export const roomTypeList = Object.freeze([
  { name: 'Schlafzimmer', value: 'BEDROOM', icon: 'bed-king' },
  { name: 'Badezimmer', value: 'BATHROOM', icon: 'shower' },
  { name: 'Wohnzimmer', value: 'LIVING_ROOM', icon: 'sofa' },
  { name: 'Küche', value: 'KITCHEN', icon: 'fridge' },
  { name: 'Kinderzimmer', value: 'CHILDRENS_ROOM', icon: 'teddy-bear' },
  { name: 'Flur', value: 'CORRIDOR', icon: 'door-open' },
  { name: 'Arbeitszimmer', value: 'OFFICE', icon: 'laptop' },
  { name: 'WC', value: 'TOILET', icon: 'toilet' },
  { name: 'Sonstiges Zimmer', value: 'OTHER' },
  { name: 'Esszimmer', value: 'DINING_ROOM', icon: 'silverware' },
  { name: 'Kellerraum', value: 'BASEMENT', icon: 'warehouse' },
  { name: 'Technikraum', value: 'ENGINEERING', icon: 'cog' },
  { name: 'Heizungsraum', value: 'HEATER', icon: 'water-boiler' },
  { name: 'Garage', value: 'GARAGE', icon: 'car' },
  { name: 'Schwimmbad/Sauna', value: 'POOL_OR_SAUNA', icon: 'pool' },
  { name: 'Laubengang', value: 'ARCADE' },
]);
export const allRoomTypesList = Object.freeze([
  {
    name: 'Hausflur (inkl. Treppenhaus)',
    value: 'HALLWAY_AND_STAIRCASE',
  },
  ...roomTypeList,
]);

export const floorTypeList = Object.freeze([
  { name: 'EG', value: { type: 'GROUND' } },
  { name: 'OG-1', value: { type: 'UPPER', level: 1 } },
  { name: 'OG-2', value: { type: 'UPPER', level: 2 } },
  { name: 'OG-3', value: { type: 'UPPER', level: 3 } },
  { name: 'OG-4', value: { type: 'UPPER', level: 4 } },
  { name: 'UG-1', value: { type: 'BASEMENT', level: 1 } },
  { name: 'UG-2', value: { type: 'BASEMENT', level: 2 } },
]);

export const manualFloorTypeList = Object.freeze([
  { name: 'Manuell', value: true },
]);

export const floorLevelList = Object.freeze([
  { name: 'EG', value: 'GROUND' },
  { name: 'UG', value: 'BASEMENT' },
  { name: 'OG', value: 'UPPER' },
  { name: 'ZG', value: 'IN_BETWEEN' },
]);

export const floorLevelListMapping = Object.freeze({
  GROUND: 'EG',
  BASEMENT: 'UG',
  UPPER: 'OG',
  IN_BETWEEN: 'ZG',
});

export const roomNotAccessibleReasonsList = Object.freeze([
  {
    name: 'Nutzer verweigert Zutritt aktuell',
    value: 'Nutzer verweigert Zutritt aktuell',
  },
  { name: 'RM-Montage wurde verweigert', value: 'RM-Montage wurde verweigert' },
  { name: 'Besonderheiten beachten', value: 'Besonderheiten beachten' },
  {
    name: 'Kontaktpers. nicht erreichbar',
    value: 'Kontaktpers. nicht erreichbar',
  },
  { name: 'Nicht zumutbar', value: 'Nicht zumutbar' },
  { name: 'Sonstiges', value: 'Sonstiges' },
]);
