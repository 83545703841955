/* eslint-disable @typescript-eslint/ban-ts-comment */
import { entityTypes } from '@/enums/generic';
import {
  deviceTypes,
  LoRaWanHardwareData,
  MeasurementUnit,
  SRTHardwareData,
} from '@/enums/device';
import { generateUniqueId } from '@/helpers/uuidUtils';
import { instanceToInstance } from 'class-transformer';

export type InspectionDeviceClasses = LoRaWanGateway | SmartRadiatorThermostat;

export abstract class InspectionDevice {
  public id: string;
  public type: string = entityTypes.inspectionDevice;
  public deviceType: InspectionDeviceType;
  public inspectionPointId: string;
  public serialNumber: string;
  public productKey: string;
  public note?: string;
  public unknownProperties?: string;
  public detectionType?: string;

  protected constructor(params: any) {
    this.id = generateUniqueId();
    this.type = entityTypes.inspectionDevice;
    this.deviceType = params.deviceType;
    this.inspectionPointId = params.inspectionPointId ?? undefined;
    this.serialNumber = params.serialNumber ?? undefined;
    this.productKey = params.productKey ?? undefined;
    this.note = params.note ?? undefined;
    this.unknownProperties = params.unknownProperties ?? undefined;
    this.detectionType = params.detectionType ?? undefined;
  }

  updateProperties<T extends InspectionDeviceTypeParams>(formData: T) {
    const wrongProperties = Object.keys(formData).filter(
      (key) => !Object.keys(this).includes(key)
    );
    if (wrongProperties.length > 0) {
      throw new Error(
        `Provided wrong properties: ${wrongProperties} with ${JSON.stringify(
          formData
        )}`
      );
    }
    Object.assign(this, instanceToInstance(formData));
  }

  getParentId(): string | undefined {
    return this.inspectionPointId;
  }

  setInspectionPointId(inspectionPointId: string) {
    this.inspectionPointId = inspectionPointId;
  }
}

type InspectionDeviceParams = {
  inspectionPointId: string;
  serialNumber: string;
  productKey: string;
  note?: string;
  unknownProperties?: string;
};

interface LoRaWanGatewayParams extends InspectionDeviceParams {
  // @ts-ignore
  deviceType: deviceTypes.LoRaWanGateway;
  eui: string;
  manufacturer: string;
}

export class LoRaWanGateway extends InspectionDevice {
  public eui: string;
  public manufacturer: string;

  constructor() {
    const params = {
      deviceType: deviceTypes.LoRaWanGateway,
      productKey: undefined,
    };
    super(params);
    this.eui = '';
    this.manufacturer = LoRaWanHardwareData.MANUFACTURER;
  }
}

interface SmartRadiatorThermostatParams
  extends Omit<InspectionDeviceParams, 'productKey'> {
  // @ts-ignore
  deviceType: deviceTypes.SmartRadiatorThermostat;
}

export class SmartRadiatorThermostat extends InspectionDevice {
  public manufacturer: string;
  public counterPressure: number | undefined;
  public measurementUnit: string;
  constructor() {
    const params = {
      deviceType: deviceTypes.SmartRadiatorThermostat,
    };
    super(params);
    this.manufacturer = SRTHardwareData.MANUFACTURER;
    this.counterPressure = undefined;
    this.measurementUnit = MeasurementUnit.N.value;
  }
}

export type InspectionDeviceType =
  | 'SMART_RADIATOR_THERMOSTAT'
  | 'LO_RA_WAN_GATEWAY';

export type InspectionDeviceTypeParams =
  | SmartRadiatorThermostatParams
  | LoRaWanGatewayParams;

export class InspectionDeviceFactory {
  static createDevice<T extends InspectionDeviceType>(type: T) {
    switch (type) {
      case deviceTypes.LoRaWanGateway:
        return new LoRaWanGateway();
      case deviceTypes.SmartRadiatorThermostat:
        return new SmartRadiatorThermostat();

      default:
        throw new Error(`Unsupported device type: ${type}`);
    }
  }
}
