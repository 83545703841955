<template>
  <div class="my-3">
    <div class="flex items-center justify-start">
      <button
        data-testid="floor-level-down"
        type="button"
        :disabled="!isFloorLevelChangeableDown"
        class="flex-col items-center justify-end rounded-lg border-2 bg-light-1 p-5 text-xl hover:bg-light-2 peer-checked:border-primary-2 peer-checked:text-primary-2 dark:border-dark-5 dark:bg-dark-3 dark:hover:bg-dark-4 dark:peer-checked:text-primary-2"
        :class="{
          'dark:border-dark-disabled-bg dark:bg-dark-disabled-bg cursor-not-allowed cursor-not-allowed text-dark-disabled-text opacity-30':
            !isFloorLevelChangeableDown,
        }"
        @click="changeFloorLevel('DOWN')"
      >
        <em class="mdi mdi-chevron-down"></em>
      </button>
      <div
        class="mx-4 text-lg font-bold"
        :class="{ 'text-primary-2': isFloorLevelChanged }"
        data-testid="floor-level-label"
      >
        {{ floorLevelName }}
      </div>
      <button
        data-testid="floor-level-up"
        type="button"
        :disabled="!isFloorLevelChangeableUp"
        class="flex-col items-center justify-end rounded-lg border-2 bg-light-1 p-5 text-xl hover:bg-light-2 peer-checked:border-primary-2 peer-checked:text-primary-2 dark:border-dark-5 dark:bg-dark-3 dark:hover:bg-dark-4 dark:peer-checked:text-primary-2"
        :class="{
          'border-light-disabled-bg bg-light-disabled-bg dark:border-dark-disabled-bg dark:bg-dark-disabled-bg cursor-not-allowed text-light-disabled-text dark:text-dark-disabled-text dark:opacity-30':
            !isFloorLevelChangeableUp,
        }"
        @click="changeFloorLevel('UP')"
      >
        <em class="mdi mdi-chevron-up"></em>
      </button>
    </div>

    <button
      class="mt-2"
      :class="{ hidden: !isFloorLevelChanged }"
      @click="resetFloorLevel"
    >
      Zurücksetzen
    </button>
    <article
      v-if="roomHasChildren && isFloorLevelChanged"
      class="message is-warning mt-5"
    >
      <div class="message-body">
        Bitte stellen Sie sicher, dass Sie alle verbundenen Räume anpassen
      </div>
    </article>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, Ref } from 'vue';
import { floorLevelListMapping } from '@/enums/room';
import { Room } from '@/models';
import { useRoomStore } from '@/store/entities/roomStore';

export default defineComponent({
  name: 'FloorLevelSelector',
  props: {
    value: {
      type: Object,
      required: true,
    },
    room: {
      type: Object as () => Room,
      required: true,
    },
  },
  emits: ['update:value'],
  setup(props, ctx) {
    const floorLevel = computed(() => {
      return props.value;
    });

    const roomStore = useRoomStore();
    const parentRoom: Ref<Room | undefined> = computed(() => {
      return props.room.parentRoomId
        ? roomStore.rooms.get(props.room.parentRoomId)
        : undefined;
    });

    const isFirstRoomOfRoomGroup = computed(() => {
      if (!props.room.roomGroupId) return false;
      const firstRoomOfRoomGroup = roomStore.getFirstRoomOfRoomgroup(
        props.room.roomGroupId
      );
      return props.room.id === firstRoomOfRoomGroup?.id;
    });

    const isFloorLevelChangeableUp = computed(() => {
      if (parentRoom.value?.parentRoomId === undefined) return false;
      if (isFirstRoomOfRoomGroup.value) return false;
      let parentFloorLevel = parentRoom.value.floorLevel.level
        ? parentRoom.value.floorLevel.level
        : 0;
      parentFloorLevel =
        parentRoom.value.floorLevel.type === 'BASEMENT'
          ? parentFloorLevel * -1
          : parentFloorLevel;

      return getNextFloorLevel.value <= Number(parentFloorLevel) + 1;
    });

    const isFloorLevelChangeableDown = computed(() => {
      if (parentRoom.value?.parentRoomId === undefined) return false;
      if (isFirstRoomOfRoomGroup.value) return false;
      let parentFloorLevel = parentRoom.value.floorLevel.level
        ? parentRoom.value.floorLevel.level
        : 0;
      parentFloorLevel =
        parentRoom.value.floorLevel.type === 'BASEMENT'
          ? parentFloorLevel * -1
          : parentFloorLevel;

      return getPreviousFloorLevel.value >= parentFloorLevel - 1;
    });

    const getNextFloorLevel = computed(() => {
      let level = floorLevel.value.level ? Number(floorLevel.value.level) : 0;
      if (floorLevel.value.type === 'BASEMENT') {
        level = level * -1;
      }
      return level + 1;
    });

    const getPreviousFloorLevel = computed(() => {
      let level = floorLevel.value.level ? Number(floorLevel.value.level) : 0;
      if (floorLevel.value.type === 'BASEMENT') {
        level = level * -1;
      }
      return level - 1;
    });

    const resolveFloorLevel = (level: number) => {
      if (level === parentRoom.value?.floorLevel.level) {
        isFloorLevelChanged.value = false;
      }
      if (level < 0) {
        return { level: Math.abs(level), type: 'BASEMENT' };
      } else if (level === 0) {
        return { type: 'GROUND' };
      }
      return { level: level, type: 'UPPER' };
    };

    const floorLevelName = computed(() => {
      const level = floorLevel.value.level ? floorLevel.value.level : '';
      const floorType = floorLevel.value
        .type as keyof typeof floorLevelListMapping;
      return floorLevelListMapping[floorType] + ' ' + level;
    });

    const isFloorLevelChanged = ref<boolean>(false);

    const resetFloorLevel = () => {
      ctx.emit('update:value', parentRoom?.value?.floorLevel);
      isFloorLevelChanged.value = false;
    };
    const changeFloorLevel = (direction: 'UP' | 'DOWN') => {
      isFloorLevelChanged.value = true;

      const newFloorLevel =
        direction === 'UP'
          ? getNextFloorLevel.value
          : getPreviousFloorLevel.value;

      ctx.emit('update:value', resolveFloorLevel(newFloorLevel));
    };

    const roomHasChildren = computed(() => {
      return roomStore.getChildrenOfRoom(props.room.id).length > 0;
    });

    return {
      isFloorLevelChanged,
      isFloorLevelChangeableUp,
      isFloorLevelChangeableDown,
      getPreviousFloorLevel,
      getNextFloorLevel,
      resetFloorLevel,
      changeFloorLevel,
      floorLevelName,
      roomHasChildren,
      parentRoom,
    };
  },
});
</script>
