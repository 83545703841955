<template>
  <div class="flex flex-col gap-5">
    <div class="text-xl font-bold">DMGW installieren</div>
    <div>Das DMGW wird am vorgesehenen Ort montiert</div>
    <div class="content-box">
      <DottedList :points="points" />
    </div>
    <div class="content-box">
      <BaseCheckbox
        v-model="isChecked"
        label="Montage abgeschlossen"
        data-testid="installationCompleted"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
import BaseCheckbox from '@/components/UI/Checkbox/BaseCheckbox.vue';
import { inject, ref, watch } from 'vue';
import DottedList from '@/components/UI/SimpleList/DottedList.vue';

const validateStep = inject('validateStep') as (valid: boolean) => void;

const points = [
  'Stromversorgung sicherstellen',
  'Knopf am DMGW gedrückt halten, bis Piepston kommt',
];
const isChecked = ref<boolean>(false);

watch(isChecked, (value: boolean): void => {
  validateStep(value);
});
</script>

<style lang="scss" scoped>
.box {
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 15%),
    0 0px 0 1px rgb(10 10 10 / 5%);
  padding: 30px;
}
</style>
