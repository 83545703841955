export interface BarcodeParseResult {
  serialNumber?: string;
  unknownProperties: string;
}

export const parseBarcode = (result: string): BarcodeParseResult => {
  return {
    serialNumber: result.substring(1, result.length - 1),
    unknownProperties: result,
  };
};
