import { DeviceType } from '@/models/devices/Device';

export const inspectionDeviceTypeDetails = {
  SMART_RADIATOR_THERMOSTAT: {
    value: 'SMART_RADIATOR_THERMOSTAT',
    label: 'Smart Radiator Thermostat',
    shortLabel: 'Test-SRT',
    color: '#7c3aed',
  },
  LO_RA_WAN_GATEWAY: {
    value: 'LO_RA_WAN_GATEWAY',
    label: 'LoRaWAN Gateway',
    shortLabel: 'LRWGW',
    color: '#22c55e',
  },
};

export const deviceTypeDetails = {
  SMOKE_DETECTOR: {
    value: 'SMOKE_DETECTOR',
    label: 'Rauchwarnmelder',
    shortLabel: 'RWM',
    color: '#946c00',
  },
  HEAT_COST_ALLOCATOR: {
    value: 'HEAT_COST_ALLOCATOR',
    label: 'Heizkostenverteiler',
    shortLabel: 'HKV',
    color: '#f58400',
  },
  WARM_WATER_METER: {
    value: 'WARM_WATER_METER',
    label: 'Warmwasserzähler',
    shortLabel: 'WWZ',
    color: '#7bc7c4',
  },
  WATER_METER: {
    value: 'WATER_METER',
    label: 'Kaltwasserzähler',
    shortLabel: 'KWZ',
    color: '#0090f5',
  },
  HEAT_ENERGY_METER: {
    value: 'HEAT_ENERGY_METER',
    label: 'Wärmemengenzähler',
    shortLabel: 'WMZ',
    color: '#f58400',
  },
  DIRECT_METER_GATEWAY: {
    value: 'DIRECT_METER_GATEWAY',
    label: 'Direct Meter Gateway',
    shortLabel: 'DMGW',
    color: '#000000',
  },
  SMART_RADIATOR_THERMOSTAT: {
    value: 'SMART_RADIATOR_THERMOSTAT',
    label: 'Smart Radiator Thermostat',
    shortLabel: 'SRT',
    color: '#710f0f',
  },
  LO_RA_WAN_GATEWAY: {
    value: 'LO_RA_WAN_GATEWAY',
    label: 'LoRaWAN Gateway',
    shortLabel: 'LRWGW',
    color: '#22c55e',
  },
  LEGACY_HEAT_ENERGY_METER: {
    value: 'LEGACY_HEAT_ENERGY_METER',
    label: 'Wärmemengenzähler (BG)',
    shortLabel: 'BG-WMZ',
    color: 'rgba(245,132,0,0.5)',
  },
  LEGACY_WARM_WATER_METER: {
    value: 'LEGACY_WARM_WATER_METER',
    label: 'Warmwasserzähler (BG)',
    shortLabel: 'BG-WWZ',
    color: 'rgba(123,199,196,0.5)',
  },
  LEGACY_WATER_METER: {
    value: 'LEGACY_WATER_METER',
    label: 'Kaltwasserzähler (BG)',
    shortLabel: 'BG-KWZ',
    color: 'rgba(0,144,245,0.5)',
  },
  LEGACY_HEATING_PLANT_METER: {
    value: 'LEGACY_HEATING_PLANT_METER',
    label: 'Heizanlagenzähler (BG)',
    shortLabel: 'BG-HAZ',
    color: 'rgba(160,55,250,0.81)',
  },
  LEGACY_WATER_PLANT_METER: {
    value: 'LEGACY_WATER_PLANT_METER',
    label: 'Wasseranlagenzähler (BG)',
    shortLabel: 'BG-WAZ',
    color: 'rgba(7,28,255,0.5)',
  },
};

export const detectionTypeList = Object.freeze([
  { name: 'Code Scannen', value: 'CODESCAN', icon: 'barcode' },
  {
    name: 'Manuelle Erfassung',
    value: 'MANUAL',
    icon: 'pencil-box-outline',
  },
]);

export const codeScanList = Object.freeze([detectionTypeList[0]]);
export const notMountedReasonsList = Object.freeze([
  {
    name: 'Schätzung, da HK nicht ausrüstbar',
    value: 'Schätzung, da HK nicht ausrüstbar',
  },
  {
    name: 'HK demontiert, kein Verbrauch',
    value: 'HK demontiert, kein Verbrauch',
  },
  { name: 'Nicht zugänglich', value: 'Nicht zugänglich' },
  { name: 'Zähler fehlt', value: 'Zähler fehlt' },
  {
    name: 'Gerät defekt, Reparatur nötig',
    value: 'Gerät defekt, Reparatur nötig',
  },
  { name: 'Eichjahr nicht feststellbar', value: 'Eichjahr nicht feststellbar' },
  {
    name: 'RM technisch nicht ausstattbar',
    value: 'RM technisch nicht ausstattbar',
  },
  { name: 'Gerät ist demontiert', value: 'Gerät ist demontiert' },
  { name: 'RM-Montage wurde verweigert', value: 'RM-Montage wurde verweigert' },
  { name: 'Besonderheiten beachten', value: 'Besonderheiten beachten' },
  { name: 'Passendes Werkzeug fehlt', value: 'Passendes Werkzeug fehlt' },
  { name: 'Sonstiges', value: 'Sonstiges' },
]);

export const notMountedReasonsListSRT = Object.freeze([
  {
    name: 'Installationsort verbaut',
    value: 'Installationsort verbaut',
  },
  {
    name: 'Gegendruck des Ventils zu hoch',
    value: 'Gegendruck des Ventils zu hoch',
  },
  {
    name: 'Mieter verweigert den Einbau',
    value: 'Mieter verweigert den Einbau',
  },
  {
    name: 'keinen passenden Adapter dabei',
    value: 'keinen passenden Adapter dabei',
  },
  {
    name: 'kein Gerät dabei',
    value: 'kein Gerät dabei',
  },
  {
    name: 'Installationsort zu eng',
    value: 'Installationsort zu eng',
  },
  {
    name: 'Altgerät nicht demontierbar',
    value: 'Altgerät nicht demontierbar',
  },
  {
    name: 'Zustand der Installation für Austausch nicht ausreichend',
    value: 'Zustand der Installation für Austausch nicht ausreichend',
  },
  {
    name: 'Sonstiges',
    value: 'Sonstiges',
  },
]);

export const deviceTypes: Record<string, DeviceType> = Object.freeze({
  SmokeDetector: 'SMOKE_DETECTOR',
  HeatMeter: 'HEAT_ENERGY_METER',
  WarmWaterMeter: 'WARM_WATER_METER',
  WaterMeter: 'WATER_METER',
  DirectMeterGateway: 'DIRECT_METER_GATEWAY',
  HeatCostAllocator: 'HEAT_COST_ALLOCATOR',
  SmartRadiatorThermostat: 'SMART_RADIATOR_THERMOSTAT',
  LoRaWanGateway: 'LO_RA_WAN_GATEWAY',
  LegacyHeatMeter: 'LEGACY_HEAT_ENERGY_METER',
  LegacyWarmWaterMeter: 'LEGACY_WARM_WATER_METER',
  LegacyWaterMeter: 'LEGACY_WATER_METER',
  LegacyHeatingPlantMeter: 'LEGACY_HEATING_PLANT_METER',
  LegacyWaterPlantMeter: 'LEGACY_WATER_PLANT_METER',
});

export enum AccessoryType {
  SRT_ADAPTER = 'SRT_ADAPTER',
  SRT_BATTERY_PACK = 'SRT_BATTERY_PACK',
  GATEWAY_HOUSING = 'GATEWAY_HOUSING',
}

export type AdapterType = {
  name: string;
  value: string;
  productKey: string;
  customizedNumber: string;
};

export const adapterTypeList = Object.freeze([
  {
    name: 'Unterstützungsring SRT',
    value: '42050',
    productKey: '91948',
    customizedNumber: '42050',
  },
  {
    name: 'Winkelanschluss SRT M30x1,5',
    value: '42051',
    productKey: '7300-00.700',
    customizedNumber: '42051',
  },
  {
    name: 'Spindelverlängerung Ventil SRT 20mm',
    value: '42052',
    productKey: '2201-20.700',
    customizedNumber: '42052',
  },
  {
    name: 'Spindelverlängerung Ventil SRT 30mm',
    value: '42053',
    productKey: '2201-30.700',
    customizedNumber: '42053',
  },
  {
    name: '20mm Danfoss RA',
    value: '42054',
    productKey: '9702-24.700',
    customizedNumber: '42054',
  },
  {
    name: '34mm Danfoss RAV',
    value: '42055',
    productKey: '9800-24.700',
    customizedNumber: '42055',
  },
  {
    name: '26mm Danfoss RAVL',
    value: '42056',
    productKey: '9700-24.700',
    customizedNumber: '42056',
  },
  {
    name: '30,5mm Vaillant',
    value: '42057',
    productKey: '9700-27.700',
    customizedNumber: '42057',
  },
  {
    name: 'M28x1,5 TA',
    value: '42058',
    productKey: '9701-28.700',
    customizedNumber: '42058',
  },
  {
    name: 'M28x1,5 Herz',
    value: '42059',
    productKey: '9700-30.700',
    customizedNumber: '42059',
  },
  {
    name: 'M28x1,5 Markaryd',
    value: '42060',
    productKey: '9700-41.700',
    customizedNumber: '42060',
  },
  {
    name: 'M28x1,5 Comap',
    value: '42061',
    productKey: '9700-55.700',
    customizedNumber: '42061',
  },
  {
    name: '22,6mm Giacomini',
    value: '42062',
    productKey: '9700-33.700',
    customizedNumber: '42062',
  },
  {
    name: 'M30x1,0 Oventrop',
    value: '42063',
    productKey: '9700-10.700',
    customizedNumber: '42063',
  },
  {
    name: 'M32x1,0 Ista',
    value: '42064',
    productKey: '9700-36.700',
    customizedNumber: '42064',
  },
  {
    name: 'Uponor(Velta)',
    value: '42065',
    productKey: '9700-34.700',
    customizedNumber: '42065',
  },
  {
    name: 'Uponor(Velta) Provario',
    value: '42066',
    productKey: '9701-34.700',
    customizedNumber: '42066',
  },
  {
    name: 'M20x1 Heimeier S2',
    value: '42067',
    productKey: '9703-24.700',
    customizedNumber: '42067',
  },
  {
    name: 'M23x1,5 Heimeier S3',
    value: '42068',
    productKey: '9704-24.700',
    customizedNumber: '42068',
  },
  {
    name: 'M20 AG 5,5mm Gampper',
    value: '42069',
    productKey: '110216',
    customizedNumber: '42069',
  },
  {
    name: 'M20 AG 7,5mm Gampper',
    value: '42070',
    productKey: '110217',
    customizedNumber: '42070',
  },
  {
    name: 'M22 Gampper',
    value: '42071',
    productKey: '110218',
    customizedNumber: '42071',
  },
  {
    name: 'M38x1,5 Meges',
    value: '42072',
    productKey: '102671',
    customizedNumber: '42072',
  },
  {
    name: 'M38x1,5 Ondal',
    value: '42073',
    productKey: '86585',
    customizedNumber: '42073',
  },
  {
    name: 'M28x1,5 PaM/Remagg/Sam',
    value: '42074',
    productKey: '76030',
    customizedNumber: '42074',
  },
  {
    name: 'M33x2,0 Rossweiner',
    value: '42076',
    productKey: '86584',
    customizedNumber: '42076',
  },
]);

export const batteryPackTypeList = [
  {
    name: 'Batterie SRT MB04XL',
    deviceSKU: '105699',
    productKey: '42006',
  },
  {
    name: 'Batterie SRT MB04',
    deviceSKU: '105227',
    productKey: '42005',
  },
];

export const srtTypeList = [
  {
    name: 'Thermostatkopf SRT V1.5',
    deviceSKU: '105689',
    productKey: '42001',
  },
  {
    name: 'Thermostatkopf SRT V1.5',
    deviceSKU: '105906',
    productKey: '42001',
  },
  {
    name: 'Thermostat / SRT inkl. Batterie',
    deviceSKU: '105456',
    productKey: '42000',
  },
];

export enum DeviceTypeEnum {
  SMOKE_DETECTOR = 'SMOKE_DETECTOR',
  HEAT_ENERGY_METER = 'HEAT_ENERGY_METER',
  WARM_WATER_METER = 'WARM_WATER_METER',
  WATER_METER = 'WATER_METER',
  DIRECT_METER_GATEWAY = 'DIRECT_METER_GATEWAY',
  HEAT_COST_ALLOCATOR = 'HEAT_COST_ALLOCATOR',
  SMART_RADIATOR_THERMOSTAT = 'SMART_RADIATOR_THERMOSTAT',
  LO_RA_WAN_GATEWAY = 'LO_RA_WAN_GATEWAY',
  LEGACY_HEAT_ENERGY_METER = 'LEGACY_HEAT_ENERGY_METER',
  LEGACY_WARM_WATER_METER = 'LEGACY_WARM_WATER_METER',
  LEGACY_WATER_METER = 'LEGACY_WATER_METER',
}

export const meteringDeviceTypes = Object.freeze({
  HEAT_ENERGY_METER: [deviceTypes.HeatMeter],
  WARM_WATER_METER: [deviceTypes.WarmWaterMeter],
  WATER_METER: [deviceTypes.WaterMeter],
});

export const deviceTypesDE: { [key: string]: string } = Object.freeze({
  SMOKE_DETECTOR: 'Rauchwarnmelder',
  HEAT_ENERGY_METER: 'Wärmemengenzähler',
  WARM_WATER_METER: 'Warmwasserzähler',
  WATER_METER: 'Kaltwasserzähler',
  DIRECT_METER_GATEWAY: 'Direct Meter Gateway',
  LO_RA_WAN_GATEWAY: 'LoRaWAN Gateway',
  HEAT_COST_ALLOCATOR: 'Heizkostenverteiler',
  SMART_RADIATOR_THERMOSTAT: 'Smart Radiator Thermostat',
  LEGACY_HEAT_ENERGY_METER: 'Wärmemengenzähler (BG)',
  LEGACY_WARM_WATER_METER: 'Warmwasserzähler (BG)',
  LEGACY_WATER_METER: 'Kaltwasserzähler (BG)',
  LEGACY_HEATING_PLANT_METER: 'Heizanlagenzähler (BG)',
  LEGACY_WATER_PLANT_METER: 'Wasseranlagenzähler (BG)',
});

export const deviceTypeList = Object.freeze([
  {
    name: 'Rauchwarnmelder',
    value: 'SMOKE_DETECTOR',
    icon: 'smoke-detector-variant-alert',
  },
  { name: 'Wärmemengenzähler', value: 'HEAT_ENERGY_METER', icon: 'heat-pump' },
  {
    name: 'Warmwasserzähler',
    value: 'WARM_WATER_METER',
    icon: 'water-thermometer',
  },
  { name: 'Kaltwasserzähler', value: 'WATER_METER', icon: 'water-alert' },
  {
    name: 'Direct Meter Gateway',
    value: 'DIRECT_METER_GATEWAY',
    icon: 'camera-metering-partial',
  },
  {
    name: 'LoRaWAN Gateway',
    value: 'LO_RA_WAN_GATEWAY',
    icon: 'router-wireless',
  },
]);

export const legacyDeviceTypeList = Object.freeze([
  {
    name: 'Wärmemengenzähler (BG)',
    value: 'LEGACY_HEAT_ENERGY_METER',
    icon: 'heating-coil',
  },
  {
    name: 'Warmwasserzähler (BG)',
    value: 'LEGACY_WARM_WATER_METER',
    icon: 'water-check-outline',
  },
  {
    name: 'Kaltwasserzähler (BG)',
    value: 'LEGACY_WATER_METER',
    icon: 'water-thermometer-outline',
  },
]);

export const radiatorDeviceTypeList = Object.freeze([
  {
    name: 'Heizkostenverteiler',
    value: 'HEAT_COST_ALLOCATOR',
    icon: 'counter',
  },
  {
    name: 'Smart Radiator Thermostat',
    value: 'SMART_RADIATOR_THERMOSTAT',
    icon: 'radiator',
  },
]);

export const inspectionDeviceTypeList = Object.freeze([
  {
    name: 'LoRaWAN Gateway',
    value: 'LO_RA_WAN_GATEWAY',
    icon: 'router-wireless',
  },
  {
    name: 'Smart Radiator Thermostat',
    value: 'SMART_RADIATOR_THERMOSTAT',
    icon: 'radiator',
  },
]);
export enum detectionTypes {
  'ManuelleErfassung' = 'MANUAL',
  'CodeScannen' = 'CODESCAN',
}

export const energyTypes = [
  {
    name: 'Wärme',
    value: ['Wärmemengenzähler', 'Heizkostenverteiler'],
    color: '#f58400',
  },
  { name: 'Warmwasser', value: ['Warmwasserzähler'], color: '#7bc7c4' },
  { name: 'Wasser', value: ['Kaltwasserzähler'], color: '#0090f5' },
  { name: 'Rauchmelder', value: ['Rauchwarnmelder'], color: 'white' },
];

export const productKeyHCA = 'HCA5KG1TB7B0 0A00L';

export enum SRTHardwareData {
  'PRODUCT_KEY' = '42000',
  'MANUFACTURER' = 'tado',
}

export enum LoRaWanHardwareData {
  'MANUFACTURER' = 'kerlink',
}

export const MeasurementUnit = {
  KWH: { value: 'KWH', name: 'kWh' },
  MWH: { value: 'MWH', name: 'mWh' },
  M3: { value: 'M3', name: 'm³' },
  VE: { value: 'VE', name: 'Verbrauchseinheiten' },
  N: { value: 'N', name: 'Newton' },
  L: { value: 'L', name: 'Liter' },
};

export const measurementUnitOptions = {
  HEAT_COST_ALLOCATOR: [MeasurementUnit.VE],
  WARM_WATER_METER: [MeasurementUnit.M3],
  WATER_METER: [MeasurementUnit.M3],
  HEAT_ENERGY_METER: [MeasurementUnit.KWH, MeasurementUnit.MWH],
  SMART_RADIATOR_THERMOSTAT: [MeasurementUnit.N],
  LEGACY_WARM_WATER_METER: [MeasurementUnit.M3],
  LEGACY_WATER_METER: [MeasurementUnit.M3],
  LEGACY_HEAT_ENERGY_METER: [MeasurementUnit.KWH, MeasurementUnit.MWH],
  LEGACY_HEATING_PLANT_METER: [
    MeasurementUnit.M3,
    MeasurementUnit.KWH,
    MeasurementUnit.MWH,
    MeasurementUnit.L,
    MeasurementUnit.VE,
  ],
  LEGACY_WATER_PLANT_METER: [MeasurementUnit.M3],
};

export const LoRaWanHardwareList = [
  { value: 'kerlink', name: 'Kerlink' },
  { value: 'RAKwireless', name: 'RAKwireless' },
];

export const LoRaWanHardwareRules = {
  kerlink: { validationRegExp: '^[\\w.-]+$', productKey: 'fevo' },
  RAKwireless: { validationRegExp: '^[A-Z0-9]{19}$', productKey: 'RAK7268C' },
};

export const AdvancedRegistrationMeterTypes = Object.freeze([
  {
    name: 'Einrohrheizung',
    value: 'Einrohrheizung',
  },
  {
    name: 'Zweirohrheizung',
    value: 'Zweirohrheizung',
  },
  {
    name: 'Dynamische Heizung',
    value: 'Dynamische Heizung',
  },
  {
    name: 'Statische Heizung',
    value: 'Statische Heizung',
  },
  {
    name: 'WMZ für WW',
    value: 'WMZ für WW',
  },
  {
    name: 'Gesamtzähler',
    value: 'Gesamtzähler',
  },
  {
    name: 'Unterstation',
    value: 'Unterstation',
  },
  {
    name: 'Übergabestation',
    value: 'Übergabestation',
  },
]);
