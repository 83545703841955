<template>
  <BaseToast
    v-if="imageUpload.isImageUploadActive"
    background="is-background-info"
    class="z-50 bg-primary-2 dark:bg-primary-1"
  >
    <div class="is-flex mb-2">
      <div>Speichern...</div>
      <div class="ml-1">
        [ {{ imageUpload.currentUploadedImage }} /
        {{ imageUpload.totalNumberOfImages }}
        Bildern]
      </div>
    </div>
    <progress class="progress is-small" max="100"></progress>
  </BaseToast>
</template>

<script lang="ts" setup>
import BaseToast from '@/components/UI/Toast/BaseToast.vue';
import * as indexedDB from '@/utils/indexedDb/IndexedDb';
import { Notification } from '@/models';
import { deleteEntityByIdIndex } from '@/utils/indexedDb/IndexedDb';
import { ref } from 'vue';
import useStores from '@/composables/useStores';
import { useImagesStore } from '@/store/images/imageStore';
import { deleteImages } from '@/api/pictures/PicturesApi';
import { Image } from '@/models/Image';

const { entitiesStore, persistenceStore, notificationStore } = useStores();

const imageUpload = ref({
  currentUploadedImage: 0,
  totalNumberOfImages: 0,
  isImageUploadActive: false,
  failedImages: [],
});

async function deleteImagesFromRemote() {
  if (!entitiesStore.activeBusinessEntityId) return;

  const imagesToBeDeleted = useImagesStore().imagesToBeDeleted;

  if (imagesToBeDeleted.length > 0) {
    const businessEntityId = imagesToBeDeleted[0].businessEntityId;
    await deleteImages(businessEntityId, imagesToBeDeleted).then(() => {
      useImagesStore().resetImagesToBeDeleted();
    });
  }
}

const isImageDetailsModified = (
  remoteImage: Image | undefined,
  image: Image,
  propertyListPass: (keyof Image)[] = []
): boolean => {
  if (!remoteImage) {
    return true;
  }

  return Object.entries(image).some(([key, value]) => {
    if (propertyListPass.includes(key as keyof Image)) {
      return false;
    }
    if (typeof value === 'object') {
      return (
        JSON.stringify(value) !==
        JSON.stringify(remoteImage[key as keyof Image])
      );
    }
    return value !== remoteImage[key as keyof Image];
  });
};

const findModifiedImages = (remoteImages: Image[], images: Image[]) => {
  const modifiedImages: Image[] = [];

  const remoteImagesMap = new Map(remoteImages.map((item) => [item.id, item]));

  images.forEach((image) => {
    const remoteImage = remoteImagesMap.get(image.id);
    if (isImageDetailsModified(remoteImage, image)) {
      modifiedImages.push(image);
    }
  });

  return modifiedImages;
};

async function uploadImages() {
  if (!entitiesStore.activeBusinessEntityId) return;

  const images = await indexedDB.getEntitiesFromDBByIndex(
    entitiesStore.activeBusinessEntityId,
    undefined,
    'businessEntityId'
  );
  const remoteImages = useImagesStore().remoteImages;
  const modifiedImages = findModifiedImages(remoteImages, images);

  imageUpload.value.totalNumberOfImages = modifiedImages.length;
  imageUpload.value.isImageUploadActive = true;

  try {
    for (const image of modifiedImages) {
      const response = await persistenceStore.saveImage(image);

      // No internet connection or Server Unreachable
      if (!response || response.status >= 400) {
        (imageUpload.value.failedImages as Image[]).push(image);
        return;
      }

      if (!image.id) return;
      if (response.status < 400) {
        imageUpload.value.currentUploadedImage++;
        await deleteEntityByIdIndex(image.id);
      }
    }

    await deleteImagesFromRemote();

    if (imageUpload.value.failedImages.length === 0) {
      await indexedDB.clearObjectStore();
    } else {
      notificationStore.addNotification(
        new Notification(
          'Keine Internet-Verbindung oder Server unerreichbar',
          'Bildspeicherung fehlgeschlagen',
          'error',
          8000
        )
      );
    }
  } catch (e) {
    console.error(e);
  } finally {
    setTimeout(() => {
      imageUpload.value.isImageUploadActive = false;
      imageUpload.value.currentUploadedImage = 0;
    }, 3000);
  }
}
defineExpose({ uploadImages });
</script>
