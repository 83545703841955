import axios from 'axios';
import { default as envConfig } from '@/config/runtimeConfig';
import { AppInfo, WebReleases } from '@/models/mobile/AppInfo';

export const fetchLatestVersion = async function (
  versionName?: string,
  versionCode?: number
): Promise<AppInfo> {
  const { data } = await axios.get<AppInfo>(
    `${envConfig.backendBaseUrl}/app/latest`,
    {
      params: {
        versionCode: versionCode,
        versionName: versionName,
      },
    }
  );
  return data;
};

export const fetchLatestReleases = async function (): Promise<WebReleases[]> {
  const { data } = await axios.get<WebReleases[]>(
    `${envConfig.backendBaseUrl}/app/web/releases`
  );
  return data;
};
