import {
  InstallationPoint,
} from '@/models/installationPoint/InstallationPoint';
import { DeviceType, DeviceTypeParams } from '@/models/devices/Device';

import { useDeviceForm } from '@/composables/useDeviceForm';
import { DeviceEventParams } from '@/models/installationPoint/DeviceEvent';
import { ref } from 'vue';
import { deviceTypes } from '@/enums/device';
import { FormAction, useFormStore } from '@/store/form/formStore';

export function useOldDeviceForm(emit: any) {
  const deviceEvent = ref<DeviceEventParams>();
  const {
    device,
    installationPoint,
    initDevice,
    updateDevice,
  } = useDeviceForm(emit);

  const initOldDevice = <T extends DeviceType>(
    deviceType: T,
    node: InstallationPoint,
    params?: Extract<DeviceTypeParams, { deviceType: T }>
  ) => {
    installationPoint.value = node;
    initDeviceEvent(deviceType);

    if (params) {
      initDevice(deviceType, params);
      return;
    }

    if (useFormStore().formAction === FormAction.EDIT) {
      initDevice(deviceType, (node as InstallationPoint).getPreviousDevice());
      return;
    }

    initDevice(deviceType);
  };

  const initDeviceEvent = <T extends DeviceType>(deviceType: T) => {
    if (!installationPoint.value) {
      throw new Error('Installation point is not initialized');
    }

    if (
      installationPoint.value?.deviceEvents?.length > 0 &&
      useFormStore().formAction === FormAction.EDIT
    ) {
      deviceEvent.value = installationPoint.value?.getPreviousDeviceEvent();
    } else {
      deviceEvent.value = {
        timestamp: new Date().toISOString(),
        counter: undefined,
      };

      if (deviceType === deviceTypes.HeatCostAllocator) {
        deviceEvent.value = {
          ...deviceEvent.value,
          controlValue: undefined,
        };
      }
    }
  };

  const createDevice = () => {
    if (!installationPoint.value) {
      throw new Error('Installation point is not initialized');
    }

    if (!device.value) {
      throw new Error('Device is not initialized');
    }

    if (!deviceEvent.value) {
      throw new Error('DeviceEvent is not initialized');
    }

    installationPoint.value.captureDevice(
      device.value.id,
      deviceEvent.value.timestamp,
      deviceEvent.value.counter,
      deviceEvent.value?.controlValue
    );
    installationPoint.value.unmountDevice(
      device.value.id,
      deviceEvent.value.timestamp,
      deviceEvent.value.counter,
      deviceEvent.value?.controlValue
    );

    updateDevice();
  };

  return {
    device,
    deviceEvent,
    initOldDevice,
    createDevice,
    updateDevice,
  };
}
