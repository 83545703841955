<template>
  <div
    ref="scrollPositionRef"
    class="overflow-x-auto"
    @mousedown="onMouseDown"
    @mouseup="onMouseUp"
  >
    <div class="flex gap-4">
      <BuildingRoomGroupCard
        v-if="entrance"
        :room-group="entrance"
        :data-testid="entrance.id + '_entrance'"
        :is-last="true"
        :is-staircase="true"
      />

      <div
        class="my-2 border-r border-light-disabled-background dark:border-dark-disabled-background"
      />

      <template v-for="(roomgroup, index) in roomgroups" :key="roomgroup.id">
        <BuildingRoomGroupCard
          :room-group="roomgroup"
          data-testid="roomGroupTile"
          :is-last="index === roomgroups.length - 1"
        />

        <div
          v-if="index !== roomgroups.length - 1 && isSwapModeActive"
          class="flex flex-col justify-center"
        >
          <em
            class="mdi mdi-swap-horizontal rounded-lg px-1 text-3xl text-primary-2 hover:dark:bg-dark-3"
            data-testid="swapButton"
            @click="swapRoomGroups(roomgroups[index], roomgroups[index + 1])"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import BuildingRoomGroupCard from '@/components/BuildingOverview/BuildingRoomGroupCard.vue';
import { inject, onMounted, onUnmounted, provide, ref } from 'vue';
import { Roomgroup } from '@/models';
import { useRoomGroupStore } from '@/store/entities/roomGroupStore';

const props = defineProps<{
  entrance: Roomgroup;
  roomgroups: Roomgroup[];
}>();

const scrollPositionRef = ref(null);
provide('scrollPositionRef', scrollPositionRef);

const isSwapModeActive = inject('isSwapModeActive', ref(false));

const swapRoomGroups = (
  roomGroupSource: Roomgroup,
  RoomGroupTarget: Roomgroup
) => {
  useRoomGroupStore().swapRoomGroup(roomGroupSource, RoomGroupTarget);
};

onMounted(() => {
  window.addEventListener('mouseup', onMouseUp);
});

onUnmounted(() => {
  window.removeEventListener('mouseup', onMouseUp);
});

const cursorPos = ref([0, 0]);
const isDragging = ref(false);
provide('isDragging', isDragging);
const onMouseDown = (ev: MouseEvent) => {
  ev.preventDefault();
  if (ev.buttons === 1) {
    cursorPos.value = [ev.pageX, ev.pageY];
    window.addEventListener('mousemove', onMouseHold);
  }
};

const onMouseUp = () => {
  window.removeEventListener('mousemove', onMouseHold);
  setTimeout(() => {
    isDragging.value = false;
  }, 100);
};

const onMouseHold = (ev: MouseEvent) => {
  isDragging.value = true;
  requestAnimationFrame(() => {
    const delta = [
      ev.pageX - cursorPos.value[0],
      ev.pageY - cursorPos.value[1],
    ];

    cursorPos.value = [ev.pageX, ev.pageY];

    if (!scrollPositionRef.value) return;
    (scrollPositionRef.value as any).scrollBy({
      left: -delta[0],
      top: -delta[1],
    });
  });
};
</script>
