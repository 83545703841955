<template>
  <BaseDropdown
    v-if="measurementUnits.length > 1"
    v-model:selection="localUnit"
    :options-list="measurementUnits"
    dropdown-text="Maßeinheit wählen"
    label="Maßeinheit"
    :validation="validation"
    :optional="optional"
    :is-compact="true"
    :disabled="disabled"
    :test-id="testId ? testId : 'measurement-unit'"
  />
  <div v-else class="p-3">
    <div class="relative">
      <label
        class="absolute left-2.5 top-4 z-10 origin-[0] -translate-y-4 scale-75 text-sm text-gray-500 dark:text-gray-400"
      >
        Maßeinheit
      </label>
      <div class="px-2.5 pb-2.5 pt-5 text-sm text-gray-900 dark:text-white">
        {{ measurementUnits[0].name }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { MeasurementUnit, measurementUnitOptions } from '@/enums/device';
import { computed, onMounted, WritableComputedRef } from 'vue';
import BaseDropdown from '@/components/UI/Dropdown/BaseDropdown.vue';
import { Device } from '@/models/devices/Device';
import { Validation } from '@vuelidate/core';

const props = defineProps<{
  device: Device<any>;
  unit: string | null;
  validation?: Validation;
  testId?: string;
  optional?: boolean;
  disabled?: boolean;
}>();

const emit = defineEmits(['update:unit']);

const localUnit: WritableComputedRef<string> = computed({
  get() {
    return props.unit;
  },
  set(value) {
    emit('update:unit', value);
  },
});

const measurementUnits = computed(() => {
  const deviceType = props.device
    .deviceType as keyof typeof measurementUnitOptions;
  if (Object.keys(measurementUnitOptions).includes(deviceType)) {
    return measurementUnitOptions[deviceType];
  }
  return [];
});

onMounted(() => {
  if (measurementUnits.value.length === 1) {
    localUnit.value = measurementUnits.value[0].value;
  }
});
</script>
