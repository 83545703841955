import { Node, Tag } from './drawingUtils';
import { useRoomStore } from '@/store/entities/roomStore';
import { useLayoutStore } from '@/store/layout/layoutStore';
import { useFunkcheckStore } from '@/store/funkcheck/funkcheckStore';
import { useInstallationPointStore } from '@/store/entities/installationPointStore';
import { useInspectionPointStore } from '@/store/entities/inspectionPointStore';
import { useOrderStore } from '@/store/order/orderStore';

let listOfRooms;
let currentActiveNodeId;

function createNode(entity) {
  const roomStore = useRoomStore();

  const node = new Node();
  node.id = entity.id;
  node.label = entity.getLabel();
  node.notificationIcons = roomStore.getRoomBadgeIcon(node.id);
  node.radioCheckErrors =
    useFunkcheckStore().getDevicesWithRadioCheckErrorInRoom(entity.id);
  node.notMountedDevices =
    useInstallationPointStore().getFailedInstallationAttempt(entity.id);
  node.inspectionPoints = useInspectionPointStore().getInspectionPointStatus({
    roomId: node.id,
  });
  node.ordinal = entity.ordinal;
  node.isAccessible = entity.isAccessible;
  if (!useOrderStore().isOnSiteInspection()) {
    node.isInstallationLocation =
      useInspectionPointStore().getCompletedGatewaysByRoomId(
        entity.id
      ).length > 0;
  }

  node.selfAction = () => {
    const layoutStore = useLayoutStore();
    layoutStore.changeCurrentNode(entity);
  };
  node.isActive = currentActiveNodeId === node.id;
  const tags = roomStore.getDeviceAndPlantTagsOfRoom(node.id);
  if (tags.length >= 1) {
    node.tags = tags.map((tag) => {
      return new Tag(tag.label, tag.info, tag.color);
    });
  }
  return node;
}

function addActionToNode(node) {
  return (action) => {
    const nodeAction = {
      label: action.label,
      icon: action.icon,
    };
    node.actions.push(nodeAction);
  };
}

function addChildNodes(children, node) {
  const roomStore = useRoomStore();
  const childNodes = children.map((child) => {
    return entityToNode(child);
  });
  children.forEach((child, index) => {
    const childNode = entityToNode(child);
    if (childNode) {
      const isNotFirstChild = index > 0;
      const isNotLastChild = index < children.length - 1;

      childNode.arrows = {
        left: isNotFirstChild,
        right: isNotLastChild,
        onClick: () => {
          roomStore.swapRoom(child);
        },
      };
      childNode.siblings = childNodes;
      childNode.parent = node;

      node.totalNumberOfChildren =
        (childNode.totalNumberOfChildren || 1) + node.totalNumberOfChildren;
      node.children.push(childNode);
    }
  });
}

function entityToNode(entity) {
  const node = createNode(entity);
  const entityChildren = getAllEntityChildren(entity);

  if (entityChildren.length > 1) {
    entityChildren.sort(function (a, b) {
      if (a.ordinal > b.ordinal) return 1;
      if (a.ordinal < b.ordinal) return -1;
      return 0;
    });
  }

  addChildNodes(entityChildren, node);

  return node;
}

function getAllEntityChildren(entity) {
  const children = [];

  listOfRooms.forEach((room) => {
    if (room.getParentId() === entity.id) {
      children.push(room);
    }
  });

  return children;
}

function getMaxDepthOfTree(root) {
  if (!root) return 1;
  let max = 0;

  const depthFirstSearch = (node, levels) => {
    if (levels > max) max = levels;
    for (const child of node.children) {
      depthFirstSearch(child, levels + 1);
    }
  };
  depthFirstSearch(root, 1);
  return max;
}

export function createTreeNode(rootRoom, listOfRoomsArray, activeNodeId = '') {
  listOfRooms = listOfRoomsArray;
  currentActiveNodeId = activeNodeId;
  const tree = entityToNode(rootRoom);
  const maxDepth = getMaxDepthOfTree(tree);
  tree.maxDepth = maxDepth;
  return tree;
}
