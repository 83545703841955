export const usageTypeList = Object.freeze([
  { name: 'Wohngebäude', value: 'LIVING' },
  { name: 'Gewerbegebäude', value: 'BUSINESS' },
  { name: 'Gemischte Nutzung', value: 'MIXED' },
]);

export const entranceList = Object.freeze([
  { name: 'Haupteingang', value: 'MAIN_ENTRY' },
  { name: 'Seiteneingang', value: 'SIDE_ENTRY' },
  { name: 'Hintereingang', value: 'BACK_ENTRY' },
]);

export const buildingSectionList = Object.freeze([
  { name: 'Vorderhaus', value: 'FRONT_BUILDING' },
  { name: 'Hinterhaus', value: 'BACK_BUILDING' },
  { name: 'Seitenflügel links', value: 'LEFT_WING' },
  { name: 'Seitenflügel rechts', value: 'RIGHT_WING' },
  { name: 'Sonstiges', value: 'OTHER' },
]);

export const responsibilityList = Object.freeze([
  { name: 'Rolle 1', value: 'Rolle1' },
  { name: 'Rolle 2', value: 'Rolle2' },
  { name: 'Rolle 3', value: 'Rolle3' },
  { name: 'Sonstige', value: 'Sonstige' },
]);

export const interiorWallOptions = Object.freeze([
  { value: 'MASONRY', name: 'Mauerwerk' },
  { value: 'REINFORCED_CONCRETE', name: 'Stahlbeton' },
  { value: 'LIGHTWEIGHT_WALLS', name: 'Leichtbauwände' },
]);

export const exteriorWallOptions = Object.freeze([
  { value: 'MASONRY', name: 'Mauerwerk' },
  { value: 'REINFORCED_CONCRETE', name: 'Stahlbeton' },
]);

export const floorOptions = Object.freeze([
  { value: 'HARDWOOD_FLOOR', name: 'Holzfußboden' },
  { value: 'REINFORCED_CONCRETE', name: 'Stahlbeton' },
  { value: 'POLISHED_FLOOR', name: 'Geschütteter Fußboden' },
]);

export const connectionOptions = Object.freeze([
  { value: 'SOCKET', name: 'Steckdose' },
  { value: 'DISTRIBUTION_BOX', name: 'Verteilerdose' },
  { value: 'SURFACE_MOUNTED', name: 'Aufputz' },
  { value: 'IN_WALL', name: 'Unterputz' },
]);
export const buildingNotAccessibleReasonsList = Object.freeze([
  {
    name: 'Nutzer verweigert Zutritt aktuell',
    value: 'Nutzer verweigert Zutritt aktuell',
  },
  {
    name: 'Nicht angetroffen, Name korrekt',
    value: 'Nicht angetroffen, Name korrekt',
  },
  { name: 'RM-Montage wurde verweigert', value: 'RM-Montage wurde verweigert' },
  { name: 'Besonderheiten beachten', value: 'Besonderheiten beachten' },
  {
    name: 'neuer Termin nötig (Abl./Monteur)',
    value: 'neuer Termin nötig (Abl./Monteur)',
  },
  {
    name: 'Zutritt verweigert durch Monteur',
    value: 'Zutritt verweigert durch Monteur',
  },
  {
    name: 'Nutzer verweigert Zutritt dauerhaft',
    value: 'Nutzer verweigert Zutritt dauerhaft',
  },
  { name: 'Nutzeinheit nicht gefunden', value: 'Nutzeinheit nicht gefunden' },
  {
    name: 'trotz Klingeln, nicht geöffnet',
    value: 'trotz Klingeln, nicht geöffnet',
  },
  {
    name: 'Kontaktpers. nicht erreichbar',
    value: 'Kontaktpers. nicht erreichbar',
  },
  { name: 'Nicht zumutbar', value: 'Nicht zumutbar' },
  { name: 'Sonstiges', value: 'Sonstiges' },
]);

export const buildingImageKeywordList = [
  'Außenansicht',
  'Gebäude',
  'Treppenhaus',
  'Klingelschild',
  'Fluchtpläne',
];
