import UserSettings, { UserSettingsType } from './UserSettings';

export function getUserSetting<K extends keyof UserSettingsType>(
  key: K
): UserSettingsType[K] | undefined {
  return UserSettings.getSetting(key);
}

export function updateUserSetting<K extends keyof UserSettingsType>(
  key: K,
  value: UserSettingsType[K]
): void {
  UserSettings.setSetting(key, value);
}

export function setHasDismissedDialog(value: boolean): void {
  updateUserSetting('hasDismissedChangelog', value);
}
