import { Pinia, Store } from '@/store/pinia-class-component';
import { default as envConfig } from '@/config/runtimeConfig';
import { getUserSetting, setHasDismissedDialog } from '@/utils/userSettings';
import { UserSettingsKeys } from '@/utils/userSettings/UserSettings';
import posthog from 'posthog-js';

export function useAppStore() {
  return new AppStore();
}

@Store
class AppStore extends Pinia {
  private _isChangelogOpen = false;

  get isChangelogOpen(): boolean {
    return this._isChangelogOpen;
  }

  toggleChangelogDialog() {
    this._isChangelogOpen = !this._isChangelogOpen;
  }

  closeChangelogDialog() {
    this._isChangelogOpen = false;
    setHasDismissedDialog(true);
  }

  shouldOpenChangelogDialog(): boolean {
    if (!posthog.isFeatureEnabled('show-changelog-de')) return false;
    const setting = getUserSetting(UserSettingsKeys.HAS_DISMISSED_CHANGELOG);
    return setting === false || !setting;
  }

  isLiveUpdateEnabled(): boolean {
    return <boolean>posthog.isFeatureEnabled('is-live-update-enabled');
  }

  getLiveUpdatePayload(): Record<string, any> {
    return posthog.getFeatureFlagPayload('is-live-update-enabled') as Record<
      string,
      any
    >;
  }
}
