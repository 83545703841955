import { Entity } from '@/models/types/Entity';
import { DifferenceChange } from 'microdiff';

export class OrderChanges {
  roomGroupProgress: Array<string>;

  _hasChanges: boolean;
  transactions: { [key: string]: EntityTransaction };
  lastSaved?: number;
  lastSavedHash: string;
  currentHash: string;

  constructor() {
    this.roomGroupProgress = [];
    this._hasChanges = false;
    this.transactions = {};
    this.lastSaved = undefined;
    this.lastSavedHash = '';
    this.currentHash = '';
  }

  get hasChanges() {
    console.warn(
      "The 'hasChanges' property is not used current but reserved for future use."
    );
    return this._hasChanges;
  }

  onSaveOrderChanges() {
    this.lastSaved = Math.floor(new Date().getTime() / 1000);
    this.lastSavedHash = this.currentHash;
  }

  async onOrderChangesUpdate() {
    this.currentHash = await this.hashTransactions(this.transactions);
  }

  async hashTransactions(transactions: { [key: string]: EntityTransaction }) {
    const jsonString = JSON.stringify(transactions);

    const hashBuffer = await crypto.subtle.digest(
      'SHA-256',
      new TextEncoder().encode(jsonString)
    );

    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('');
  }

  addRoomGroupProgress(roomGroupId: string) {
    if (this.roomGroupProgress.includes(roomGroupId)) return;
    this.roomGroupProgress.push(roomGroupId);
  }

  removeRoomGroupProgress(roomGroupId: string) {
    this.roomGroupProgress = this.roomGroupProgress.filter(
      (roomGroupProgress) => roomGroupProgress !== roomGroupId
    );
  }

  hasRoomGroupProgress(roomGroupId: string) {
    return this.roomGroupProgress.includes(roomGroupId);
  }

  upsertTransaction(
    entity: Entity,
    type: EntityTransactionType,
    diff: DifferenceChange[] = []
  ) {
    const oldDiff = this.transactions[entity.id]?.diff ?? [];
    const newDiff = diff.map((item) => {
      return new TransactionDiff(
        item.oldValue,
        item.value,
        item.path,
        item.type
      );
    });

    const mergedDiff = this.mergeDiffs(oldDiff, newDiff);
    const timestamp = new Date().toISOString();

    this.transactions[entity.id] = new EntityTransaction(
      entity,
      entity.type,
      entity.id,
      type,
      timestamp,
      mergedDiff
    );
  }

  mergeDiffs(oldDiff: TransactionDiff[], newDiff: TransactionDiff[]) {
    const mergedDiff = new Map();

    oldDiff.forEach((item) => {
      mergedDiff.set(item.path, item);
    });

    newDiff.forEach((item) => {
      const pathKey = item.path;
      if (mergedDiff.has(pathKey)) {
        const existingItem = mergedDiff.get(pathKey);
        item.oldValue = existingItem.oldValue;
      }
      mergedDiff.set(pathKey, item);
    });

    return Array.from(mergedDiff.values());
  }
}

export class TransactionDiff {
  oldValue: any;
  newValue: any;
  path: string;
  type: string;

  constructor(oldValue: any, newValue: any, path: any[], type: string) {
    this.oldValue =
      oldValue instanceof Object ? JSON.stringify(oldValue) : oldValue;
    this.newValue =
      newValue instanceof Object ? JSON.stringify(newValue) : newValue;
    this.path = path?.join('.');
    this.type = type;
  }
}

export enum TransactionDiffType {
  CREATE = 'CREATE',
  CHANGE = 'CHANGE',
  REMOVE = 'REMOVE',
}

export class EntityTransaction {
  entity: Entity;
  entityType: string;
  entityId: string;
  type: EntityTransactionType;
  timestamp: string;
  diff: any;

  constructor(
    entity: Entity,
    entityType: string,
    entityId: string,
    type: EntityTransactionType,
    timestamp: string,
    diff?: any
  ) {
    this.entity = entity;
    this.entityType = entityType;
    this.entityId = entityId;
    this.type = type;
    this.diff = diff;
    this.timestamp = timestamp ?? new Date().toISOString();
  }
}

export enum EntityTransactionType {
  DELETE_ENTITY = 'DELETE_ENTITY',
  UPDATE_ENTITY = 'UPDATE_ENTITY',
  CREATE_ENTITY = 'CREATE_ENTITY',
}
