<template>
  <Teleport :disabled="isTeleportDisabled" to="#modal-header">
    <div class="relative">
      <div class="flex justify-center text-3xl">
        <div v-if="wizardTitle" class="justify-center font-bold">
          {{ wizardTitle }}
        </div>
        <div v-else class="text-3xl font-bold">Heizkostenverteiler</div>
      </div>
      <div v-if="tabs?.length > 1 && isStepperShown">
        <div v-if="progressHeader" class="h-2 bg-primary-4 dark:bg-dark-5">
          <div
            :style="{ width: progress + '%' }"
            class="h-full bg-primary-2 transition-all"
          />
        </div>

        <ol v-else-if="!progressHeader" class="m-0 flex p-0 transition-all">
          <li
            v-for="(tab, index) in tabs"
            :key="`tab-${index}`"
            class="flex items-center p-0"
            :class="[
              {
                'after:border-light-4 dark:after:border-dark-4':
                  index >= activeTabIndex,
                'after:border-primary-1 dark:after:border-primary-1':
                  index < activeTabIndex,
                'mr-3 after:inline-block after:w-full after:border-2 after:border-b':
                  index < tabs.length - 1,
                'w-auto': index === tabs.length - 1,
                'w-full': index < tabs.length - 1,
              },
            ]"
          >
            <span
              class="flex h-8 w-8 shrink-0 items-center justify-center rounded-full border-2 lg:h-8 lg:w-8"
              :class="[
                {
                  'border-primary-1 bg-primary-1 text-white': tab.isActive,
                  'border-primary-1 bg-primary-1': tab.isValid,
                  'border-light-4 dark:border-dark-4':
                    !tab.isValid && !tab.isActive,
                },
              ]"
            >
              <em v-if="tab.isValid" class="mdi mdi-check" />
              <span v-else>
                <em v-if="tab.icon" :class="tab.icon" class="fas" />
                <span v-else>
                  {{ index + 1 }}
                </span>
              </span>
            </span>
            <span class="mx-2 text-left text-sm">
              {{ tab.title }}
            </span>
          </li>
        </ol>
      </div>

      <div v-if="minimalHeader" class="flex justify-end">
        <div>
          Schritt <span>{{ currentStep }}</span> von
          <span>{{ totalTabs }}</span>
        </div>
      </div>
    </div>
  </Teleport>

  <slot
    name="default"
    :go-to-next-step="goToNextStep"
    :go-to-previous-step="goToPreviousStep"
  />

  <Teleport v-if="!disableNavigationalButtons" to="#modal-footer">
    <TertiaryButton
      v-if="activeTabIndex > 0"
      :is-disabled="activeTabIndex === 0"
      class="button mr-4"
      label="Zurück"
      @click="selectTab(activeTabIndex - 1)"
    />
    <PrimaryButton
      v-if="activeTabIndex < totalTabs - 1"
      :is-disabled="!isCurrentStepValid"
      label="Weiter"
      data-testid="nextButton"
      @click="selectTab(activeTabIndex + 1)"
    />
  </Teleport>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useFormStore } from '@/store/form/formStore';
import PrimaryButton from '@/components/UI/Button/PrimaryButton.vue';
import TertiaryButton from '@/components/UI/Button/TertiaryButton.vue';

/**
 * Wizard stepper component.
 * @displayName FormStepper
 *
 * @props
 * {boolean} [minimal-header=false] - A minimal version of the header is displayed.
 * {boolean} [disable-auto-mount=false] - The stepper component is not mounted automatically.
 * {number} [valid-step=0] - The index of the step that is valid.
 * {boolean} [disable-navigational-buttons] - Disables wizard navigation buttons
 *
 * @events
 * {function} [on-stepper-completion] - Triggers when the all the steps are completed.
 * {function} [on-tab-change] - Triggers when clicked on tabs.
 *
 * @slot default - This slot is used to display the content of the step.
 *
 */
export default defineComponent({
  name: 'FormStepper',
  components: { TertiaryButton, PrimaryButton },
  provide() {
    return {
      addTab: this.addTab,
      removeTab: this.removeTab,
      validateStep: this.validateStep,
      onCompletion: this.onCompletion,
    };
  },
  props: {
    /*
     * Can be used to maintain a state step validity
     */
    wizardTitle: {
      type: String,
      required: false,
      default: '',
    },
    validStep: {
      type: Number,
      required: false,
      default: -1,
    },
    isStepperShown: {
      type: Boolean,
      required: false,
      default: true,
    },
    minimalHeader: {
      type: Boolean,
      required: false,
      default: false,
    },
    progressHeader: {
      type: Boolean,
      required: false,
      default: false,
    },
    disableAutoMount: {
      type: Boolean,
      required: false,
      default: false,
    },
    disableNavigationalButtons: {
      type: Boolean,
      required: false,
      default: false,
    },
    headerTeleportTarget: {
      type: String,
      required: false,
      default: '',
    },
  },
  emits: ['on-stepper-completion', 'on-tab-change', 'close-stepper'],
  setup() {
    const formStore = useFormStore();

    return {
      formStore,
    };
  },
  data() {
    return {
      tabs: [] as Array<TabContent>,
      activeTabIndex: 0 as number,
      maxStep: 0 as number,
    };
  },
  computed: {
    progress() {
      return (100 * this.currentStep) / this.totalTabs;
    },
    marginTop() {
      return this.minimalHeader ? 'margin-top: 0' : 'margin-top: 70px';
    },
    totalTabs(): number {
      return this.tabs.length;
    },
    isCurrentStepValid(): boolean {
      return this.tabs[this.activeTabIndex]?.isValid;
    },
    currentStep(): number {
      return this.activeTabIndex + 1;
    },
    isFirstStep(): boolean {
      return this.currentStep === 1;
    },
    isLastStep(): boolean {
      return this.currentStep === this.totalTabs;
    },
    isTeleportDisabled(): boolean {
      return !this.headerTeleportTarget || this.headerTeleportTarget === '';
    },
    currentTab(): TabContent {
      return this.tabs[this.activeTabIndex];
    },
  },
  methods: {
    /**
     * @param {TabContent} item StepperContent component as properties
     */
    addTab(item: TabContent): void {
      const index = this.tabs.length;
      item.tabId = index;
      this.tabs.splice(index, 0, item);
      // if a step is added before the current one, go to it
      if (index < this.activeTabIndex + 1) {
        this.changeTab(this.activeTabIndex + 1, index);
      }
      if (item.selected) {
        this.activeTabIndex = index;
        this.selectTab(this.activeTabIndex);
      }

      item.isMounted = !this.disableAutoMount;

      if (index < this.validStep) {
        this.tabs[index].isValid = true;
      }
    },
    /**
     * @param {TabContent} item StepperContent component as properties
     */
    removeTab(item: TabContent) {
      const tabs = this.tabs;
      const index = tabs.indexOf(item);
      if (index > -1) {
        // Go one step back if the current step is removed
        if (index === this.activeTabIndex) {
          this.maxStep = this.activeTabIndex - 1;
          this.changeTab(this.activeTabIndex, this.activeTabIndex - 1);
        }
        if (index < this.activeTabIndex) {
          this.maxStep = this.activeTabIndex - 1;
          this.activeTabIndex = this.activeTabIndex - 1;
        }
        tabs.splice(index, 1);
      }
    },
    changeTab(oldIndex: number, newIndex: number) {
      const oldTab = this.tabs[oldIndex];
      const newTab = this.tabs[newIndex];
      if (oldTab) {
        oldTab.isActive = false;
      }
      if (newTab) {
        newTab.isActive = true;
      }
      this.activeTabIndex = newIndex;
      return true;
    },
    selectTab(index: number): void {
      this.tabs.forEach((tab) => {
        tab.isActive = false;
        tab.isMounted = !this.disableAutoMount;
      });
      this.activeTabIndex = index;
      this.tabs[index].isActive = true;

      this.onTabChange(index);
    },
    validateStep(stepIsValid: boolean): void {
      this.tabs[this.activeTabIndex].isValid = stepIsValid;
    },
    isStepValid(index: number): boolean {
      if (this.tabs[index].isActive) {
        return false;
      }
      return index < this.activeTabIndex || this.tabs[index].isValid;
    },
    onCompletion(): void {
      this.$emit('on-stepper-completion');
    },
    onTabChange(index: number) {
      this.$emit('on-tab-change', index);
    },

    goToNextStep() {
      let nextIndex = this.activeTabIndex + 1;
      if (this.activeTabIndex < this.totalTabs - 1) {
        if (this.tabs[nextIndex].isSkippable) {
          nextIndex = nextIndex + 1;
        }
      }

      if (
        nextIndex >= this.totalTabs ||
        this.activeTabIndex >= this.totalTabs
      ) {
        this.onCompletion();
        return;
      }
      this.selectTab(nextIndex);
    },
    goToPreviousStep() {
      if (this.activeTabIndex > 0) {
        this.selectTab(this.activeTabIndex - 1);
        return;
      }
      this.formStore.setToLastForm();
    },
    closeStepper() {
      this.$emit('close-stepper');
    },
  },
});
</script>

<style scoped lang="scss">
.minimal-header {
  margin-top: -40px;
}
.nav-header {
  display: flex;
  flex-wrap: wrap;
  font-size: 22px;
  width: 100%;
  justify-content: space-between;
}

.nav-header::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  border-top: 2px solid #bababa;
  z-index: 0;
}

.stepper-header {
  height: 80px;
  display: flex;
  align-items: center;
  position: relative;
}

.nav-item {
  border: 2px solid #9191a1;
  border-radius: 50%;
  padding: 20px;
  width: 75px;
  height: 75px;
  position: relative;
  max-width: 100%;
  text-align: center;
  z-index: 1;
  background-color: white;
}

.nav-item {
  &.active {
    border: 2px solid $primary-color;
  }
}

.nav-link {
  color: #9191a1;

  &.active {
    color: $primary-color;
  }
}

.active {
  border-color: $primary-color;
}

.valid {
  background-color: $primary-color;
  border-color: $primary-color;
  color: white;
}

.step-disabled {
  border: 1px solid gray;
}

.stepper-content {
  margin-bottom: 10px;
  min-height: 300px;
}

.button {
  padding: 20px 40px;
}

.is-fixed-bottom {
  position: fixed;
  bottom: 60px;
  right: 60px;
}
</style>
