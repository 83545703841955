import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createPinia } from 'pinia';
import { getIndexedDB } from './utils/indexedDb/IndexedDb';
import { configureAxios } from '@/api/axios.interceptor';
import { errorHandler } from '@/utils/errorHandler';
import { initSentry } from '@/modules/sentry';
import '@mdi/font/css/materialdesignicons.css';
import './assets/main.scss';
import './assets/styles/index.css';
import { App as CapacitorApp } from '@capacitor/app';
import { default as envConfig } from '@/config/runtimeConfig';

import '@fortawesome/fontawesome-free/css/all.css';

import { AnalyticsPlugin, piniaAnalytics } from '@/plugins/bxAnalytics';
import { actionsMap } from '@/plugins/analyticsActions';
import { useLayoutStore } from '@/store/layout/layoutStore';

const pinia = createPinia();

/*
 * Add axios interceptor to handle JWT token
 */
configureAxios();

const app = createApp(App).directive('click-outside', {
  beforeMount(el, binding) {
    el.event = function (event: Event) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event);
      }
    };

    document.body.addEventListener('click', el.event, { capture: true });
  },
  unmounted: function (el) {
    document.body.removeEventListener('click', el.event, { capture: true });
  },
});

app.config.errorHandler = (err) => {
  errorHandler(err);
};

if (envConfig.isDevelopment !== 'true') {
  const analyticsConfig = {
    provider: 'posthog',
    apiKey: envConfig.postHogApiKey,
    config: {},
  };

  pinia.use(piniaAnalytics(actionsMap));
  app.use(AnalyticsPlugin, analyticsConfig);
}

app.use(pinia);
app.use(router);
initSentry(app);
app.mount('#app');

/*
 * IndexedDb can be unavailable sometimes
 * (ex: with Jest) so a catch error is necessary
 */
getIndexedDB().catch(() => {
  console.debug('IndexedDb is not available');
});

CapacitorApp.addListener('backButton', ({ canGoBack }) => {
  if (!canGoBack) {
    CapacitorApp.exitApp().then((r) => undefined);
  } else {
    useLayoutStore().goBack();
  }
});

/*
 * debug purposes
 */
CapacitorApp.addListener('pause', () => {
  console.debug('App Paused');
});

CapacitorApp.addListener('resume', () => {
  console.debug('App Resumed');
});

CapacitorApp.addListener('appRestoredResult', (restoredEvent) => {
  console.debug('App Restored', restoredEvent);
});

CapacitorApp.addListener('appStateChange', ({ isActive }) => {
  if (!isActive) {
    console.log('App is in background at', new Date().toISOString());
  } else {
    console.log('App is in foreground at', new Date().toISOString());
    // Trigger a redraw for the grey screen
    document.body.style.display = 'none';
    document.body.offsetHeight;
    document.body.style.display = 'block';
  }
});
