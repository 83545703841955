<template>
  <div data-testId="deviceList">
    <div class="flex flex-col gap-10">
      <div v-if="Object.values(plantTypes).length === 0">
        <BaseInformation
          status="info"
          information-text="In diesem Raum sind noch keine Anlagen installiert."
        />
      </div>
      <DeviceTypeTable
        v-for="(value, key) in plantTypes"
        :key="key"
        :devices="value"
        :device-type="key"
        :on-edit="editPlant"
        :on-delete="openDeletePlantConfirmationModal"
      />
    </div>
    <div class="fixed bottom-8 right-10 z-10 flex justify-between">
      <ScrollButton
        button-text="Anlage hinzufügen"
        button-icon="water-boiler"
        data-testid="addPlantButton"
        @on-click="addPlant"
      />
    </div>
    <BasePrompt
      question="Sind Sie sicher, dass Sie diese Anlage löschen möchten?"
      :open="isDeleteConfirmationModalOpen"
      title="Anlage löschen"
      :cancel="closeModal"
      :close="closeModal"
      :proceed="deletePlant"
    />
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';

import { useDeviceStore } from '@/store/entities/deviceStore';
import { useEntitiesStore } from '@/store/entities/entitiesStore';
import { useFormStore } from '@/store/form/formStore';
import { useInstallationPointStore } from '@/store/entities/installationPointStore';
import BaseInformation from '@/components/UI/Hint/BaseInformation.vue';
import BasePrompt from '@/components/UI/Modal/BasePrompt.vue';
import { usePlantStore } from '@/store/entities/plantStore';
import DeviceTypeTable from '@/components/Forms/DeviceListFormComponents/DeviceTypeTable.vue';
import ScrollButton from '@/components/UI/Button/ScrollButton.vue';
import { Form } from '@/models/Form';
import { entityTypes } from '@/enums/generic';

const props = defineProps({
  parentId: {
    type: String,
    required: true,
  },
});

const plantStore = usePlantStore();
const installationPointStore = useInstallationPointStore();
const entitiesStore = useEntitiesStore();
const formStore = useFormStore();

const isDeleteConfirmationModalOpen = ref(false);
const toBeDeletedInstallationPoint = ref({});

const plantTypes = computed(() => {
  const plants = {};
  const plantsInRoom = plantStore.getAllPlantsInRoom(props.parentId);
  plantsInRoom.forEach((plant) => {
    if (!plants[plant.plantType]) {
      plants[plant.plantType] = [];
    }
    plants[plant.plantType].push(plant);
  });
  return plants;
});

const addPlant = () => {
  useFormStore().createEntity(
    new Form().setParentId(props.parentId).setName(entityTypes.plant)
  );
};

function editPlant(plant) {
  formStore.editEntity(plant, props.parentId);
}

function openDeletePlantConfirmationModal(device) {
  toBeDeletedInstallationPoint.value = device;
  isDeleteConfirmationModalOpen.value = true;
}

function deletePlant() {
  const relatedDevices = useDeviceStore().getDevicesByInstallationPointId(
    toBeDeletedInstallationPoint.value.id
  );

  for (const device of relatedDevices) {
    entitiesStore.deleteEntity(device);
  }

  if (toBeDeletedInstallationPoint.value.radiatorId) {
    const radiator = useEntitiesStore().getEntityById(
      toBeDeletedInstallationPoint.value.radiatorId
    );
    if (radiator.isPlaceholderRadiator()) {
      entitiesStore.deleteEntity(radiator);
    }
  }

  entitiesStore.deleteEntity(toBeDeletedInstallationPoint.value);
  closeModal();
}

function closeModal() {
  isDeleteConfirmationModalOpen.value = false;
  toBeDeletedInstallationPoint.value = {};
}
</script>
