<template>
  <div class="login-container">
    <div class="login-cover">
      <img :src="loginCover" alt="" />
    </div>

    <div class="login-form">
      <div class="logo">
        <img :src="logoUrl" alt="beyonnex-logo" />
      </div>

      <component
        :is="currentFormComponent"
        @login="login()"
        @forget-password="setForgetPasswordForm()"
        @send-recovery-email="sendRecoveryEmail()"
        @go-to-login-form="setLoginForm()"
        @set-new-password="setNewPassword()"
        @validate-change-password="validateChangePassword()"
      ></component>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineAsyncComponent,
  defineComponent,
  inject,
  provide,
  ref,
} from 'vue';

import { User } from '@/models/auth/User';

import { useRouter } from 'vue-router';
import { UserAuth } from '@/models/auth/UserAuth';
import { useUserStore } from '@/store/user/userStore';

import loginCover from '@/assets/img/login/login-bg.jpeg';
import logoUrl from '@/assets/logo.svg';
import { useValidation } from '@/composables/useValidation';
import { ANALYTICS_PROVIDER } from '@/plugins/bxAnalytics';
import ForgetPassword from '@/components/Login/ForgetPassword.vue';
import LoginForm from '@/components/Login/LoginForm.vue';
import ChangePassword from '@/components/Login/ChangePassword.vue';
import ResetPassword from '@/components/Login/ResetPassword.vue';

export default defineComponent({
  name: 'Login',
  setup() {
    const userStore = useUserStore();
    const router = useRouter();

    const userForms = {
      LOGIN_FORM: LoginForm,
      FORGET_PASSWORD: ForgetPassword,
      CHANGE_PASSWORD: ChangePassword,
      RESET_PASSWORD: ResetPassword,
    };

    const user = ref(new User());
    const { v$ } = useValidation({ user });

    const authenticationMessage = ref<UserAuth>(new UserAuth());

    const isLoading = ref<boolean>(false);

    const setAuthenticationMessage = (userAuth: UserAuth) => {
      authenticationMessage.value = userAuth;
      authenticationMessage.value.setMessage(userAuth.message);
    };

    const currentFormComponent = ref(userForms.LOGIN_FORM);
    const setForgetPasswordForm = () => {
      authenticationMessage.value.reset();
      currentFormComponent.value = userForms.FORGET_PASSWORD;
    };
    const setLoginForm = () => {
      authenticationMessage.value.reset();
      currentFormComponent.value = userForms.LOGIN_FORM;
    };
    const setResetCodeForm = () => {
      authenticationMessage.value.reset();
      currentFormComponent.value = userForms.RESET_PASSWORD;
    };
    const setChangePasswordForm = () => {
      authenticationMessage.value.reset();
      currentFormComponent.value = userForms.CHANGE_PASSWORD;
    };

    provide('v$', v$);
    provide('user', user);
    provide('authenticationMessage', authenticationMessage);
    provide('isLoading', isLoading);

    const analytics = inject(ANALYTICS_PROVIDER);

    /*
     * Store dispatch actions
     */
    const login = () => {
      isLoading.value = true;
      userStore.login(user.value).then((authentication: UserAuth) => {
        isLoading.value = false;

        if (authentication.isPasswordChangeRequired()) {
          user.value.password = '';
          setChangePasswordForm();
          return;
        }
        if (authentication.isSuccessful()) {
          analytics?.identifyUser(user.value.username);
          router.push({ name: 'Home' });
        }
        setAuthenticationMessage(authentication);
      });
    };

    const sendRecoveryEmail = () => {
      isLoading.value = true;
      userStore.forgetPassword(user.value).then((response: UserAuth) => {
        isLoading.value = false;
        if (response.isSuccessful()) {
          setResetCodeForm();
        }
        setAuthenticationMessage(response);
      });
    };

    const setNewPassword = () => {
      isLoading.value = true;
      userStore.setNewPassword(user.value).then((response: UserAuth) => {
        isLoading.value = false;
        if (response.isSuccessful()) {
          setLoginForm();
        }
        setAuthenticationMessage(response);
      });
    };

    const validateChangePassword = () => {
      isLoading.value = true;
      userStore
        .changePasswordWithResetCode(user.value)
        .then((response: UserAuth) => {
          isLoading.value = false;
          if (response.isSuccessful()) {
            setLoginForm();
          }
          setAuthenticationMessage(response);
        });
    };

    return {
      login,
      v$,
      user,
      isLoading,
      currentFormComponent,
      authenticationMessage,
      validateChangePassword,
      setForgetPasswordForm,
      setLoginForm,
      sendRecoveryEmail,
      setNewPassword,
      loginCover,
      logoUrl,
    };
  },
});
</script>

<style scoped lang="scss">
.logo {
  position: absolute;
  top: 20px;
  right: 99px;
  width: 200px;
}

.login-container {
  display: flex;
  width: 100%;
  height: 100vh;

  & > div {
    flex: 1;
  }
}
.login-cover {
  & img {
    height: 100vh;
    width: 100%;
    object-fit: cover;
  }
}
.login-form {
  display: flex;
  justify-content: center;
  align-items: center;

  & > form {
    width: 70%;
  }
}
::v-deep(.label) {
  font-size: 16px;
}
::v-deep(.action-button) {
  width: 100%;
  font-size: 16px;
  border-radius: 8px;
  padding: 20px 0;
}
</style>
