import { AnalyticsProvider } from '@/plugins/bxAnalytics/types/AnalyticsProvider';
import { ActionsMap } from '@/plugins/bxAnalytics';

export const actionsMap: ActionsMap = {
  actions: [
    'setActiveMapRoomGroup',
    'changeRoomGroupProgress',
    'startOrder',
    'finishOrder',
    'setBuilding',
  ],

  analyticsFunctions: {
    startOrder: (args: any[], analyticsProvider: AnalyticsProvider) => {
      analyticsProvider.setEventContext({ orderId: args[0] });
      return { eventName: 'start_order' };
    },
    finishOrder: (_args: any[], analyticsProvider: AnalyticsProvider) => {
      const orderId = analyticsProvider.getEventContext()['orderId'];
      analyticsProvider.clearEventContext();
      return {
        eventName: 'close_order',
        properties: { orderId: orderId },
      };
    },
    setActiveMapRoomGroup: (args: any[]) => {
      return {
        eventName: 'select_roomGroup',
        properties: { roomGroupId: args[0] },
      };
    },
    changeRoomGroupProgress: (args: any[]) => {
      return {
        eventName: 'change_roomGroup_progress',
        properties: { roomGroupId: args[0]['id'] },
      };
    },
    setBuilding: (args: any[]) => {
      return {
        eventName: 'set_building',
        properties: { buildingId: args[0]['id'] },
      };
    },
  },
};
