export enum ContactPersonRole {
  CUSTOMER = 'CUSTOMER',
  LANDLORD = 'LANDLORD',
  FACILITY_MANAGER = 'FACILITYMANAGER',
  BUILDING_ADMINISTRATOR = 'BUILDINGADMINISTRATOR',
  ACCOUNTING_MANAGER = 'ACCOUNTINGMANAGER',
  SOMEONE = 'SOMEONE',
}

export function getRoleDisplayName(role: ContactPersonRole): string {
  switch (role) {
    case ContactPersonRole.CUSTOMER:
      return 'Kunde';
    case ContactPersonRole.LANDLORD:
      return 'Vermieter';
    case ContactPersonRole.FACILITY_MANAGER:
      return 'Gebäudemanager';
    case ContactPersonRole.BUILDING_ADMINISTRATOR:
      return 'Gebäudeverwalter';
    case ContactPersonRole.ACCOUNTING_MANAGER:
      return 'Buchhaltungsleiter';
    default:
      return 'Jemand'; // Handle any other unknown roles
  }
}
