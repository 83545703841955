<template>
  <FunkcheckTimer />
  <button
    v-if="!isRunning"
    class="button is-wide is-primary"
    data-testid="startFunkcheckBtn"
    @click="getScanResults()"
  >
    Funkcheck starten
  </button>
</template>

<script lang="ts" setup>
import FunkcheckTimer from './FunkcheckTimer.vue';
import { inject, computed } from 'vue';
import { Notification } from '@/models';
import useStores from '@/composables/useStores';
import { DeviceClasses } from '@/models/devices/Device';

const { notificationStore, entitiesStore, formStore, funkcheckStore } =
  useStores();

const onStepCompletion = inject('onCompletion') as () => void;
const gateway = entitiesStore.getEntityById(
  formStore.lastForm.id as string
) as DeviceClasses;
const gatewaySerialNumber = gateway.serialNumber;

const isRunning = computed(() => !!funkcheckStore.activeFunkcheck.startedAt);

async function getScanResults() {
  funkcheckStore.updateActiveFunkcheck(undefined, new Date());
  try {
    const result = await funkcheckStore.getScanResults();
    if (result.data.receivedDevices) {
      funkcheckStore.updateDevicesFunkcheckStatus(result.data.receivedDevices);
      notificationStore.addNotification(
        new Notification(
          'In der Funkcheck-Ansicht können Sie die Scan-Ergebnisse einsehen',
          'Der Funkcheck war erfolgreich 🚀',
          'success',
          10_000
        )
      );
      onStepCompletion();
    }
  } catch {
    notificationStore.addNotification(
      new Notification(
        'Es konnte kein Ergebnis gefunden werden.',
        'Etwas ist schief gelaufen',
        'error',
        10_000
      )
    );
    funkcheckStore.updateActiveFunkcheck(undefined, null);
  }
}
</script>
