import axios from 'axios';
import { apiEndpoint } from '@/enums/generic';
import { ImageObjectType } from '@/models/Image';
import { PictureConfirmation } from '@/api/pictures/PictureConfirmation';
import { Image } from '@/models/Image';

const businessEntitiesUrl = apiEndpoint.businessEntitiesUrl;

export const putImage = async function (image: Image) {
  return axios({
    method: 'PUT',
    url: `${businessEntitiesUrl}/${image.businessEntityId}/pictures`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: image,
  }).catch(function (error) {
    return error.response;
  });
};

export const fetchImagesConfirmation = async function (
  businessEntityId: string
): Promise<Array<PictureConfirmation>> {
  try {
    const { data } = await axios.get<PictureConfirmation[]>(
      `${businessEntitiesUrl}/${businessEntityId}/pictures/pictures-confirmation`
    );
    return data;
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const fetchImages = async function (
  businessEntityId: string,
  objectId: string
): Promise<Image[]> {
  const response = await axios({
    method: 'GET',
    url: `${businessEntitiesUrl}/${businessEntityId}/pictures/objects/${objectId}`,
  }).catch((error) => {
    console.error(error.response);
  });
  if (!response || (response.status && response.status >= 400)) {
    return [];
  } else {
    return response.data;
  }
};

export const deleteImages = async function (
  businessEntityId: string,
  imagesToBeDeleted: Array<Image>
) {
  const response = await axios({
    method: 'DELETE',
    url: `${businessEntitiesUrl}/${businessEntityId}/pictures`,
    data: imagesToBeDeleted,
  }).catch((error) => {
    console.error(error.response);
  });
};
