<template>
  <LayoutWrapper>
    <div class="mb-12 mt-24 md:mt-9">
      <div
        class="mb-6 flex flex-col justify-center align-middle md:mb-0 md:flex-row md:justify-between"
      >
        <h1 class="flex self-center md:self-auto">Funkcheck</h1>
        <div>
          <SecondaryButton
            v-if="!isFunkcheckRunning"
            data-testid="fetchLastScanResultBtn"
            label="Vorherige Ergebnisse"
            @click="fetchLastScanResults"
          />
        </div>
      </div>
      <div class="view-container timer">
        <BaseInformation
          v-if="hasNoResult"
          class="ml-2"
          status="warning"
          data-testid="noResultErrorMsg"
          information-text="Die Ergebnisse konnten nicht abgerufen werden. Ein möglicher Grund hierfür ist, dass bisher kein Funkcheck
            stattgefunden hat."
        />

        <div class="is-flex is-justify-content-flex-end">
          <div
            v-if="funkcheckTimestamp"
            class="m-4"
            data-testid="lastFunkcheckTimestamp"
          >
            <span class="has-text-weight-bold"
              >Der letzte Funkcheck hat stattgefunden am:</span
            >
            {{ funkcheckTimestamp }}
          </div>
        </div>
        <DeviceManagement v-if="!isFunkcheckRunning" />
        <div v-else>
          <FunkcheckTimer />
        </div>
      </div>
    </div>
  </LayoutWrapper>
</template>

<script>
import DeviceManagement from '@/components/Funkcheck/DeviceManagement.vue';
import FunkcheckTimer from '@/components/Funkcheck/FunkcheckTimer.vue';
import { useNotificationStore } from '@/store/notifications/notificationStore';
import { Notification } from '@/models';
import { useFunkcheckStore } from '@/store/funkcheck/funkcheckStore';
import LayoutWrapper from '@/components/LayoutWrapper.vue';
import SecondaryButton from '@/components/UI/Button/SecondaryButton.vue';
import BaseInformation from '@/components/UI/Hint/BaseInformation.vue';

export default {
  name: 'Funkcheck',
  components: {
    BaseInformation,
    SecondaryButton,
    LayoutWrapper,
    FunkcheckTimer,
    DeviceManagement,
  },
  setup() {
    const notificationStore = useNotificationStore();
    const funkcheckStore = useFunkcheckStore();

    return { notificationStore, funkcheckStore };
  },
  data() {
    return {
      funkcheckTimestamp: undefined,
      hasNoResult: false,
    };
  },
  computed: {
    isFunkcheckRunning() {
      return !!this.funkcheckStore.activeFunkcheck.startedAt;
    },
  },
  methods: {
    fetchLastScanResults() {
      this.funkcheckStore
        .getLastScanResult()
        .then((result) => {
          if (result.data?.receivedDevices) {
            this.funkcheckTimestamp = new Date(result.data.finishedAt);
            this.funkcheckTimestamp = this.funkcheckTimestamp.toLocaleString();

            this.funkcheckStore.updateDevicesFunkcheckStatus(
              result.data.receivedDevices
            );
          } else if (result.status === 204) {
            this.hasNoResult = true;
          }
        })
        .catch((error) => {
          console.error(error);
          if (error.message === 'NO_GATEWAY_FOUND') {
            this.notificationStore.addNotification(
              new Notification(
                'Bitte installieren Sie zuerst ein Gateway.',
                'Es wurde kein Gateway gefunden.',
                'error',
                10_000
              )
            );
          } else {
            this.notificationStore.addNotification(
              new Notification(
                'Bitte versuchen Sie es später erneut.',
                'Der Service ist aktuell nicht verfügbar.',
                'error',
                10_000
              )
            );
            this.hasNoResult = true;
          }
        });
    },
  },
};
</script>

<style scoped lang="scss">
.view-container {
  width: 100%;
  min-height: 100%;
}

.hint-wrapper {
  border: solid 1px $primary-color;
  padding: 25px;
}
</style>
