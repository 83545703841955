export class Form {
  public name?: string;
  public id?: string;
  public parentId?: string;
  public payload?: any;
  public setHistory?: boolean;

  constructor() {
    this.name = undefined;
    this.id = undefined;
    this.parentId = undefined;
    this.payload = undefined;
    this.setHistory = false;
  }

  setName(name: string) {
    this.name = name;
    return this;
  }

  hasHistory(setHistory: boolean) {
    this.setHistory = setHistory;
    return this;
  }

  setId(id: string) {
    this.id = id;
    return this;
  }

  setPayload(payload: any) {
    this.payload = payload;
    return this;
  }

  setParentId(parentId: string) {
    this.parentId = parentId;
    return this;
  }
}
