<template>
  <div class="grid grid-cols-2 gap-4 md:grid-cols-4">
    <div
      v-for="(image, index) in images"
      :key="index"
      class="relative"
      data-testId="images"
    >
      <button
        class="absolute right-[-7px] top-5 ml-auto inline-flex items-center rounded-full bg-primary-2 px-2 py-1.5 text-base text-white hover:bg-primary-1 hover:text-gray-900 dark:bg-primary-1 dark:text-gray-300 dark:hover:bg-primary-0 dark:hover:text-white"
        aria-label="deleteImage"
        data-testid="deleteImageButton"
        @click="deleteImage(index, image)"
      >
        <em class="mdi mdi-close px-1" />
      </button>
      <img
        :src="image.getImage()"
        alt="image-placeholder"
        :class="['rounded-xl', { 'mb-0': image.keywords?.length > 0 }]"
        :data-testId="`image-${index}`"
        @click="openImage(image)"
      />
      <div
        :class="[
          'mx-2 line-clamp-1 break-words',
          { 'mb-7': image.keywords?.length > 0 },
        ]"
      >
        <span
          v-for="(keyword, index) in image.keywords"
          :key="index"
          class="mr-3 text-sm"
        >
          {{ keyword }}
        </span>
      </div>
    </div>
    <ImageDetails
      v-if="isImageOpen"
      :image="selectedImage"
      :is-image-open="isImageOpen"
      :capture-image-information="captureImageInformation"
      :image-keyword-list="imageKeywordList"
      @update:is-image-open="closeImage"
      @update-image-information="updateImageInformation($event)"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { Image } from '@/models/Image';
import ImageDetails, {
  ImageInformation,
} from '@/components/UI/Image/ImageDetails.vue';
import { useImagesStore } from '@/store/images/imageStore';

interface Props {
  images: Image[];
  captureImageInformation?: boolean;
  imageKeywordList?: string[];
}

const props = withDefaults(defineProps<Props>(), {
  imageKeywordList: undefined,
});

const emit = defineEmits(['update-images', 'update-image-information']);

const imagesStore = useImagesStore();

const isImageOpen = computed(() => {
  return imagesStore.isImageOpen;
});

const selectedImage = computed(() => {
  return imagesStore.image;
});

const openImage = (image: Image) => {
  imagesStore.setImage(image);
  imagesStore.openImage();
};

const closeImage = () => {
  imagesStore.closeImage();
};

const deleteImage = async (index: number, image: Image) => {
  emit('update-images', index);

  if (!image.id) return;
};

const updateImageInformation = (imageInformation: ImageInformation) => {
  emit('update-image-information', imageInformation);
};
</script>

<style scoped></style>
