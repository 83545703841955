/* eslint-disable guard-for-in */
import {
  PPRadiator,
  GSRadiator,
  HWRadiator,
  GGRadiator,
  GRRadiator,
  PGRadiator,
  UnknownRadiator,
} from '@/models/radiators/Radiator';
import { radiatorTypes } from '@/enums/radiator/generic';
import { wizardStepsUnknown } from '@/enums/radiator/Unknown';
import { wizardStepsPP } from '@/enums/radiator/PP';
import { wizardStepsGS } from '@/enums/radiator/GS';
import { wizardStepsHW } from '@/enums/radiator/HW';
import { wizardStepsGG } from '@/enums/radiator/GG';
import { wizardStepsGR } from '@/enums/radiator/GR';
import { wizardStepsPG } from '@/enums/radiator/PG';

function stepStatus(isDone, summary) {
  return {
    isDone,
    summary,
  };
}

function validateManufacturer(radiator) {
  const isDone = true;
  const summary = radiator.manufacturer;
  return stepStatus(isDone, summary);
}

export const getRadiatorStepsStatus = (radiator) => {
  let isDone;
  switch (radiator.radiatorType) {
    case radiatorTypes.HW:
      for (const step in wizardStepsHW) {
        isDone = {
          ...isDone,
          [step]: stepHelperRadiatorHW(radiator)(wizardStepsHW[step]).isDone,
        };
      }
      return isDone;
    case radiatorTypes.GS:
      for (const step in wizardStepsGS) {
        isDone = {
          ...isDone,
          [step]: stepHelperRadiatorGS(radiator)(wizardStepsGS[step]).isDone,
        };
      }
      return isDone;
    case radiatorTypes.PP:
      for (const step in wizardStepsPP) {
        isDone = {
          ...isDone,
          [step]: stepHelperRadiatorPP(radiator)(wizardStepsPP[step]).isDone,
        };
      }
      return isDone;
    case radiatorTypes.GG:
      for (const step in wizardStepsGG) {
        isDone = {
          ...isDone,
          [step]: stepHelperRadiatorGG(radiator)(wizardStepsGG[step]).isDone,
        };
      }
      return isDone;
    case radiatorTypes.GR:
      for (const step in wizardStepsGR) {
        isDone = {
          ...isDone,
          [step]: stepHelperRadiatorGR(radiator)(wizardStepsGR[step]).isDone,
        };
      }
      return isDone;
    case radiatorTypes.PG:
      for (const step in wizardStepsPG) {
        isDone = {
          ...isDone,
          [step]: stepHelperRadiatorPG(radiator)(wizardStepsPG[step]).isDone,
        };
      }
      return isDone;
    case radiatorTypes.UNKNOWN:
      for (const step in wizardStepsUnknown) {
        isDone = {
          ...isDone,
          [step]: stepHelperRadiatorUNKNOWN(radiator)(wizardStepsUnknown[step])
            .isDone,
        };
      }
      return isDone;
  }
};

export const stepHelperRadiatorHW = (radiator) => {
  const {
    PIPE_COUNT,
    DIMENSIONS,
    SPACING,
    PIPE_STRENGTH,
    DISTRIBUTION_CHANNEL_STRENGTH,
    DISTRIBUTION_CHANNEL_SHAPE,
    DISTRIBUTION_CHANNEL_STRUCTURE,
    PIPE_SHAPE,
    PIPE_TOP_VIEW,
    PIPE_CONNECTION,
    PIPE_ROUTING,
    CONNECTION_TYPE,
    MANUFACTURER,
  } = wizardStepsHW;

  if (!(radiator instanceof HWRadiator)) {
    throw new Error('radiator is not of type HW');
  }
  const steps = {
    [PIPE_COUNT]: () => {
      const isDone = radiator.pipeCount != undefined;
      const summary = radiator.pipeCount;
      return stepStatus(isDone, summary);
    },
    [DIMENSIONS]: () => {
      const isDone =
        radiator.height != undefined &&
        radiator.depth != undefined &&
        radiator.length != undefined;
      const summary = `BH: ${radiator.height}mm, BL: ${radiator.length}mm, BT: ${radiator.depth}mm`;
      return stepStatus(isDone, summary);
    },
    [SPACING]: () => {
      const isDone = radiator.spacing != undefined;
      const summary = `${radiator.spacing}mm`;
      return stepStatus(isDone, summary);
    },
    [PIPE_STRENGTH]: () => {
      const isDone =
        radiator.pipeStrength.min != undefined &&
        radiator.pipeStrength.max != undefined;

      const summary = `min: ${radiator.pipeStrength.min}, max: ${radiator.pipeStrength.max}`;
      return stepStatus(isDone, summary);
    },
    [DISTRIBUTION_CHANNEL_STRENGTH]: () => {
      const isDone =
        radiator.distributionChannelStrength.min != undefined &&
        radiator.distributionChannelStrength.max != undefined;

      const summary = `min: ${radiator.distributionChannelStrength.min}, max: ${radiator.distributionChannelStrength.max}`;
      return stepStatus(isDone, summary);
    },
    [DISTRIBUTION_CHANNEL_SHAPE]: () => {
      const isDone = radiator.distributionChannelShape != undefined;
      const summary = radiator.distributionChannelShape;
      return stepStatus(isDone, summary);
    },
    [DISTRIBUTION_CHANNEL_STRUCTURE]: () => {
      const isDone = radiator.distributionChannelStructure != undefined;
      const summary = radiator.distributionChannelStructure;
      return stepStatus(isDone, summary);
    },
    [PIPE_SHAPE]: () => {
      const isDone = radiator.pipeShape != undefined;
      const summary = radiator.pipeShape;
      return stepStatus(isDone, summary);
    },
    [PIPE_TOP_VIEW]: () => {
      const isDone = radiator.pipeTopView != undefined;
      const summary = radiator.pipeTopView;
      return stepStatus(isDone, summary);
    },
    [PIPE_CONNECTION]: () => {
      const isDone = radiator.pipeConnection != undefined;
      const summary = radiator.pipeConnection;
      return stepStatus(isDone, summary);
    },
    [PIPE_ROUTING]: () => {
      const isDone = radiator.pipeRouting != undefined;
      const summary = radiator.pipeRouting;
      return stepStatus(isDone, summary);
    },
    [CONNECTION_TYPE]: () => {
      const isDone = radiator.connectionType != undefined;
      const summary = radiator.connectionType;
      return stepStatus(isDone, summary);
    },
    [MANUFACTURER]: () => validateManufacturer(radiator),
  };
  return (wizardStep) => {
    return steps[wizardStep]();
  };
};

export const stepHelperRadiatorPP = (radiator) => {
  const {
    HAS_COVER,
    DIMENSIONS,
    SPACING_AND_PANEL_THICKNESS,
    LAMELLA_DEPTH,
    LAYERS,
    LAMELLA_SHAPE,
    LAMELLA_CONNECTION,
    CONNECTION_TYPE,
    MANUFACTURER,
    PROFILE_SHAPE,
  } = wizardStepsPP;

  if (!(radiator instanceof PPRadiator)) {
    throw new Error('radiator is not of type PP');
  }
  const steps = {
    [HAS_COVER]: () => {
      const isDone = radiator.hasCover != undefined;
      const summary = radiator.hasCover
        ? 'Mit Verkleidung'
        : 'Ohne Verkleidung';
      return stepStatus(isDone, summary);
    },
    [DIMENSIONS]: () => {
      const isDone = () => {
        if (
          radiator.height == undefined ||
          radiator.length == undefined ||
          radiator.depth.withoutCoverPanel == undefined
        ) {
          return false;
        }

        if (
          radiator.hasCoverPanel &&
          radiator.depth.withCoverPanel == undefined
        ) {
          return false;
        }

        if (
          !radiator.hasCoverPanel &&
          radiator.depth.withCoverPanel !== undefined
        ) {
          return false;
        }

        return true;
      };

      const summary = radiator.hasCoverPanel
        ? `BH: ${radiator.height}mm, BL: ${radiator.length}mm, BT(ohne AB): ${radiator.depth.withoutCoverPanel}mm, BT(mit AB): ${radiator.depth.withCoverPanel}mm`
        : `BH: ${radiator.height}mm, BL: ${radiator.length}mm, BT(ohne AB): ${radiator.depth.withoutCoverPanel}mm`;
      return stepStatus(isDone(), summary);
    },
    [SPACING_AND_PANEL_THICKNESS]: () => {
      const isDone =
        radiator.spacing != undefined && radiator.panelThickness != undefined;
      const summary = `T: ${radiator.spacing}mm PD: ${radiator.panelThickness}mm`;
      return stepStatus(isDone, summary);
    },
    [LAMELLA_DEPTH]: () => {
      const isDone = radiator.lamellaDepth != undefined;
      const summary = `LT: ${radiator.lamellaDepth}mm`;
      return stepStatus(isDone, summary);
    },
    [LAYERS]: () => {
      const isDone = radiator.layers.length > 0;
      const summary = radiator.layers.join('');
      return stepStatus(isDone, summary);
    },
    [LAMELLA_SHAPE]: () => {
      const isDone = radiator.lamellaShape != undefined;
      const summary = radiator.lamellaShape;
      return stepStatus(isDone, summary);
    },
    [LAMELLA_CONNECTION]: () => {
      const isDone = radiator.lamellaConnection != undefined;
      const summary = radiator.lamellaConnection;
      return stepStatus(isDone, summary);
    },
    [CONNECTION_TYPE]: () => {
      const isDone = radiator.connectionType != undefined;
      const summary = radiator.connectionType;
      return stepStatus(isDone, summary);
    },
    [MANUFACTURER]: () => validateManufacturer(radiator),
    [PROFILE_SHAPE]: () => {
      const isDone =
        radiator.profileShape.distributionChannel != undefined &&
        radiator.profileShape.transition != undefined;
      const summary = `${radiator.profileShape.distributionChannel}${radiator.profileShape.transition}`;
      return stepStatus(isDone, summary);
    },
  };
  return (wizardStep) => {
    return steps[wizardStep]();
  };
};

export const stepHelperRadiatorGS = (radiator) => {
  const {
    ELEMENT_COUNT,
    DIMENSIONS,
    SPACING,
    WATER_CHAMBER_WIDTH,
    COLUMN_SHAPE,
    CONNECTION_TYPE,
    BACK_PLATE,
    MANUFACTURER,
  } = wizardStepsGS;

  if (!(radiator instanceof GSRadiator)) {
    throw new Error('radiator is not of type GS');
  }
  const steps = {
    [ELEMENT_COUNT]: () => {
      const isDone = radiator.elementCount != undefined;
      const summary = radiator.elementCount;
      return stepStatus(isDone, summary);
    },
    [DIMENSIONS]: () => {
      const isDone =
        radiator.height != undefined &&
        radiator.depth != undefined &&
        radiator.length != undefined;
      const summary = `BH: ${radiator.height}mm, BL: ${radiator.length}mm, BT: ${radiator.depth}mm`;
      return stepStatus(isDone, summary);
    },
    [SPACING]: () => {
      const isDone = radiator.spacing != undefined;
      const summary = `${radiator.spacing}mm`;
      return stepStatus(isDone, summary);
    },
    [WATER_CHAMBER_WIDTH]: () => {
      const isDone = radiator.waterChamberWidth != undefined;
      const summary = `${radiator.waterChamberWidth}mm`;
      return stepStatus(isDone, summary);
    },
    [COLUMN_SHAPE]: () => {
      const isDone = radiator.columnShape != undefined;
      const summary = radiator.columnShape;
      return stepStatus(isDone, summary);
    },
    [CONNECTION_TYPE]: () => {
      const isDone = radiator.connectionType != undefined;
      const summary = radiator.connectionType;
      return stepStatus(isDone, summary);
    },
    [BACK_PLATE]: () => {
      const isDone = radiator.backPlate != undefined;
      const summary = radiator.backPlate;
      return stepStatus(isDone, summary);
    },
    [MANUFACTURER]: () => validateManufacturer(radiator),
  };
  return (wizardStep) => {
    return steps[wizardStep]();
  };
};

export const stepHelperRadiatorGG = (radiator) => {
  const {
    DIMENSIONS,
    SPACING,
    ELEMENT_COUNT,
    WATER_CHAMBER_WIDTH,
    COLUMN_COUNT,
    COLUMN_SHAPE,
    COLUMN_TYPE,
    DECORATION,
    CONNECTION_TYPE,
    MANUFACTURER,
  } = wizardStepsGG;

  if (!(radiator instanceof GGRadiator)) {
    throw new Error('radiator is not of type GG');
  }
  const steps = {
    [ELEMENT_COUNT]: () => {
      const isDone = radiator.elementCount != undefined;
      const summary = radiator.elementCount;
      return stepStatus(isDone, summary);
    },
    [DIMENSIONS]: () => {
      const isDone =
        radiator.height != undefined &&
        radiator.depth != undefined &&
        radiator.length != undefined;
      const summary = `BH: ${radiator.height}mm, BL: ${radiator.length}mm, BT: ${radiator.depth}mm`;
      return stepStatus(isDone, summary);
    },
    [SPACING]: () => {
      const isDone = radiator.spacing != undefined;
      const summary = `${radiator.spacing}mm`;
      return stepStatus(isDone, summary);
    },
    [WATER_CHAMBER_WIDTH]: () => {
      const isDone = radiator.waterChamberWidth != undefined;
      const summary = `${radiator.waterChamberWidth}mm`;
      return stepStatus(isDone, summary);
    },
    [COLUMN_SHAPE]: () => {
      const isDone = radiator.columnShape != undefined;
      const summary = radiator.columnShape;
      return stepStatus(isDone, summary);
    },
    [COLUMN_COUNT]: () => {
      const isDone = radiator.columnCount != undefined;
      const summary = radiator.columnCount;
      return stepStatus(isDone, summary);
    },
    [CONNECTION_TYPE]: () => {
      const isDone = radiator.connectionType != undefined;
      const summary = radiator.connectionType;
      return stepStatus(isDone, summary);
    },
    [MANUFACTURER]: () => validateManufacturer(radiator),
    [DECORATION]: () => {
      const isDone = radiator.decoration != undefined;
      const summary = radiator.decoration;
      return stepStatus(isDone, summary);
    },
    [COLUMN_TYPE]: () => {
      const isDone = radiator.columnType != undefined;
      const summary = radiator.columnType;
      return stepStatus(isDone, summary);
    },
  };
  return (wizardStep) => {
    return steps[wizardStep]();
  };
};

export const stepHelperRadiatorGR = (radiator) => {
  const {
    DIMENSIONS,
    SPACING,
    PIPE_STRENGTH,
    TYPE_KEY,
    DISTRIBUTION_CHANNEL_SHAPE,
    PIPE_SHAPE,
    PIPE_INNER_SHAPE,
    ELEMENT_COUNT,
    MOUNTING_VARIANT,
    CONNECTION_TYPE,
    MANUFACTURER,
  } = wizardStepsGR;

  if (!(radiator instanceof GRRadiator)) {
    throw new Error('radiator is not of type GR');
  }
  const steps = {
    [ELEMENT_COUNT]: () => {
      const isDone = radiator.elementCount != undefined;
      const summary = radiator.elementCount;
      return stepStatus(isDone, summary);
    },
    [DIMENSIONS]: () => {
      const isDone =
        radiator.height != undefined &&
        radiator.depth != undefined &&
        radiator.length != undefined;
      const summary = `BH: ${radiator.height}mm, BL: ${radiator.length}mm, BT: ${radiator.depth}mm`;
      return stepStatus(isDone, summary);
    },
    [SPACING]: () => {
      const isDone = radiator.spacing != undefined;
      const summary = `${radiator.spacing}mm`;
      return stepStatus(isDone, summary);
    },
    [PIPE_STRENGTH]: () => {
      const isDone =
        radiator.pipeStrength.min != undefined &&
        radiator.pipeStrength.max != undefined;
      const summary = `min: ${radiator.pipeStrength.min}, max: ${radiator.pipeStrength.max}`;
      return stepStatus(isDone, summary);
    },
    [TYPE_KEY]: () => {
      const isDone = radiator.typeKey != undefined;
      const summary = radiator.typeKey;
      return stepStatus(isDone, summary);
    },
    [DISTRIBUTION_CHANNEL_SHAPE]: () => {
      const isDone = radiator.distributionChannelShape != undefined;
      const summary = radiator.distributionChannelShape;
      return stepStatus(isDone, summary);
    },
    [MANUFACTURER]: () => validateManufacturer(radiator),
    [PIPE_INNER_SHAPE]: () => {
      const isDone = radiator.pipeInnerShape != undefined;
      const summary = radiator.pipeInnerShape;
      return stepStatus(isDone, summary);
    },
    [PIPE_SHAPE]: () => {
      const isDone = radiator.pipeShape != undefined;
      const summary = radiator.pipeShape;
      return stepStatus(isDone, summary);
    },
    [MOUNTING_VARIANT]: () => {
      const isDone = radiator.mountingVariant != undefined;
      const summary = radiator.mountingVariant;
      return stepStatus(isDone, summary);
    },
    [CONNECTION_TYPE]: () => {
      const isDone = radiator.connectionType != undefined;
      const summary = radiator.connectionType;
      return stepStatus(isDone, summary);
    },
  };
  return (wizardStep) => {
    return steps[wizardStep]();
  };
};

export const stepHelperRadiatorPG = (radiator) => {
  if (!(radiator instanceof PGRadiator)) {
    throw new Error('radiator is not of type PG');
  }

  const {
    DIMENSIONS,
    SPACING_AND_PANEL_THICKNESS,
    LAMELLA_DEPTH,
    LAMELLA_SHAPE,
    LAMELLA_CONNECTION,
    LAYERS,
    PROFILE_SHAPE,
    HAS_COVER,
    ROTATED_90_DEGREES,
    GAP_PARAMETER,
    CONNECTION_TYPE,
    MANUFACTURER,
  } = wizardStepsPG;

  const steps = {
    [DIMENSIONS]: () => {
      const isDone =
        radiator.height != undefined &&
        radiator.depth != undefined &&
        radiator.length != undefined;
      const summary = `BH: ${radiator.height}mm, BL: ${radiator.length}mm, BT: ${radiator.depth}mm`;
      return stepStatus(isDone, summary);
    },
    [SPACING_AND_PANEL_THICKNESS]: () => {
      const isDone =
        radiator.spacing != undefined && radiator.panelThickness != undefined;
      const summary = `T: ${radiator.spacing}mm PD: ${radiator.panelThickness}mm`;
      return stepStatus(isDone, summary);
    },
    [LAMELLA_DEPTH]: () => {
      const isDone = radiator.lamellaDepth != undefined;
      const summary = `${radiator.lamellaDepth}mm`;
      return stepStatus(isDone, summary);
    },
    [LAMELLA_SHAPE]: () => {
      const isDone = radiator.lamellaShape != undefined;
      const summary = radiator.lamellaShape;
      return stepStatus(isDone, summary);
    },
    [LAMELLA_CONNECTION]: () => {
      const isDone = radiator.lamellaConnection != undefined;
      const summary = radiator.lamellaConnection;
      return stepStatus(isDone, summary);
    },
    [LAYERS]: () => {
      const isDone = radiator.layers.length > 0;
      const summary = radiator.layers.join('');
      return stepStatus(isDone, summary);
    },
    [PROFILE_SHAPE]: () => {
      const isDone =
        radiator.profileShape.distributionChannel != undefined &&
        radiator.profileShape.transition != undefined;
      const summary = `${radiator.profileShape.distributionChannel}${radiator.profileShape.transition}`;
      return stepStatus(isDone, summary);
    },
    [HAS_COVER]: () => {
      const isDone = radiator.hasCover != undefined;
      const summary = radiator.hasCover
        ? 'Mit Verkleidung'
        : 'Ohne Verkleidung';
      return stepStatus(isDone, summary);
    },
    [ROTATED_90_DEGREES]: () => {
      const isDone = radiator.rotated90degrees != undefined;
      const summary = radiator.rotated90degrees
        ? '90 Grad gedreht'
        : 'nicht gedreht';
      return stepStatus(isDone, summary);
    },
    [GAP_PARAMETER]: () => {
      const isDone = radiator.gapParameter != undefined;
      const summary = radiator.gapParameter;
      return stepStatus(isDone, summary);
    },
    [CONNECTION_TYPE]: () => {
      const isDone = radiator.connectionType != undefined;
      const summary = radiator.connectionType;
      return stepStatus(isDone, summary);
    },
    [MANUFACTURER]: () => validateManufacturer(radiator),
  };

  return (wizardStep) => {
    return steps[wizardStep]();
  };
};

export const stepHelperRadiatorUNKNOWN = (radiator) => {
  const { DIMENSIONS, NOTE } = wizardStepsUnknown;

  if (!(radiator instanceof UnknownRadiator)) {
    throw new Error('radiator is not of type UNKNOWN');
  }
  const steps = {
    [DIMENSIONS]: () => {
      const isDone =
        radiator.height != undefined &&
        radiator.length != undefined &&
        radiator.depth != undefined;
      const summary = `BH: ${radiator.height}mm, BL: ${radiator.length}mm, BT: ${radiator.depth}mm`;
      return stepStatus(isDone, summary);
    },
    [NOTE]: () => {
      const isDone = radiator.note !== undefined && radiator.note !== '';
      const summary = radiator.note;
      return stepStatus(isDone, summary);
    },
  };
  return (wizardStep) => {
    return steps[wizardStep]();
  };
};
