export function buildPLTFromDeviceList(
  deviceList: Array<{ serialNumber: number }>
): string {
  const objectKeys = [
    'Number',
    'Manufacturer ID',
    'Device ID',
    'Fabrication ID',
    'Version',
    'Device Type',
  ];
  let result = deviceList
    .map((element: any, index: number) => {
      return objectKeys
        .map((propertyName) => {
          switch (propertyName) {
            case 'Number':
              return index + 1;
            case 'Manufacturer ID':
              return 'QDS';
            case 'Device ID':
              return element['serialNumber'];
            default:
              return '*';
          }
        })
        .join('\t');
    })
    .join('\n');

  const header = '[PLANT]\n' + objectKeys.join('\t');
  result = [header, result].join('\n');
  return result;
}
