import { registerPlugin } from '@capacitor/core';
import { RadiatorIdentification } from '@/native/plugins/RadiatorIdentification/definitions';

const RadiatorIdentification = registerPlugin<RadiatorIdentification>(
  'RadiatorIdentification',
  {
    web: () => import('./web').then((m) => new m.RadiatorIdentificationWeb()),
  }
);

export * from './definitions';
export { RadiatorIdentification };
