import { Notification } from '@/models/Notification';
import { Pinia, Store } from '@/store/pinia-class-component';
import { Notifications } from '@/native/plugins/Notifications';

export function useNotificationStore() {
  return new NotificationStore();
}

@Store
class NotificationStore extends Pinia {
  private _notifications: Array<Notification>;

  constructor() {
    super();
    this._notifications = [];
  }

  get notifications(): Array<Notification> {
    return this._notifications;
  }

  removeNotification(id: string) {
    this._notifications.splice(
      this._notifications.findIndex((notification) => notification.id === id),
      1
    );
  }

  addNotification(notification: Notification) {
      Notifications.displayNotification(notification, this);
  }
}
