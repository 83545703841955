import { Room, Roomgroup } from '@/models';
import { getAllEntitiesByCriteria } from '@/store/entities/helper';
import { entityTypes } from '@/enums/generic';
import { useEntitiesStore } from '@/store/entities/entitiesStore';
import { Store } from '@/store/pinia-class-component';
import { useRoomStore } from '@/store/entities/roomStore';
import { miniEntityStore } from '@/store/entities/types/miniEntityStore';
import { useInstallationPointStore } from '@/store/entities/installationPointStore';

export function useRoomGroupStore() {
  return new RoomGroupStore();
}

@Store
export class RoomGroupStore extends miniEntityStore {
  private _roomGroups: Map<string, Roomgroup>;
  private _activeRoomGroupId = '';

  constructor() {
    super();
    this._roomGroups = new Map();
    this._activeRoomGroupId = '';
  }

  get roomGroups(): Map<string, Roomgroup> {
    return this._roomGroups;
  }

  get activeRoomGroupId(): string {
    return this._activeRoomGroupId;
  }

  resetState() {
    this._roomGroups = new Map();
    this._activeRoomGroupId = '';
  }

  getStores() {
    const roomStore = useRoomStore();
    const entitiesStore = useEntitiesStore();

    return {
      roomStore,
      entitiesStore,
    };
  }

  get getCurrentRoomGroup() {
    return this._roomGroups.get(this._activeRoomGroupId);
  }

  get uniqueRoomGroupNumber() {
    const roomGroupsNumber = new Map();
    this._roomGroups.forEach((roomGroup) => {
      if (roomGroup.number) {
        roomGroupsNumber.set(+roomGroup.number, roomGroup.id);
      }
    });
    return roomGroupsNumber;
  }

  get uniqueDeviceReference() {
    const deviceReference = new Map();

    this._roomGroups.forEach((roomGroup) => {
      if (roomGroup.deviceReference) {
        deviceReference.set(roomGroup.deviceReference, roomGroup.id);
      }
    });
    return deviceReference;
  }

  isDeviceReferenceDuplicated(roomGroup: Roomgroup) {
    const deviceReferenceId = this.uniqueDeviceReference.get(
      roomGroup.deviceReference
    );
    return !!(deviceReferenceId && deviceReferenceId !== roomGroup.id);
  }

  isRoomGroupNumberDuplicated(roomGroup: Roomgroup) {
    const roomGroupNumberId = this.uniqueRoomGroupNumber.get(roomGroup.number);
    return !!(roomGroupNumberId && roomGroupNumberId !== roomGroup.id);
  }

  /*
   * Actions
   */
  addEntity(newRoomGroup: Roomgroup): void {
    this._roomGroups.set(newRoomGroup.id, newRoomGroup);
  }

  deleteEntity(roomGroup: Roomgroup) {
    if (!roomGroup.id) {
      throw new Error(`Cannot delete roomgroup without a valid id`);
    }

    if (roomGroup.type !== entityTypes.roomgroup) {
      throw new Error(
        `Cannot delete an entity that is not a roomgroup, entity: ${roomGroup.type}`
      );
    }

    if (
      this.getStores().entitiesStore.getAllEntitiesByParentId(roomGroup.id)
        .length > 1
    ) {
      throw new Error(
        `Cannot delete a roomgroup with more than 1 room, children: ${
          this.getStores().entitiesStore.getAllEntitiesByParentId(roomGroup.id)
            .length
        }`
      );
    }

    const firstRoomOfRoomGroup =
      this.getStores().roomStore.getFirstRoomOfRoomgroup(roomGroup.id);
    if (firstRoomOfRoomGroup) {
      this.getStores().entitiesStore.deleteEntity(firstRoomOfRoomGroup);
    }
    this._roomGroups.delete(roomGroup.id);
  }

  getRoomGroupsByBuildingId(buildingId: string) {
    return getAllEntitiesByCriteria(
      this._roomGroups,
      (node: Roomgroup) => node.buildingId === buildingId
    );
  }

  getNewRoomgroupOrdinal(parentId: string) {
    const entity = this.getStores().entitiesStore.getEntityById(parentId);

    if (entity.type === entityTypes.building) {
      return 1;
    }

    const children = this.getStores().roomStore.getChildrenOfRoom(
      entity.id
    ) as Array<Room>;

    const siblings = [];

    children.forEach((child) => {
      if (
        entity &&
        entity.roomGroupId &&
        entity.floorLevel.type === child.floorLevel.type
      ) {
        siblings.push(child);
      }
    });

    return siblings.length + 1;
  }

  isRoomgroupEmpty(roomgroup: Roomgroup) {
    const firstRoomOfRoomGroup =
      this.getStores().roomStore.getFirstRoomOfRoomgroup(roomgroup.id);
    if (!firstRoomOfRoomGroup) {
      throw new Error('First room of roomgroup is undefined');
    } else {
      const childrenOfFirstRoom =
        this.getStores().entitiesStore.getAllEntitiesByParentId(
          firstRoomOfRoomGroup.id
        );

      return childrenOfFirstRoom.length === 0;
    }
  }

  swapRoomGroup(roomGroupSource: Roomgroup, roomGroupTarget: Roomgroup) {
    if (roomGroupSource.type !== entityTypes.roomgroup) {
      throw new Error('Invoked SWAP_Roomgroup: entity is not a roomgroup');
    }

    const entitiesStore = useEntitiesStore();

    const sourceOrdinal = roomGroupSource.ordinal;

    roomGroupSource.ordinal = roomGroupTarget.ordinal;
    roomGroupTarget.ordinal = sourceOrdinal;

    entitiesStore.saveEntity(roomGroupSource);
    entitiesStore.saveEntity(roomGroupTarget);
  }

  setActiveMapRoomGroup(roomgroupId: string) {
    if (!this._roomGroups.get(roomgroupId)) {
      throw new Error(`Can only set a roomgroup to be active in the map view`);
    }
    this._activeRoomGroupId = roomgroupId;
  }

  isRoomGroupInspectionPlaceholder(roomGroupId: string): boolean {
    return (
      useInstallationPointStore().getInspectionPlaceholders({
        roomGroupId: roomGroupId,
      }).length > 0
    );
  }
}
