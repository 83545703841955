<template>
  <div v-if="!isMinimal" class="flex justify-between px-5 pt-5">
    <div class="flex flex-row gap-4">
      <OptionsDropdown width="w-[20em]" class="relative">
        <template #toggle="{ onClick }">
          <button
            data-testid="optionsDropdown"
            class="flex flex-row items-center gap-1 rounded-lg border bg-white px-3 py-1.5 font-medium hover:bg-light-3 dark:border-dark-4 dark:bg-dark-3 dark:hover:border-dark-5 dark:hover:bg-dark-3 dark:hover:bg-dark-5"
            @click="onClick"
          >
            <em class="mdi mdi-filter-variant" />
            Status
            <div
              v-if="statusFilter.length > 0"
              class="h-5 w-5 items-center rounded-md bg-dark-5 text-xs leading-relaxed text-white"
            >
              {{ statusFilter.length }}
            </div>
            <em class="mdi mdi-chevron-down" />
          </button>
        </template>
        <li @click="useFunkcheckStore().statusFilter = []">
          <em class="mdi mdi-filter-variant-remove mr-4" />Filter zurücksetzen
        </li>
        <li
          v-for="(status, key) in DeviceRadioCheckStatus"
          :key="key"
          class="flex flex-row justify-between"
          @click.stop="filterTable(statusFilter, key)"
        >
          <Checkbox
            :label="status"
            :checked="statusFilter.includes(key)"
            @click.prevent
          />
          <em
            class="mr-3 px-1 text-[1.2em]"
            :class="getDeviceStatusClasses(key)"
          />
        </li>
      </OptionsDropdown>
      <OptionsDropdown width="w-[20em]" class="relative">
        <template #toggle="{ onClick }">
          <button
            data-testid="optionsDropdown"
            class="flex flex-row items-center gap-1 rounded-lg border bg-white px-3 py-1.5 font-medium hover:bg-light-3 dark:border-dark-4 dark:bg-dark-3 dark:hover:border-dark-3 dark:hover:bg-dark-3 dark:hover:bg-dark-5"
            @click="onClick"
          >
            <em class="mdi mdi-filter-variant" />
            Gebäude
            <div
              v-if="buildingFilter.length > 0"
              class="h-5 w-5 items-center rounded-md bg-dark-5 text-xs leading-relaxed text-white"
            >
              {{ buildingFilter.length }}
            </div>
            <em class="mdi mdi-chevron-down" />
          </button>
        </template>

        <li @click="useFunkcheckStore().buildingFilter = []">
          <em class="mdi mdi-filter-variant-remove mr-4" />Filter zurücksetzen
        </li>
        <li
          v-for="(address, index) in buildingAddresses"
          :key="address + '_' + index"
          @click.stop="filterTable(buildingFilter, address)"
        >
          <Checkbox
            :label="address"
            :checked="buildingFilter.includes(address)"
            @click.prevent
          />
        </li>
      </OptionsDropdown>
    </div>

    <BaseInput
      v-model="searchQuery"
      label="Suche"
      class="basis-1/3"
      icon="mdi-search"
    />
  </div>
  <div v-if="!isMinimal" class="flex flex-row justify-between px-5 py-3">
    <div class="flex flex-row gap-2">
      <div
        v-for="status in statusFilter"
        :key="status"
        class="h-10 rounded-xl border border-dark-3 bg-dark-2 px-3 py-1"
      >
        <em class="px-1 text-[1em]" :class="getDeviceStatusClasses(status)" />
        {{ DeviceRadioCheckStatus[status] }}
        <em
          class="mdi mdi-close rounded-full bg-dark-3 px-1"
          @click="filterTable(statusFilter, status)"
        />
      </div>
      <div
        v-for="(building, index) in buildingFilter"
        :key="building + index"
        class="h-10 rounded-xl border border-dark-3 bg-dark-2 px-3 py-1"
      >
        {{ building }}
        <em
          class="mdi mdi-close rounded-full bg-dark-3 px-1"
          @click="filterTable(buildingFilter, building)"
        ></em>
      </div>
    </div>
    <em
      class="mdi mdi-filter-variant-remove p-1"
      @click="useFunkcheckStore().resetAllFilters()"
    ></em>
  </div>

  <table class="m-0">
    <thead>
      <tr>
        <th class="w-[3%]"></th>
        <th class="w-[15%]" @click="sortTable('serialNumber')">
          {{ i18n.label.serialNumber }}
          <em
            v-if="currentSortColumn === 'serialNumber'"
            :class="{
              'mdi mdi-arrow-down': currentSortOrder === 'desc',
              'mdi mdi-arrow-up': currentSortOrder === 'asc',
            }"
          />
        </th>
        <th class="w-[15%]" @click="sortTable('status')">
          {{ i18n.label.status }}
          <em
            v-if="currentSortColumn === 'status'"
            :class="{
              'mdi mdi-arrow-down': currentSortOrder === 'desc',
              'mdi mdi-arrow-up': currentSortOrder === 'asc',
            }"
          />
        </th>
        <th class="w-[2%]"></th>
        <th class="w-[10%]" @click="sortTable('floorLevel')">
          Stockwerk
          <em
            v-if="currentSortColumn === 'floorLevel'"
            :class="{
              'mdi mdi-arrow-down': currentSortOrder === 'desc',
              'mdi mdi-arrow-up': currentSortOrder === 'asc',
            }"
          />
        </th>
        <th class="w-[10%]" @click="sortTable('ordinal')">
          Ordinal
          <em
            v-if="currentSortColumn === 'ordinal'"
            :class="{
              'mdi mdi-arrow-down': currentSortOrder === 'desc',
              'mdi mdi-arrow-up': currentSortOrder === 'asc',
            }"
          />
        </th>
        <th class="w-[10%]" @click="sortTable('roomLabel')">
          Raum
          <em
            v-if="currentSortColumn === 'roomLabel'"
            :class="{
              'mdi mdi-arrow-down': currentSortOrder === 'desc',
              'mdi mdi-arrow-up': currentSortOrder === 'asc',
            }"
          />
        </th>
        <th class="w-[30%]" @click="sortTable('roomgroup')">
          {{ i18n.label.roomgroup }}
          <em
            v-if="currentSortColumn === 'roomgroup'"
            :class="{
              'mdi mdi-arrow-down': currentSortOrder === 'desc',
              'mdi mdi-arrow-up': currentSortOrder === 'asc',
            }"
          />
        </th>
      </tr>
    </thead>
    <tbody v-if="tableItems.length > 0" id="tbody_id">
      <SRTTableRow
        v-for="(device, index) in tableItems"
        :key="device.id"
        v-model:expanded="expanded"
        :device="device"
        :index="index"
        :is-minimal="isMinimal"
      />
    </tbody>
  </table>

  <div
    v-if="!isMinimal"
    class="flex items-center justify-between border-t px-5 dark:border-dark-4"
  >
    <div class="inline-flex w-full items-center text-sm font-normal">
      {{ paginationDisplay }}
    </div>
    <BaseSelect
      v-model="itemsPerPage"
      label="pro Seite"
      type="number"
      :select-list="[
        { name: 10, value: 10 },
        { name: 20, value: 20 },
        { name: 100, value: 100 },
      ]"
    />
    <ul class="inline-flex list-none text-sm">
      <li
        class="m-0 ms-0 flex h-10 items-center justify-center rounded-s-lg border bg-white p-4 hover:bg-light-3 dark:border-dark-3 dark:bg-dark-2 dark:hover:bg-dark-4"
        @click="currentPage > 1 ? currentPage-- : undefined"
      >
        <em class="mdi mdi-chevron-left" />
      </li>
      <li
        v-for="(page, index) in totalPages"
        :key="page"
        :class="{ 'bg-light-0 dark:bg-dark-3': currentPage === index + 1 }"
        class="m-0 flex h-10 items-center justify-center border bg-white p-4 hover:bg-light-3 dark:border-dark-3 dark:bg-dark-2 dark:hover:bg-dark-4"
        @click="currentPage = index + 1"
      >
        {{ index + 1 }}
      </li>
      <li
        class="m-0 flex h-10 items-center justify-center rounded-e-lg border bg-white p-4 hover:bg-light-3 dark:border-dark-3 dark:bg-dark-2 dark:hover:bg-dark-4"
        @click="currentPage < totalPages ? currentPage++ : undefined"
      >
        <em class="mdi mdi-chevron-right" />
      </li>
    </ul>
  </div>
</template>
<script setup lang="ts">
import { computed, ref } from 'vue';
import { useDeviceStore } from '@/store/entities/deviceStore';
import {
  LoRaWanGateway,
  SmartRadiatorThermostat,
} from '@/models/devices/Device';
import BaseSelect from '@/components/UI/Select/BaseSelect.vue';
import Checkbox from '@/components/UI/Input/Checkbox.vue';
import BaseInput from '@/components/UI/Input/BaseInput.vue';
import OptionsDropdown from '@/components/UI/Dropdown/OptionsDropdown.vue';
import i18n from '@/utils/i18n/radiocheck.json';
import { useInstallationPointStore } from '@/store/entities/installationPointStore';
import { useRoomGroupStore } from '@/store/entities/roomGroupStore';
import { useRoomStore } from '@/store/entities/roomStore';
import { DeviceRadioCheckStatus } from '@/enums/radiocheck';
import { useBuildingStore } from '@/store/entities/buildingStore';
import { timeAgo } from '@/helpers/timeAgo';
import { useFunkcheckStore } from '@/store/funkcheck/funkcheckStore';
import SRTTableRow from '@/components/LoRaWanRadioCheck/SRTTableRow.vue';
import {
  DownlinkReceptionQuality,
  RadioCheckResponse,
  UplinkReceptionQuality,
} from '@/api/funkcheck/LoRaWanRadioCheck';

const props = defineProps<{
  isMinimal: boolean;
  radioCheckDevices?: RadioCheckResponse;
}>();

const funkcheckStore = useFunkcheckStore();
const statusFilter = computed(() => funkcheckStore.statusFilter);
const buildingFilter = computed(() => funkcheckStore.buildingFilter);
const searchQuery = ref('');
const currentPage = ref(1);
const itemsPerPage = ref(100);
const currentSortColumn = ref<keyof SRTTableElementType>('status');
const currentSortOrder = ref('asc');

const expanded = ref(undefined);
const devices: SmartRadiatorThermostat[] = useDeviceStore().getSRTs;
const loRaWanGateways: LoRaWanGateway[] = useDeviceStore().getLoRaWanGateways;

const totalPages = computed(() => {
  return Math.ceil(filteredItems.value.length / itemsPerPage.value);
});

const paginationDisplay = computed(() => {
  const totalItems = filteredItems.value.length;
  const startIndex = (currentPage.value - 1) * itemsPerPage.value + 1;
  const endIndex = Math.min(startIndex + itemsPerPage.value - 1, totalItems);
  return `${startIndex} - ${endIndex} von ${totalItems}`;
});

const sortTable = (column: keyof SRTTableElementType) => {
  if (currentSortColumn.value === column) {
    currentSortOrder.value = currentSortOrder.value === 'asc' ? 'desc' : 'asc';
  } else {
    currentSortColumn.value = column;
    currentSortOrder.value = 'asc';
  }
};

const filterTable = (
  filter: Array<string | undefined>,
  value: string | undefined
) => {
  if (filter.includes(value)) {
    const index = filter.indexOf(value);
    filter.splice(index, 1);
  } else {
    filter.push(value);
  }
};

const buildingAddresses = computed(() => {
  return Array.from(useBuildingStore().buildings).map(([key, value]) => {
    return value.shortAddress;
  });
});

const matchesFilter = (
  item: SRTTableElementType,
  filterValues: string[]
): boolean =>
  filterValues.some((filterValue) =>
    Object.values(item).some((value) =>
      String(value).toLowerCase().includes(filterValue?.toLowerCase())
    )
  );

const filterItems = (
  items: Array<SRTTableElementType>
): Array<SRTTableElementType> => {
  return items.filter(
    (item) =>
      (buildingFilter.value.length === 0 ||
        matchesFilter(item, buildingFilter.value)) &&
      (statusFilter.value.length === 0 ||
        matchesFilter(item, statusFilter.value)) &&
      Object.values(item).some((value) =>
        String(value).toLowerCase().includes(searchQuery.value.toLowerCase())
      )
  );
};

const sortItems = (items: SRTTableElementType[]): SRTTableElementType[] => {
  if (!currentSortColumn.value || !currentSortOrder.value) return [...items];

  const modifier = currentSortOrder.value === 'asc' ? 1 : -1;

  return [...items].sort((a, b) => {
    const aValue = a[currentSortColumn.value];
    const bValue = b[currentSortColumn.value];
    if (currentSortColumn.value === 'status') {
      const keys = Object.keys(DeviceRadioCheckStatus);
      if (keys.indexOf(String(aValue)) < keys.indexOf(String(bValue)))
        return -1 * modifier;
      if (keys.indexOf(String(aValue)) > keys.indexOf(String(bValue)))
        return 1 * modifier;
    }

    if (aValue < bValue) return -1 * modifier;
    if (aValue > bValue) return 1 * modifier;

    // Special case for 'floorLevel'
    if (currentSortColumn.value === 'floorLevel') {
      if (a['ordinal'] && b['ordinal']) {
        const ordinalComparison = (a['ordinal'] - b['ordinal']) * modifier;
        if (ordinalComparison !== 0) return ordinalComparison;
      }
    }

    return 0;
  });
};

const paginateItems = (items: SRTTableElementType[]) => {
  const start = (currentPage.value - 1) * itemsPerPage.value;
  const end = start + itemsPerPage.value;
  return items.slice(start, end);
};

const filteredItems = computed(() => {
  return filterItems(mappedItems.value);
});

const tableItems = computed(() => {
  const filtered = filterItems(mappedItems.value);
  const sorted = sortItems(filtered);
  return paginateItems(sorted);
});

const uplinks = (device: SmartRadiatorThermostat) => {
  if (!result(device)) return undefined;

  const uplinksOrderedByRssi: Record<string, UplinkReceptionQuality> =
    Object.entries(result(device)?.uplinkResponses)
      .sort(([, a], [, b]) => {
        if (a.rssi && b.rssi) {
          return b.rssi - a.rssi;
        }
        return !a ? 1 : -1;
      })
      .reduce((acc: Record<string, UplinkReceptionQuality>, [key, val]) => {
        acc[key] = val;
        return acc;
      }, {});

  return uplinksOrderedByRssi;
};

const downlink = (device: SmartRadiatorThermostat) => {
  return {
    rssi: downlinkRssi(device),
    snr: downlinkSnr(device),
    lastReceived: downlinkLastReceived(device),
    status: downlinkStatus(device),
  };
};

const result = (device: SmartRadiatorThermostat) => {
  return props.radioCheckDevices?.[device.id];
};

const downlinkReceptionData = (device: SmartRadiatorThermostat) => {
  return result(device)?.downlinkResponse;
};

const downlinkRssi = (device: SmartRadiatorThermostat) => {
  if (!downlinkReceptionData(device)) return undefined;
  return downlinkReceptionData(device)?.rssi;
};

const downlinkSnr = (device: SmartRadiatorThermostat) => {
  if (!downlinkReceptionData(device)) return undefined;
  return downlinkReceptionData(device)?.snr;
};

const downlinkLastReceived = (device: SmartRadiatorThermostat) => {
  if (!downlinkReceptionData(device)) return undefined;

  const lastReceived = downlinkReceptionData(device).lastReceived;
  if (!lastReceived) return undefined;

  return timeAgo(new Date(Date.parse(lastReceived)));
};

const downlinkStatus = (device: SmartRadiatorThermostat) => {
  if (!downlinkReceptionData(device)) return undefined;
  return downlinkReceptionData(device)?.status;
};

const installationPointRoomGroupId = (device: SmartRadiatorThermostat) => {
  const installationPoint = useInstallationPointStore().installationPoints.get(
    device.installationPointId
  );
  return installationPoint?.billedInRoomGroupId ?? '';
};

const roomGroupLabel = (device: SmartRadiatorThermostat) => {
  const roomGroup = useRoomGroupStore().roomGroups.get(
    installationPointRoomGroupId(device)
  );
  return roomGroup?.getLabel() ?? '';
};

const roomGroupOrdinal = (device: SmartRadiatorThermostat) => {
  const roomGroup = useRoomGroupStore().roomGroups.get(
    installationPointRoomGroupId(device)
  );
  return roomGroup?.ordinal ?? undefined;
};

const buildingAddress = (device: SmartRadiatorThermostat) => {
  const roomGroup = useRoomGroupStore().roomGroups.get(
    installationPointRoomGroupId(device)
  );
  if (!roomGroup?.buildingId) return '';

  const building = useBuildingStore().buildings.get(roomGroup.buildingId);
  return building?.shortAddress ?? '';
};

const floorLevel = (device: SmartRadiatorThermostat) => {
  const roomGroup = useRoomGroupStore().roomGroups.get(
    installationPointRoomGroupId(device)
  );
  if (!roomGroup) return undefined;
  const firstRoom = useRoomStore().getFirstRoomOfRoomgroup(roomGroup.id);
  if (!firstRoom) {
    return;
  }
  return firstRoom.getFloor();
};

const room = (device: SmartRadiatorThermostat) => {
  const installationPoint = useInstallationPointStore().installationPoints.get(
    device.installationPointId
  );
  if (!installationPoint) return undefined;
  return useRoomStore().rooms.get(installationPoint.roomId);
};

export type SRTTableElementType = {
  uuid: string;
  status?: string;
  serialNumber: string;
  floorLevel: string | undefined;
  roomgroup: string;
  roomLabel: string | undefined;
  room: any;
  ordinal: number | undefined;
  roomgroupId: string;
  downlink: DownlinkReceptionQuality;
  uplinks?: Record<string, UplinkReceptionQuality>;
  address: string;
};

const mappedItems = computed(() => {
  const items: Array<SRTTableElementType> = devices.map((device) => {
    return {
      uuid: device.id,
      status: result(device)?.status,
      serialNumber: device.serialNumber,
      floorLevel: floorLevel(device),
      roomgroup: roomGroupLabel(device),
      roomLabel: room(device)?.getLabel(),
      room: room(device),
      ordinal: roomGroupOrdinal(device),
      roomgroupId: installationPointRoomGroupId(device),
      downlink: downlink(device),
      uplinks: uplinks(device),
      address: buildingAddress(device),
    };
  });
  return items;
});

const getDeviceStatusClasses = (status: string) => {
  if (!status) {
    return 'mdi mdi-help radiocheck-status-warning';
  }
  if (status === 'OK') {
    return 'mdi mdi-check radiocheck-status-success';
  }
  if (status === 'NO_MATCHED_GW') {
    return 'mdi mdi-wifi-remove radiocheck-status-error';
  }

  if (status === 'BAD_SIGNAL') {
    return 'mdi mdi-wifi-alert radiocheck-status-error';
  }

  return 'mdi mdi-help radiocheck-status-warning';
};
</script>
