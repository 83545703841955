import {
  DeviceRadioCheckStatus,
  OverallRadioCheckStatus,
  ReceptionQualityStatus,
} from '@/enums/radiocheck';
import {
  LoRaWanGateway,
  SmartRadiatorThermostat,
} from '@/models/devices/Device';

export type RadioCheckPayload = {
  gateways: Array<LoRaWanGateway>;
  srts: Array<SmartRadiatorThermostat>;
  runId?: number;
};

export type EchoResult = {
  runId: number;
  notFoundSrts: Array<string>;
};

export class LoRaWanRadioCheck {
  public status?: OverallRadioCheckStatus;
  public result?: RadioCheckResponse;

  constructor() {
    this.status = undefined;
    this.result = undefined;
  }
}

export interface RadioCheckResponse {
  [key: string]: RadioCheckResult;
}

export interface RadioCheckResult {
  status: DeviceRadioCheckStatus;
  uplinkResponses: Record<string, UplinkReceptionQuality>;
  downlinkResponse: DownlinkReceptionQuality;
}

export class ReceptionQuality {
  public rssi?: number;
  public snr?: number;
  public status?: ReceptionQualityStatus;

  constructor() {
    this.rssi = undefined;
    this.snr = undefined;
    this.status = undefined;
  }
}

export class UplinkReceptionQuality extends ReceptionQuality {
  public receiveTime?: string;
  public spreadingFactor?: number;

  constructor() {
    super();
    this.receiveTime = undefined;
    this.spreadingFactor = undefined;
  }
}

export class DownlinkReceptionQuality extends ReceptionQuality {
  public lastReceived?: string;

  constructor() {
    super();
    this.lastReceived = undefined;
  }
}
