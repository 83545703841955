import type { Plugin } from '@capacitor/core';
export interface BarcodeScannerPlugin extends Plugin {
  start(payload: { label: string | undefined }): Promise<ScanResult>;
}

export type CallbackID = string;

export enum ScanCodeFormat {
  ALL = 'ALL',
  CODE_128 = 'CODE_128',
  CODE_39 = 'CODE_39',
  CODE_93 = 'CODE_93',
  CODA_BAR = 'CODA_BAR',
  DATA_MATRIX = 'DATA_MATRIX',
  EAN_13 = 'EAN_13',
  EAN_8 = 'EAN_8',
  ITF = 'ITF',
  QR_CODE = 'QR_CODE',
  UPC_A = 'UPC_A',
  UPC_E = 'UPC_E',
  PDF_417 = 'PDF_417',
  AZTEC = 'AZTEC',
}

export interface ScanResult {
  content: string;
  format: string;
  contentType: string;
  hasContent: boolean;
}
