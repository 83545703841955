<template>
  <div class="flex flex-col">
    <div class="flex flex-row justify-between font-bold">
      <div class="self-center">{{ deviceType.label }}</div>
      <TertiaryButton
        v-if="isAddButtonVisible"
        :label="`${deviceType.shortLabel} hinzufügen`"
        icon="mdi-plus"
        data-testid="addHKVButton"
        @click="createDevice"
      />
    </div>

    <div v-if="devices.length > 0">
      <div
        v-for="device in devices"
        :key="device.id"
        class="flex flex-row justify-between border-t py-2 dark:border-dark-5"
      >
        <div class="flex flex-row justify-center align-middle">
          <div class="mr-3 self-center">
            <em class="mdi mdi-cellphone" />
          </div>
          <div class="mr-3">
            <div class="has-text-weight-bold">S/Nr</div>
            <div class="has-text-weight-bold">EAN</div>
          </div>
          <div>
            <div>{{ device.serialNumber }}</div>
            <div>{{ device.productKey }}</div>
          </div>
        </div>
        <div class="relative flex flex-col justify-center">
          <OptionsDropdown
            icon="mdi-dots-vertical"
            direction-x="left"
            direction="down"
            :data-testid="`radiatorDropdownButton_${device.serialNumber}`"
          >
            <li
              :data-testid="`editDropdownItem_${device.serialNumber}`"
              @click="editDevice(device)"
            >
              <em class="mdi mdi-pencil mr-2" />Bearbeiten
            </li>
            <li
              :data-testid="`deleteDropdownItem_${
                device?.type === 'radiator'
                  ? device.radiatorType
                  : device.serialNumber
              }`"
              @click="openDeleteDeviceConfirmationModal(device)"
            >
              <em class="mdi mdi-delete mr-2" />Löschen
            </li>
          </OptionsDropdown>
        </div>
      </div>
    </div>
  </div>
  <BasePrompt
    v-if="isDeleteConfirmationModalOpen"
    :open="isDeleteConfirmationModalOpen"
    :close="closeModal"
    :cancel="closeModal"
    :proceed="deleteDevice"
    title="Gerät löschen"
    question="Bist du sicher, dass du dieses Gerät löschen möchtest?"
  />
  <BasePrompt
    :open="promptOpen"
    :close="togglePrompt"
    :cancel="togglePrompt"
    :proceed="onIdentifyRadiator"
    title="Bevor diesem Heizkörper ein HKV hinzugefügt werden kann, muss dieser identifiziert werden."
    question="Möchten Sie den Heizkörper nun identifizieren?"
  />
</template>

<script lang="ts">
import { Radiator } from '@/models/radiators/Radiator';
import { computed, defineComponent, ref } from 'vue';
import { entityTypes } from '@/enums/generic';
import { HeatCostAllocator, SmartRadiatorThermostat } from '@/models/devices';
import { deviceTypeDetails, DeviceTypeEnum, deviceTypes } from '@/enums/device';
import { useEntitiesStore } from '@/store/entities/entitiesStore';
import { useFormStore } from '@/store/form/formStore';
import { Form } from '@/models/Form';
import BasePrompt from '@/components/UI/Modal/BasePrompt.vue';
import TertiaryButton from '@/components/UI/Button/TertiaryButton.vue';
import { InstallationPoint } from '@/models/installationPoint/InstallationPoint';
import OptionsDropdown from '@/components/UI/Dropdown/OptionsDropdown.vue';
import { useDeviceStore } from '@/store/entities/deviceStore';

export default defineComponent({
  name: 'RadiatorCardDeviceList',
  components: {
    OptionsDropdown,
    TertiaryButton,
    BasePrompt,
  },
  props: {
    deviceType: {
      type: Object,
      required: true,
    },
    devices: {
      type: Array,
      required: true,
    },
    radiator: { type: Radiator, required: true },
    parentId: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const entitiesStore = useEntitiesStore();
    const formStore = useFormStore();

    const isDeleteConfirmationModalOpen = ref(false);
    const toBeDeletedInstallationPoint = ref<InstallationPoint | undefined>(
      undefined
    );

    const createDevice = () => {
      if (isPlaceholderRadiator.value && isHcaDevice.value) {
        togglePrompt();
        return;
      }
      formStore.createEntity(
        new Form()
          .setName(entityTypes.installationPoint)
          .setParentId(props.parentId)
          .setPayload({
            deviceType: props.deviceType.value,
            radiatorId: props.radiator.id,
            new: true,
          })
      );
    };

    const isPlaceholderRadiator = computed(() => {
      return props.radiator.isPlaceholderRadiator();
    });

    const isHcaDevice = computed(() => {
      return props.deviceType.value === deviceTypes.HeatCostAllocator;
    });

    const promptOpen = ref(false);
    const togglePrompt = () => {
      promptOpen.value = !promptOpen.value;
    };
    const onIdentifyRadiator = () => {
      formStore.editEntity(props.radiator, props.parentId, {}, true);
      togglePrompt();
    };

    const isAddButtonVisible = computed(() => {
      return props.deviceType ===
        deviceTypeDetails[DeviceTypeEnum.HEAT_COST_ALLOCATOR]
        ? props.devices.length < 2
        : props.devices.length < 1;
    });

    const editDevice = (
      device: HeatCostAllocator | SmartRadiatorThermostat
    ) => {
      const installationPoint = entitiesStore.getEntityById(
        device.installationPointId
      );
      formStore.editDevice(installationPoint, props.parentId);
    };

    const openDeleteDeviceConfirmationModal = (
      device: HeatCostAllocator | SmartRadiatorThermostat
    ) => {
      const installationPoint = entitiesStore.getEntityById(
        device.installationPointId
      );
      toBeDeletedInstallationPoint.value = installationPoint;
      isDeleteConfirmationModalOpen.value = true;
    };

    const deleteDevice = () => {
      if (!toBeDeletedInstallationPoint.value) {
        throw new Error('InstallationPoint is undefined');
      }

      const relatedDevices = useDeviceStore().getDevicesByInstallationPointId(
        toBeDeletedInstallationPoint.value.id
      );

      for (const device of relatedDevices) {
        entitiesStore.deleteEntity(device);
      }

      if (isPlaceholderRadiator.value) {
        entitiesStore.deleteEntity(props.radiator);
      }

      entitiesStore.deleteEntity(toBeDeletedInstallationPoint.value);
      closeModal();
    };

    const closeModal = () => {
      isDeleteConfirmationModalOpen.value = false;
    };

    return {
      isDeleteConfirmationModalOpen,
      openDeleteDeviceConfirmationModal,
      editDevice,
      deleteDevice,
      closeModal,
      createDevice,
      isAddButtonVisible,
      promptOpen,
      togglePrompt,
      onIdentifyRadiator,
    };
  },
});
</script>

<style scoped></style>
