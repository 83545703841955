export const wizardStepsGG = Object.freeze({
  DIMENSIONS: 'Maße',
  SPACING: 'Teilung',
  ELEMENT_COUNT: 'Gliederzahl',
  WATER_CHAMBER_WIDTH: 'Wasserkammerbreite',
  COLUMN_COUNT: 'Säulenanzahl',
  COLUMN_SHAPE: 'Säulenform',
  COLUMN_TYPE: 'Säulenart',
  DECORATION: 'Verzierung',
  CONNECTION_TYPE: 'Anschlussart',
  MANUFACTURER: 'Hersteller',
});

export const imagesGG = Object.freeze({
  SPACING: require('@/assets/img/radiators/GG/spacing.svg'),
  WATER_CHAMBER_WIDTH: require('@/assets/img/radiators/GG/waterChamberWidth.svg'),
  DIMENSIONS: require('@/assets/img/radiators/GG/dimensions.svg'),
  COLUMN_COUNT: require('@/assets/img/radiators/GG/columnCount.png'),
  ELEMENT_COUNT: require('@/assets/img/radiators/GG/elementCount.svg'),
});

export const connectionTypesGG = Object.freeze([
  {
    name: 'E',
    subtitle: 'Gleichseitiger Anschluss, mit oberem Vorlauf',
    value: 'E1',
    image: require('@/assets/img/radiators/common/connectionTypes/AA_E1.svg'),
  },
  {
    name: 'E',
    subtitle: 'Untere Anbindung mit Trennscheibe im Vorlauf',
    value: 'E2',
    image: require('@/assets/img/radiators/common/connectionTypes/AA_E2.svg'),
  },
  {
    name: 'E',
    subtitle: 'Obere Vorlaufeinführung in Verbindung mit unterer Anbindung',
    value: 'E3',
    image: require('@/assets/img/radiators/common/connectionTypes/AA_E3.svg'),
  },
  {
    name: 'E',
    subtitle: 'Obere Vorlaufeinführung in Verbindung mit unterer Anbindung',
    value: 'E4',
    image: require('@/assets/img/radiators/common/connectionTypes/AA_E4.svg'),
  },
  {
    name: 'E',
    subtitle:
      'Untere Vorlaufeinführung Achtung! Keine HKV-Montage, siehe Handbuch',
    value: 'E5',
    image: require('@/assets/img/radiators/common/connectionTypes/AA_E5.svg'),
  },
  {
    name: 'W',
    subtitle: 'Vorlauf oben, Rücklauf unten',
    value: 'W1',
    image: require('@/assets/img/radiators/common/connectionTypes/AA_W1.svg'),
  },
  {
    name: 'W',
    subtitle: 'Kurzschlussstrecke (Einrohrsystem)',
    value: 'W2',
    image: require('@/assets/img/radiators/common/connectionTypes/AA_W2.svg'),
  },
  {
    name: 'W',
    subtitle: 'Reihenschaltung von mehreren Heizkörpern',
    value: 'W3',
    image: require('@/assets/img/radiators/common/connectionTypes/AA_W3.svg'),
  },
  {
    name: 'W',
    subtitle:
      'Untere Vorlaufeinführung Achtung! Keine HKV-Montage, siehe Handbuch',
    value: 'W4',
    image: require('@/assets/img/radiators/common/connectionTypes/AA_W4.svg'),
  },
  {
    name: 'R',
    subtitle: 'Beide Anschlüsse unten, links und rechts',
    value: 'R1',
    image: require('@/assets/img/radiators/common/connectionTypes/AA_R1.svg'),
  },
  {
    name: 'R',
    subtitle: 'Kurzschlussstrecke (Einrohrsystem)',
    value: 'R2',
    image: require('@/assets/img/radiators/common/connectionTypes/AA_R2.svg'),
  },
  {
    name: 'R',
    subtitle: 'Reihenschaltung von mehreren Heizkörpern',
    value: 'R3',
    image: require('@/assets/img/radiators/common/connectionTypes/AA_R3.svg'),
  },
  {
    name: 'S',
    subtitle: 'Mit unterer Trennscheibe am Vorlauf oder mittig',
    value: 'S1',
    image: require('@/assets/img/radiators/common/connectionTypes/AA_S1.svg'),
  },
  {
    name: 'S',
    subtitle:
      'Obere Einführung von Vor- und Rücklauf, obere Trennscheibe am Rücklauf',
    value: 'S2',
    image: require('@/assets/img/radiators/common/connectionTypes/AA_S2.svg'),
  },
  {
    name: 'S',
    subtitle: 'Reihenschaltung mit Trennscheibe vorlaufseitig',
    value: 'S3',
    image: require('@/assets/img/radiators/common/connectionTypes/AA_S3.svg'),
  },
  {
    name: 'M',
    subtitle: 'Mittenanschluss mit Ventileinsatz am Vorlaufanschluss',
    value: 'M1',
    image: require('@/assets/img/radiators/common/connectionTypes/AA_M1.svg'),
  },
]);

export const columnShapesGG = Object.freeze([
  {
    name: '1',
    subtitle: 'Symetrische einfache Form',
    value: 1,
    image: require('@/assets/img/radiators/GG/columnShape/SF_1.svg'),
  },
  {
    name: '2',
    subtitle: 'Einfache Form, Rand glatt gekantet',
    value: 2,
    image: require('@/assets/img/radiators/GG/columnShape/SF_2.svg'),
  },
  {
    name: '3',
    subtitle: 'Unsymmetrisch vorne glatt',
    value: 3,
    image: require('@/assets/img/radiators/GG/columnShape/SF_3.svg'),
  },
  {
    name: '4',
    subtitle: 'Asymmetrisch hinten breiter',
    value: 4,
    image: require('@/assets/img/radiators/GG/columnShape/SF_4.svg'),
  },
  {
    name: '5',
    subtitle: 'Randumkantung',
    value: 5,
    image: require('@/assets/img/radiators/GG/columnShape/SF_5.svg'),
  },
]);

export const decorationsGG = Object.freeze([
  {
    name: '0',
    subtitle: 'ohne',
    value: 0,
    image: require('@/assets/img/radiators/GG/decoration/V_0.png'),
  },
  {
    name: '1',
    subtitle: 'teilverziert',
    value: 1,
    image: require('@/assets/img/radiators/GG/decoration/V_1.png'),
  },
  {
    name: '2',
    subtitle: '	vollverziert',
    value: 2,
    image: require('@/assets/img/radiators/GG/decoration/V_2.png'),
  },
]);

export const columnTypesGG = Object.freeze([
  {
    name: '1',
    subtitle: 'alle Säulen mit gleicher Form',
    value: 1,
    image: require('@/assets/img/radiators/GG/columnType/SA_1.svg'),
  },
  {
    name: '2',
    subtitle: 'unterschiedliche Säulenformen',
    value: 2,
    image: require('@/assets/img/radiators/GG/columnType/SA_2.svg'),
  },
]);
