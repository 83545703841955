export enum OverallRadioCheckStatus {
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
  FAILED = 'FAILED',
  TIMED_OUT = 'TIMED_OUT',
  PENDING = 'PENDING',
  CANCELED = 'CANCELED',
}

export enum DeviceRadioCheckStatus {
  NO_MATCHED_GW = 'NO_MATCHED_GW',
  BAD_SIGNAL = 'BAD_SIGNAL',
  OK = 'OK',
  TIMED_OUT = 'TIMED_OUT',
  PENDING = 'PENDING',
}

export enum ReceptionQualityStatus {
  ABOVE_THRESHOLD = 'ABOVE_THRESHOLD',
  INVALID_DATA = 'INVALID_DATA',
  EQUAL_THRESHOLD = 'EQUAL_THRESHOLD',
  TIMED_OUT = 'TIMED_OUT',
  PENDING = 'PENDING',
}

export const RSSI_THRESHOLD = -105;
export const SNR_THRESHOLD = -8;
export const SF_THRESHOLD = 8;
