<template>
  <div class="has-text-weight-bold is-size-5 pb-5">
    Funkcheck wird durchgeführt
  </div>
  <div v-if="isRunning">
    Sobald der Funkcheck abgeschlossen ist werden Sie informiert.
  </div>
  <div v-if="isRetrying">
    Es konnte kein aktuelles Ergebnis gefunden werden.
    <br />
    Der Funkcheck wird erneut durchgeführt.
  </div>
  <div class="is-flex is-justify-content-center mt-5">
    <BaseCountdownTimer
      v-if="isRunning"
      size="is-large"
      :time="getRemainingTime"
    ></BaseCountdownTimer>
  </div>
</template>

<script lang="ts">
import BaseCountdownTimer from '../UI/Timer/BaseCountdownTimer.vue';
import { computed, defineComponent } from 'vue';
import { useFunkcheckStore } from '@/store/funkcheck/funkcheckStore';

export default defineComponent({
  name: 'FunkcheckTimer',
  components: {
    BaseCountdownTimer,
  },
  setup() {
    const funkcheckStore = useFunkcheckStore();

    const isRunning = computed(() => {
      return !!funkcheckStore.activeFunkcheck?.startedAt;
    });

    const isRetrying = computed(() => {
      return (
        isRunning.value &&
        funkcheckStore.activeFunkcheck?.attempt &&
        funkcheckStore.activeFunkcheck.attempt > 0
      );
    });

    const getRemainingTime = computed(() => {
      return funkcheckStore.getRemainingTime();
    });

    return {
      isRetrying,
      isRunning,
      getRemainingTime,
    };
  },
});
</script>
