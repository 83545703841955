<template>
  <tr>
    <td class="pl-2.5"></td>
    <td class="pl-5">{{ gateway.serialNumber }}</td>
    <td>{{ gateway.eui }}</td>
    <td
      class="cursor-pointer pr-3 hover:bg-light-0 hover:dark:bg-dark-3"
      @click="goToRoomGroup"
    >
      <div class="flex justify-between">
        <div class="flex flex-row gap-5">
          <span>
            {{ buildingAddress }}
          </span>
          <span>
            {{ roomGroup?.getLabel() }}
          </span>
          <span>
            {{ room?.getLabel() }}
          </span>
        </div>
        <em class="mdi mdi-arrow-right ml-4 mr-2 text-xl" />
      </div>
    </td>
  </tr>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useInstallationPointStore } from '@/store/entities/installationPointStore';
import { useRoomStore } from '@/store/entities/roomStore';
import { useRoomGroupStore } from '@/store/entities/roomGroupStore';
import { useBuildingStore } from '@/store/entities/buildingStore';
import { LoRaWanGateway } from '@/models/devices';

const router = useRouter();
const props = defineProps<{
  gateway: LoRaWanGateway;
}>();

const installationPoint = computed(() => {
  return useInstallationPointStore().installationPoints.get(
    props.gateway.installationPointId
  );
});
const room = computed(() => {
  if (!installationPoint.value) return undefined;
  return useRoomStore().rooms.get(installationPoint.value.roomId);
});
const roomGroup = computed(() => {
  if (!room.value?.roomGroupId) return undefined;
  return useRoomGroupStore().roomGroups.get(room.value.roomGroupId);
});

const buildingAddress = computed(() => {
  if (!roomGroup.value?.buildingId) return undefined;
  return useBuildingStore().buildings.get(roomGroup.value.buildingId)
    ?.shortAddress;
});

const goToRoomGroup = () => {
  if (!roomGroup.value) return undefined;
  useRoomGroupStore().setActiveMapRoomGroup(roomGroup.value.id);
  router.push('/detailansicht');
};
</script>
