import { radiatorTypes } from '@/enums/radiator/generic';
import { PlaceholderRadiator } from '@/models/radiators/Radiator';
import { generateUniqueId } from '@/helpers/uuidUtils';
import {
  PPRadiator,
  GSRadiator,
  HWRadiator,
  GGRadiator,
  GRRadiator,
  PGRadiator,
  UnknownRadiator,
} from './Radiator';

export {
  PPRadiator,
  GSRadiator,
  HWRadiator,
  GGRadiator,
  GRRadiator,
  PGRadiator,
  UnknownRadiator,
} from './Radiator';

const radiatorTypToClassRelation = {
  [radiatorTypes.PP]: PPRadiator,
  [radiatorTypes.GS]: GSRadiator,
  [radiatorTypes.HW]: HWRadiator,
  [radiatorTypes.GG]: GGRadiator,
  [radiatorTypes.GR]: GRRadiator,
  [radiatorTypes.PG]: PGRadiator,
  [radiatorTypes.UNKNOWN]: UnknownRadiator,
  [radiatorTypes.PlaceholderRadiator]: PlaceholderRadiator,
};

/**
 * Create a new instance for a specific radiator class.
 * @param type radiatorData.radiatorTypes
 * @return {*} new radiator instance
 */
export const createRadiator = function (type = radiatorTypes.UNKNOWN) {
  const RadiatorClass = radiatorTypToClassRelation[type];
  if (!RadiatorClass) {
    throw new Error('No class found for radiator ' + type);
  }
  const newInstance = new RadiatorClass();
  newInstance.id = generateUniqueId();
  return newInstance;
};
