import axios from 'axios';
import { default as envConfig } from '@/config/runtimeConfig';
import { RadioCheckPayload } from '@/api/funkcheck/LoRaWanRadioCheck';

export const singleRadioCheckApi = async function (
  serialNumber: string,
  businessEntityId: string
): Promise<any> {
  return axios({
    method: 'GET',
    url: `${envConfig.backendBaseUrl}/business-entities/${businessEntityId}/lorawan-devices/${serialNumber}/radiocheck`,
  }).catch(function (error) {
    console.error(
      `Single radio check failed by ${serialNumber}: ` + error.response
    );
  });
};

export const radioCheckApi = async function (
  businessEntityId: string,
  payload: RadioCheckPayload
): Promise<any> {
  return axios({
    method: 'POST',
    url: `${envConfig.backendBaseUrl}/business-entities/${businessEntityId}/radiocheck`,
    data: payload,
  }).catch(function (error) {
    console.error(error.response);
  });
};

export const triggerEchoApi = async function (
  businessEntityId: string,
  payload: RadioCheckPayload
): Promise<any> {
  return axios({
    method: 'POST',
    url: `${envConfig.backendBaseUrl}/business-entities/${businessEntityId}/radiocheck/echo`,
    data: payload,
  }).catch(function (error) {
    console.error(error.response);
  });
};
