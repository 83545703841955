<template>
  <form @submit.prevent>
    <fieldset>
      <legend class="title">Login</legend>
      <AuthenticationMessage :user-auth="authenticationMessage" />
      <BaseInput
        label="Benutzername"
        placeholder="Benutzername"
        :validation="v$.user.username"
        test-id="username"
        :model-value="user.username"
        @update:model-value="
          (newValue) => (user.username = newValue.toLowerCase())
        "
      />

      <BaseInput
        v-model="user.password"
        class="mt-5"
        test-id="password"
        label="Passwort"
        placeholder="Passwort"
        type="password"
        :validation="v$.user.password"
      />
    </fieldset>
    <button
      class="button is-primary is-outlined is-inverted mb-5 mt-1 pl-0"
      type="button"
      @click="forgetPassword()"
    >
      Passwort vergessen ?
    </button>
    <br />
    <button
      type="submit"
      data-testid="login-button"
      :class="[
        'button is-black is-login-button is-wide',
        { 'is-loading': isLoading },
      ]"
      :disabled="isSubmitDisabled"
      @click="login()"
    >
      Anmelden
    </button>
  </form>
</template>

<script lang="ts">
import { computed, defineComponent, inject, Ref } from 'vue';
import BaseInput from '@/components/UI/Input/BaseInput.vue';
import AuthenticationMessage from '@/components/Login/AuthenticationMessage.vue';
import { Validation } from '@vuelidate/core';

export default defineComponent({
  name: 'LoginForm',
  components: { BaseInput, AuthenticationMessage },
  emits: ['update:user', 'login', 'forget-password'],
  setup(_props, { emit }) {
    /*
     * Injected properties
     */
    const user = inject('user');
    const authenticationMessage = inject('authenticationMessage');
    const v$ = inject<Ref<Validation>>('v$');
    const isLoading = inject<boolean>('isLoading');

    const login = () => {
      emit('login');
    };

    const forgetPassword = () => {
      emit('forget-password');
    };

    const isSubmitDisabled = computed(() => {
      return v$?.value.$validationGroups?.login.$invalid;
    });

    return {
      v$,
      user,
      login,
      isLoading,
      isSubmitDisabled,
      authenticationMessage,
      forgetPassword,
    };
  },
});
</script>
<style></style>
