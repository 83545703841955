export class Action {
  public label: string;
  public action: (param?: any) => void;
  public icon: string;
  public parameters?: Record<string, unknown>;

  constructor(
    label: string,
    action: (param?: any) => void,
    icon: string,
    parameters = {}
  ) {
    this.label = label;
    this.action = action;
    this.icon = icon;
    this.parameters = parameters;
  }
}
