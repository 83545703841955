import { ScanCodeFormat, ScanResult } from '@/native/plugins/BarcodeScanner';
import { DeviceTypeEnum, AccessoryType } from '@/enums/device';
import { parseDataMatrix } from '@/utils/scanner/dataMatrixParser';
import { parseQrCode } from '@/utils/scanner/QRCodeParser';
import { parseBarcode } from '@/utils/scanner/barcodeParser';

export const parseCode = (
  scanResult: ScanResult,
  deviceType?: AccessoryType | DeviceTypeEnum
) => {
  const scanCodeType = scanResult.format;
  const scanContent = scanResult.content;
  switch (scanCodeType) {
    case ScanCodeFormat.DATA_MATRIX:
      return parseDataMatrix(scanContent, deviceType);
    case ScanCodeFormat.QR_CODE:
      return parseQrCode(scanContent, deviceType);
    case ScanCodeFormat.CODE_39:
    case ScanCodeFormat.CODE_93:
    case ScanCodeFormat.CODE_128:
    case ScanCodeFormat.ITF:
      return parseBarcode(scanContent);
    default:
      console.warn('No parser for barcode type ' + scanCodeType);
      return { unknownProperties: scanContent };
  }
};
