<template>
  <div class="flex flex-col gap-5">
    <div class="text-xl font-bold">Empfangsstärke Mobilfunk</div>
    <div>
      Bitte wählen Sie die Mobilfunkstärke des Tablets aus.
      <br />
      Um fortfahren zu können, muss die Empfangsqualität <br />mindestens 2
      Balken betragen.
    </div>
    <RadioSelector
      v-model:selection="selected"
      :options-list="mobileSignalStrength"
      radio-class="is-3"
    />
  </div>
</template>

<script lang="ts" setup>
import RadioSelector from '@/components/UI/Radio/RadioSelector.vue';
import { mobileSignalStrength } from '@/enums/funkcheck';
import { inject, ref, computed, watch } from 'vue';
import { useNotificationStore } from '@/store/notifications/notificationStore';
import { Notification } from '@/models';

const notificationStore = useNotificationStore();
const validateStep = inject('validateStep') as (valid: boolean) => void;
const selected = ref<number>(0);

const isValid = computed(() => selected.value >= 2 && selected.value <= 4);

watch(selected, () => {
  if (selected.value === 1) {
    notificationStore.addNotification(
      new Notification(
        'Bitte suchen Sie eine Position mit mindestens 2 Balken.',
        'Die Empfangsstärke ist nicht ausreichend!',
        'warning'
      )
    );
  }
  validateStep(isValid.value);
});
</script>

<style scoped></style>
