import { LocalDb } from '@/native/plugins/LocalDb';
import { Entity } from '@/models/types/Entity';
import { useEntitiesStore } from '@/store/entities/entitiesStore';
import { OrderChanges } from '@/models/OrderChanges';
import { Order } from '@/models/Order';

export type LocalStorageFunctions = ReturnType<typeof useLocalStorage>;

export function useLocalStorage() {
  const entitiesStore = useEntitiesStore();

  async function loadLocalBusinessEntity(
    businessEntityId: string
  ): Promise<Record<any, any>> {
    return await LocalDb.loadBusinessEntity({ id: businessEntityId });
  }

  function deleteLocalEntity(entity: Entity) {
    LocalDb.deleteEntity({
      entity,
      businessEntityId: entitiesStore.activeBusinessEntityId,
    });
  }

  function saveBusinessEntityToLocal(businessEntity: string) {
    LocalDb.persistBusinessEntity({
      businessEntity: JSON.parse(JSON.stringify(businessEntity)),
    });
  }

  function saveEntityToLocal(entity: Entity) {
    LocalDb.persistEntity({
      entity,
      businessEntityId: entitiesStore.activeBusinessEntityId,
    });
  }

  async function getActiveOrder(orderId: string) {
    return await LocalDb.getActiveOrder({
      activeId: orderId,
    });
  }

  function clearLocalOrder(businessEntityId: string, activeOrderId: string) {
    LocalDb.deleteBusinessEntity({ id: businessEntityId });
    LocalDb.clearOrder({ activeOrderId: activeOrderId });
  }

  function updateOrderChanges(activeOrderId: string, changes: OrderChanges) {
    LocalDb.updateOrderChanges({
      activeOrderId: activeOrderId,
      orderChanges: changes,
    });
  }

  function setActiveOrder(order: Order) {
    LocalDb.setActiveOrder(order);
  }

  return {
    setActiveOrder,
    updateOrderChanges,
    clearLocalOrder,
    getActiveOrder,
    loadLocalBusinessEntity,
    deleteLocalEntity,
    saveBusinessEntityToLocal,
    saveEntityToLocal,
  };
}
