export const wizardStepsGS = Object.freeze({
  ELEMENT_COUNT: 'Gliederzahl',
  DIMENSIONS: 'Maße',
  SPACING: 'Teilung',
  WATER_CHAMBER_WIDTH: 'Wasserkammerbreite',
  COLUMN_SHAPE: 'Säulenform',
  CONNECTION_TYPE: 'Anschlussart',
  BACK_PLATE: 'Verwendetes Rückteil',
  MANUFACTURER: 'Hersteller',
});

export const wizardStepImagesGS = Object.freeze({
  GS_SPACING_LATERAL: require('@/assets/img/radiators/GG/spacing.svg'),
  GS_SPACING_GENERAL: require('@/assets/img/radiators/GS/spacing/spacing_general.svg'),
  GS_WATER_CHAMBER: require('@/assets/img/radiators/GG/waterChamberWidth.svg'),
  GS_DIMENSIONS: require('@/assets/img/radiators/GS/dimensions.svg'),
  GS_ELEMENT_COUNT: require('@/assets/img/radiators/GS/element_count.svg'),
});

export const connectionTypesGS = Object.freeze([
  {
    name: 'E',
    subtitle: 'Gleichseitiger Anschluss, mit oberem Vorlauf',
    value: 'E1',
    image: require('@/assets/img/radiators/common/connectionTypes/AA_E1.svg'),
  },
  {
    name: 'E',
    subtitle: 'Untere Anbindung mit Trennscheibe im Vorlauf',
    value: 'E2',
    image: require('@/assets/img/radiators/common/connectionTypes/AA_E2.svg'),
  },
  {
    name: 'E',
    subtitle: 'Obere Vorlaufeinführung in Verbindung mit unterer Anbindung',
    value: 'E3',
    image: require('@/assets/img/radiators/common/connectionTypes/AA_E3.svg'),
  },
  {
    name: 'E',
    subtitle: 'Obere Vorlaufeinführung in Verbindung mit unterer Anbindung',
    value: 'E4',
    image: require('@/assets/img/radiators/common/connectionTypes/AA_E4.svg'),
  },
  {
    name: 'E',
    subtitle:
      'Untere Vorlaufeinführung Achtung! Keine HKV-Montage, siehe Handbuch',
    value: 'E5',
    image: require('@/assets/img/radiators/common/connectionTypes/AA_E5.svg'),
  },
  {
    name: 'W',
    subtitle: 'Vorlauf oben, Rücklauf unten',
    value: 'W1',
    image: require('@/assets/img/radiators/common/connectionTypes/AA_W1.svg'),
  },
  {
    name: 'W',
    subtitle: 'Kurzschlussstrecke (Einrohrsystem)',
    value: 'W2',
    image: require('@/assets/img/radiators/common/connectionTypes/AA_W2.svg'),
  },
  {
    name: 'W',
    subtitle: 'Reihenschaltung von mehreren Heizkörpern',
    value: 'W3',
    image: require('@/assets/img/radiators/common/connectionTypes/AA_W3.svg'),
  },
  {
    name: 'W',
    subtitle:
      'Untere Vorlaufeinführung Achtung! Keine HKV-Montage, siehe Handbuch',
    value: 'W4',
    image: require('@/assets/img/radiators/common/connectionTypes/AA_W4.svg'),
  },
  {
    name: 'R',
    subtitle: 'Beide Anschlüsse unten, links und rechts',
    value: 'R1',
    image: require('@/assets/img/radiators/common/connectionTypes/AA_R1.svg'),
  },
  {
    name: 'R',
    subtitle: 'Kurzschlussstrecke (Einrohrsystem)',
    value: 'R2',
    image: require('@/assets/img/radiators/common/connectionTypes/AA_R2.svg'),
  },
  {
    name: 'R',
    subtitle: 'Reihenschaltung von mehreren Heizkörpern',
    value: 'R3',
    image: require('@/assets/img/radiators/common/connectionTypes/AA_R3.svg'),
  },
  {
    name: 'S',
    subtitle: 'Mit unterer Trennscheibe am Vorlauf oder mittig',
    value: 'S1',
    image: require('@/assets/img/radiators/common/connectionTypes/AA_S1.svg'),
  },
  {
    name: 'S',
    subtitle:
      'Obere Einführung von Vor- und Rücklauf, obere Trennscheibe am Rücklauf',
    value: 'S2',
    image: require('@/assets/img/radiators/common/connectionTypes/AA_S2.svg'),
  },
  {
    name: 'S',
    subtitle: 'Reihenschaltung mit Trennscheibe vorlaufseitig',
    value: 'S3',
    image: require('@/assets/img/radiators/common/connectionTypes/AA_S3.svg'),
  },
  {
    name: 'M',
    subtitle: 'Mittenanschluss mit Ventileinsatz am Vorlaufanschluss',
    value: 'M1',
    image: require('@/assets/img/radiators/common/connectionTypes/AA_M1.svg'),
  },
  {
    name: 'L',
    subtitle: 'Lanzenventil',
    value: 'L1',
    image: require('@/assets/img/radiators/common/connectionTypes/AA_L1.svg'),
  },
  {
    name: 'L',
    subtitle: 'Sonderform Lanzenventil',
    value: 'L2',
    image: require('@/assets/img/radiators/common/connectionTypes/AA_L2.svg'),
  },
]);

export const radiatorColumnShapeGS = Object.freeze([
  {
    name: '1',
    subtitle: 'Symetrische einfache Form',
    value: 1,
    image: require('@/assets/img/radiators/GG/columnShape/SF_1.svg'),
  },
  {
    name: '2',
    subtitle: 'Einfache Form, Rand glatt gekantet',
    value: 2,
    image: require('@/assets/img/radiators/GG/columnShape/SF_2.svg'),
  },
  {
    name: '3',
    subtitle: 'Unsymmetrisch vorne glatt',
    value: 3,
    image: require('@/assets/img/radiators/GG/columnShape/SF_3.svg'),
  },
  {
    name: '4',
    subtitle: 'Asymmetrisch hinten breiter',
    value: 4,
    image: require('@/assets/img/radiators/GG/columnShape/SF_4.svg'),
  },
  {
    name: '5',
    subtitle: 'Randumkantung',
    value: 5,
    image: require('@/assets/img/radiators/GG/columnShape/SF_5.svg'),
  },
]);

export const radiatorBackPlatesGS = Object.freeze([
  {
    name: 'PLAN',
    subtitle: 'PLAN',
    value: 'PLAN',
    image: require('@/assets/img/radiators/common/backPlates/RT_01.svg'),
  },
  {
    name: 'PLANAD',
    subtitle: 'PLAN Rohradapter',
    value: 'PLANAD',
    image: require('@/assets/img/radiators/common/backPlates/RT_02.svg'),
  },
  {
    name: 'PLANW',
    subtitle: 'PLAN + Wärmeleitblech',
    value: 'PLANW',
    image: require('@/assets/img/radiators/common/backPlates/RT_03.svg'),
  },
  {
    name: 'PLANWS',
    subtitle: 'PLAN + Wärmeleitblech + Silikon',
    value: 'PLANWS',
    image: require('@/assets/img/radiators/common/backPlates/RT_04.svg'),
  },
  {
    name: 'PROFIL',
    subtitle: 'PROFIL',
    value: 'PROFIL',
    image: require('@/assets/img/radiators/common/backPlates/RT_05.svg'),
  },
  {
    name: 'RSH',
    subtitle: 'RSH',
    value: 'RSH',
    image: require('@/assets/img/radiators/common/backPlates/RT_06.svg'),
  },
  {
    name: 'SSR',
    subtitle: 'SSR',
    value: 'SSR',
    image: require('@/assets/img/radiators/common/backPlates/RT_07.svg'),
  },
  {
    name: 'SSRS',
    subtitle: 'SSR + Silikon',
    value: 'SSRS',
    image: require('@/assets/img/radiators/common/backPlates/RT_08.svg'),
  },
]);
