<template>
  <form @submit.prevent>
    <fieldset class="content">
      <legend class="title">Passwort vergessen?</legend>
      <AuthenticationMessage :user-auth="authenticationMessage" />
      <p>
        Bitte geben Sie Ihren Benutzernamen ein, damit wir Ihnen einen Reset
        Code zum Passwort zurücksetzen schicken können.
      </p>

      <BaseInput
        v-model="user.username"
        class="mt-5"
        label="Benutzername"
        placeholder="Benutzername"
        :validation="v$.user.username"
      />
    </fieldset>

    <div class="action-buttons mt-5">
      <button
        type="button"
        class="button is-black is-inverted"
        @click="goToLoginForm()"
      >
        <em class="fa fa-arrow-left mr-3"></em>
        Zurück zum Login
      </button>
      <button
        type="submit"
        :class="['button is-black is-big', { 'is-loading': isLoading }]"
        :disabled="isSubmitDisabled"
        @click="forgetPassword()"
      >
        Passwort zurücksetzen
      </button>
    </div>
  </form>
</template>

<script lang="ts">
import { computed, defineComponent, inject, Ref } from 'vue';
import BaseInput from '@/components/UI/Input/BaseInput.vue';
import AuthenticationMessage from '@/components/Login/AuthenticationMessage.vue';
import { Validation } from '@vuelidate/core';

export default defineComponent({
  name: 'ForgetPassword',
  components: { BaseInput, AuthenticationMessage },

  emits: ['update:user', 'go-to-login-form', 'send-recovery-email'],
  setup(_props, { emit }) {
    /*
     * Injected properties
     */
    const user = inject('user');
    const authenticationMessage = inject('authenticationMessage');
    const v$ = inject<Ref<Validation>>('v$');
    const isLoading = inject<boolean>('isLoading');

    const goToLoginForm = () => {
      emit('go-to-login-form');
    };

    const forgetPassword = () => {
      emit('send-recovery-email');
    };

    const isSubmitDisabled = computed(() => {
      return v$?.value.$validationGroups?.forgetPassword.$invalid;
    });

    return {
      authenticationMessage,
      v$,
      user,
      isLoading,
      isSubmitDisabled,
      goToLoginForm,
      forgetPassword,
    };
  },
});
</script>

<style>
.action-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
