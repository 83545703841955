<template>
  <div class="flex flex-col gap-4">
    <div
      v-for="(adapter, index) in localInstallationPoint.accessories"
      :key="adapter.id"
    >
      <AdapterDetails
        v-model:adapter="localInstallationPoint.accessories[index]"
        :remove-adapter="removeAdapter"
      />
    </div>
    <PlusButton
      test-id="addAdapter"
      title="Adapter hinzufügen"
      @add-element="addAdapter"
    />
  </div>
</template>
<script setup lang="ts">
import { InstallationPoint } from '@/models/installationPoint/InstallationPoint';
import { Adapter } from '@/models/installationPoint/Accessory';
import AdapterDetails from '@/components/Forms/DeviceFormComponents/AdapterDetails.vue';
import { computed, WritableComputedRef } from 'vue';
import PlusButton from '@/components/UI/Button/PlusButton.vue';

const props = defineProps<{
  installationPoint: InstallationPoint;
}>();

const emit = defineEmits(['update:installationPoint']);

const localInstallationPoint: WritableComputedRef<InstallationPoint> = computed(
  {
    get() {
      return props.installationPoint;
    },
    set(value) {
      emit('update:installationPoint', value);
    },
  }
);

const addAdapter = () => {
  const adapter = new Adapter();
  localInstallationPoint.value.accessories = [
    ...localInstallationPoint.value.accessories,
    adapter,
  ];
};

const removeAdapter = (adapter: Adapter) => {
  const index = localInstallationPoint.value.accessories.findIndex(
    (accessory) => accessory.id === adapter.id
  );

  const accessoriesCopy = [...localInstallationPoint.value.accessories];
  accessoriesCopy.splice(index, 1);

  localInstallationPoint.value.accessories = accessoriesCopy;
};
</script>
