import { entityTypes } from '@/enums/generic';
import { generateUniqueId } from '@/helpers/uuidUtils';
import { radiatorTypes, radiatorFormTypes } from '@/enums/radiator/generic';
type keys = keyof typeof radiatorTypes;

/**
 * Any adjustments to the properties of this class
 * should be reflected in the android kotlin class {@see Radiator.kt}
 */
export class Radiator {
  public id: string;
  public parentRoomId?: string;
  public ordinal: number;
  public type: string; // only for frontend, to retrieve the class from a json
  public manufacturer: string;
  public identificationResult?: RadiatorRating;
  public radiatorType: (typeof radiatorTypes)[keys] | '';
  public note?: string;

  constructor() {
    this.id = generateUniqueId();
    this.parentRoomId = undefined;
    this.radiatorType = '';
    this.ordinal = 1;
    this.type = entityTypes.radiator;
    this.manufacturer = ''; // Hersteller
    this.identificationResult = undefined;
    this.note = '';
  }

  getParentId() {
    return this.parentRoomId;
  }

  getLabel() {
    return 'Heizkörper';
  }

  getRadiatorLabel() {
    return `${radiatorFormTypes[this.radiatorType].name} ${
      this.identificationResult
        ? ': ' + this.identificationResult?.manufacturer
        : ''
    }`;
  }

  getDimensions(
    length: string | number | undefined,
    height: string | number | undefined,
    depth: string | number | undefined
  ) {
    return `${height ? height + 'mm X ' : ''}${length ? length + 'mm X ' : ''}${
      depth ? depth + 'mm' : ''
    }`;
  }

  getIcon() {
    return 'fas fa-angle-double-down';
  }

  isPlaceholderRadiator() {
    return this.radiatorType === radiatorTypes.PlaceholderRadiator;
  }
}

export class PPRadiator extends Radiator {
  public hasCover?: boolean; // Kompaktverkleidung
  public height?: number; // Bauhöhe
  public length?: number; // Baulänge
  public depth: { withCoverPanel?: number; withoutCoverPanel?: number }; // Bautiefe mit/ohne Abschlussblech
  public hasCoverPanel: boolean; // Abschlussblech
  public spacing?: number; // Teilung
  public panelThickness?: number; // Plattendicke
  public lamellaDepth?: number; // Lamellentiefe
  public layers: []; // Lagenaufbau
  public lamellaShape?: number; // LamellenForm
  public lamellaConnection?: number; // Lamellenanschluss
  public connectionType?: string; // Anschlussart
  public profileShape: {
    distributionChannel?: number;
    transition?: number;
  };

  constructor() {
    super();
    this.radiatorType = radiatorTypes.PP;
    this.hasCover = undefined; // Kompaktverkleidung
    this.height = undefined; // Bauhöhe
    this.length = undefined; // Baulänge
    this.depth = { withCoverPanel: undefined, withoutCoverPanel: undefined }; // Bautiefe mit/ohne Abschlussblech
    this.hasCoverPanel = false; // Abschlussblech
    this.spacing = undefined; // Teilung
    this.panelThickness = undefined; // Plattendicke
    this.lamellaDepth = undefined; // Lamellentiefe
    this.layers = []; // Lagenaufbau
    this.lamellaShape = undefined; // LamellenForm
    this.lamellaConnection = undefined; // Lamellenanschluss
    this.connectionType = undefined; // Anschlussart
    this.profileShape = {
      distributionChannel: undefined,
      transition: undefined,
    }; // Profilformen
  }

  getDimensions() {
    return super.getDimensions(
      this.length,
      this.height,
      this.depth.withCoverPanel
        ? this.depth.withCoverPanel
        : this.depth.withoutCoverPanel
    );
  }
}

export class GSRadiator extends Radiator {
  public elementCount?: number; // Gliederzahl
  public height?: number; // Bauhöhe
  public depth?: number; // Bautiefe
  public length?: number; // Baulänge
  public spacing?: number; // Teilung
  public waterChamberWidth?: number; // Wasserkammerbreite
  public columnShape?: number; // Säulenform
  public backPlate?: string; // Rueckteil
  public connectionType?: string; // Anschlussart

  constructor() {
    super();
    this.radiatorType = radiatorTypes.GS;
    this.elementCount = undefined; // Gliederzahl
    this.height = undefined; // Bauhöhe
    this.depth = undefined; // Bautiefe
    this.length = undefined; // Baulänge
    this.spacing = undefined; // Teilung
    this.waterChamberWidth = undefined; // Wasserkammerbreite
    this.columnShape = undefined; // Säulenform
    this.backPlate = undefined; // Rueckteil
    this.connectionType = undefined; // Anschlussart
  }

  getDimensions() {
    return super.getDimensions(this.length, this.height, this.depth);
  }
}

export class HWRadiator extends Radiator {
  public pipeCount?: number; // Rohrzahl
  public height?: number; // Bauhöhe
  public depth?: number; // Bautiefe
  public length?: number; // Baulänge
  public spacing?: number; // Teilung
  public pipeStrength: { min?: number; max?: number }; // Rohrstärke
  public distributionChannelStrength: { min?: number; max?: number }; // Verteilkanalstärke
  public distributionChannelShape?: number; // Form Verteilkanal
  public distributionChannelStructure?: number; // Aufbau Verteilkanal
  public pipeShape?: number; // Form Rohre
  public pipeTopView?: number; // Draufsicht Rohre
  public pipeConnection?: number; // Anbindung Rohre
  public pipeRouting?: number; // Rohrfürhung
  public connectionType?: string; // Anschlussartpublic

  constructor() {
    super();
    this.radiatorType = radiatorTypes.HW;
    this.pipeCount = undefined; // Rohrzahl
    this.height = undefined; // Bauhöhe
    this.depth = undefined; // Bautiefe
    this.length = undefined; // Baulänge
    this.spacing = undefined; // Teilung
    this.pipeStrength = { min: undefined, max: undefined }; // Rohrstärke
    this.distributionChannelStrength = { min: undefined, max: undefined }; // Verteilkanalstärke
    this.distributionChannelShape = undefined; // Form Verteilkanal
    this.distributionChannelStructure = undefined; // Aufbau Verteilkanal
    this.pipeShape = undefined; // Form Rohre
    this.pipeTopView = undefined; // Draufsicht Rohre
    this.pipeConnection = undefined; // Anbindung Rohre
    this.pipeRouting = undefined; // Rohrfürhung
    this.connectionType = undefined; // Anschlussart
  }
  getDimensions() {
    return super.getDimensions(this.length, this.height, this.depth);
  }
}
export class GGRadiator extends Radiator {
  public height?: number; // Bauhöhe
  public depth?: number; // Bautiefe
  public length?: number; // Baulänge
  public spacing?: number; // Teilung
  public elementCount?: number; // Gliederzahl
  public waterChamberWidth?: number; // Wasserkammerbreite
  public columnCount?: number; // Saeulenzahl
  public columnShape?: number; // Säulenform
  public columnType?: number; // Säulenart
  public decoration?: number; // verzierung
  public connectionType?: string; // Anschlussartpublic

  constructor() {
    super();
    this.radiatorType = radiatorTypes.GG;
    this.height = undefined; // Bauhöhe
    this.depth = undefined; // Bautiefe
    this.length = undefined; // Baulänge
    this.spacing = undefined; // Teilung
    this.elementCount = undefined; // Gliederzahl
    this.waterChamberWidth = undefined; // Wasserkammerbreite
    this.columnCount = undefined; // Saeulenzahl
    this.columnShape = undefined; // Säulenform
    this.columnType = undefined; // Säulenart
    this.decoration = undefined; // verzierung
    this.connectionType = undefined; // Anschlussart
  }
  getDimensions() {
    return super.getDimensions(this.length, this.height, this.depth);
  }
}

export class GRRadiator extends Radiator {
  public height?: number; // Bauhöhe
  public depth?: number; // Bautiefe
  public length?: number; // Baulänge
  public spacing?: number; // Teilung
  public pipeStrength: { min?: number; max?: number }; // Rohrstärke
  public typeKey?: number; // Typenschlüssel
  public distributionChannelShape?: number; // Form Verteilkanal
  public pipeShape?: number; // Form Rohre
  public pipeInnerShape?: number; // Rohrinnenform
  public elementCount?: number; // Gliederzahl
  public mountingVariant?: string; // Montagevariante
  public connectionType?: string; // Anschlussart

  constructor() {
    super();
    this.radiatorType = radiatorTypes.GR;
    this.height = undefined; // Bauhöhe
    this.depth = undefined; // Bautiefe
    this.length = undefined; // Baulänge
    this.spacing = undefined; // Teilung
    this.pipeStrength = { min: undefined, max: undefined }; // Rohrstärke
    this.typeKey = undefined; // Typenschlüssel
    this.distributionChannelShape = undefined; // Form Verteilkanal
    this.pipeShape = undefined; // Form Rohre
    this.pipeInnerShape = undefined; // Rohrinnenform
    this.elementCount = undefined; // Gliederzahl
    this.mountingVariant = undefined; // Montagevariante
    this.connectionType = undefined; // Anschlussart
  }
  getDimensions() {
    return super.getDimensions(this.length, this.height, this.depth);
  }
}

export class PGRadiator extends Radiator {
  public length?: number; // Baulänge
  public height?: number; // Bauhöhe
  public depth?: number; // Bautiefe
  public spacing?: number; // Teilung
  public panelThickness?: number; // Plattendicke
  public lamellaDepth?: number; // Lamellentiefe
  public lamellaShape?: number; // Lamellenform
  public lamellaConnection?: number; // anbindung Lamelle
  public layers: string[]; // Lagenaufbau
  public profileShape: {
    distributionChannel?: string;
    transition?: string;
  }; // Profilformen
  public hasCover?: boolean; // Kompaktverkleidung
  public rotated90degrees?: boolean; // 90 grad gedreht
  public gapParameter?: string; // Fugenparameter
  public connectionType?: string; // Anschlussart

  constructor() {
    super();
    this.radiatorType = radiatorTypes.PG;
    this.height = undefined;
    this.depth = undefined;
    this.length = undefined;
    this.spacing = undefined;
    this.panelThickness = undefined;
    this.lamellaDepth = undefined;
    this.lamellaShape = undefined;
    this.lamellaConnection = undefined;
    this.layers = [];
    this.profileShape = {
      distributionChannel: undefined,
      transition: undefined,
    };
    this.hasCover = undefined;
    this.rotated90degrees = undefined;
    this.gapParameter = undefined;
    this.connectionType = undefined;
  }
  getDimensions() {
    return super.getDimensions(this.length, this.height, this.depth);
  }
}

export class UnknownRadiator extends Radiator {
  public length?: number;
  public height?: number;
  public depth?: number;

  constructor() {
    super();
    this.radiatorType = radiatorTypes.UNKNOWN;
    this.length = undefined;
    this.height = undefined;
    this.depth = undefined;
  }
  getDimensions() {
    return super.getDimensions(this.length, this.height, this.depth);
  }
}

export class PlaceholderRadiator extends Radiator {
  constructor() {
    super();
    this.radiatorType = radiatorTypes.PlaceholderRadiator;
  }
}

export type RadiatorClasses =
  | PPRadiator
  | GSRadiator
  | HWRadiator
  | GGRadiator
  | GRRadiator
  | PGRadiator;
