<template>
  <article
    v-if="userAuth.message"
    class="message"
    :class="{
      'is-danger': !userAuth.isSuccessful(),
      'is-success': userAuth.isSuccessful(),
    }"
  >
    <div class="message-body">
      {{ userAuth.message }}
    </div>
  </article>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { UserAuth } from '@/models/auth/UserAuth';

export default defineComponent({
  name: 'AuthenticationMessage',
  props: {
    userAuth: {
      type: Object as () => UserAuth,
      required: true,
    },
  },
});
</script>
