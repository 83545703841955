import { Entity } from '@/models/types/Entity';

import diff, { Difference, DifferenceChange } from 'microdiff';
import { InstallationPoint } from '@/models/installationPoint/InstallationPoint';
import { TransactionDiffType } from '@/models/OrderChanges';

export function generateDiff(oldEntity: Entity, newEntity: Entity) {
  if (
    oldEntity instanceof InstallationPoint &&
    newEntity instanceof InstallationPoint
  ) {
    return getInstallationPointDiff(oldEntity, newEntity);
  }
  return diff(oldEntity, newEntity) as DifferenceChange[];
}

function getInstallationPointDiff(
  oldEntity: InstallationPoint,
  newEntity: InstallationPoint
) {
  const oldAccessories = JSON.parse(JSON.stringify(oldEntity.accessories));
  const newAccessories = JSON.parse(JSON.stringify(newEntity.accessories));

  const oldDeviceEvents = JSON.parse(JSON.stringify(oldEntity.deviceEvents));
  const newDeviceEvents = JSON.parse(JSON.stringify(newEntity.deviceEvents));

  oldEntity.accessories = [];
  oldEntity.deviceEvents = [];

  newEntity.accessories = [];
  newEntity.deviceEvents = [];

  return [
    ...diffArraysById(oldAccessories, newAccessories, 'accessories'),
    ...diffArraysById(oldDeviceEvents, newDeviceEvents, 'deviceEvents'),
    ...diff(oldEntity, newEntity),
  ];
}

function diffArraysById(
  oldArray: Array<Record<string, string>>,
  newArray: Array<Record<string, string>>,
  key = 'accessories'
) {
  const oldArrayMap = new Map(oldArray.map((item) => [item.id, item]));
  const newArrayMap = new Map(newArray.map((item) => [item.id, item]));

  const diffs: Difference[] = [];

  oldArrayMap.forEach((oldObj, id) => {
    const newObject = newArrayMap.get(id);
    if (!newObject) {
      diffs.push({
        path: [key, id],
        type: TransactionDiffType.REMOVE,
        oldValue: oldObj,
      });
    }
  });

  newArrayMap.forEach((newObj, id) => {
    const oldObj = oldArrayMap.get(id);
    if (!oldObj) {
      diffs.push({
        path: [key, id],
        type: TransactionDiffType.CREATE,
        value: newObj,
      });
    } else {
      const diffResult = diff(oldObj, newObj);
      const oldValue: Record<string, any> = {};
      const newValue: Record<string, any> = {};

      diffResult.forEach((diffItem) => {
        const key = diffItem.path[0];
        if ('oldValue' in diffItem && diffItem.oldValue) {
          oldValue[key] = diffItem.oldValue;
        }
        if ('value' in diffItem && diffItem.value) {
          newValue[key] = diffItem.value;
        }
      });

      if (diffResult.length) {
        diffs.push({
          path: [key, id],
          type: TransactionDiffType.CHANGE,
          value: newValue,
          oldValue: oldValue,
        });
      }
    }
  });

  return diffs;
}
