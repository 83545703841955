<template>
  <div
    ref="scrollPositionRef"
    class="grow-1 flex gap-5 overflow-x-auto py-2"
    @mousedown="onMouseDown"
    @mouseup="onMouseUp"
  >
    <div
      v-for="building in buildings"
      :key="building.id"
      data-testid="buildingSelectorButton"
    >
      <BuildingCard
        :building="building"
        :is-active-building="isActiveBuilding(building)"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { onMounted, onUnmounted, provide, ref } from 'vue';
import BuildingCard from '@/components/BuildingOverview/BuildingCard.vue';
import { useBuildingCard } from '@/composables/useBuildingCard';
import { Building } from '@/models';

const props = defineProps<{
  buildings: [Building];
}>();

const { isActiveBuilding } = useBuildingCard();

const scrollPositionRef = ref(null);
provide('scrollPositionRef', scrollPositionRef);

onMounted(() => {
  window.addEventListener('mouseup', onMouseUp);
});

onUnmounted(() => {
  window.removeEventListener('mouseup', onMouseUp);
});

const cursorPos = ref([0, 0]);
const isDragging = ref(false);
provide('isDragging', isDragging);
const onMouseDown = (ev: MouseEvent) => {
  ev.preventDefault();
  if (ev.buttons === 1) {
    cursorPos.value = [ev.pageX, ev.pageY];
    window.addEventListener('mousemove', onMouseHold);
  }
};

const onMouseUp = () => {
  window.removeEventListener('mousemove', onMouseHold);
  setTimeout(() => {
    isDragging.value = false;
  }, 100);
};

const onMouseHold = (ev: MouseEvent) => {
  isDragging.value = true;
  requestAnimationFrame(() => {
    const delta = [
      ev.pageX - cursorPos.value[0],
      ev.pageY - cursorPos.value[1],
    ];

    cursorPos.value = [ev.pageX, ev.pageY];

    if (!scrollPositionRef.value) return;
    (scrollPositionRef.value as any).scrollBy({
      left: -delta[0],
      top: -delta[1],
    });
  });
};
</script>
