<template>
  <tr>
    <td></td>
    <td v-if="eui"><em />{{ eui }}</td>
    <td v-if="!eui"></td>
    <td>
      <em
        class="mr-3 px-1"
        :class="['mdi', statusIcon, statusTextColor, 'text-2xl']"
        data-testid="statusReception"
      />
      {{ receiveDate }}
    </td>
    <td>
      <em class="mdi text-[1.2em]" :class="receptionDirectionIcon"></em>
    </td>
    <td
      :class="{
        'text-light-error dark:text-dark-error':
          receptionQuality?.rssi < RSSI_THRESHOLD,
      }"
    >
      {{ receptionQuality?.rssi ? receptionQuality?.rssi : '-' }}
    </td>
    <td
      :class="{
        'text-light-error dark:text-dark-error':
          receptionQuality?.snr < SNR_THRESHOLD,
      }"
    >
      {{ receptionQuality?.snr ? receptionQuality?.snr : '-' }}
    </td>
    <td
      :class="{
        'text-light-error dark:text-dark-error':
          (receptionQuality as UplinkReceptionQuality)?.spreadingFactor < SF_THRESHOLD,
      }"
    >
      {{
        (receptionQuality as UplinkReceptionQuality)?.spreadingFactor
          ? (receptionQuality as UplinkReceptionQuality)?.spreadingFactor
          : '-'
      }}
    </td>
    <td></td>
  </tr>
</template>

<script setup lang="ts">
import {
  ReceptionQualityStatus,
  RSSI_THRESHOLD,
  SF_THRESHOLD,
  SNR_THRESHOLD,
} from '@/enums/radiocheck';
import { SRTTableElementType } from '@/components/LoRaWanRadioCheck/SRTTable.vue';
import {
  DownlinkReceptionQuality,
  UplinkReceptionQuality,
} from '@/api/funkcheck/LoRaWanRadioCheck';
import { computed, toRef } from 'vue';
import { useRadioCheckStatus } from '@/composables/useRadioCheckStatus';

interface Props {
  receptionQuality: UplinkReceptionQuality | DownlinkReceptionQuality;
  receiveDate: string | undefined;
  direction: string;
  eui?: string;
}

const props = withDefaults(defineProps<Props>(), {
  eui: '-',
});

const receptionDirectionIcon = computed(() => {
  if (props.direction === 'uplink') {
    return ' mdi-arrow-up';
  }
  if (props.direction === 'downlink') {
    return 'mdi-arrow-down';
  }
  return '';
});

const deviceStatus = computed(() => {
  return props.receptionQuality?.status;
});
const { statusIcon, statusTextColor } = useRadioCheckStatus(
  toRef(deviceStatus)
);
</script>
